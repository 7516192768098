<script>
  import FontPicker from "$components/FontPicker.svelte";

  export let page;

  const layoutOptions = [
    { value: "list", label: "List" },
    { value: "grid", label: "Grid" },
  ];

  let showGridBuilder = false;

  function handleLayoutChange(layoutStyle) {
    page.layout_style = layoutStyle;
  }

  function handleFontChange(font) {
    page.font = font;
  }
</script>

<div class="mb-8 bg-white rounded-lg shadow-md p-6">
  <h2 class="text-lg font-semibold mb-8">Layout</h2>

  <div class="space-y-4">
    <label for="layout-select" class="block text-sm font-medium text-gray-700">
      Choose layout style
    </label>
    <div class="flex items-center space-x-4">
      <div class="w-full flex space-x-2">
        {#each layoutOptions as option}
          <button
            class="px-4 py-2 text-sm font-medium rounded-md transition-colors duration-150 ease-in-out {page.layout_style ===
            option.value
              ? 'bg-purple-100 text-purple-700 border border-purple-300'
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}"
            on:click={() => handleLayoutChange(option.value)}
          >
            {option.label}
          </button>
        {/each}
      </div>
    </div>
    <div class="mt-4 p-4 bg-gray-50 rounded-md">
      <p class="text-sm text-gray-600">
        {#if page.layout_style === "list"}
          The list layout will display your links in a vertical list.
        {:else if page.layout_style === "grid"}
          The grid layout will display your links in a mosaic grid pattern.
        {/if}
      </p>
    </div>
  </div>
</div>

<div class="mb-8 bg-white rounded-lg shadow-md p-6">
  <h2 class="text-lg font-semibold mb-8">Fonts</h2>

  <div class="space-y-4">
    <label for="font-select" class="block text-sm font-medium text-gray-700">
      Choose font
    </label>
    <div class="w-full">
      <FontPicker
        initialSelectedFont={page.font}
        on:font-change={(event) => handleFontChange(event.detail)}
      />
    </div>
  </div>
</div>
