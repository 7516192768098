<script>
  import { onMount } from "svelte";
  import { getThemeFromId } from "$utils/themes";
  import socialLinks from "$utils/social_links.js";

  export let page;
  export let socialLinkClicked;

  let containerWidth;
  let containerRef;
  let theme = getThemeFromId(page).theme;

  $: validLinks = page.social_links.filter((link) => link.url);

  function getSocialLinkInfo(url) {
    const lowerUrl = url.toLowerCase();

    for (const [platform, info] of Object.entries(socialLinks)) {
      if (lowerUrl.includes(info.baseUrl)) {
        return { icon: info.icon, platform };
      }
    }
    return { icon: "fas fa-globe", platform: "other" };
  }

  function calculateSpacing() {
    if (!containerRef) return;
    const iconCount = validLinks.length;
    const iconWidth = 32; // Assuming each icon is roughly 32px wide
    const minSpacing = 16; // Minimum spacing between icons
    const maxSpacing = 24; // Maximum spacing between icons
    const availableWidth = containerWidth - iconCount * iconWidth;
    const spacing = Math.max(
      minSpacing,
      Math.min(maxSpacing, availableWidth / (iconCount + 1))
    );

    containerRef.style.setProperty("--icon-spacing", `${spacing}px`);
  }

  $: if (validLinks) {
    calculateSpacing();
  }

  onMount(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        containerWidth = entry.contentRect.width;
        calculateSpacing();
      }
    });

    if (containerRef) {
      resizeObserver.observe(containerRef);
    }

    return () => {
      if (containerRef) {
        resizeObserver.unobserve(containerRef);
      }
    };
  });
</script>

{#if validLinks.length > 0}
  <div
    bind:this={containerRef}
    class="social-icons-container my-6 opacity-[0.9]"
  >
    {#each validLinks as link}
      {@const linkInfo = getSocialLinkInfo(link.url)}
      <a
        href={link.url}
        target="_blank"
        rel="noopener noreferrer"
        class="social-icon-link inline-block text-xl transition-transform duration-100 ease-in-out hover:scale-110"
        style="--socialIconColor: {theme.socialIconColor || theme.textColor}"
        on:click={() =>
          socialLinkClicked({
            platform: linkInfo.platform,
            url: link.url,
            socialLinkId: link.id,
          })}
      >
        <i class="{linkInfo.icon} fa-fw"></i>
        <span class="tooltip">{link.url}</span>
      </a>
    {/each}
  </div>
{/if}

<style>
  .social-icons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--icon-spacing, 24px);
  }

  .social-icon-link {
    color: var(--textColor);
    position: relative;
  }

  .tooltip {
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 0px 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 0.75rem;
    white-space: nowrap;
  }

  .social-icon-link:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
</style>
