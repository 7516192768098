<script>
  import Alert from "$components/Alert.svelte";
  import Ajax from "$utils/ajax";
  import blockTypes from "../blocks/_blocks.js";

  export let block;
  export let updater;

  let showDeleteAlert = false;
  let BlockComponent = blockTypes[block.block_type]?.component;
  let blockLabel = blockTypes[block.block_type]?.label || "Unknown";

  const openDeleteAlert = () => {
    showDeleteAlert = true;
  };

  const closeDeleteAlert = () => {
    showDeleteAlert = false;
  };

  const handleDelete = async () => {
    let [errors, _resp] = await Ajax.delete(`/dashboard/blocks/${block.id}`);

    if (errors) {
      notify({
        type: "error",
        message: "Failed to delete block. We will look into this.",
      });
    } else {
      updater(({ blocks }) => {
        return { blocks: blocks.filter((b) => b.id !== block.id) };
      });

      closeDeleteAlert();
    }
  };
</script>

{#if BlockComponent}
  <div id={`block-${block.id}`} class="relative">
    <div class="absolute top-0 left-0 flex items-center">
      <span
        class="bg-gray-400 opacity-70 text-white text-[9px] px-1 py-0.5 rounded-br-md shadow-sm tracking-wide uppercase transform -translate-y-1 -translate-x-1"
      >
        {blockLabel}
      </span>
    </div>

    <svelte:component
      this={BlockComponent}
      {block}
      {updater}
      on:delete-block={openDeleteAlert}
    />

    {#if !block.enabled}
      <div
        class="md:hidden absolute top-0 left-0 right-0 bg-red-50 text-red-600 bg-opacity-100 text-center py-0 text-[10px] font-medium tracking-wide uppercase"
      >
        Disabled
      </div>
    {:else if block.hidden}
      <div
        class="md:hidden absolute top-0 left-0 right-0 bg-orange-50 text-orange-600 bg-opacity-100 text-center py-0 text-[10px] font-medium tracking-wide uppercase"
      >
        Currently hidden (schedule)
      </div>
    {/if}
  </div>

  <Alert
    bind:isOpen={showDeleteAlert}
    title="Delete Block"
    message="Are you sure you want to delete this block? This action cannot be undone."
    type="warning"
    showCloseButton={true}
    primaryAction="Delete"
    secondaryAction="Cancel"
    on:close={closeDeleteAlert}
    on:primaryAction={handleDelete}
    on:secondaryAction={closeDeleteAlert}
  />
{/if}
