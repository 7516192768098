<script>
  import Page from "$app-components/blocks/_layouts/Page.svelte";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;
  export let blockClicked = function () {};
  export let blockViewed = function () {};
  export let onActualPage;

  function getSpotifyEmbedUrl(url) {
    const match = url.match(
      /(?:spotify:(?:track|album|playlist|artist|user):([a-zA-Z0-9]+)|(?:https?:\/\/)?(?:open\.)?spotify\.com\/(?:track|album|playlist|artist|user)\/([a-zA-Z0-9]+))/
    );
    if (match) {
      const id = match[1] || match[2];
      const type = url.includes("track")
        ? "track"
        : url.includes("album")
          ? "album"
          : url.includes("playlist")
            ? "playlist"
            : url.includes("user")
              ? "user"
              : "artist";
      if (type === "user") {
        return null;
      }
      return `https://open.spotify.com/embed/${type}/${id}`;
    }
    return null;
  }

  $: spotifyEmbedUrl = getSpotifyEmbedUrl(block.url);
  $: directRedirect = !spotifyEmbedUrl;
</script>

<Page
  {block}
  {theme}
  {buttonStyle}
  {hoverEffect}
  {blockClicked}
  {blockViewed}
  iframeUrl={spotifyEmbedUrl}
  goToButtonText="Open in Spotify"
  width="640px"
  height="640px"
  {directRedirect}
  {onActualPage}
/>
