<script>
  import { fade, fly, scale } from "svelte/transition";

  let visible = false;
  let sectionEl;

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          visible = true;
          observer.unobserve(entry.target);
        }
      });
    },
    {
      rootMargin: "50px",
    }
  );

  function initIntersectionObserver(node) {
    observer.observe(node);
    return {
      destroy() {
        observer.unobserve(node);
      },
    };
  }
</script>

<section>
  <div
    class="max-w-3xl mx-auto px-4 sm:px-6"
    use:initIntersectionObserver
    bind:this={sectionEl}
  >
    {#if visible}
      <div
        class="bg-white rounded-2xl overflow-hidden"
        in:fly={{ y: 50, duration: 200 }}
      >
        <!-- Step 1: Copy Code -->
        <div
          class="p-4 sm:p-6 border-b border-gray-100"
          in:fade={{ delay: 100, duration: 300 }}
        >
          <h3
            class="text-sm sm:text-base font-medium text-purple-900 mb-3 sm:mb-4"
          >
            Step 1:&nbsp;&nbsp;Copy the embed code
          </h3>
          <div
            class="bg-gray-50 rounded-lg p-3 sm:p-4 hover:bg-gray-100 transition-colors duration-200 overflow-x-auto"
          >
            <pre
              class="text-xs sm:text-sm text-gray-600 whitespace-pre-wrap break-all sm:break-normal"><code
                >&lt;iframe src="https://bio.ink/yourpage" style="border:none; width:100%; height:600px;"&gt;&lt;/iframe&gt;</code
              ></pre>
          </div>
        </div>

        <!-- Step 2: Preview -->
        <div class="p-4 sm:p-6" in:fade={{ delay: 200, duration: 300 }}>
          <h3
            class="text-sm sm:text-base font-medium text-purple-900 mb-3 sm:mb-4"
          >
            Step 2:&nbsp;&nbsp;Your BioInk page will appear like this
          </h3>
          <div
            class="border-2 border-dashed border-gray-200 rounded-lg hover:border-purple-300 transition-colors duration-300"
          >
            <div
              class="bg-gradient-to-br from-purple-50 to-indigo-50 flex items-center justify-center p-3 sm:p-4"
            >
              <div
                class="w-full bg-white rounded shadow p-2 sm:p-3 hover:shadow-lg transition-shadow duration-300"
                in:scale={{ delay: 200, duration: 300 }}
              >
                <div
                  class="w-8 h-8 sm:w-12 sm:h-12 bg-purple-100 rounded-full mx-auto mb-2 hover:bg-purple-200 transition-colors duration-200"
                ></div>
                <div class="space-y-1 sm:space-y-1.5">
                  <div
                    class="h-1.5 sm:h-2 bg-gray-100 rounded w-2/3 mx-auto hover:bg-gray-200 transition-colors duration-200"
                  ></div>
                  <div
                    class="h-4 sm:h-6 bg-gray-100 rounded w-full !mt-2 sm:!mt-3 hover:bg-gray-200 transition-colors duration-200"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    {/if}
  </div>
</section>
