<script>
  import { onMount } from "svelte";
  import { spring } from "svelte/motion";
  import { fly } from "svelte/transition";

  let currentMetric = 0;
  const metrics = [
    { label: "Total Views", value: "124,582", change: "+12.4%" },
    { label: "Click Rate", value: "8.2%", change: "+2.1%" },
    { label: "Active Users", value: "1,284", change: "+8.7%" },
  ];

  let viewCount = spring(0);
  let chartValues = spring([20, 45, 32, 67, 89, 54, 76]);
  let isVisible = false;
  let transitioning = false;

  // Simulated real-time updates
  onMount(() => {
    isVisible = true;

    const interval = setInterval(() => {
      // Start transition
      transitioning = true;
      setTimeout(() => {
        currentMetric = (currentMetric + 1) % metrics.length;
        transitioning = false;
      }, 300);

      // Animate chart values
      chartValues.set([
        Math.floor(Math.random() * 50) + 30,
        Math.floor(Math.random() * 50) + 30,
        Math.floor(Math.random() * 50) + 30,
        Math.floor(Math.random() * 50) + 30,
        Math.floor(Math.random() * 50) + 30,
        Math.floor(Math.random() * 50) + 30,
        Math.floor(Math.random() * 50) + 30,
      ]);

      // Increment view counter
      viewCount.set($viewCount + Math.floor(Math.random() * 5));
    }, 3000);

    return () => clearInterval(interval);
  });
</script>

<div class="bg-white rounded-2xl p-8 shadow-lg">
  <!-- Analytics Dashboard -->
  <div class="space-y-8">
    <!-- Main Metric Card -->
    {#if isVisible}
      <div
        class="bg-gradient-to-br from-purple-500 to-indigo-600 rounded-xl p-6 text-white"
        in:fly={{ y: 20, duration: 800 }}
      >
        <div class="flex justify-between items-start">
          <div class="metric-transition {transitioning ? 'metric-exit' : ''}">
            <h3 class="text-sm font-medium opacity-80">
              {metrics[currentMetric].label}
            </h3>
            <p class="text-3xl font-bold mt-1">
              {metrics[currentMetric].value}
            </p>
          </div>
          <span
            class="text-emerald-300 bg-emerald-900/20 px-2 py-1 rounded text-sm metric-transition {transitioning
              ? 'metric-exit'
              : ''}"
          >
            {metrics[currentMetric].change}
          </span>
        </div>
      </div>
    {/if}

    <!-- Real-time Chart -->
    <div class="h-48 flex items-end justify-between space-x-2">
      {#each $chartValues as value}
        <div
          class="w-full bg-purple-100 rounded-t transition-all duration-1000 ease-in-out"
          style="height: {value}%"
        >
          <div
            class="w-full bg-purple-500/20 h-full rounded-t"
            style="clip-path: url(#wave)"
          ></div>
        </div>
      {/each}
    </div>

    <!-- Live Counter -->
    <div class="flex items-center space-x-3">
      <div class="w-2 h-2 bg-emerald-500 rounded-full animate-pulse"></div>
      <p class="text-purple-600">
        <span class="font-semibold">{Math.floor($viewCount)}</span> people viewing
        now
      </p>
    </div>
  </div>
</div>

<!-- Wave SVG Filter -->
<svg class="hidden">
  <defs>
    <clipPath id="wave" clipPathUnits="objectBoundingBox">
      <path d="M0,1 L0,0.3 C0.33,0.5 0.66,0.1 1,0.3 L1,1"></path>
    </clipPath>
  </defs>
</svg>

<style>
  .animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
  }

  .metric-transition {
    transition: all 0.3s ease-in-out;
  }

  .metric-exit {
    opacity: 0;
    transform: translateY(-10px);
  }

  .metric-enter {
    opacity: 0;
    transform: translateY(10px);
  }
</style>
