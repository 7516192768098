<script>
  import { fade } from "svelte/transition";

  export let username;
  export let usernameError;
  export let nextStep;

  function handleKeydown(event) {
    if (event.key === "Enter") {
      event.target.blur();
      nextStep();
    }
  }
</script>

<div
  in:fade
  class="bg-gradient-to-r from-purple-50 to-indigo-50 p-4 sm:p-8 rounded-lg shadow-md"
>
  <p class="text-lg sm:text-xl text-gray-700 mb-6 sm:mb-8 text-center">
    We've imported your Linktree username. Feel free to customize it below.
  </p>
  <div
    class="flex flex-col sm:flex-row items-center justify-center bg-white p-3 sm:p-4 rounded-lg shadow-sm space-y-3 sm:space-y-0"
  >
    <span
      class="text-purple-600 font-semibold sm:mr-2 text-base sm:text-lg shrink-0"
      >https://bio.ink/</span
    >
    <input
      type="text"
      bind:value={username}
      on:keyup={() => (usernameError = "")}
      on:keydown={handleKeydown}
      class="w-full sm:flex-grow p-3 sm:p-4 border-2 border-purple-300 rounded-lg focus:ring-4 focus:ring-purple-400 focus:border-transparent text-lg sm:text-xl transition-all duration-300 ease-in-out"
      placeholder="Your unique username"
    />
  </div>
  {#if usernameError}
    <p
      class="text-red-500 text-sm mt-4 sm:mt-6 mb-4 sm:mb-6 text-center font-medium"
    >
      {usernameError}
    </p>
  {/if}
  <p class="text-sm text-gray-600 mt-3 sm:mt-4 text-center">
    This will be your unique BioInk URL.
  </p>
</div>
