<script>
  import Dashboard from "$layouts/Dashboard.svelte";
  import { onMount } from "svelte";
  import { notify } from "$utils/notify";

  export let siteUrl;
  export let page;
  export let permissions;
  export let currentUser;

  let showHeader = true;
  let showSocialLinks = true;
  let showChat = true;
  let embedCode = "";
  let username = page.username;

  onMount(async () => {
    updateEmbedCode();
  });

  function updateEmbedCode() {
    const baseUrl = siteUrl;
    const params = new URLSearchParams();
    if (!showHeader) params.append("header", "false");
    if (!showSocialLinks) params.append("social_links", "false");
    if (!showChat) params.append("chat", "false");
    const queryString = params.toString() ? `?${params.toString()}` : "";

    embedCode = `<iframe src="${baseUrl}/${username}${queryString}" width="100%" height="600" frameborder="0"></iframe>`;
  }

  function copyEmbedCode() {
    navigator.clipboard.writeText(embedCode);
    notify({
      message: "Embed code copied to clipboard!",
      type: "success",
    });
  }
</script>

<Dashboard {currentUser} {permissions} {page}>
  <div class="bg-white shadow-lg rounded-xl p-4 sm:p-8">
    <h1
      class="text-2xl font-extrabold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-indigo-600"
    >
      Embed on your website
    </h1>
    <p class="text-gray-700 text-base leading-relaxed mb-8">
      Seamlessly integrate your Bio.ink page into your website. Customize the
      appearance and copy the generated code to your website.
    </p>

    <div class="mb-10">
      <h2 class="text-xl font-bold mb-4 text-gray-800">
        Customization Options
      </h2>
      <div class="flex flex-wrap gap-4">
        <label
          class="group flex items-center space-x-2 cursor-pointer bg-gradient-to-r from-purple-50 to-indigo-50 p-3 rounded-lg shadow-md transition-all duration-300 hover:shadow-lg"
        >
          <input
            type="checkbox"
            bind:checked={showHeader}
            on:change={updateEmbedCode}
            class="form-checkbox h-5 w-5 text-purple-600 rounded-md transition duration-150 ease-in-out"
          />
          <span
            class="text-sm font-medium text-gray-800 group-hover:text-purple-600 transition-colors duration-300"
          >
            Show Header
          </span>
        </label>

        <label
          class="group flex items-center space-x-2 cursor-pointer bg-gradient-to-r from-purple-50 to-indigo-50 p-3 rounded-lg shadow-md transition-all duration-300 hover:shadow-lg"
        >
          <input
            type="checkbox"
            bind:checked={showSocialLinks}
            on:change={updateEmbedCode}
            class="form-checkbox h-5 w-5 text-purple-600 rounded-md transition duration-150 ease-in-out"
          />
          <span
            class="text-sm font-medium text-gray-800 group-hover:text-purple-600 transition-colors duration-300"
          >
            Show social links
          </span>
        </label>

        <label
          class="group flex items-center space-x-2 cursor-pointer bg-gradient-to-r from-purple-50 to-indigo-50 p-3 rounded-lg shadow-md transition-all duration-300 hover:shadow-lg"
        >
          <input
            type="checkbox"
            bind:checked={showChat}
            on:change={updateEmbedCode}
            class="form-checkbox h-5 w-5 text-purple-600 rounded-md transition duration-150 ease-in-out"
          />
          <span
            class="text-sm font-medium text-gray-800 group-hover:text-purple-600 transition-colors duration-300"
          >
            Show chat
          </span>
        </label>
      </div>
    </div>

    <div class="mb-10">
      <h2 class="text-xl font-bold mb-4 text-gray-800">Embed Code</h2>
      <div class="relative">
        <textarea
          class="w-full h-20 p-3 border-2 border-purple-200 rounded-lg focus:ring-4 focus:ring-purple-300 focus:border-purple-500 transition duration-300 ease-in-out bg-gray-50 font-mono text-sm resize-none"
          readonly
          value={embedCode}
        ></textarea>
        <button
          on:click={copyEmbedCode}
          class="absolute top-2 right-2 bg-gradient-to-r from-purple-500 to-indigo-500 text-white px-4 py-1 text-xs font-medium rounded-md hover:from-purple-600 hover:to-indigo-600 transition duration-300 ease-in-out focus:outline-none focus:ring-4 focus:ring-purple-300 shadow-md hover:shadow-lg"
        >
          Copy Code
        </button>
      </div>
    </div>

    <div>
      <h2 class="text-xl font-bold mb-4 text-gray-800">Live Preview</h2>
      <div class="rounded-lg overflow-hidden shadow-xl">
        {@html embedCode}
      </div>
    </div>
  </div>
</Dashboard>
