<script>
  import Modal from "$components/Modal.svelte";
  import Ajax from "$utils/ajax";
  import { notify } from "$utils/notify";

  export let block;
  export let updater;

  let isModalOpen = false;
  let selectedAnimation = block.prioritize_effect || null;
  let isSaving = false;

  const excludedTypes = [];

  const animations = [
    { id: null, label: "None", class: "" },
    { id: "buzz", label: "Buzz", class: "prioritize-animate-buzz" },
    { id: "wobble", label: "Wobble", class: "prioritize-animate-wobble" },
    { id: "pop", label: "Pop", class: "prioritize-animate-pop" },
    { id: "pulse", label: "Pulse", class: "prioritize-animate-pulse" },
    { id: "bounce", label: "Bounce", class: "prioritize-animate-bounce" },
    { id: "flash", label: "Flash", class: "prioritize-animate-flash" },
    { id: "swing", label: "Swing", class: "prioritize-animate-swing" },
    { id: "glow", label: "Glow", class: "prioritize-animate-glow" },
  ];

  function handleAnimationChange(animation) {
    selectedAnimation = animation;
  }

  function closeModal() {
    isModalOpen = false;
  }

  async function saveAndClose() {
    isSaving = true;

    const [error] = await Ajax.put(
      `/dashboard/blocks/${block.id}/update_prioritize_effect`,
      {
        prioritize_effect: selectedAnimation,
      }
    );

    if (error) {
      notify({
        type: "error",
        message: error?.data?.[0] || "An error occurred. Please try again.",
      });
    } else {
      block.prioritize_effect = selectedAnimation;

      updater(({ blocks }) => {
        return { blocks: blocks.map((b) => (b.id === block.id ? block : b)) };
      });

      closeModal();
    }

    isSaving = false;
  }
</script>

<Modal bind:isOpen={isModalOpen} size="md">
  <div slot="header">
    <h3 class="text-md sm:text-xl font-bold text-gray-900">Prioritize Link</h3>
  </div>

  <div class="mt-6">
    <div class="bg-purple-50 rounded-lg p-4 mb-8 border border-purple-100">
      <h4 class="text-purple-800 font-medium mb-2">Why Prioritize?</h4>
      <p class="text-sm text-purple-700">
        Make important links stand out by adding eye-catching animations. This
        helps guide your visitors' attention to key content.
      </p>
    </div>

    <div class="grid grid-cols-2 sm:grid-cols-3 gap-3">
      {#each animations as animation}
        <button
          class="relative p-4 rounded-lg border-2 transition-all duration-200 hover:shadow-md text-center
            {selectedAnimation === animation.id
            ? 'border-purple-500 bg-purple-50'
            : 'border-gray-200 hover:border-purple-300'}"
          on:click={() => handleAnimationChange(animation.id)}
        >
          <span
            class="inline-block text-lg font-medium text-gray-900 {animation.id ===
            selectedAnimation
              ? animation.class
              : ''}"
          >
            {animation.label}
          </span>

          {#if selectedAnimation === animation.id}
            <div class="absolute -right-2 -top-2">
              <span
                class="flex h-6 w-6 items-center justify-center rounded-full bg-purple-500 text-white"
              >
                <svg class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </div>
          {/if}
        </button>
      {/each}
    </div>
  </div>

  <div class="mt-8 flex justify-end space-x-3 border-t pt-6">
    <button
      type="button"
      class="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none"
      on:click={closeModal}
      disabled={isSaving}
    >
      Cancel
    </button>
    <button
      type="button"
      class="inline-flex justify-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none disabled:opacity-50"
      on:click={saveAndClose}
      disabled={isSaving}
    >
      {#if isSaving}
        <svg
          class="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        Saving...
      {:else}
        Save
      {/if}
    </button>
  </div>
</Modal>

{#if excludedTypes.includes(block.block_type) == false}
  <button
    on:click={() => (isModalOpen = true)}
    class="{block.prioritize_effect
      ? 'text-purple-500'
      : 'text-gray-400'} hover:text-purple-500 transition-colors duration-200 ease-in-out relative group mr-4"
    title="Prioritize"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-4 w-4"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <polygon
        points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"
      />
    </svg>
    <span
      class="absolute bottom-[140%] left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
    >
      Prioritize
    </span>
  </button>
{/if}
