<script>
  import { onMount } from "svelte";
  import { goto } from "$utils/goto";
  import { chatStore } from "$stores/chat-store";

  let currentPath = "";
  let activeIndicator;
  let navList;
  let initialLoad = true;

  export let permissions = {};

  onMount(() => {
    currentPath = window.location.pathname;
    updateIndicatorPosition();
    setTimeout(() => (initialLoad = false), 0);
  });

  const updateIndicatorPosition = () => {
    if (!navList || !activeIndicator) return;

    const activeItem =
      navList.querySelector(`a[href="${currentPath}"]`) ||
      navList.querySelector(`a[href^="${currentPath}/"]`);

    if (activeItem) {
      const itemRect = activeItem.getBoundingClientRect();
      const listRect = navList.getBoundingClientRect();

      if (initialLoad) {
        // Initial position without animation
        activeIndicator.style.transition = "none";
        activeIndicator.style.transform = `translateX(${itemRect.left - listRect.left}px)`;
        activeIndicator.style.width = `${itemRect.width}px`;
      } else {
        // Subsequent updates with animation
        activeIndicator.style.transition =
          "transform 100ms ease-in-out, width 100ms ease-in-out";
        activeIndicator.style.transform = `translateX(${itemRect.left - listRect.left}px)`;
        activeIndicator.style.width = `${itemRect.width}px`;
      }
    }
  };

  const loadingIndicatorOnPage = () => {
    const overlay = document.createElement("div");
    overlay.style.position = "fixed";
    overlay.style.top = "43px";
    overlay.style.left = "0";
    overlay.style.bottom = "62px";
    overlay.style.width = "100%";
    overlay.style.backgroundColor = "rgb(248, 250, 252)";
    overlay.style.zIndex = "9999";
    overlay.style.display = "flex";
    overlay.style.justifyContent = "center";
    overlay.style.alignItems = "center";

    // Create skeleton loader
    const skeleton = document.createElement("div");
    skeleton.style.width = "100%";
    skeleton.style.display = "flex";
    skeleton.style.justifyContent = "center";

    overlay.appendChild(skeleton);
    document.body.appendChild(overlay);

    return overlay;
  };

  const hideLoadingIndicator = (overlay) => {
    if (overlay && overlay.parentNode) {
      overlay.parentNode.removeChild(overlay);
    }
  };

  $: navItems = [
    {
      href: "/dashboard",
      label: "Blocks",
      icon: "M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z",
      permissionKey: "blocks",
    },
    {
      href: "/dashboard/appearance",
      label: "Appearance",
      icon: "M9.53 16.122a3 3 0 00-5.78 1.128 2.25 2.25 0 01-2.4 2.245 4.5 4.5 0 008.4-2.245c0-.399-.078-.78-.22-1.128zm0 0a15.998 15.998 0 003.388-1.62m-5.043-.025a15.994 15.994 0 011.622-3.395m3.42 3.42a15.995 15.995 0 004.764-4.648l3.876-5.814a1.151 1.151 0 00-1.597-1.597L14.146 6.32a15.996 15.996 0 00-4.649 4.763m3.42 3.42a6.776 6.776 0 00-3.42-3.42",
      permissionKey: "appearance",
    },
    {
      href: "/dashboard/analytics",
      label: "Analytics",
      icon: "M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z",
      permissionKey: "analytics",
    },
    {
      href: "/dashboard/chat",
      label: "Chat",
      icon: "M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z",
      permissionKey: "chat",
      dot: $chatStore.unread,
    },
    {
      href: "/dashboard/settings",
      label: "Settings",
      icon: "M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z M15 12a3 3 0 11-6 0 3 3 0 016 0z",
      permissionKey: "settings",
    },
  ];

  $: {
    if (currentPath) {
      setTimeout(updateIndicatorPosition, 0);
    }
  }

  $: isActive = (href) =>
    currentPath === href ||
    (href !== "/dashboard" && currentPath.startsWith(href + "/"));
</script>

<nav
  class="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 z-10"
>
  <div bind:this={activeIndicator} class="absolute top-0 h-0.5 bg-purple-600" />
  <ul bind:this={navList} class="flex justify-around relative">
    {#each navItems as item (item.href)}
      {#if permissions[item.permissionKey]}
        <li class="flex-1">
          <a
            href={item.href}
            on:click|preventDefault|stopPropagation={(event) => {
              if (!isActive(item.href)) {
                event.preventDefault();
                currentPath = item.href;
                const overlay = loadingIndicatorOnPage();
                goto(item.href, {
                  onFinish: () => {
                    hideLoadingIndicator(overlay);
                  },
                });
              }
            }}
            class={`flex flex-col items-center py-2 px-1 text-xs ${
              isActive(item.href)
                ? "text-purple-600"
                : "text-gray-500 hover:text-purple-600"
            }`}
          >
            <div class="relative">
              <svg
                class={`h-6 w-6 ${
                  isActive(item.href)
                    ? "text-purple-600"
                    : "text-gray-400 group-hover:text-purple-600"
                }`}
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d={item.icon}
                ></path>
              </svg>
              {#if item.dot}
                <div
                  class="absolute top-0.5 right-0.5 w-2 h-2 scale-90 rounded-full bg-red-400"
                ></div>
              {/if}
            </div>
            <span class="mt-1">{item.label}</span>
          </a>
        </li>
      {/if}
    {/each}
  </ul>
</nav>
