<script>
  import { fly } from "svelte/transition";
  import { spring } from "svelte/motion";

  let currentStep = 0;
  let unlocked = false;
  let shakeError = false;

  const lockCoords = spring(
    { x: 0, y: 0 },
    {
      stiffness: 0.1,
      damping: 0.15,
    }
  );

  const steps = [
    {
      type: "code",
      title: "Access Code Required",
      description: "Enter code to unlock",
    },
    {
      type: "age",
      title: "Age Verification",
      description: "You must be 18+ to view this content",
    },
    {
      type: "sensitive",
      title: "Sensitive Content Warning",
      description: "This content may contain sensitive material",
    },
  ];

  function handleNext() {
    if (currentStep < steps.length - 1) {
      currentStep++;
    } else {
      unlocked = true;
    }
  }

  function handleError() {
    shakeError = true;
    lockCoords.set({ x: 20, y: 0 });
    setTimeout(() => {
      shakeError = false;
      lockCoords.set({ x: 0, y: 0 });
    }, 500);
  }

  function reset() {
    currentStep = 0;
    unlocked = false;
  }
</script>

<style>
  .animate-shake {
    animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
</style>

<div
  class="w-full sm:w-[448px] mx-auto bg-white rounded-lg border-2 border-gray-200 overflow-hidden">
  {#if !unlocked}
    <div class="p-4 sm:p-8">
      <div class="flex justify-center mb-4 sm:mb-6">
        <div
          style="transform: translate({$lockCoords.x}px, {$lockCoords.y}px)"
          class="w-12 h-12 sm:w-16 sm:h-16 bg-purple-100 rounded-full flex items-center justify-center {shakeError
            ? 'animate-shake'
            : ''}">
          <svg
            class="w-6 h-6 sm:w-8 sm:h-8 text-purple-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
          </svg>
        </div>
      </div>

      <div class="text-center" in:fly={{ y: 20, duration: 300 }}>
        <h3 class="text-lg sm:text-xl font-bold text-gray-900 mb-2">
          {steps[currentStep].title}
        </h3>
        <p class="text-sm sm:text-base text-gray-600 mb-4 sm:mb-6">
          {steps[currentStep].description}
        </p>

        <div
          class="flex flex-col sm:flex-row sm:space-x-3 space-y-2 sm:space-y-0 justify-center">
          <button
            class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none w-full sm:w-auto"
            on:click={handleNext}>
            Continue
          </button>
          <button
            class="inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none w-full sm:w-auto"
            on:click={handleError}>
            Deny
          </button>
        </div>
      </div>
    </div>
  {:else}
    <div class="p-4 sm:p-8 text-center" in:fly={{ y: 20, duration: 300 }}>
      <div
        class="w-12 h-12 sm:w-16 sm:h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4 sm:mb-6">
        <svg
          class="w-6 h-6 sm:w-8 sm:h-8 text-green-600"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
      <h3 class="text-lg sm:text-xl font-bold text-gray-900 mb-2">
        Content Unlocked!
      </h3>
      <p class="text-sm sm:text-base text-gray-600 mb-4 sm:mb-6">
        You now have access to the protected content
      </p>
      <button
        class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 w-full sm:w-auto"
        on:click={reset}>
        Start Over
      </button>
    </div>
  {/if}
</div>
