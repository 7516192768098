import LinkView from "$app-components/blocks/link/View.svelte";
import LinkAddEdit from "$app-components/blocks/link/AddEdit.svelte";
import LinkPage from "$app-components/blocks/link/Page.svelte";

import HeadingView from "$app-components/blocks/heading/View.svelte";
import HeadingAddEdit from "$app-components/blocks/heading/AddEdit.svelte";
import HeadingPage from "$app-components/blocks/heading/Page.svelte";

import ImageView from "$app-components/blocks/image/View.svelte";
import ImageAddEdit from "$app-components/blocks/image/AddEdit.svelte";
import ImagePage from "$app-components/blocks/image/Page.svelte";

import YoutubeView from "$app-components/blocks/youtube/View.svelte";
import YoutubeAddEdit from "$app-components/blocks/youtube/AddEdit.svelte";
import YoutubePage from "$app-components/blocks/youtube/Page.svelte";

import TextView from "$app-components/blocks/text/View.svelte";
import TextAddEdit from "$app-components/blocks/text/AddEdit.svelte";
import TextPage from "$app-components/blocks/text/Page.svelte";

import ProductView from "$app-components/blocks/product/View.svelte";
import ProductAddEdit from "$app-components/blocks/product/AddEdit.svelte";
import ProductPage from "$app-components/blocks/product/Page.svelte";

import CalendlyView from "$app-components/blocks/calendly/View.svelte";
import CalendlyAddEdit from "$app-components/blocks/calendly/AddEdit.svelte";
import CalendlyPage from "$app-components/blocks/calendly/Page.svelte";

import VideoView from "$app-components/blocks/video/View.svelte";
import VideoAddEdit from "$app-components/blocks/video/AddEdit.svelte";
import VideoPage from "$app-components/blocks/video/Page.svelte";

import TiktokView from "$app-components/blocks/tiktok/View.svelte";
import TiktokAddEdit from "$app-components/blocks/tiktok/AddEdit.svelte";
import TiktokPage from "$app-components/blocks/tiktok/Page.svelte";

import FacebookView from "$app-components/blocks/facebook/View.svelte";
import FacebookAddEdit from "$app-components/blocks/facebook/AddEdit.svelte";
import FacebookPage from "$app-components/blocks/facebook/Page.svelte";

import SpotifyView from "$app-components/blocks/spotify/View.svelte";
import SpotifyAddEdit from "$app-components/blocks/spotify/AddEdit.svelte";
import SpotifyPage from "$app-components/blocks/spotify/Page.svelte";

import BandsintownView from "$app-components/blocks/bandsintown/View.svelte";
import BandsintownAddEdit from "$app-components/blocks/bandsintown/AddEdit.svelte";
import BandsintownPage from "$app-components/blocks/bandsintown/Page.svelte";

import ShopifyView from "$app-components/blocks/shopify/View.svelte";
import ShopifyAddEdit from "$app-components/blocks/shopify/AddEdit.svelte";
import ShopifyPage from "$app-components/blocks/shopify/Page.svelte";

import SoundcloudView from "$app-components/blocks/soundcloud/View.svelte";
import SoundcloudAddEdit from "$app-components/blocks/soundcloud/AddEdit.svelte";
import SoundcloudPage from "$app-components/blocks/soundcloud/Page.svelte";

import GoFundMeView from "$app-components/blocks/gofundme/View.svelte";
import GoFundMeAddEdit from "$app-components/blocks/gofundme/AddEdit.svelte";
import GoFundMePage from "$app-components/blocks/gofundme/Page.svelte";

import SpringView from "$app-components/blocks/spring/View.svelte";
import SpringAddEdit from "$app-components/blocks/spring/AddEdit.svelte";
import SpringPage from "$app-components/blocks/spring/Page.svelte";

import AudiomackView from "$app-components/blocks/audiomack/View.svelte";
import AudiomackAddEdit from "$app-components/blocks/audiomack/AddEdit.svelte";
import AudiomackPage from "$app-components/blocks/audiomack/Page.svelte";

import AmazonView from "$app-components/blocks/amazon/View.svelte";
import AmazonAddEdit from "$app-components/blocks/amazon/AddEdit.svelte";
import AmazonPage from "$app-components/blocks/amazon/Page.svelte";

import PinterestView from "$app-components/blocks/pinterest/View.svelte";
import PinterestAddEdit from "$app-components/blocks/pinterest/AddEdit.svelte";
import PinterestPage from "$app-components/blocks/pinterest/Page.svelte";

import TwitterView from "$app-components/blocks/twitter/View.svelte";
import TwitterAddEdit from "$app-components/blocks/twitter/AddEdit.svelte";
import TwitterPage from "$app-components/blocks/twitter/Page.svelte";

import TypeformView from "$app-components/blocks/typeform/View.svelte";
import TypeformAddEdit from "$app-components/blocks/typeform/AddEdit.svelte";
import TypeformPage from "$app-components/blocks/typeform/Page.svelte";

import BonfireView from "$app-components/blocks/bonfire/View.svelte";
import BonfireAddEdit from "$app-components/blocks/bonfire/AddEdit.svelte";
import BonfirePage from "$app-components/blocks/bonfire/Page.svelte";

import TwitchView from "$app-components/blocks/twitch/View.svelte";
import TwitchAddEdit from "$app-components/blocks/twitch/AddEdit.svelte";
import TwitchPage from "$app-components/blocks/twitch/Page.svelte";

import FormView from "$app-components/blocks/form/View.svelte";
import FormAddEdit from "$app-components/blocks/form/AddEdit.svelte";
import FormPage from "$app-components/blocks/form/Page.svelte";

import ContactInfoView from "$app-components/blocks/contactinfo/View.svelte";
import ContactInfoAddEdit from "$app-components/blocks/contactinfo/AddEdit.svelte";
import ContactInfoPage from "$app-components/blocks/contactinfo/Page.svelte";

import ThreadsView from "$app-components/blocks/threads/View.svelte";
import ThreadsAddEdit from "$app-components/blocks/threads/AddEdit.svelte";
import ThreadsPage from "$app-components/blocks/threads/Page.svelte";

import MapView from "$app-components/blocks/map/View.svelte";
import MapAddEdit from "$app-components/blocks/map/AddEdit.svelte";
import MapPage from "$app-components/blocks/map/Page.svelte";

export default {
  link: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
          </svg>`,
    label: "Link",
    component: LinkView,
    componentTab: "Content",
    addEditComponent: LinkAddEdit,
    pageComponent: LinkPage,
  },
  heading: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" />
          </svg>`,
    label: "Heading",
    component: HeadingView,
    componentTab: "Content",
    addEditComponent: HeadingAddEdit,
    pageComponent: HeadingPage,
  },
  image: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>`,
    label: "Image",
    component: ImageView,
    componentTab: "Content",
    addEditComponent: ImageAddEdit,
    pageComponent: ImagePage,
  },
  text: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
          </svg>`,
    label: "Text",
    component: TextView,
    componentTab: "Content",
    addEditComponent: TextAddEdit,
    pageComponent: TextPage,
  },
  video: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
          </svg>`,
    label: "Video",
    component: VideoView,
    componentTab: "Content",
    addEditComponent: VideoAddEdit,
    pageComponent: VideoPage,
  },
  form: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
            <line x1="3" y1="10" x2="21" y2="10" />
            <path d="M7 15h10" />
            <path d="M7 19h7" />
          </svg>`,
    label: "Form",
    component: FormView,
    componentTab: "Content", 
    addEditComponent: FormAddEdit,
    pageComponent: FormPage,
  },
  contactinfo: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
          </svg>`,
    label: "Contact Info",
    componentTab: "Content",
    component: ContactInfoView,
    addEditComponent: ContactInfoAddEdit,
    pageComponent: ContactInfoPage,
  },
  map: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>`,
    label: "Map",
    component: MapView,
    componentTab: "Content",
    addEditComponent: MapAddEdit,
    pageComponent: MapPage,
  },
  youtube: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>`,
    label: "YouTube",
    component: YoutubeView,
    componentTab: "Content",
    addEditComponent: YoutubeAddEdit,
    pageComponent: YoutubePage,
  },
  tiktok: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-7 md:w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z" fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
          </svg>`,
    label: "TikTok",
    component: TiktokView,
    componentTab: "Content",
    addEditComponent: TiktokAddEdit,
    pageComponent: TiktokPage,
  },
  facebook: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-7 md:w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z" fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
          </svg>`,
    label: "Facebook",
    component: FacebookView,
    componentTab: "Content",
    addEditComponent: FacebookAddEdit,
    pageComponent: FacebookPage,
  },
  spotify: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-7 md:w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.359-.66.48-1.021.24-2.82-1.74-6.36-2.101-10.561-1.141-.418.122-.779-.179-.899-.539-.12-.421.18-.78.54-.9 4.56-1.021 8.52-.6 11.64 1.32.42.18.479.659.301 1.02zm1.44-3.3c-.301.42-.841.6-1.262.3-3.239-1.98-8.159-2.58-11.939-1.38-.479.12-1.02-.12-1.14-.6-.12-.48.12-1.021.6-1.141C9.6 9.9 15 10.561 18.72 12.84c.361.181.54.78.241 1.2zm.12-3.36C15.24 8.4 8.82 8.16 5.16 9.301c-.6.179-1.2-.181-1.38-.721-.18-.601.18-1.2.72-1.381 4.26-1.26 11.28-1.02 15.721 1.621.539.3.719 1.02.419 1.56-.299.421-1.02.599-1.559.3z" fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
          </svg>`,
    label: "Spotify",
    component: SpotifyView,
    componentTab: "Content",
    addEditComponent: SpotifyAddEdit,
    pageComponent: SpotifyPage,
  },
  pinterest: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="currentColor" viewBox="0 0 24 24" role="img">
      <path d="M12 0c-6.627 0-12 5.372-12 12 0 5.084 3.163 9.426 7.627 11.174-.105-.949-.2-2.405.042-3.441.218-.937 1.407-5.965 1.407-5.965s-.359-.719-.359-1.782c0-1.668.967-2.914 2.171-2.914 1.023 0 1.518.769 1.518 1.69 0 1.029-.655 2.568-.994 3.995-.283 1.194.599 2.169 1.777 2.169 2.133 0 3.772-2.249 3.772-5.495 0-2.873-2.064-4.882-5.012-4.882-3.414 0-5.418 2.561-5.418 5.207 0 1.031.397 2.138.893 2.738.098.119.112.224.083.345l-.333 1.36c-.053.22-.174.267-.402.161-1.499-.698-2.436-2.889-2.436-4.649 0-3.785 2.75-7.262 7.929-7.262 4.163 0 7.398 2.967 7.398 6.931 0 4.136-2.607 7.464-6.227 7.464-1.216 0-2.359-.631-2.75-1.378l-.748 2.853c-.271 1.043-1.002 2.35-1.492 3.146 1.124.347 2.317.535 3.554.535 6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12z"/>
    </svg>`,
    label: "Pinterest",
    component: PinterestView,
    componentTab: "Content",
    addEditComponent: PinterestAddEdit,
    pageComponent: PinterestPage,
  },
  twitter: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-7 md:w-7" fill="currentColor" viewBox="0 0 24 24">
            <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
          </svg>`,
    label: "Twitter",
    component: TwitterView,
    componentTab: "Content",
    addEditComponent: TwitterAddEdit,
    pageComponent: TwitterPage,
  },
  typeform: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-7 md:w-7" viewBox="0 0 122.3 80.3">
            <path d="M94.3,0H65.4c-26,0-28,11.2-28,26.2l0,27.9c0,15.6,2,26.2,28.1,26.2h28.8c26,0,28-11.2,28-26.1V26.2C122.3,11.2,120.3,0,94.3,0z M0,20.1C0,6.9,5.2,0,14,0c8.8,0,14,6.9,14,20.1v40.1c0,13.2-5.2,20.1-14,20.1c-8.8,0-14-6.9-14-20.1V20.1z" fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
          </svg>`,
    label: "Typeform",
    component: TypeformView,
    componentTab: "Content", 
    addEditComponent: TypeformAddEdit,
    pageComponent: TypeformPage,
  },
  twitch: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-7 md:w-7" fill="currentColor" viewBox="0 0 24 24">
            <path d="M11.571 4.714h1.715v5.143H11.57zm4.715 0H18v5.143h-1.714zM6 0L1.714 4.286v15.428h5.143V24l4.286-4.286h3.428L22.286 12V0zm14.571 11.143l-3.428 3.428h-3.429l-3 3v-3H6.857V1.714h13.714Z"/>
          </svg>`,
    label: "Twitch",
    component: TwitchView,
    componentTab: "Content",
    addEditComponent: TwitchAddEdit,
    pageComponent: TwitchPage,
  },
  threads: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-7 md:w-7" fill="currentColor" viewBox="0 0 192 192">
            <path fill="currentColor" d="M141.537 88.9883C140.71 88.5919 139.87 88.2104 139.019 87.8451C137.537 60.5382 122.616 44.905 97.5619 44.745C97.4484 44.7443 97.3355 44.7443 97.222 44.7443C82.2364 44.7443 69.7731 51.1409 62.102 62.7807L75.881 72.2328C81.6116 63.5383 90.6052 61.6848 97.2286 61.6848C97.3051 61.6848 97.3819 61.6848 97.4576 61.6855C105.707 61.7381 111.932 64.1366 115.961 68.814C118.893 72.2193 120.854 76.925 121.825 82.8638C114.511 81.6207 106.601 81.2385 98.145 81.7233C74.3247 83.0954 59.0111 96.9879 60.0396 116.292C60.5615 126.084 65.4397 134.508 73.775 140.011C80.8224 144.663 89.899 146.938 99.3323 146.423C111.79 145.74 121.563 140.987 128.381 132.296C133.559 125.696 136.834 117.143 138.28 106.366C144.217 109.949 148.617 114.664 151.047 120.332C155.179 129.967 155.42 145.8 142.501 158.708C131.182 170.016 117.576 174.908 97.0135 175.059C74.2042 174.89 56.9538 167.575 45.7381 153.317C35.2355 139.966 29.8077 120.682 29.6052 96C29.8077 71.3178 35.2355 52.0336 45.7381 38.6827C56.9538 24.4249 74.2039 17.11 97.0132 16.9405C119.988 17.1113 137.539 24.4614 149.184 38.788C154.894 45.8136 159.199 54.6488 162.037 64.9503L178.184 60.6422C174.744 47.9622 169.331 37.0357 161.965 27.974C147.036 9.60668 125.202 0.195148 97.0695 0H96.9569C68.8816 0.19447 47.2921 9.6418 32.7883 28.0793C19.8819 44.4864 13.2244 67.3157 13.0007 95.9325L13 96L13.0007 96.0675C13.2244 124.684 19.8819 147.514 32.7883 163.921C47.2921 182.358 68.8816 191.806 96.9569 192H97.0695C122.03 191.827 139.624 185.292 154.118 170.811C173.081 151.866 172.51 128.119 166.26 113.541C161.776 103.087 153.227 94.5962 141.537 88.9883ZM98.4405 129.507C88.0005 130.095 77.1544 125.409 76.6196 115.372C76.2232 107.93 81.9158 99.626 99.0812 98.6368C101.047 98.5234 102.976 98.468 104.871 98.468C111.106 98.468 116.939 99.0737 122.242 100.233C120.264 124.935 108.662 128.946 98.4405 129.507Z"/>
          </svg>`,
    label: "Threads",
    component: ThreadsView,
    componentTab: "Content",
    addEditComponent: ThreadsAddEdit,
    pageComponent: ThreadsPage,
  },
  product: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
          </svg>`,
    label: "Product",
    component: ProductView,
    componentTab: "Monetization",
    addEditComponent: ProductAddEdit,
    pageComponent: ProductPage,
  },
  amazon: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" viewBox="0 0 35.418 35.418" fill="none" stroke="currentColor">
            <path d="M20.948,9.891c-0.857,0.068-1.847,0.136-2.837,0.269c-1.516,0.195-3.032,0.461-4.284,1.053 c-2.439,0.994-4.088,3.105-4.088,6.209c0,3.898,2.506,5.875,5.669,5.875c1.057,0,1.913-0.129,2.703-0.328 c1.255-0.396,2.31-1.123,3.562-2.441c0.727,0.99,0.923,1.453,2.177,2.509c0.329,0.133,0.658,0.133,0.922-0.066 c0.791-0.659,2.174-1.848,2.901-2.508c0.328-0.267,0.263-0.66,0.066-0.992c-0.727-0.924-1.45-1.718-1.45-3.498v-5.943 c0-2.513,0.195-4.822-1.647-6.537c-1.518-1.391-3.891-1.916-5.735-1.916c-0.264,0-0.527,0-0.792,0 c-3.362,0.197-6.921,1.647-7.714,5.811c-0.13,0.525,0.267,0.726,0.53,0.793l3.691,0.464c0.396-0.07,0.593-0.398,0.658-0.73 c0.333-1.449,1.518-2.176,2.836-2.309c0.067,0,0.133,0,0.265,0c0.79,0,1.646,0.332,2.109,0.987 c0.523,0.795,0.461,1.853,0.461,2.775L20.948,9.891L20.948,9.891z M20.223,17.749c-0.461,0.925-1.253,1.519-2.11,1.718 c-0.131,0-0.327,0.068-0.526,0.068c-1.45,0-2.31-1.123-2.31-2.775c0-2.11,1.254-3.104,2.836-3.565 c0.857-0.197,1.847-0.265,2.836-0.265v0.793C20.948,15.243,21.01,16.43,20.223,17.749z M35.418,26.918v0.215 c-0.035,1.291-0.716,3.768-2.328,5.131c-0.322,0.25-0.645,0.107-0.503-0.254c0.469-1.145,1.541-3.803,1.04-4.412 c-0.355-0.465-1.826-0.43-3.079-0.322c-0.572,0.072-1.075,0.105-1.469,0.183c-0.357,0.033-0.431-0.287-0.071-0.537 c0.466-0.323,0.969-0.573,1.541-0.756c2.039-0.608,4.406-0.25,4.729,0.146C35.348,26.414,35.418,26.629,35.418,26.918z M32.016,29.428c-0.466,0.357-0.965,0.682-1.468,0.973c-3.761,2.261-8.631,3.441-12.856,3.441c-6.807,0-12.895-2.512-17.514-6.709 c-0.396-0.324-0.073-0.789,0.393-0.539C5.549,29.5,11.709,31.26,18.084,31.26c4.013,0,8.342-0.754,12.463-2.371 c0.285-0.104,0.608-0.252,0.895-0.356C32.087,28.242,32.661,28.965,32.016,29.428z" fill="currentColor"/>
          </svg>`,
    label: "Amazon",
    component: AmazonView,
    componentTab: "Monetization",
    addEditComponent: AmazonAddEdit,
    pageComponent: AmazonPage,
  },
  shopify: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" viewBox="-18 0 292 292" preserveAspectRatio="xMidYMid">
            <path d="M223.774 57.34c-.201-1.46-1.48-2.268-2.537-2.357-1.055-.088-23.383-1.743-23.383-1.743s-15.507-15.395-17.209-17.099c-1.703-1.703-5.029-1.185-6.32-.805-.19.056-3.388 1.043-8.678 2.68-5.18-14.906-14.322-28.604-30.405-28.604-.444 0-.901.018-1.358.044C129.31 3.407 123.644.779 118.75.779c-37.465 0-55.364 46.835-60.976 70.635-14.558 4.511-24.9 7.718-26.221 8.133-8.126 2.549-8.383 2.805-9.45 10.462C21.3 95.806.038 260.235.038 260.235l165.678 31.042 89.77-19.42S223.973 58.8 223.775 57.34zM156.49 40.848l-14.019 4.339c.005-.988.01-1.96.01-3.023 0-9.264-1.286-16.723-3.349-22.636 8.287 1.04 13.806 10.469 17.358 21.32zm-27.638-19.483c2.304 5.773 3.802 14.058 3.802 25.238 0 .572-.005 1.095-.01 1.624-9.117 2.824-19.024 5.89-28.953 8.966 5.575-21.516 16.025-31.908 25.161-35.828zm-11.131-10.537c1.617 0 3.246.549 4.805 1.622-12.007 5.65-24.877 19.88-30.312 48.297l-22.886 7.088C75.694 46.16 90.81 10.828 117.72 10.828z" fill="currentColor"/>
            <path d="M221.237 54.983c-1.055-.088-23.383-1.743-23.383-1.743s-15.507-15.395-17.209-17.099c-.637-.634-1.496-.959-2.394-1.099l-12.527 256.233 89.762-19.418S223.972 58.8 223.774 57.34c-.201-1.46-1.48-2.268-2.537-2.357" fill="currentColor"/>
            <path d="M135.242 104.585l-11.069 32.926s-9.698-5.176-21.586-5.176c-17.428 0-18.305 10.937-18.305 13.693 0 15.038 39.2 20.8 39.2 56.024 0 27.713-17.577 45.558-41.277 45.558-28.44 0-42.984-17.7-42.984-17.7l7.615-25.16s14.95 12.835 27.565 12.835c8.243 0 11.596-6.49 11.596-11.232 0-19.616-32.16-20.491-32.16-52.724 0-27.129 19.472-53.382 58.778-53.382 15.145 0 22.627 4.338 22.627 4.338" fill="currentColor"/>
          </svg>`,
    label: "Shopify",
    component: ShopifyView,
    componentTab: "Monetization",
    addEditComponent: ShopifyAddEdit,
    pageComponent: ShopifyPage,
  },
  gofundme: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
          </svg>`,
    label: "GoFundMe",
    component: GoFundMeView,
    componentTab: "Monetization",
    addEditComponent: GoFundMeAddEdit,
    pageComponent: GoFundMePage,
  },
  spring: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 48 48" stroke="currentColor">
            <path d="M20.1333 37C18.0643 37 15.1449 36.396 12.2273 33.7707L14.5163 31.2264C18.6066 34.9082 22.8695 33.4538 24.1848 32.2247C24.2764 32.1385 24.3609 32.0487 24.4507 31.9659C20.1509 31.5468 16.9938 28.4883 16.7772 28.2788C13.4775 25.0301 12.7274 20.6176 15.0763 18.2423C17.4252 15.867 21.8395 16.5149 25.141 19.7601C25.4332 20.0365 28.6467 23.1901 29.2806 27.1906C30.2306 26.4825 31.0026 25.5627 31.5353 24.5043C32.068 23.4459 32.3468 22.2779 32.3496 21.093C32.3577 20.0897 32.1631 19.0951 31.7773 18.1689C31.3915 17.2428 30.8226 16.4041 30.1046 15.7032C28.6027 14.2259 26.4756 13.423 24.132 13.423V10C27.3701 10 30.3406 11.1516 32.4905 13.2416C33.5389 14.2613 34.3701 15.4825 34.9341 16.8318C35.4982 18.1811 35.7834 19.6306 35.7726 21.093C35.7678 23.2856 35.1112 25.4272 33.8863 27.2457C32.6613 29.0641 30.9233 30.4773 28.8932 31.3056C28.3795 32.614 27.5681 33.7848 26.5232 34.7251C25.4332 35.7446 23.2992 36.8732 20.5823 36.9877C20.4379 36.9959 20.2882 37 20.1333 37ZM18.6929 20.2126C18.4774 20.1984 18.2613 20.2284 18.0578 20.3008C17.8544 20.3732 17.6678 20.4864 17.5097 20.6334C16.6751 21.4909 17.2068 23.8997 19.1807 25.8471C19.2088 25.8735 22.299 28.8704 25.9562 28.564C25.972 25.3312 22.7973 22.2674 22.7639 22.2375L22.7428 22.2146C21.5806 21.0613 19.9149 20.2126 18.6929 20.2126Z" fill="currentColor"/>
          </svg>`,
    label: "Spring",
    component: SpringView,
    componentTab: "Monetization",
    addEditComponent: SpringAddEdit,
    pageComponent: SpringPage,
  },
  bonfire: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z" />
          </svg>`,
    label: "Bonfire",
    component: BonfireView,
    componentTab: "Monetization",
    addEditComponent: BonfireAddEdit,
    pageComponent: BonfirePage,
  },
  soundcloud: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 300 300" stroke="currentColor">
      <g>
        <path d="M14.492,208.896c0.619,0,1.143-0.509,1.232-1.226l3.365-26.671l-3.355-27.278c-0.1-0.717-0.623-1.23-1.242-1.23c-0.635,0-1.176,0.524-1.26,1.23l-2.941,27.278l2.941,26.662C13.316,208.377,13.857,208.896,14.492,208.896z" fill="currentColor"/>
        <path d="M3.397,198.752c0.608,0,1.101-0.473,1.19-1.18l2.608-16.574l-2.608-16.884c-0.09-0.685-0.582-1.18-1.19-1.18c-0.635,0-1.127,0.495-1.217,1.19L0,180.999l2.18,16.569C2.27,198.269,2.762,198.752,3.397,198.752z" fill="currentColor"/>
        <path d="M27.762,148.644c-0.08-0.867-0.715-1.5-1.503-1.5c-0.782,0-1.418,0.633-1.491,1.5l-2.811,32.355l2.811,31.174c0.073,0.862,0.709,1.487,1.491,1.487c0.788,0,1.423-0.625,1.503-1.487l3.18-31.174L27.762,148.644z" fill="currentColor"/>
        <path d="M38.152,214.916c0.922,0,1.668-0.759,1.758-1.751l3.005-32.156l-3.005-33.258c-0.09-0.999-0.836-1.749-1.758-1.749c-0.935,0-1.692,0.751-1.756,1.754l-2.656,33.253l2.656,32.156C36.46,214.158,37.217,214.916,38.152,214.916z" fill="currentColor"/>
        <path d="M50.127,215.438c1.074,0,1.936-0.86,2.025-2.011l-0.01,0.008l2.83-32.426l-2.83-30.857c-0.08-1.132-0.941-2.005-2.016-2.005c-1.09,0-1.947,0.873-2.012,2.014l-2.502,30.849l2.502,32.418C48.18,214.578,49.037,215.438,50.127,215.438z" fill="currentColor"/>
        <path d="M67.132,181.017l-2.655-50.172c-0.074-1.272-1.065-2.286-2.281-2.286c-1.207,0-2.195,1.013-2.269,2.286l-2.35,50.172l2.35,32.418c0.074,1.278,1.063,2.278,2.269,2.278c1.217,0,2.207-1,2.281-2.278v0.009L67.132,181.017z" fill="currentColor"/>
        <path d="M74.386,215.766c1.339,0,2.45-1.111,2.513-2.529v0.021l2.482-32.233l-2.482-61.656c-0.063-1.418-1.174-2.529-2.513-2.529c-1.37,0-2.471,1.111-2.545,2.529l-2.185,61.656l2.195,32.222C71.915,214.655,73.016,215.766,74.386,215.766z" fill="currentColor"/>
        <path d="M86.645,111.435c-1.508,0-2.725,1.238-2.787,2.799l-2.033,66.801l2.033,31.884c0.063,1.553,1.279,2.783,2.787,2.783c1.504,0,2.73-1.22,2.783-2.788v0.016l2.307-31.895l-2.307-66.801C89.375,112.663,88.148,111.435,86.645,111.435z" fill="currentColor"/>
        <path d="M99.01,215.766c1.656,0,2.975-1.336,3.037-3.056v0.019l2.133-31.693l-2.133-69.045c-0.063-1.714-1.381-3.056-3.037-3.056c-1.666,0-3.005,1.342-3.031,3.056l-1.916,69.045l1.916,31.693C96.005,214.43,97.344,215.766,99.01,215.766z" fill="currentColor"/>
        <path d="M111.477,215.734c1.787,0,3.237-1.463,3.291-3.318v0.029l1.963-31.404l-1.963-67.289c-0.054-1.854-1.504-3.311-3.291-3.311c-1.8,0-3.25,1.456-3.303,3.311l-1.725,67.289l1.736,31.389C108.227,214.271,109.677,215.734,111.477,215.734z" fill="currentColor"/>
        <path d="M129.359,181.041l-1.777-64.836c-0.043-2-1.609-3.571-3.551-3.571c-1.947,0-3.514,1.571-3.555,3.584l-1.594,64.823l1.594,31.198c0.041,1.984,1.607,3.556,3.555,3.556c1.941,0,3.508-1.572,3.551-3.585v0.029L129.359,181.041z" fill="currentColor"/>
        <path d="M136.682,215.853c2.064,0,3.773-1.717,3.805-3.828v0.017l1.613-30.984l-1.613-77.153c-0.031-2.119-1.74-3.833-3.805-3.833c-2.063,0-3.767,1.722-3.809,3.844l-1.434,77.111l1.434,31.016C132.915,214.136,134.619,215.853,136.682,215.853z" fill="currentColor"/>
        <path d="M149.291,92.814c-2.229,0-4.037,1.849-4.074,4.103l-1.667,84.151l1.677,30.526c0.027,2.225,1.836,4.068,4.064,4.068c2.195,0,4.037-1.844,4.047-4.105v0.037l1.82-30.526l-1.82-84.151C153.328,94.655,151.486,92.814,149.291,92.814z" fill="currentColor"/>
        <path d="M160.82,215.882c0.09,0.008,101.623,0.056,102.275,0.056c20.385,0,36.904-16.722,36.904-37.357c0-20.624-16.52-37.349-36.904-37.349c-5.059,0-9.879,1.034-14.275,2.907c-2.922-33.671-30.815-60.077-64.842-60.077c-8.318,0-16.429,1.662-23.593,4.469c-2.788,1.09-3.534,2.214-3.556,4.392v118.539C156.861,213.752,158.607,215.655,160.82,215.882z" fill="currentColor"/>
      </g>
    </svg>`,
    label: "SoundCloud",
    component: SoundcloudView,
    componentTab: "Integrations",
    addEditComponent: SoundcloudAddEdit,
    pageComponent: SoundcloudPage,
  },
  calendly: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>`,
    label: "Calendly",
    component: CalendlyView,
    componentTab: "Integrations",
    addEditComponent: CalendlyAddEdit,
    pageComponent: CalendlyPage,
  },
  bandsintown: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-7 md:w-7" fill="currentColor" viewBox="0 0 24 24" role="img">
            <path d="M18.783 0H24v12h-5.217V0zm-6.261 5h5.217v7h-5.217V5zM6.26 5h5.217v7H6.261V5zM24 24H0V0h5.217v19h13.566v-1H6.26v-5H24v11Z"/>
          </svg>`,
    label: "Bandsintown",
    component: BandsintownView,
    componentTab: "Integrations",
    addEditComponent: BandsintownAddEdit,
    pageComponent: BandsintownPage,
  },
  audiomack: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="currentColor" viewBox="0 0 24 24" role="img">
      <path d="M.33 11.39s.54-.09.77.14c.22.23.07.71-.22.72-.3.01-.57.06-.77-.14a.443.443 0 01.22-.72zm5.88 3.26c-.05.01-.11-.02-.16-.06-.39-.53-.53-2.37-.71-2.48-.18-.11-.85 1.02-2.19.9-.55-.05-1.12-.41-1.45-.66.03-.41.03-1.39.86-1.07.51.19 1.37.72 2.13-.23.84-1.05 1.3-.74 1.57-.51.28.22.1 1.41.51 1.08.41-.33 2.08-2.39 2.08-2.39s1.29-1.29 1.49.06c.2 1.36 1.04 2.87 1.27 2.82.22-.04 2.82-5.27 3.19-5.61.37-.34 1.63-.29 1.57.57-.06.87-.19 6.25-.19 6.25s-.15 1.52.09.71c.1-.34.21-.64.34-1 .64-2.03 1.73-5.51 2.28-7.3.12-.42.23-.79.32-1.07v-.01c.03-.13.06-.23.09-.32.05-.15.08-.26.09-.28.02-.07.09-.12.19-.16.09-.06.2-.06.31-.06.31-.03.69.01 1.04.11.11 0 .22.03.32.11 0 0 .01 0 .02.01.03.02.06.05.1.1h.01c.01.02.03.05.05.07.19.29.31.81.19 1.74-.3 2.31-.53 7.07-.53 7.07s-.05.23.44-.77c.01-.04.03-.07.05-.1.03-.02.06-.04.1-.08.29-.36 1.09-.56 1.65-.56.23.03.43.09.54.16.22.33.09 1.55.09 1.55-.46.04-1.34.29-1.65.33-.31.05-.78 2.05-1.44 1.85-.66-.21-2.13-1.12-2.13-1.24 0-.11.12-1.44.15-1.79v-.07-.01c.03-.27.01-.39-.12-.12-.11.23-.58 1.72-1.11 3.34-.05.14-1.05 3.13-1.18 3.49-.15.42-.29.75-.38.91-.13.19-.32.3-.58.23-.65-.2-1.46-1.08-1.47-1.3-.02-1.24.06-7.9-.24-7.35-.32.57-2.73 4.52-2.73 4.52-.04.01-.07.01-.11.01-.17-.02-.44-.07-.51-.23 0-.01-.01-.02-.01-.03-.01-.01-.01-.02-.02-.04-.03-.11-.04-.23-.07-.33-.11-.36-.28-.88-.47-1.4-.27-.9-.56-1.82-.61-1.92-.09-.2-.22-.12-.35 0-.54.45-1.68 2.45-2.72 2.56z"/>
    </svg>`,
    label: "Audiomack",
    component: AudiomackView,
    componentTab: "Integrations",
    addEditComponent: AudiomackAddEdit,
    pageComponent: AudiomackPage,
  },
};
