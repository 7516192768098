<script>
  import { onMount } from "svelte";

  export let browserBreakdown;

  let mounted = false;

  onMount(() => {
    mounted = true;
  });

  // Sort browsers by percentage
  $: sortedBrowsers = browserBreakdown.sort(
    (a, b) => b.percentage - a.percentage
  );

  function getProgressBarWidth(percentage) {
    return percentage;
  }
</script>

<style>
  /* Add any additional styles here if needed */
</style>

<div class="bg-white p-4 sm:p-6 rounded-lg shadow-lg">
  <div class="space-y-4">
    {#each sortedBrowsers as browser, index}
      {#if mounted}
        <div
          class="flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0 sm:space-x-4">
          <div class="flex items-center sm:w-1/4">
            <span class="font-semibold text-gray-700 truncate">
              {browser.name}
            </span>
          </div>
          <div class="flex-grow sm:flex sm:justify-between sm:items-center">
            <div
              class="bg-gray-200 h-2 rounded-full overflow-hidden sm:w-1/2 hidden sm:block">
              <div
                class="bg-purple-600 h-full rounded-full transition-all duration-500 ease-out"
                style="width: {getProgressBarWidth(browser.percentage)}%">
              </div>
            </div>
            <div
              class="flex flex-wrap items-center space-x-3 sm:space-x-4 text-sm sm:w-1/2 sm:justify-end">
              <span
                class="font-semibold text-blue-600 bg-blue-100 px-2 py-1 rounded">
                {browser.percentage}%
              </span>
            </div>
          </div>
        </div>
      {/if}
    {/each}
  </div>
  {#if sortedBrowsers.length === 0}
    <p class="text-center text-gray-500 mt-4">No browser data available yet.</p>
  {/if}
</div>
