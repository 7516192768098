import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import AwsS3 from '@uppy/aws-s3';
import Url from '@uppy/url';
import { COMPANION_URL, COMPANION_ALLOWED_HOSTS } from '@uppy/transloadit';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

const buildDashboardElement = () => {
  if (document.getElementById('uppy-dashboard')) {
    return;
  }

  const dashboardElement = document.createElement('div');
  dashboardElement.id = 'uppy-dashboard';

  document.body.appendChild(dashboardElement);
};

const downloadImage = async (url) => {
  const dashboard = document.getElementById('uppy-dashboard');
  const overlay = document.createElement('div');

  // Create loading overlay
  const overlayStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(255, 255, 255, 0.9)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999
  };

  // Create spinner and loading message
  const spinnerStyles = `
    .spinner {
      width: 40px;
      height: 40px;
      margin: 0 auto 1rem;
      border: 3px solid #f3f3f3;
      border-top: 3px solid #3498db;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  Object.assign(overlay.style, overlayStyles);

  overlay.innerHTML = `
    <div style="text-align: center;">
      <div class="spinner"></div>
      <div>Processing Image...</div>
    </div>
    <style>${spinnerStyles}</style>
  `;

  dashboard.appendChild(overlay);

  try {
    const response = await fetch(`/dashboard/images?url=${encodeURIComponent(url)}`);
    return response.blob();
  } finally {
    overlay.remove();
  }
};

export const createUploader = (options = {}) => {
  const uppy = new Uppy({
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: options.fileType === 'video' ? ['video/*'] : ['image/*']
    },
    ...options
  });

  buildDashboardElement();

  uppy.use(Dashboard, {
    target: '#uppy-dashboard',
    closeAfterFinish: true
  });

  uppy.use(Url, {
    companionUrl: COMPANION_URL,
    allowedHosts: COMPANION_ALLOWED_HOSTS, 
  });

  uppy.use(AwsS3, {
    getUploadParameters: async (file) => {
      const response = await fetch('/api/upload/presigned_url', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filename: file.name,
          content_type: file.type
        })
      });

      const { url, fields } = await response.json();

      return {
        method: 'POST',
        url: url,
        fields: fields
      };
    }
  });

  uppy.on('file-added', async (file) => {
    if (file.type.startsWith('image/')) {      
      if (file.source === 'Url' && file.remote && file.remote.body) {
        file.data = await downloadImage(file.remote.body.url);
        file.isRemote = false;        
      }

      if(options.crop) {
        const result = await options.crop(file.data);
        
        result.on("process", async (event) => {
          uppy.setFileState(file.id, {
            data: event.dest
          });

          uppy.upload();
        });
      } else {
        uppy.upload();
      }
    }
  });

  uppy.on('dashboard:modal-closed', () => {
    const dashboardElement = document.getElementById('uppy-dashboard');

    if (dashboardElement) {
      dashboardElement.remove();
    }

    document.body.classList.remove('uppy-Dashboard-isFixed');
  });

  return uppy;
};

export const showUploader = (options = {}) => {
  const uppy = createUploader(options);
  uppy.getPlugin('Dashboard').openModal();

  return new Promise((resolve, reject) => {
    uppy.on('complete', (result) => {
      if (result.successful && result.successful.length > 0) {
        resolve(result.successful[0].uploadURL);
      } else {
        reject(new Error('Upload failed'));
      }
    });

    uppy.on('error', (error) => {
      reject(error);
    });
  });
};
