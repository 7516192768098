import Ably from 'ably';

let ablyInstance = null;

export async function initializeAbly(userId = null) {
  if (ablyInstance) return ablyInstance;

  ablyInstance = new Ably.Realtime({ 
    key: "F49VGw.9HdG7A:y1alaROTjPgq95F28UOHb682WysbEXx6wSW7llbob18",
    clientId: userId
  });

  return new Promise((resolve) => {
    ablyInstance.connection.once("connected", () => {
      resolve(ablyInstance);
    });
  });
}

export function closeConnection() {
  if (ablyInstance) {
    ablyInstance.close();
    ablyInstance = null;
  }
}

export async function subscribeToChannel({ channelName, eventName, callback, presence = false }) {
  if (!ablyInstance) {
    throw new Error("Ably client is not initialized. Call initializeAbly first.");
  }

  const channel = ablyInstance.channels.get(channelName);
  
  if (presence) {
    await channel.presence.enter();
  }

  channel.subscribe(eventName, callback);

  return () => {
    channel.unsubscribe(eventName, callback);

    if (presence) {
      channel.presence.leave();
    }
  };
}

export async function presenceData(channelName) {
  if (!ablyInstance) {
    throw new Error("Ably client is not initialized. Call initializeAbly first.");
  }

  const channel = ablyInstance.channels.get(channelName);
  return channel.presence.get();
}
