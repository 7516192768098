<script>
  import Dashboard from "$layouts/Dashboard.svelte";
  import Ajax from "$utils/ajax";
  import { notify } from "$utils/notify";
  import { onMount } from "svelte";

  import ChatThreads from "./chat/ChatThreads.svelte";

  export let page;
  export let permissions;
  export let currentUser;

  let chatEnabled = page.chat_enabled;
  let isMobileView = false;

  onMount(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => window.removeEventListener("resize", checkMobileView);
  });

  function checkMobileView() {
    isMobileView = window.innerWidth < 768;
  }

  async function toggleChat() {
    try {
      const response = await Ajax.post("/dashboard/settings/save_chat", {
        chat_enabled: !chatEnabled,
      });

      chatEnabled = !chatEnabled;

      notify({
        message: `Chat ${chatEnabled ? "enabled" : "disabled"} successfully!`,
        type: "success",
      });

      if (chatEnabled) {
        setTimeout(() => location.reload(), 0);
      }
    } catch (error) {
      notify({
        message: "Failed to update chat settings",
        type: "error",
      });
    }
  }
</script>

<Dashboard
  {currentUser}
  {permissions}
  {page}
  ySpacing={!isMobileView}
  xSpacing={!isMobileView}
  bgColor={isMobileView ? "bg-white" : "bg-gray-50"}>
  <div
    class="bg-white {isMobileView
      ? 'min-h-[calc(100vh-7rem)]'
      : 'rounded-xl shadow-lg'} p-4 md:p-8 space-y-4 md:space-y-10">
    <div
      class="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 md:gap-6">
      <div class="flex items-center justify-between w-full md:w-auto">
        <h1 class="text-lg md:text-xl font-bold text-gray-800">Chat</h1>

        {#if permissions.settings}
          <div class="flex items-center gap-3 md:hidden">
            <span class="text-sm font-medium text-gray-600">
              {chatEnabled ? "Enabled" : "Disabled"}
            </span>
            <button
              on:click={toggleChat}
              class="relative inline-flex items-center h-7 rounded-full w-14 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-colors duration-200 ease-in-out"
              class:bg-purple-600={chatEnabled}
              class:bg-gray-300={!chatEnabled}>
              <span
                class="inline-block h-3 w-5 h-5 transform transition ease-in-out duration-200 bg-white rounded-full shadow-md"
                class:translate-x-8={chatEnabled}
                class:translate-x-1={!chatEnabled}></span>
            </button>
          </div>
        {/if}
      </div>

      {#if permissions.settings}
        <div class="hidden md:flex items-center gap-4">
          <span class="text-sm font-medium text-gray-600">
            {chatEnabled ? "Enabled" : "Disabled"}
          </span>
          <button
            on:click={toggleChat}
            class="relative inline-flex items-center h-7 rounded-full w-14 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-colors duration-200 ease-in-out"
            class:bg-purple-600={chatEnabled}
            class:bg-gray-300={!chatEnabled}>
            <span
              class="inline-block w-5 h-5 transform transition ease-in-out duration-200 bg-white rounded-full shadow-md"
              class:translate-x-8={chatEnabled}
              class:translate-x-1={!chatEnabled}></span>
          </button>
        </div>
      {/if}
    </div>

    {#if chatEnabled}
      <ChatThreads {page} />
    {:else}
      <div
        class="flex flex-col items-center justify-center h-[calc(100vh-20rem)] md:h-auto p-6 md:p-12 bg-purple-50 rounded-xl border-2 border-purple-200">
        <svg
          class="w-16 h-16 md:w-20 md:h-20 text-purple-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
        </svg>
        <h3 class="mt-4 text-lg md:text-xl font-semibold text-gray-900">
          Chat Feature
        </h3>
        <p
          class="mt-3 text-sm md:text-base text-gray-600 text-center max-w-md leading-relaxed">
          Enable chat to allow visitors to communicate with you directly through
          your profile page. Use the toggle above to enable chat functionality.
        </p>
      </div>
    {/if}
  </div>
</Dashboard>
