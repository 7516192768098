<script>
  import { onMount } from "svelte";
  import Ajax from "$utils/ajax";
  import { notify } from "$utils/notify";
  import Input from "$components/Input.svelte";
  import Alert from "$components/Alert.svelte";

  let members = [];
  let newMemberEmail = "";
  let newMemberRole = "member";
  let saving = false;
  let showConfirmAlert = false;
  let memberToRemove = null;

  onMount(async () => {
    const [error, response] = await Ajax.get("/dashboard/settings/members");
    if (!error && response.members) {
      members = response.members;
    }
  });

  async function addMember() {
    if (!newMemberEmail || !newMemberRole) {
      notify({
        message: "Please enter an email and select a role",
        type: "error",
      });
      return;
    }

    saving = true;
    const [errors, response] = await Ajax.post("/dashboard/settings/members", {
      email: newMemberEmail,
      role: newMemberRole,
    });

    if (errors) {
      notify({
        message: errors[0],
        type: "error",
      });
    } else {
      notify({
        message: "Member added successfully",
        type: "success",
      });
      members = [...members, response];
      newMemberEmail = "";
      newMemberRole = "member";
    }
    saving = false;
  }

  function confirmRemoveMember(memberId) {
    memberToRemove = memberId;
    showConfirmAlert = true;
  }

  async function removeMember() {
    if (!memberToRemove) return;

    saving = true;
    const [errors, response] = await Ajax.delete(
      `/dashboard/settings/members/${memberToRemove}`
    );

    if (errors && errors.length > 0) {
      notify({
        message: errors[0] || "Failed to remove member",
        type: "error",
      });
    } else {
      notify({
        message: "Member removed successfully",
        type: "success",
      });
      members = members.filter((m) => m.id !== memberToRemove);
    }
    saving = false;
    showConfirmAlert = false;
    memberToRemove = null;
  }

  async function updateMemberRole(memberId, newRole) {
    saving = true;
    const [errors, response] = await Ajax.put(
      `/dashboard/settings/members/${memberId}`,
      {
        role: newRole,
      }
    );

    if (errors && errors.length > 0) {
      notify({
        message: errors[0],
        type: "error",
      });

      let oldRole = newRole === "admin" ? "member" : "admin";

      members = members.map((m) =>
        m.id === memberId ? { ...m, role: oldRole } : m
      );
    } else {
      notify({
        message: "Role updated successfully",
        type: "success",
      });
      members = members.map((m) =>
        m.id === memberId ? { ...m, role: newRole } : m
      );
    }
    saving = false;
  }
</script>

<div class="sm:px-6 lg:px-8">
  <div class="bg-white shadow rounded-lg p-4 sm:p-6">
    <h1 class="text-xl font-bold mb-6">Members</h1>

    <div class="mb-8">
      <h2 class="text-lg font-semibold mb-4">Add New Member</h2>
      <div class="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-sm">
        <div
          class="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0"
        >
          <div class="flex-1">
            <Input
              id="email"
              type="email"
              bind:value={newMemberEmail}
              placeholder="Enter email address"
            />
          </div>
          <div class="flex-1/2">
            <select
              id="role"
              bind:value={newMemberRole}
              class="block w-full rounded-lg text-zinc-900 focus:ring-0 sm:text-sm sm:leading-6 border-zinc-300 focus:border-zinc-400 pl-3 pr-10 py-2 shadow-sm"
            >
              <option value="member">Member</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          <div class="flex-1/2">
            <button
              on:click={addMember}
              disabled={saving}
              class="w-full px-6 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition duration-150 ease-in-out flex items-center justify-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                  clip-rule="evenodd"
                />
              </svg>
              Add
            </button>
          </div>
        </div>
      </div>
    </div>

    <div>
      <h2 class="text-lg font-semibold mb-4">Current Members</h2>
      {#if members.length === 0}
        <p class="text-gray-500">No members added yet.</p>
      {:else}
        <ul class="space-y-4">
          {#each members as member (member.id)}
            <li
              class="bg-white p-4 rounded-lg shadow-xs border border-gray-200 transition-all duration-100 hover:shadow-sm"
            >
              <div
                class="flex flex-col sm:flex-row sm:items-center sm:justify-between"
              >
                <div
                  class="flex items-center space-x-4 mb-4 sm:mb-0 overflow-hidden pt-1 pb-1 -mt-1 -mb-1"
                >
                  <div class="bg-purple-100 rounded-lg p-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-8 w-8 text-purple-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                      />
                    </svg>
                  </div>
                  <div>
                    <p class="font-semibold text-gray-800">{member.email}</p>
                    <div class="flex flex-wrap items-center gap-2 mt-1">
                      <select
                        bind:value={member.role}
                        on:change={(event) =>
                          updateMemberRole(member.id, event.target.value)}
                        disabled={saving}
                        class="w-28 text-sm py-1 px-2 border border-gray-200 rounded-md focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-200 ease-in-out"
                      >
                        <option value="member">Member</option>
                        <option value="admin">Admin</option>
                      </select>
                      <span
                        class={`px-2 py-1 text-xs font-medium rounded-full ${
                          member.enabled
                            ? "bg-green-100 text-green-800"
                            : "bg-yellow-100 text-yellow-800"
                        }`}
                      >
                        {member.enabled
                          ? "Active"
                          : "User has not accepted invite"}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="flex justify-end">
                  <button
                    on:click={() => confirmRemoveMember(member.id)}
                    disabled={saving}
                    class="w-full sm:w-auto px-3 py-1 sm:py-1.5 bg-red-100 text-red-600 text-sm rounded-md hover:bg-red-200 focus:outline-none transition duration-150 ease-in-out flex items-center justify-center sm:justify-start space-x-1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                    <span>Remove</span>
                  </button>
                </div>
              </div>
            </li>
          {/each}
        </ul>
      {/if}
    </div>

    <div class="mt-8 p-4 bg-blue-50 rounded-md border border-blue-100">
      <div class="flex items-start">
        <div class="flex-shrink-0">
          <svg
            class="h-5 w-5 text-blue-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div class="ml-3">
          <h4 class="text-sm font-medium text-blue-800">Member</h4>
          <p class="mt-2 text-sm text-blue-700">
            Members can do everything except update settings.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<Alert
  isOpen={showConfirmAlert}
  title="Confirm Remove Member"
  message="Are you sure you want to remove this member?"
  type="warning"
  primaryAction="Remove"
  secondaryAction="Cancel"
  on:close={() => (showConfirmAlert = false)}
  on:primaryAction={removeMember}
  on:secondaryAction={() => (showConfirmAlert = false)}
/>
