<script>
  import Logo from "$components/Logo.svelte";
  import { slide } from "svelte/transition";

  let isMenuOpen = false;

  function toggleMenu() {
    isMenuOpen = !isMenuOpen;
  }
</script>

<nav
  class="fixed w-full z-50 backdrop-blur-lg bg-white/100 border-b border-purple-100">
  <div class="max-w-6xl mx-auto px-6 sm:px-6">
    <div class="flex justify-between items-center h-14 sm:h-16">
      <div class="flex items-center">
        <div
          class="transition-transform duration-200 hover:scale-102 scale-90 sm:scale-100">
          <Logo klass="!h-6 !sm:h-8" />
        </div>
        <div class="hidden md:block ml-8"></div>
      </div>
      <div class="hidden md:flex items-center space-x-6">
        <a
          href="/login"
          class="text-purple-600 hover:text-purple-800 text-sm font-medium transition-colors duration-200">
          Log In
        </a>
        <a
          href="/register"
          class="bg-purple-600 text-white px-5 py-2 rounded-full text-sm font-medium hover:bg-purple-700 transition-colors duration-200">
          Get Started
        </a>
      </div>
      <div class="md:hidden">
        <button class="mobile-menu-button p-2" on:click={toggleMenu}>
          <svg
            class="w-5 h-5 text-purple-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            {#if !isMenuOpen}
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M4 6h16M4 12h16M4 18h16"></path>
            {:else}
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M6 18L18 6M6 6l12 12"></path>
            {/if}
          </svg>
        </button>
      </div>
    </div>
  </div>
  {#if isMenuOpen}
    <div
      class="md:hidden mobile-menu bg-white shadow-lg"
      transition:slide={{ duration: 150 }}>
      <div class="h-px bg-purple-100"></div>

      <div class="px-6 py-4 space-y-2">
        <a
          href="/login"
          class="block px-4 py-3 text-sm text-purple-600 hover:text-purple-800 hover:bg-purple-50 rounded-xl transition-all duration-200">
          Log In
        </a>
        <a
          href="/register"
          class="block px-4 py-3 text-sm text-purple-600 hover:text-purple-800 hover:bg-purple-50 rounded-xl transition-all duration-200">
          Get Started
        </a>
      </div>
    </div>
  {/if}
</nav>
