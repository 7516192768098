<script>
  import { onMount } from "svelte";
  import BlockAnalytics from "$app-components/blocks/_bottom_actions/Analytics.svelte";

  export let topBlocks;

  let mounted = false;
  let selectedBlock = null;
  let isAnalyticsModalOpen = false;

  onMount(() => {
    mounted = true;
  });

  function getBlockIcon(blockType) {
    switch (blockType) {
      case "link":
        return "M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1";
      case "youtube":
        return "M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z M21 12a9 9 0 11-18 0 9 9 0 0118 0z";
      case "image":
        return "M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z";
      case "text":
        return "M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z";
      case "product":
        return "M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z";
      default:
        return "M12 6v6m0 0v6m0-6h6m-6 0H6";
    }
  }

  function getBlockTypeColor(blockType) {
    switch (blockType) {
      case "link":
        return "bg-blue-100 text-blue-800";
      case "youtube":
        return "bg-red-100 text-red-800";
      case "image":
        return "bg-green-100 text-green-800";
      case "text":
        return "bg-purple-100 text-purple-800";
      case "product":
        return "bg-yellow-100 text-yellow-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  }

  function handleClick(block) {
    selectedBlock = block;
    isAnalyticsModalOpen = true;
  }
</script>

<style>
  /* Add any additional styles here if needed */
</style>

<div class="overflow-x-auto">
  <div class="min-w-full bg-white rounded-lg overflow-hidden shadow-lg">
    {#if topBlocks.length > 0}
      <div class="bg-gray-800 text-white p-4 hidden md:flex">
        <div class="w-1/12 font-bold">Rank</div>
        <div class="w-7/12 font-bold">Block</div>
        <div class="w-2/12 font-bold">Type</div>
        <div class="w-2/12 font-bold">Clicks</div>
      </div>
      <div class="divide-y divide-gray-200">
        {#each topBlocks as block, index}
          {#if mounted}
            <div
              class="hover:bg-gray-100 transition-colors duration-200 cursor-pointer p-4 flex flex-col md:flex-row items-start md:items-center"
              on:click={() => handleClick(block)}>
              <div class="flex items-center w-full md:w-1/12 mb-2 md:mb-0">
                <span
                  class="text-2xl font-bold mr-3 md:mr-0 {index === 0
                    ? 'text-yellow-500'
                    : index === 1
                      ? 'text-gray-400'
                      : index === 2
                        ? 'text-yellow-700'
                        : 'text-gray-700'}">
                  {index + 1}
                </span>
              </div>
              <div
                class="w-full md:w-7/12 mb-2 md:mb-0 flex items-center justify-between">
                <div class="flex items-center">
                  <svg
                    class="w-6 h-6 text-blue-500 mr-4 flex-shrink-0 hidden md:block"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d={getBlockIcon(block.block_type)}></path>
                  </svg>
                  <span
                    class="font-medium text-gray-800 truncate max-w-full"
                    title={block.title}>
                    {block.title}
                  </span>
                </div>
                <div class="flex items-center md:hidden">
                  <svg
                    class="w-5 h-5 text-green-500 mr-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                    ></path>
                  </svg>
                  <span class="text-xl font-bold text-gray-700"
                    >{block.click_count}</span>
                </div>
              </div>
              <div
                class="w-full md:w-2/12 mb-2 md:mb-0 flex items-center justify-between md:justify-start">
                <span
                  class={`text-xs font-medium px-2 py-1 rounded-full ${getBlockTypeColor(block.block_type)}`}>
                  {block.block_type}
                </span>
              </div>
              <div class="w-full md:w-2/12 hidden md:block">
                <div class="flex items-center">
                  <svg
                    class="w-5 h-5 text-green-500 mr-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                    ></path>
                  </svg>
                  <span class="text-xl font-bold text-gray-700"
                    >{block.click_count}</span>
                </div>
              </div>
            </div>
          {/if}
        {/each}
      </div>
    {:else}
      <div class="p-4 text-center text-gray-500">
        No blocks with clicks yet.
      </div>
    {/if}
  </div>
</div>

{#if selectedBlock}
  <BlockAnalytics
    block={selectedBlock}
    showButton={false}
    bind:isModalOpen={isAnalyticsModalOpen} />
{/if}
