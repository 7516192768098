<script>
  import { createEventDispatcher } from "svelte";

  export let id = "";
  export let name = "";
  export let checked = false;
  export let disabled = false;
  export let value = "";
  export let label = "";
  export let klass = "";

  const dispatch = createEventDispatcher();

  function handleChange(event) {
    checked = event.target.checked;
    dispatch("change", { checked, value });
  }

  function toggleChecked() {
    if (!disabled) {
      checked = !checked;
      dispatch("change", { checked, value });
    }
  }

  $: classes = `
    h-4 w-4 rounded border-gray-300 text-purple-600
    ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}
    focus:ring-0 focus:ring-offset-0
    ${klass}
  `;
</script>

<div class="relative flex items-start">
  <div class="flex h-6 items-center">
    <input
      {id}
      {name}
      type="checkbox"
      bind:checked
      {disabled}
      {value}
      class={classes}
      on:change={handleChange}
      {...$$restProps}
    />
  </div>
  <div class="ml-3 text-sm leading-6">
    <slot name="label">
      {#if label}
        <label
          for={id}
          class="text-gray-900 {disabled ? 'opacity-50' : 'cursor-pointer'}"
          on:click={toggleChecked}
        >
          {label}
        </label>
      {/if}
    </slot>
    <slot name="description">
      <p class="text-gray-500"><slot></slot></p>
    </slot>
  </div>
</div>
