<script>
  import { spring } from "svelte/motion";
  import { getThemeFromId } from "$utils/themes";

  import ChatWindow from "./ChatWindow.svelte";

  export let page;
  export let userId;

  let isOpen = false;
  let theme = getThemeFromId(page).theme;
  let userFont = page.font;

  const buttonScale = spring(1);

  function toggleChat() {
    isOpen = !isOpen;
    buttonScale.set(isOpen ? 0.9 : 1);
    setTimeout(() => buttonScale.set(1), 100);
  }
</script>

<div class="fixed bottom-3 right-3 sm:!bottom-6 sm:!right-6 z-20">
  {#if isOpen}
    <ChatWindow {toggleChat} {page} {userId} />
  {:else}
    <button
      class="p-3 sm:p-4 rounded-full shadow-md hover:shadow-lg transition-all ml-auto"
      on:click={toggleChat}
      style="transform: scale({$buttonScale}); background-color: {theme.cardBackgroundColor}; color: {theme.linkColor};"
    >
      {#if !isOpen}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 sm:h-6 sm:w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
          />
        </svg>
      {/if}
    </button>
  {/if}
</div>

<style>
  /* Custom scrollbar for messages */
  .overflow-y-auto {
    scrollbar-width: thin;
    scrollbar-color: rgba(139, 92, 246, 0.3) transparent;
  }

  .overflow-y-auto::-webkit-scrollbar {
    width: 6px;
  }

  .overflow-y-auto::-webkit-scrollbar-track {
    background: transparent;
  }

  .overflow-y-auto::-webkit-scrollbar-thumb {
    background-color: rgba(139, 92, 246, 0.3);
    border-radius: 3px;
  }

  /* Button hover animation */
  button {
    transition: transform 0.2s ease;
  }
</style>
