<script>
  import { onMount } from "svelte";

  export let referrers;

  let mounted = false;

  onMount(() => {
    mounted = true;
  });

  // Group referrers by name and sum their views
  $: groupedReferrers = referrers.reduce((acc, curr) => {
    const name = curr.name.toLowerCase();
    if (!acc[name]) {
      acc[name] = { name, views: 0 };
    }
    acc[name].views += curr.views;
    return acc;
  }, {});

  // Convert to array and sort by views
  $: sortedReferrers = Object.values(groupedReferrers).sort(
    (a, b) => b.views - a.views
  );

  function getProgressBarWidth(views, maxViews) {
    return (views / maxViews) * 100;
  }

  $: maxViews =
    sortedReferrers.length > 0
      ? Math.max(...sortedReferrers.map((r) => r.views))
      : 0;
</script>

<div class="bg-white p-4 sm:p-6 rounded-lg shadow-lg">
  <div class="space-y-4">
    {#each sortedReferrers as referrer, index}
      {#if mounted}
        <div
          class="flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0 sm:space-x-4"
        >
          <div class="flex items-center sm:w-1/4">
            {#if referrer.name === "direct"}
              <span class="font-semibold text-gray-700 truncate"> Direct </span>
            {:else}
              <a
                href={referrer.name}
                target="_blank"
                rel="noopener noreferrer"
                class="font-normal text-purple-700 hover:text-purple-900 truncate mr-4"
              >
                {referrer.name}
              </a>
            {/if}
          </div>
          <div class="flex-grow sm:flex sm:justify-between sm:items-center">
            <div
              class="bg-gray-200 h-2 rounded-full overflow-hidden sm:w-1/2 hidden sm:block"
            >
              <div
                class="bg-purple-600 h-full rounded-full transition-all duration-500 ease-out"
                style="width: {getProgressBarWidth(referrer.views, maxViews)}%"
              ></div>
            </div>
            <div
              class="flex flex-wrap items-center space-x-3 sm:space-x-4 text-sm sm:w-1/2 sm:justify-end"
            >
              <span
                class="font-semibold text-blue-600 bg-blue-100 px-2 py-1 rounded"
              >
                {referrer.views.toLocaleString()} views
              </span>
            </div>
          </div>
        </div>
      {/if}
    {/each}
  </div>
  {#if sortedReferrers.length === 0}
    <p class="text-center text-gray-500 mt-4">
      No referrer data available yet.
    </p>
  {/if}
</div>

<style>
  /* Add any additional styles here if needed */
</style>
