<script>
  import { createEventDispatcher } from "svelte";
  import { notify } from "$utils/notify";

  import { cropper } from "$utils/cropper";
  import { showUploader } from "$utils/upload";

  import Button from "$components/Button.svelte";
  import Form from "$components/Form.svelte";
  import Image from "$components/Image.svelte";

  import Ajax from "$utils/ajax";

  const dispatch = createEventDispatcher();

  export let block = null;

  let saving = false;
  let fileInput;
  let isUploading = false;
  let uploadedImageUrl = block ? block.image_url : "";
  let width = block?.fields?.width || 100; // Default to 100%

  const saveImage = async (event) => {
    event.preventDefault();
    if (saving) return;
    saving = true;

    let sharedData = {
      image_url: uploadedImageUrl,
      block_type: "image",
      fields: {
        width: width,
      },
    };

    const [ajaxErrors, resp] = block
      ? await Ajax.put(`/dashboard/blocks/${block.id}`, sharedData)
      : await Ajax.post("/dashboard/blocks", sharedData);

    if (ajaxErrors) {
      notify({
        type: "error",
        text: block ? "Unable to update image" : "Unable to add image",
      });
    } else {
      if (!block) {
        uploadedImageUrl = "";
        width = 100;
      }

      if (block) {
        dispatch("edited-block", resp);
      } else {
        dispatch("added-block", resp);
      }

      dispatch("close");
    }

    saving = false;
  };

  async function handleFileSelected(event) {
    isUploading = true;

    try {
      const uploadedUrl = await showUploader({
        fileType: "image",
        crop: cropper.crop,
      });
      uploadedImageUrl = uploadedUrl;
    } catch (error) {
      notify({
        message: "Upload failed. Please try again.",
        type: "error",
      });
    } finally {
      isUploading = false;
    }
  }

  function clearImage() {
    uploadedImageUrl = "";
    width = 100;
  }
</script>

<h2 class="text-xl font-bold text-gray-800 mb-12 mt-4 text-center">
  {block ? "Edit Image" : "Add Image"}
</h2>

<Form on:submit={saveImage}>
  <div class="flex flex-col gap-8">
    <div class="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
      <div class="relative mb-4">
        <Button
          type="button"
          klass="w-full h-12 px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-purple-300 transition duration-200 ease-in-out !text-md"
          on:click={handleFileSelected}
        >
          Upload Image
        </Button>
      </div>

      <div class="w-full h-64 bg-gray-100 rounded-lg overflow-hidden relative">
        <Image
          src={uploadedImageUrl}
          alt="Uploaded image"
          objectFit="contain"
          klass="w-full h-full"
          style={`scale: ${width / 100}`}
        />
        {#if uploadedImageUrl}
          <Button
            type="button"
            klass="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded"
            on:click={clearImage}
          >
            Clear
          </Button>
        {/if}
      </div>

      {#if uploadedImageUrl}
        <div class="mt-6 bg-gray-50 p-4 rounded-lg">
          <label class="block text-sm font-medium text-gray-700 mb-3">
            Image Width <span
              class="text-purple-600 font-semibold ml-1 bg-purple-100 px-2 py-0.5 rounded"
              >{width}%</span
            >
          </label>
          <input
            type="range"
            min="50"
            max="100"
            bind:value={width}
            class="w-full h-3 bg-gray-200 rounded-full appearance-none cursor-pointer hover:bg-gray-300 focus:outline-none"
          />
          <div class="flex justify-between text-xs text-gray-500 mt-1">
            <span>50%</span>
            <span>100%</span>
          </div>
        </div>
      {/if}
    </div>

    <Button
      type="submit"
      klass="w-full h-12 mt-4"
      disabled={saving || isUploading || !uploadedImageUrl}
    >
      {#if saving}
        <span class="inline-block animate-spin mr-2">&#8635;</span>
      {/if}
      {block ? "Update Image" : "Add Image"}
    </Button>
  </div>
</Form>
