<script>
  import { fade } from "svelte/transition";
  import ThemeSelector from "../../dashboard/appearance/ThemeSelector.svelte";

  export let selectedTheme;

  let page = { theme: "classic-blue" };

  $: selectedTheme = page?.theme;

  // function convertLinktreeTheme(ltTheme) {
  //   if (!ltTheme || ltTheme.key !== "custom") return null;

  //   // Map Linktree theme to our theme format
  //   let backgroundColor = ltTheme.background?.color || "#ffffff";
  //   let textColor = ltTheme.typeface?.color || "#000000";
  //   let buttonColor = ltTheme.buttonStyle?.backgroundStyle?.color || "#ffffff";
  //   let buttonTextColor = ltTheme.buttonStyle?.textStyle?.color || "#000000";
  //   let shadowColor = ltTheme.buttonStyle?.shadowStyle?.color || "#000000";

  //   return {
  //     id: "custom",
  //     name: "Linktree Theme",
  //     theme: {
  //       backgroundImage,
  //       backgroundColor,
  //       textColor,
  //       linkColor: textColor,
  //       buttonColor,
  //       buttonTextColor,
  //       cardBackgroundColor: buttonColor,
  //       cardShadowColor: shadowColor,
  //       imagePlaceholderColor: "#666666",
  //       imagePlaceholderTextColor: "#cccccc",
  //     },
  //   };
  // }
</script>

<div in:fade>
  <div class="bg-gray-100 p-4 rounded-lg shadow-sm mb-8">
    <p class="text-base text-gray-700 text-center font-medium">
      Select a theme for your page - you can fully customize colors and styles
      after setup
    </p>
  </div>

  <ThemeSelector bind:page showCustom={false} />
</div>
