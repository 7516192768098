<script>
  import { onMount } from "svelte";
  import { fade, fly } from "svelte/transition";

  let blocks = [
    {
      type: "link",
      title: "My Website",
      url: "https://example.com",
      icon: "🌐",
      color: "from-blue-400 to-blue-600",
    },
    {
      type: "social",
      title: "Instagram",
      url: "https://instagram.com",
      icon: "📸",
      color: "from-pink-500 to-rose-500",
    },
    {
      type: "video",
      title: "Latest Video",
      thumbnail: "https://picsum.photos/300/100",
      icon: "🎥",
      color: "from-purple-500 to-purple-700",
    },
    {
      type: "text",
      title: "About Me",
      content: "Digital creator & content curator",
      icon: "✨",
      color: "from-amber-400 to-orange-500",
    },
  ];

  let isVisible = false;
  let observer;

  onMount(() => {
    observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        isVisible = true;
        observer.disconnect();
      }
    });

    observer.observe(document.querySelector("#grid-container"));

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  });
</script>

<style>
  .bg-grid-pattern {
    background-image: linear-gradient(to right, #6366f1 1px, transparent 1px),
      linear-gradient(to bottom, #6366f1 1px, transparent 1px);
    background-size: 24px 24px;
  }
</style>

<div
  id="grid-container"
  class="relative w-full h-[320px] bg-gradient-to-br from-purple-50 to-pink-50 rounded-2xl p-4 overflow-hidden">
  <div class="absolute inset-0 bg-grid-pattern opacity-5"></div>

  {#if isVisible}
    <div class="relative grid grid-cols-2 gap-3 h-full">
      {#each blocks as block, i}
        <div
          in:fly={{ y: 20, delay: i * 100, duration: 600 }}
          class="group relative bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
          <div
            class="absolute inset-0 bg-gradient-to-br {block.color} opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          </div>

          <div class="relative h-full p-3 flex flex-col justify-between">
            <div class="flex items-start justify-between">
              <span class="text-2xl">{block.icon}</span>
              <div
                class="w-7 h-7 rounded-lg bg-gray-100 group-hover:bg-white/20 flex items-center justify-center transition-colors duration-300">
                <svg
                  class="w-3.5 h-3.5 text-gray-500 group-hover:text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                </svg>
              </div>
            </div>

            <div class="mt-2">
              <h3
                class="text-base font-semibold text-gray-800 group-hover:text-white transition-colors duration-300">
                {block.title}
              </h3>
              {#if block.type === "text"}
                <p
                  class="mt-1 text-xs text-gray-600 group-hover:text-white/90 transition-colors duration-300">
                  {block.content}
                </p>
              {/if}
              {#if block.type === "video"}
                <img
                  src={block.thumbnail}
                  alt="Video thumbnail"
                  class="mt-1.5 rounded-lg" />
              {/if}
            </div>
          </div>
        </div>
      {/each}
    </div>
  {/if}
</div>
