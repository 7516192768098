export const currencies = [
  { code: "USD", symbol: "$" },
  { code: "EUR", symbol: "€" },
  { code: "GBP", symbol: "£" },
  { code: "JPY", symbol: "¥" },
  { code: "AUD", symbol: "A$" },
  { code: "CAD", symbol: "C$" },
  { code: "CHF", symbol: "CHF" },
  { code: "CNY", symbol: "¥" },
  { code: "SEK", symbol: "kr" },
  { code: "NZD", symbol: "NZ$" },
  { code: "MXN", symbol: "$" },
  { code: "SGD", symbol: "S$" },
  { code: "HKD", symbol: "HK$" },
  { code: "NOK", symbol: "kr" },
  { code: "KRW", symbol: "₩" },
  { code: "TRY", symbol: "₺" },
  { code: "RUB", symbol: "₽" },
  { code: "INR", symbol: "₹" },
  { code: "BRL", symbol: "R$" },
  { code: "ZAR", symbol: "R" },
  { code: "AED", symbol: "د.إ" },
  { code: "AFN", symbol: "؋" },
  { code: "ALL", symbol: "L" },
  { code: "AMD", symbol: "֏" },
  { code: "ANG", symbol: "ƒ" },
  { code: "AOA", symbol: "Kz" },
  { code: "ARS", symbol: "$" },
  { code: "AWG", symbol: "ƒ" },
  { code: "AZN", symbol: "₼" },
  { code: "BAM", symbol: "KM" },
  { code: "BBD", symbol: "$" },
  { code: "BDT", symbol: "৳" },
  { code: "BGN", symbol: "лв" },
  { code: "BHD", symbol: ".د.ب" },
  { code: "BIF", symbol: "FBu" },
  { code: "BMD", symbol: "$" },
  { code: "BND", symbol: "$" },
  { code: "BOB", symbol: "Bs." },
  { code: "BSD", symbol: "$" },
  { code: "BTN", symbol: "Nu." },
  { code: "BWP", symbol: "P" },
  { code: "BYN", symbol: "Br" },
  { code: "BZD", symbol: "BZ$" },
  { code: "CDF", symbol: "FC" },
  { code: "CLP", symbol: "$" },
  { code: "COP", symbol: "$" },
  { code: "CRC", symbol: "₡" },
  { code: "CUC", symbol: "$" },
  { code: "CUP", symbol: "₱" },
  { code: "CVE", symbol: "$" },
  { code: "CZK", symbol: "Kč" },
  { code: "DJF", symbol: "Fdj" },
  { code: "DKK", symbol: "kr" },
  { code: "DOP", symbol: "RD$" },
  { code: "DZD", symbol: "دج" },
  { code: "EGP", symbol: "£" },
  { code: "ERN", symbol: "Nfk" },
  { code: "ETB", symbol: "Br" },
  { code: "FJD", symbol: "$" },
  { code: "FKP", symbol: "£" },
  { code: "GEL", symbol: "₾" },
  { code: "GGP", symbol: "£" },
  { code: "GHS", symbol: "₵" },
  { code: "GIP", symbol: "£" },
  { code: "GMD", symbol: "D" },
  { code: "GNF", symbol: "FG" },
  { code: "GTQ", symbol: "Q" },
  { code: "GYD", symbol: "$" },
  { code: "HNL", symbol: "L" },
  { code: "HRK", symbol: "kn" },
  { code: "HTG", symbol: "G" },
  { code: "HUF", symbol: "Ft" },
  { code: "IDR", symbol: "Rp" },
  { code: "ILS", symbol: "₪" },
  { code: "IMP", symbol: "£" },
  { code: "IQD", symbol: "ع.د" },
  { code: "IRR", symbol: "﷼" },
  { code: "ISK", symbol: "kr" },
  { code: "JEP", symbol: "£" },
  { code: "JMD", symbol: "J$" },
  { code: "JOD", symbol: "د.ا" },
  { code: "KES", symbol: "KSh" },
  { code: "KGS", symbol: "лв" },
  { code: "KHR", symbol: "៛" },
  { code: "KMF", symbol: "CF" },
  { code: "KPW", symbol: "₩" },
  { code: "KWD", symbol: "د.ك" },
  { code: "KYD", symbol: "$" },
  { code: "KZT", symbol: "₸" },
  { code: "LAK", symbol: "₭" },
  { code: "LBP", symbol: "ل.ل" },
  { code: "LKR", symbol: "₨" },
  { code: "LRD", symbol: "$" },
  { code: "LSL", symbol: "L" },
  { code: "LYD", symbol: "ل.د" },
  { code: "MAD", symbol: "د.م." },
  { code: "MDL", symbol: "L" },
  { code: "MGA", symbol: "Ar" },
  { code: "MKD", symbol: "ден" },
  { code: "MMK", symbol: "K" },
  { code: "MNT", symbol: "₮" },
  { code: "MOP", symbol: "MOP$" },
  { code: "MRU", symbol: "UM" },
  { code: "MUR", symbol: "₨" },
  { code: "MVR", symbol: ".ރ" },
  { code: "MWK", symbol: "MK" },
  { code: "MYR", symbol: "RM" },
  { code: "MZN", symbol: "MT" },
  { code: "NAD", symbol: "$" },
  { code: "NGN", symbol: "₦" },
  { code: "NIO", symbol: "C$" },
  { code: "NPR", symbol: "₨" },
  { code: "OMR", symbol: "﷼" },
  { code: "PAB", symbol: "B/." },
  { code: "PEN", symbol: "S/." },
  { code: "PGK", symbol: "K" },
  { code: "PHP", symbol: "₱" },
  { code: "PKR", symbol: "₨" },
  { code: "PLN", symbol: "zł" },
  { code: "PYG", symbol: "₲" },
  { code: "QAR", symbol: "﷼" },
  { code: "RON", symbol: "lei" },
  { code: "RSD", symbol: "Дин." },
  { code: "RWF", symbol: "R₣" },
  { code: "SAR", symbol: "﷼" },
  { code: "SBD", symbol: "$" },
  { code: "SCR", symbol: "₨" },
  { code: "SDG", symbol: "ج.س." },
  { code: "SHP", symbol: "£" },
  { code: "SLL", symbol: "Le" },
  { code: "SOS", symbol: "S" },
  { code: "SRD", symbol: "$" },
  { code: "SSP", symbol: "£" },
  { code: "STN", symbol: "Db" },
  { code: "SVC", symbol: "$" },
  { code: "SYP", symbol: "£" },
  { code: "SZL", symbol: "L" },
  { code: "THB", symbol: "฿" },
  { code: "TJS", symbol: "SM" },
  { code: "TMT", symbol: "T" },
  { code: "TND", symbol: "د.ت" },
  { code: "TOP", symbol: "T$" },
  { code: "TTD", symbol: "TT$" },
  { code: "TWD", symbol: "NT$" },
  { code: "TZS", symbol: "TSh" },
  { code: "UAH", symbol: "₴" },
  { code: "UGX", symbol: "USh" },
  { code: "UYU", symbol: "$U" },
  { code: "UZS", symbol: "лв" },
  { code: "VES", symbol: "Bs" },
  { code: "VND", symbol: "₫" },
  { code: "VUV", symbol: "VT" },
  { code: "WST", symbol: "WS$" },
  { code: "XAF", symbol: "FCFA" },
  { code: "XCD", symbol: "EC$" },
  { code: "XOF", symbol: "CFA" },
  { code: "XPF", symbol: "₣" },
  { code: "YER", symbol: "﷼" },
  { code: "ZMW", symbol: "ZK" },
  { code: "ZWL", symbol: "Z$" },
];

export function getCurrencySymbol(currencyCode) {
  const currency = currencies.find((c) => c.code === currencyCode);
  return currency ? currency.symbol : "";
}

export function formatCurrency(amount, currencyCode) {
  const symbol = getCurrencySymbol(currencyCode);
  const formattedAmount = Number(amount).toFixed(2);
  const [integerPart, decimalPart] = formattedAmount.split(".");
  const displayAmount = decimalPart === "00" ? integerPart : formattedAmount;

  if (currencyCode === false) {
    return displayAmount;
  }

  if (symbol) {
    return `${symbol}${displayAmount}`;
  } else {
    return `${displayAmount} ${currencyCode}`;
  }
}
