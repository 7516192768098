<script>
  import { createEventDispatcher, onMount } from "svelte";

  export let action = null;
  export let method = "post";

  let formEl;
  let previousFormData;
  let csrfToken;

  const dispatch = createEventDispatcher();

  function handleSubmit(event) {
    if (action) {
      // If action is provided, let the form submit normally
      return;
    }
    event.preventDefault();
    const formData = new FormData(event.target);
    dispatch("submit", Object.fromEntries(formData));
  }

  function handleChange(event) {
    const formData = new FormData(event.target.form);
    const changedFields = {};

    for (let [key, value] of formData.entries()) {
      if (previousFormData.get(key) !== value) {
        // For checkboxes, use their checked state instead of value
        if (event.target.type === "checkbox") {
          changedFields[key] = event.target.checked;
        } else {
          changedFields[key] = value;
        }
      }
    }

    // Check for unchecked checkboxes (they won't appear in formData)
    const checkboxes = event.target.form.querySelectorAll(
      'input[type="checkbox"]'
    );
    checkboxes.forEach((checkbox) => {
      if (!checkbox.checked && previousFormData.get(checkbox.name) !== null) {
        changedFields[checkbox.name] = false;
      }
    });

    if (Object.keys(changedFields).length > 0) {
      dispatch("change", changedFields);
    }

    previousFormData = formData;
  }

  onMount(() => {
    previousFormData = new FormData(formEl);
    csrfToken = document
      .querySelector("meta[name='csrf-token']")
      ?.getAttribute("content");
  });
</script>

<form
  bind:this={formEl}
  on:submit={handleSubmit}
  on:change|preventDefault|stopPropagation={handleChange}
  {action}
  {method}
>
  {#if action && csrfToken}
    <input type="hidden" name="_csrf_token" value={csrfToken} />
  {/if}
  <slot />
</form>
