<script>
  import socialLinks from "$utils/social_links.js";

  export let links;

  // Sort social links by clicks in descending order and handle unknown platforms
  $: sortedSocialLinks = links
    .sort((a, b) => b.clicks - a.clicks)
    .map((link) => ({
      ...link,
      platform: socialLinks[link.platform] ? link.platform : "other",
    }));
</script>

{#if sortedSocialLinks.length === 0}
  <div class="bg-white rounded-lg shadow-md p-5">
    <div class="text-center text-gray-500 py-8">
      <i class="fas fa-chart-line text-4xl mb-4"></i>
      <p class="text-lg font-semibold">
        No social link data available for this month.
      </p>
      <p class="text-sm mt-2">
        <a
          href="/dashboard/appearance"
          class="text-purple-600 hover:text-purple-800 hover:underline"
          >Start sharing your links to see analytics here!</a
        >
      </p>
    </div>
  </div>
{:else}
  <div class="bg-white rounded-lg shadow-md p-5">
    <div class="space-y-3">
      {#each sortedSocialLinks as link, index}
        <div
          class="flex items-center border-b border-gray-200 pb-3 last:border-b-0 last:pb-0"
        >
          <div
            class="w-10 h-10 rounded-full flex items-center justify-center mr-3 transform hover:scale-110 transition-transform duration-200"
            style="background-color: {socialLinks[link.platform].color}"
          >
            <i class="{socialLinks[link.platform].icon} text-xl text-white"></i>
          </div>
          <div class="flex-grow ml-2">
            <h4
              class="font-bold text-base text-gray-900 {link.platform != 'other'
                ? 'capitalize'
                : ''}"
            >
              {link.platform === "other" ? link.url : link.platform}
            </h4>

            {#if link.platform != "other"}
              <a
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                class="text-xs text-blue-600 hover:text-blue-800 hover:underline truncate block"
              >
                {link.url.replace(socialLinks[link.platform].baseUrl, "")}
              </a>
            {/if}
          </div>
          <div class="text-right ml-3">
            <span class="text-2xl font-extrabold text-purple-600"
              >{link.clicks}</span
            >
            <p
              class="text-2xs uppercase tracking-wide text-gray-500 inline ml-2"
            >
              {@html link.clicks === 1 ? "click&nbsp;" : "clicks"}
            </p>
          </div>
        </div>
      {/each}
    </div>
  </div>
{/if}
