<script>
  import { createEventDispatcher } from "svelte";
  import { notify } from "$utils/notify";
  import { showUploader } from "$utils/upload";

  import Button from "$components/Button.svelte";
  import Form from "$components/Form.svelte";
  import Video from "$components/Video.svelte";
  import Input from "$components/Input.svelte";

  import Ajax from "$utils/ajax";

  const dispatch = createEventDispatcher();

  export let block = null;

  let saving = false;
  let videoUrl = block ? block.url : "";
  let title = block ? block.title : "";

  const saveVideo = async (event) => {
    event.preventDefault();
    if (saving) return;
    saving = true;

    let sharedData = {
      url: videoUrl,
      title: title,
      block_type: "video",
    };

    const [ajaxErrors, resp] = block
      ? await Ajax.put(`/dashboard/blocks/${block.id}`, sharedData)
      : await Ajax.post("/dashboard/blocks", sharedData);

    if (ajaxErrors) {
      notify({
        type: "error",
        text: block ? "Unable to update video" : "Unable to save video",
      });
    } else {
      if (block) {
        dispatch("edited-block", resp);
      } else {
        dispatch("added-block", resp);
      }

      dispatch("close");
    }

    saving = false;
  };

  const handleUpload = async () => {
    try {
      const uploadedUrl = await showUploader({ fileType: "video" });
      videoUrl = uploadedUrl;
    } catch (error) {
      notify({
        type: "error",
        text: "Failed to upload video",
      });
    }
  };

  const clearVideo = () => {
    videoUrl = "";
  };
</script>

<h2 class="text-xl font-bold text-gray-800 mb-12 mt-4 text-center">
  {block ? "Edit Video" : "Add Video"}
</h2>

<Form on:submit={saveVideo}>
  <div class="flex flex-col gap-8">
    <div class="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
      <div class="relative mb-10">
        <Input
          label="Video Title"
          name="title"
          bind:value={title}
          placeholder="Enter video title"
          required
        />
      </div>

      <div class="relative mb-4">
        <Button
          type="button"
          klass="w-full h-12 px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-purple-300 transition duration-200 ease-in-out !text-md"
          on:click={handleUpload}
        >
          Upload Video
        </Button>
      </div>

      <div class="w-full h-64 bg-gray-100 rounded-lg overflow-hidden relative">
        {#if videoUrl}
          <Video src={videoUrl} />
          <Button
            type="button"
            klass="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded"
            on:click={clearVideo}
          >
            Clear
          </Button>
        {:else}
          <div class="h-full flex items-center justify-center text-gray-500">
            <span>No video uploaded</span>
          </div>
        {/if}
      </div>
    </div>

    <Button
      type="submit"
      klass="w-full h-12 mt-4"
      disabled={saving || !videoUrl || !title}
    >
      {#if saving}
        <span class="inline-block animate-spin mr-2">&#8635;</span>
      {/if}
      {block ? "Update Video" : "Add Video"}
    </Button>
  </div>
</Form>
