<script>
  import { onMount, tick } from "svelte";
  import { fade } from "svelte/transition";
  import Ajax from "$utils/ajax";
  import Alert from "$components/Alert.svelte";

  export let messages = [];
  export let thread;
  export let onBack;
  export let isMobileView = false;
  export let page;
  export let loadingPromise;

  let messagesContainer;
  let messageInput;
  let newMessage = "";
  let isSending = false;
  let isClosing = false;
  let showArchiveModal = false;

  async function scrollToBottom() {
    await tick();

    if (messagesContainer) {
      messagesContainer.scrollTo({
        top: messagesContainer.scrollHeight,
        behavior: "auto",
      });
    }
  }

  async function handleSendMessage() {
    if (!newMessage.trim() || isSending) return;

    isSending = true;

    const [error, response] = await Ajax.post("/api/chat_messages", {
      guest_user_id: thread.user_id,
      page_id: page.id,
      body: newMessage,
    });

    if (!error) {
      newMessage = "";
      await tick();

      if (loadingPromise) {
        await loadingPromise;
      }

      if (isMobileView) {
        messageInput?.blur();
      }

      await scrollToBottom();
    }

    isSending = false;
  }

  function formatDate(dateString) {
    return new Date(dateString).toLocaleString([], {
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  async function handleClose() {
    isClosing = true;
    await tick();
    setTimeout(() => {
      onBack();
    }, 200); // Match animation duration
  }

  async function handleArchive() {
    const [error] = await Ajax.post("/dashboard/chat/archive", {
      guest_user_id: thread.user_id,
    });

    if (!error) {
      showArchiveModal = false;
      onBack();
    }
  }

  onMount(async () => {
    await scrollToBottom();
    if (!isMobileView) {
      messageInput?.focus();
    }
  });
</script>

<div
  class="flex flex-col h-full w-full {isMobileView
    ? 'fixed inset-0 ' + (isClosing ? 'slide-down' : 'slide-up')
    : ''} bg-white"
>
  <!-- Fixed info bar -->
  <div
    class="flex-none border-b border-gray-200 bg-white p-3 md:p-4 flex items-center justify-between z-10 shadow-sm"
  >
    {#if isMobileView}
      <button class="p-2 hover:bg-gray-100 rounded-full" on:click={handleClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-gray-600"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    {/if}

    <div class="flex items-center gap-8 text-sm text-gray-600">
      {#if !isMobileView}
        {#if thread.location}
          <div class="flex items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            <span>{thread.location}</span>
          </div>
        {/if}

        <div class="flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
          <span>{thread.ip_address}</span>
        </div>
      {/if}
    </div>

    <button
      class="inline-flex items-center gap-2 px-4 py-1.5 text-sm font-medium text-red-700 bg-red-50 border border-red-200 rounded-lg hover:bg-red-100 hover:border-red-300 transition-all duration-200 focus:outline-none shadow-sm"
      on:click={() => {
        showArchiveModal = true;
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-4 h-4"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M21 8v13H3V8M1 3h22v5H1zM10 12h4" />
      </svg>
      <span>Archive</span>
    </button>
  </div>

  <div bind:this={messagesContainer} class="flex-1 p-4 md:p-6 overflow-y-auto">
    {#each messages as message, i}
      <div
        class="mb-4 flex"
        class:justify-end={message.author_type === "admin"}
        transition:fade|local={{ duration: 100 }}
        class:no-fade={i < messages.length - 1}
      >
        <div
          class="max-w-[85%] md:max-w-[80%] p-3 md:p-4 rounded-2xl shadow-sm hover:shadow-md transition-shadow"
          class:bg-gradient-to-br={message.author_type === "admin"}
          class:from-purple-600={message.author_type === "admin"}
          class:to-purple-700={message.author_type === "admin"}
          class:text-white={message.author_type === "admin"}
          class:bg-gray-50={message.author_type === "guest"}
          class:rounded-tr-none={message.author_type === "admin"}
          class:rounded-tl-none={message.author_type === "guest"}
          class:border={message.author_type === "guest"}
          class:border-gray-200={message.author_type === "guest"}
        >
          <p
            class={`leading-relaxed text-sm md:text-base ${message.author_type === "admin" ? "text-white" : "text-gray-800"}`}
          >
            {message.body}
          </p>
          <p
            class={`text-xs mt-2 ${message.author_type === "admin" ? "text-purple-200" : "text-gray-400"}`}
          >
            {formatDate(message.created_at)}
          </p>
        </div>
      </div>
    {/each}
  </div>

  <!-- Message input -->
  <div class="flex-none p-3 md:p-4 border-t border-gray-200 bg-white">
    <form
      on:submit|preventDefault={handleSendMessage}
      class="flex gap-2 md:gap-3"
    >
      <input
        bind:this={messageInput}
        type="text"
        bind:value={newMessage}
        placeholder="Type your message..."
        class="flex-1 p-2 md:p-3 px-4 md:px-6 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent text-sm md:text-base"
      />
      <button
        type="submit"
        disabled={isSending}
        class="px-4 md:px-6 py-2 md:py-3 bg-purple-600 text-white rounded-full hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition-colors duration-0 text-sm md:text-base disabled:opacity-75 disabled:cursor-not-allowed"
      >
        {#if isSending}
          <svg
            class="animate-spin h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        {:else}
          Send
        {/if}
      </button>
    </form>
  </div>
</div>

<Alert
  bind:isOpen={showArchiveModal}
  title="Archive Conversation"
  message="Are you sure you want to archive this conversation? This action cannot be undone."
  type="error"
  primaryAction="Archive"
  secondaryAction="Cancel"
  on:primaryAction={handleArchive}
  on:secondaryAction={() => (showArchiveModal = false)}
/>

<style>
  .no-fade {
    animation: none !important;
  }

  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes slideDown {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(100%);
    }
  }

  .slide-up {
    animation: slideUp 0.2s ease-out forwards;
  }

  .slide-down {
    animation: slideDown 0.2s ease-out forwards;
  }
</style>
