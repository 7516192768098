<script>
  import { afterUpdate, onMount, onDestroy } from "svelte";

  import { notify } from "$utils/notify";
  import { chatStore } from "$stores/chat-store";
  import { initializeAbly, subscribeToChannel } from "$utils/ably";

  import Sidebar from "$layouts/shared/Sidebar.svelte";
  import TabBar from "$layouts/shared/TabBar.svelte";
  import Logo from "$components/Logo.svelte";

  import "../entrypoints/prioritize.css";

  export let permissions;
  export let ySpacing = true;
  export let xSpacing = true;
  export let bgColor = "bg-gray-50";
  export let currentUser;
  export let page;

  let toggleSidebar;
  let sidebarShouldCollapse;

  function displayNotice() {
    const urlParams = new URLSearchParams(window.location.search);
    const notice = urlParams.get("notice");

    if (notice) {
      notify({
        message: notice,
        type: "error",
      });

      setTimeout(() => {
        window.history.replaceState({}, document.title, location.pathname);
      }, 0);
    }
  }

  async function subscribeToPage() {
    if (
      page?.chat_enabled &&
      typeof window.realtimeSubscribed === "undefined"
    ) {
      window.realtimeSubscribed = true;
      await initializeAbly();
      await subscribeToChannel({
        channelName: page.realtime_token,
        eventName: "general",
        presence: true,
        callback: (message) => {
          if (message?.data?.action == "refresh-chat") {
            window.dispatchEvent(new CustomEvent("refresh-chat"));
            chatStore.setUnread(true);
          }
        },
      });
    }
  }

  afterUpdate(() => {
    displayNotice();
  });

  onMount(async () => {
    if (currentUser && currentUser.email && typeof H !== "undefined") {
      H.identify(currentUser.email, {
        id: currentUser.id,
      });
    }

    subscribeToPage();
  });
</script>

<div class="flex overflow-hidden {bgColor}">
  <!-- Desktop Sidebar -->
  <div class="hidden lg:block">
    <Sidebar
      bind:toggleSidebar
      {permissions}
      bind:shouldCollapse={sidebarShouldCollapse}
    />
  </div>

  <!-- Main content -->
  <div
    class="flex-1 overflow-hidden {sidebarShouldCollapse
      ? 'lg:pl-16'
      : 'lg:pl-72'}"
  >
    <div class="lg:hidden">
      <div
        class="flex items-center justify-between bg-gray-50 border-b border-gray-200 px-4 py-1.5"
      >
        <div class="mt-1 mb-1">
          <Logo klass="w-auto" height="22px" />
        </div>
      </div>
    </div>

    <main
      class="flex-1 relative overflow-y-auto focus:outline-none pb-16 lg:pb-0"
    >
      <div class="{ySpacing ? 'py-8' : ''} relative">
        <div class={xSpacing ? "px-4 sm:px-6 lg:px-8" : ""}>
          <slot />
        </div>
      </div>
    </main>

    <!-- Mobile Tab Bar -->
    <div class="lg:hidden">
      <TabBar {permissions} />
    </div>
  </div>
</div>
