<script>
  import DefaultLayout from "$layouts/Default.svelte";
  import { goto } from "$utils/goto";

  import Form from "$components/Form.svelte";
  import Input from "$components/Input.svelte";
  import Button from "$components/Button.svelte";
  import Card from "$components/Card.svelte";
  import Link from "$components/Link.svelte";
  import Logo from "$components/Logo.svelte";

  import Ajax from "$utils/ajax";

  import { notify } from "$utils/notify";

  export let email;
  export let user;
  export let tokenPage;

  let errors = {};

  let resetPassword = async (event) => {
    let data = event.detail;
    const [errors, resp] = await Ajax.post("/forgot_password", data);

    if (errors) {
      notify({ message: errors.join("\n"), type: "error" });
    } else {
      notify({
        message: "Password reset instructions sent to your email",
        type: "success",
      });

      goto("/login");
    }
  };

  let changePassword = async (event) => {
    if (event.detail.password !== event.detail.password_confirmation) {
      errors.password_confirmation = "Passwords do not match";
      return;
    }

    let data = event.detail;
    const [ajaxErrors, resp] = await Ajax.put("/forgot_password/x", data);

    if (ajaxErrors) {
      errors = ajaxErrors;
    } else {
      notify({
        message: "Your password has been successfully changed",
        type: "success",
      });

      location.href = "/dashboard";
    }
  };
</script>

<DefaultLayout>
  <div class="flex justify-center mb-16">
    <Logo />
  </div>

  <Card size="lg" klass="mx-auto max-w-sm">
    <svelte:fragment slot="title">
      <h2 class="text-xl font-extrabold text-center mb-8">
        {#if tokenPage}
          {#if user}
            Change Password
          {:else}
            Invalid Link
          {/if}
        {:else}
          Forgot Password
        {/if}
      </h2>
      {#if !tokenPage}
        <p class="text-sm text-gray-600 text-center mb-8">
          Remember your password? <Link to="/login">Log in</Link> to your account.
        </p>
      {/if}
    </svelte:fragment>

    <svelte:fragment slot="content">
      <div class="pt-2">
        {#if tokenPage}
          {#if user}
            <Form on:submit={changePassword}>
              <div class="space-y-5 flex flex-col gap-2">
                <Input
                  name="password"
                  type="password"
                  label="New Password"
                  error={errors.password}
                  required />
                <Input
                  name="password_confirmation"
                  type="password"
                  label="Confirm New Password"
                  error={errors.password_confirmation}
                  required />
                <div>
                  <Button
                    type="submit"
                    enableAnimation={true}
                    klass="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-md transition mt-2 duration-300">
                    Change Password <span aria-hidden="true" class="ml-2"
                      >→</span>
                  </Button>
                </div>
              </div>
            </Form>
          {:else}
            <p class="text-center text-red-600">
              This password reset link is invalid or has expired. Please request
              a new one.
            </p>
            <div class="mt-4">
              <Link
                to="/forgot_password"
                klass="w-full bg-purple-600 mt-8 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-md transition mt-2 duration-300 text-center block">
                Request New Link
              </Link>
            </div>
          {/if}
        {:else}
          <Form on:submit={resetPassword}>
            <div class="space-y-5 flex flex-col gap-2">
              <Input
                name="email"
                type="email"
                label="Email"
                required
                value={email} />

              <div>
                <Button
                  type="submit"
                  enableAnimation={true}
                  klass="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-md transition mt-2 duration-300">
                  Reset Password <span aria-hidden="true" class="ml-2">→</span>
                </Button>
              </div>
            </div>
          </Form>
        {/if}
      </div>
    </svelte:fragment>
  </Card>
</DefaultLayout>
