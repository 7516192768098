<script>
  import Modal from "$components/Modal.svelte";
  import Ajax from "$utils/ajax";
  import { notify } from "$utils/notify";

  export let block;
  export let updater;

  let isModalOpen = false;
  let selectedLocks = [];
  let isSaving = false;
  let customCode = "";
  let minAge = 18;

  const excludedTypes = ["text", "heading", "image", "map"];

  const lockTypes = [
    {
      id: "code",
      label: "Access Code",
      description: "Require a specific code to unlock",
      icon: `<path stroke-linecap="round" stroke-linejoin="round" d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />`,
    },
    {
      id: "age",
      label: "Age Verification",
      description: "Verify visitor's age before access",
      icon: `<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />`,
    },
    {
      id: "sensitive",
      label: "Sensitive Content",
      description: "Show content warning before access",
      icon: `<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />`,
    },
  ];

  function loadExistingLocks() {
    if (block.locks) {
      selectedLocks = block.locks.map((lock) => lock.lock_type);

      block.locks.forEach((lock) => {
        if (lock.lock_type === "code" && lock.data?.code) {
          customCode = lock.data.code;
        } else if (lock.lock_type === "age" && lock.data?.min_age) {
          minAge = lock.data.min_age;
        }
      });
    }
  }

  $: if (isModalOpen) {
    loadExistingLocks();
  }

  function toggleLock(lockId) {
    const existingLock = selectedLocks.find((l) => l === lockId);

    if (existingLock) {
      selectedLocks = selectedLocks.filter((l) => l !== lockId);
    } else {
      selectedLocks = [...selectedLocks, lockId];
    }
  }

  function closeModal() {
    isModalOpen = false;
  }

  function validate(data) {
    if (data.code && !customCode?.trim()) {
      notify({
        type: "error",
        message: "Please enter an access code",
      });

      return false;
    }

    if (data.age && !minAge) {
      notify({
        type: "error",
        message: "Please enter a minimum age",
      });

      return false;
    }

    return true;
  }

  async function saveAndClose() {
    isSaving = true;

    let data = {};

    selectedLocks.forEach((lockId) => {
      const lock = lockTypes.find((l) => l.id === lockId);
      data[lock.id] = {};

      if (lock.id === "code") {
        data[lock.id]["code"] = customCode;
      } else if (lock.id === "age") {
        data[lock.id]["min_age"] = minAge;
      }
    });

    if (validate(data) === false) {
      isSaving = false;
      return;
    }

    const [error, response] = await Ajax.put(
      `/dashboard/blocks/${block.id}/update_locks`,
      {
        locks: data,
      }
    );

    if (error) {
      notify({
        type: "error",
        message: error?.data?.[0] || "An error occurred. Please try again.",
      });
    } else {
      block.locks = response;

      updater(({ blocks }) => {
        const updatedBlocks = blocks.map((b) => {
          if (b.id === block.id) {
            return { ...b, locks: response };
          }
          return b;
        });
        return { blocks: updatedBlocks };
      });

      closeModal();
    }

    isSaving = false;
  }

  function handleInputClick(e) {
    e.stopPropagation();
  }
</script>

<Modal bind:isOpen={isModalOpen} size="md">
  <div slot="header" class="px-4 sm:px-6">
    <h3 class="text-md sm:text-xl font-bold text-gray-900">Lock Content</h3>
  </div>

  <div class="mt-6 px-4 sm:px-6">
    <div class="bg-purple-50 rounded-lg p-4 mb-6 border border-purple-100">
      <h4 class="text-purple-800 font-medium mb-2">Content Protection</h4>
      <p class="text-sm text-purple-700">
        Set visitor requirements to access content. Multiple locks can be
        combined.
      </p>
    </div>

    <div class="space-y-3">
      {#each lockTypes as lock}
        <button
          class="w-full relative p-3 sm:p-4 rounded-lg border-2 transition-all duration-200 hover:shadow-md text-left
            {selectedLocks.some((l) => l === lock.id)
            ? 'border-purple-500 bg-purple-50'
            : 'border-gray-200 hover:border-purple-300'}"
          on:click={() => toggleLock(lock.id)}
        >
          <div class="flex items-start sm:items-center">
            <div class="flex-shrink-0">
              <svg
                class="h-5 w-5 sm:h-6 sm:w-6 text-purple-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="1.5"
              >
                {@html lock.icon}
              </svg>
            </div>
            <div class="ml-3 sm:ml-4">
              <h4 class="text-base sm:text-lg font-medium text-gray-900">
                {lock.label}
              </h4>
              <p class="text-xs sm:text-sm text-gray-500">{lock.description}</p>
            </div>
          </div>

          {#if selectedLocks.some((l) => l === lock.id)}
            <div class="absolute -right-2 -top-2">
              <span
                class="flex h-5 w-5 sm:h-6 sm:w-6 items-center justify-center rounded-full bg-purple-500 text-white"
              >
                <svg
                  class="h-3 w-3 sm:h-4 sm:w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </div>

            {#if lock.id === "code"}
              <div class="mt-3 sm:mt-4">
                <input
                  type="text"
                  bind:value={customCode}
                  on:click={handleInputClick}
                  placeholder="Enter access code"
                  class="w-full text-sm rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                />
              </div>
            {/if}

            {#if lock.id === "age"}
              <div class="mt-3 sm:mt-4">
                <input
                  type="number"
                  bind:value={minAge}
                  on:click={handleInputClick}
                  min="13"
                  max="100"
                  class="w-full text-sm rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                />
              </div>
            {/if}
          {/if}
        </button>
      {/each}
    </div>
  </div>

  <div
    class="mt-6 sm:mt-8 flex justify-end space-x-3 border-t pt-4 sm:pt-6 px-4 sm:px-6"
  >
    <button
      type="button"
      class="px-3 sm:px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none"
      on:click={closeModal}
      disabled={isSaving}
    >
      Cancel
    </button>
    <button
      type="button"
      class="inline-flex justify-center rounded-md border border-transparent bg-purple-600 px-3 sm:px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none disabled:opacity-50"
      on:click={saveAndClose}
      disabled={isSaving}
    >
      {#if isSaving}
        <svg
          class="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        Saving...
      {:else}
        Save
      {/if}
    </button>
  </div>
</Modal>

{#if excludedTypes.includes(block.block_type) === false}
  <button
    on:click={() => (isModalOpen = true)}
    class="{block.locks.length > 0
      ? 'text-purple-500'
      : 'text-gray-400'} hover:text-purple-500 transition-colors duration-200 ease-in-out relative group mr-4"
    title="Lock"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-4 w-4"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
      <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
    </svg>
    <span
      class="absolute bottom-[140%] left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap"
    >
      Lock
    </span>
  </button>
{/if}
