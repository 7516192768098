<script>
  import { createEventDispatcher } from "svelte";
  import { formatCurrency } from "$utils/currency";
  import View from "$app-components/blocks/_layouts/View.svelte";
  import Image from "$components/Image.svelte";

  const dispatch = createEventDispatcher();

  export let block;
  export let updater;

  function handleDelete() {
    dispatch("delete-block");
  }

  $: formattedPrice =
    block.fields.price && parseFloat(block.fields.price) !== 0
      ? formatCurrency(block.fields.price, block.fields.currency)
      : null;
</script>

<View {block} {updater} on:delete-block={handleDelete}>
  <Image
    src={block.fields.images && block.fields.images.length > 0
      ? block.fields.images[0]
      : ""}
    alt={block.title}
    klass="w-12 h-12 rounded-md object-cover flex-shrink-0" />
  <div class="flex-1 min-w-0 pl-2">
    <h3 class="text-md font-semibold text-gray-900 truncate mb-1">
      {block.title}
    </h3>
    {#if formattedPrice}
      <div class="flex items-center">
        <span
          class="text-sm font-medium text-white bg-emerald-700 px-3 py-1 rounded-full flex-shrink-0">
          {formattedPrice}
        </span>
      </div>
    {/if}
  </div>
</View>
