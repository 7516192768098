<script>
  export let blocks;

  $: directRedirectBlock = blocks?.find((block) => block.direct_redirect);
  $: expiresAt = directRedirectBlock?.redirect_datetime_expires_at
    ? new Date(directRedirectBlock.redirect_datetime_expires_at)
    : null;
</script>

{#if directRedirectBlock}
  <div class="bg-white border border-purple-400 p-3 rounded-lg mb-4">
    <div class="flex items-center space-x-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4 text-purple-500 flex-shrink-0"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M13 7l5 5m0 0l-5 5m5-5H6"
        />
      </svg>
      <div class="min-w-0 flex-1">
        <div class="flex items-center space-x-2">
          <span class="text-xs text-gray-600 shrink-0">Redirecting to:</span>
          <a
            href={directRedirectBlock.url}
            class="text-xs text-purple-600 hover:text-purple-800 truncate"
            target="_blank"
            rel="noopener noreferrer"
          >
            {directRedirectBlock.url.replace("https://", "")}
          </a>
        </div>
        {#if expiresAt}
          <div class="text-xs text-gray-500 flex items-center mt-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-3 w-3 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            Expires: {expiresAt.toLocaleString()}
          </div>
        {/if}
      </div>
    </div>
  </div>
{/if}
