<script>
  import { createEventDispatcher } from "svelte";

  export let type = "text";
  export let name = "";
  export let value = "";
  export let placeholder = "";
  export let required = false;
  export let disabled = false;
  export let error = null;
  export let klass = "";
  export let label = "";
  export let hint = null;
  export let prepend = "";
  export let containerClass = "w-full"; // New prop for container customization
  export let self;

  const dispatch = createEventDispatcher();

  const baseClasses =
    "block w-full rounded-lg text-zinc-900 focus:ring-0 sm:text-sm sm:leading-6 border-zinc-300 focus:border-zinc-400";

  const stateClasses = {
    default: "",
    error: "ring-red-300 text-red-900 focus:ring-red-500",
    disabled: "bg-gray-50 text-gray-500 cursor-not-allowed",
  };

  $: inputClasses = `
    ${baseClasses}
    ${error ? stateClasses.error : stateClasses.default}
    ${disabled ? stateClasses.disabled : ""}
    ${klass}
    ${prepend || $$slots.prepend ? "rounded-l-none" : ""}
  `;

  function handleInput(event) {
    value = event.target.value;
    dispatch("input", { value });
  }

  function handleFocus(event) {
    dispatch("focus", event);
  }

  function handleBlur(event) {
    dispatch("blur", event);
  }

  function handleKeydown(event) {
    dispatch("keydown", { event, value, data: { [name]: value } });
  }

  function handleKeyup(event) {
    dispatch("keyup", { event, value, data: { [name]: value } });
  }

  function handleKeypress(event) {
    dispatch("keypress", { event, value, data: { [name]: value } });
  }
</script>

<div class={containerClass}>
  <slot name="label">
    {#if label}
      <label
        for={name}
        class="block text-sm font-medium leading-6 text-gray-900 mb-1.5"
      >
        <slot name="label-content">
          {label}
          {#if required}
            <span class="text-red-500 ml-1">*</span>
          {/if}
        </slot>
      </label>
    {/if}
  </slot>

  <div class="flex rounded-md shadow-sm">
    {#if prepend || $$slots.prepend}
      <span
        class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm"
      >
        <slot name="prepend">
          {prepend}
        </slot>
      </span>
    {/if}

    <input
      {type}
      {name}
      {placeholder}
      {required}
      {disabled}
      id={name}
      class={inputClasses}
      {value}
      bind:this={self}
      on:input={handleInput}
      on:focus={handleFocus}
      on:blur={handleBlur}
      on:keydown={handleKeydown}
      on:keyup={handleKeyup}
      on:keypress={handleKeypress}
    />
  </div>

  {#if error}
    <slot name="error">
      <div class="flex items-center mt-2">
        <svg
          class="h-5 w-5 text-red-500 mr-2 shrink-0"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
        <p class="text-sm text-red-600">{error}</p>
      </div>
    </slot>
  {:else if hint}
    <p class="mt-2 text-sm text-gray-500">{hint}</p>
  {/if}
</div>
