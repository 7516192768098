import { writable } from 'svelte/store';

// Create a store to persist sidebar state
export const sidebarStore = writable({
  isOpen: false,
  isHovered: false
});

// Subscribe to store changes to handle body overflow
sidebarStore.subscribe(($sidebarStore) => {
  if (typeof document !== 'undefined') { // Check if running in browser
    if ($sidebarStore.isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }
});
