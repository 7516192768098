<script>
  import Page from "$app-components/blocks/_layouts/Page.svelte";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;
  export let blockClicked = function () {};
  export let blockViewed = function () {};
  export let onActualPage;

  function getSpringUrl(url) {
    // Check if it's a product URL or main website
    const productMatch = url.includes("?product");

    if (productMatch) {
      return url;
    } else {
      const slugMatch = url.match(/\/\/([^.]+)/);
      const slug = slugMatch ? slugMatch[1] : "";
      return `https://embed.creator-spring.com/linktree?slug=${slug}&currency=USD&currency-code=false`;
    }
  }

  $: springUrl = getSpringUrl(block.url);
</script>

<Page
  {block}
  {theme}
  {buttonStyle}
  {hoverEffect}
  {blockClicked}
  {blockViewed}
  iframeUrl={springUrl}
  klass="bg-white overflow-hidden"
  goToButtonText={block.url != springUrl ? "Open in Spring" : null}
  width={block.url == springUrl ? "90vw" : "650px"}
  height={block.url == springUrl ? "90vh" : "630px"}
  {onActualPage}
/>
