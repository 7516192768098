<script>
  import Button from "$components/Button.svelte";
  import Card from "$components/Card.svelte";
  import Modal from "$components/Modal.svelte";
  import { fade } from "svelte/transition";
  import { onMount } from "svelte";

  import blockTypes from "../blocks/_blocks.js";
  import { notify } from "$utils/notify";

  export let isOpen = false;
  export let updater;

  let view = "options";
  let saving = false;

  let selectedBlockType = null;
  let BlockComponent;
  let activeSection;
  let tabsContainer;
  let searchTerm = "";
  let filteredBlockTypes = [];

  // Dynamically generate sections based on componentTab
  const sections = Object.entries(blockTypes).reduce((acc, [type, block]) => {
    if (!acc[block.componentTab]) {
      acc[block.componentTab] = [];
    }
    acc[block.componentTab].push(type);
    return acc;
  }, {});

  // Set the initial active section to the first section
  $: activeSection = Object.keys(sections)[0];

  const closeModal = () => {
    isOpen = false;
    reset();
  };

  const selectBlockType = (type) => {
    selectedBlockType = type;
    view = "form";
    BlockComponent = blockTypes[type].addEditComponent;
    searchTerm = "";
  };

  const showOptions = () => {
    view = "options";
    selectedBlockType = null;
  };

  const reset = () => {
    view = "options";
    saving = false;
    selectedBlockType = null;
    BlockComponent = null;
    activeSection = Object.keys(sections)[0];
    searchTerm = "";
  };

  const handleAddedBlock = (event) => {
    updater(({ blocks }) => {
      const newBlocks = [...blocks, event.detail];
      return { blocks: newBlocks };
    });

    notify({
      message: "Block added successfully",
      type: "success",
      duration: 1000,
    });

    setTimeout(() => {
      const newBlockElement = document.getElementById(
        `block-${event.detail.id}`
      );
      if (newBlockElement) {
        newBlockElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 50);

    closeModal();
  };

  const setActiveSection = (sectionName) => {
    activeSection = sectionName;
    if (tabsContainer) {
      const activeTab = tabsContainer.querySelector(
        `[data-section="${sectionName}"]`
      );
      if (activeTab) {
        activeTab.scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "nearest",
        });
      }
    }
  };

  const handleSearch = () => {
    if (searchTerm.trim() === "") {
      filteredBlockTypes = [];
    } else {
      filteredBlockTypes = Object.entries(blockTypes).filter(([type, block]) =>
        block.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  };

  $: {
    searchTerm;
    handleSearch();
  }

  onMount(() => {
    if (tabsContainer) {
      const activeTab = tabsContainer.querySelector(
        `[data-section="${activeSection}"]`
      );
      if (activeTab) {
        activeTab.scrollIntoView({ inline: "center", block: "nearest" });
      }
    }
  });

  $: if (!isOpen) {
    reset();
  }
</script>

<Button
  klass="rounded-lg py-2 px-3 text-sm font-semibold leading-6 active:text-white/80 bg-zinc-900 hover:bg-zinc-700 text-white w-full !bg-purple-600 hover:!bg-purple-800 text-white py-3 md:py-4 px-4 flex items-center justify-center"
  on:click={() => (isOpen = true)}
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="h-4 w-4 md:h-5 md:w-5 mr-2"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
      clip-rule="evenodd"
    ></path>
  </svg>
  Add Block
</Button>

<Modal
  bind:isOpen
  size="2xl"
  showBackButton={view === "form"}
  closeOnOutsideClick={false}
  on:back={showOptions}
>
  <svelte:fragment slot="backButton">
    <span class="flex items-center">
      <svg
        class="h-4 w-4 md:h-5 md:w-5 mr-2 md:mr-3"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 19l-7-7m0 0l7-7m-7 7h18"
        />
      </svg>
      Back
    </span>
  </svelte:fragment>
  <Card size="full" shadow={false}>
    <svelte:fragment slot="content">
      <div class="">
        {#if view === "options"}
          <div class="text-center mb-6 md:mb-8">
            <h2 class="text-xl md:text-2xl font-bold text-gray-800">
              Add a block
            </h2>
          </div>
          <div class="mb-6 md:mb-8">
            <div class="relative">
              <input
                type="text"
                bind:value={searchTerm}
                placeholder="Search blocks..."
                class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-400 focus:border-transparent"
              />
              <svg
                class="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
          {#if searchTerm.trim() === ""}
            <div class="mb-6 md:mb-8">
              <ul
                class="flex overflow-x-auto pb-0 border-b overflow-y-hidden add-block-section-tabs"
                bind:this={tabsContainer}
              >
                {#each Object.keys(sections) as sectionName}
                  <li class="flex-shrink-0 -mb-px mr-1">
                    <button
                      data-section={sectionName}
                      class="bg-white inline-block py-2 px-4 text-sm md:text-base font-semibold whitespace-nowrap {activeSection ===
                      sectionName
                        ? 'text-purple-600 border-b-2 border-purple-600'
                        : 'text-gray-500 hover:text-gray-800'}"
                      on:click={() => setActiveSection(sectionName)}
                      >{sectionName.charAt(0).toUpperCase() +
                        sectionName.slice(1)}</button
                    >
                  </li>
                {/each}
              </ul>
            </div>
            <div class="block-types-container">
              {#each Object.entries(sections) as [sectionName, sectionTypes]}
                <div
                  id={sectionName}
                  class="mb-6 md:mb-8 transition-opacity duration-300 ease-in-out"
                  class:hidden={activeSection !== sectionName}
                  class:block={activeSection === sectionName}
                >
                  {#if activeSection === sectionName}
                    <div
                      class="grid grid-cols-2 sm:grid-cols-3 gap-4 md:gap-6"
                      in:fade={{ duration: 100 }}
                    >
                      {#each sectionTypes as type}
                        {#if blockTypes[type]}
                          <button
                            class="p-4 md:p-6 bg-white border border-gray-200 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 flex flex-col items-center justify-center group"
                            on:click={() => selectBlockType(type)}
                          >
                            <div
                              class="text-purple-500 group-hover:text-purple-700 transition-colors duration-300"
                            >
                              {@html blockTypes[type].icon}
                            </div>
                            <span
                              class="mt-2 md:mt-4 text-sm md:text-base font-medium text-gray-800 group-hover:text-purple-700"
                              >{blockTypes[type].label}</span
                            >
                          </button>
                        {/if}
                      {/each}
                    </div>
                  {/if}
                </div>
              {/each}
            </div>
          {:else}
            <div class="block-types-container">
              <div
                class="grid grid-cols-2 sm:grid-cols-3 gap-4 md:gap-6"
                in:fade={{ duration: 100 }}
              >
                {#each filteredBlockTypes as [type, block]}
                  <button
                    class="p-4 md:p-6 bg-white border border-gray-200 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 flex flex-col items-center justify-center group"
                    on:click={() => selectBlockType(type)}
                  >
                    <div
                      class="text-purple-500 group-hover:text-purple-700 transition-colors duration-300"
                    >
                      {@html block.icon}
                    </div>
                    <span
                      class="mt-2 md:mt-4 text-sm md:text-base font-medium text-gray-800 group-hover:text-purple-700"
                      >{block.label}</span
                    >
                  </button>
                {/each}
              </div>
              {#if filteredBlockTypes.length === 0}
                <p class="text-center text-gray-500 mt-8">
                  No blocks found matching your search.
                </p>
              {/if}
            </div>
          {/if}
        {:else if view === "form" && BlockComponent}
          <svelte:component
            this={BlockComponent}
            on:close={closeModal}
            on:added-block={handleAddedBlock}
          />
        {/if}
      </div>
    </svelte:fragment>
  </Card>
</Modal>

<style>
  .add-block-section-tabs {
    overflow-x: auto;
    scrollbar-width: none;
  }

  .add-block-section-tabs::-webkit-scrollbar {
    display: none;
  }

  .block-types-container {
    min-height: calc(100vh - 350px);
  }
</style>
