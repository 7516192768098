<script>
  import { onMount } from "svelte";
  import { getBlockStyle, getBorderRadius } from "$utils/block-style";
  import { useHoverEffect } from "$utils/block-hover";

  import IframeModal from "$components/IframeModal.svelte";
  import Modal from "$components/Modal.svelte";
  import Image from "$components/Image.svelte";
  import LockModal from "$app-components/blocks/_modals/LockModal.svelte";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;
  export let height = "600px";
  export let width = "600px";
  export let iframeUrl = block.url;
  export let goToButtonText;
  export let klass;
  export let useIframe = true;
  export let blockClicked;
  export let blockViewed;
  export let directRedirect = false;
  export let disableStyles = false;
  export let padding = "p-2.5";
  export let modalSize;
  export let onActualPage;

  let showModal = false;
  let showLockModal = false;
  let isVisible = false;
  let effectClass = "";
  let isUnlocked = false;

  $: featuredBlock = block.layout_style === "featured" && block.image_url;

  export function hideModal() {
    showModal = false;
  }

  function handleClick() {
    blockClicked({ block_id: block.id });

    if (block.locks?.length > 0 && !isUnlocked) {
      showLockModal = true;
      return;
    }

    if (directRedirect) {
      window.open(block.url, "_blank");
    } else {
      showModal = !showModal;
    }
  }

  function handleLockComplete() {
    showLockModal = false;
    isUnlocked = true;

    if (directRedirect) {
      window.open(block.url, "_blank");
    } else {
      showModal = true;
    }
  }

  function useHoverEffectHelper(node, options) {
    if (disableStyles) return;

    return useHoverEffect(node, options);
  }

  $: currentButtonStyle = disableStyles
    ? ""
    : getBlockStyle(theme, buttonStyle, block);

  $: blockRadius = disableStyles ? "" : getBorderRadius(buttonStyle, block);

  $: if (isVisible && block.prioritize_effect) {
    effectClass = `prioritize-animate-${block.prioritize_effect}`;
    setTimeout(() => (effectClass = ""), 2000);
  }

  $: if (isVisible) {
    blockViewed({ block_id: block.id });
  }

  onMount(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          isVisible = true;
        }
      });
    });

    const element = document.getElementById(`block-${block.id}`);
    if (element) observer.observe(element);

    return () => {
      if (element) observer.unobserve(element);
    };
  });
</script>

<div
  id="block-{block.id}"
  class="overflow-hidden transition-all ease-in-out w-full relative {effectClass} {featuredBlock
    ? '!border-none'
    : ''}"
  style={currentButtonStyle}
  use:useHoverEffectHelper={{
    theme,
    buttonStyle,
    hoverEffect,
    getBlockStyle,
    block,
  }}
>
  <div
    class="flex items-center {disableStyles
      ? ''
      : `transition-colors ease-in-out cursor-pointer ${featuredBlock ? 'p-0' : padding}`}"
    style={disableStyles
      ? ""
      : `
      color: ${
        buttonStyle.startsWith("fill")
          ? "var(--buttonTextColor)"
          : "var(--linkColor)"
      };
    `}
    on:click={disableStyles ? null : handleClick}
  >
    <slot {handleClick}>
      {#if featuredBlock}
        <div class="relative w-full h-full max-h-[320px]">
          <Image
            src={block.image_url}
            alt={block.title}
            klass="w-full h-full object-cover"
          />
          <div
            class="absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-black/50 via-black/0 to-transparent flex items-end p-6"
          >
            <h2
              class="text-white text-sm md:text-md font-medium text-left drop-shadow-xl line-clamp-4"
            >
              {block.title}
            </h2>
          </div>
        </div>
      {:else}
        {#if block.image_url}
          <Image
            src={block.image_url}
            alt={block.title}
            klass="w-12 h-12 object-cover !absolute left-2.5 top-1/2 transform -translate-y-1/2"
            style="border-radius: {blockRadius}"
          />
        {/if}
        <div
          class="flex-grow min-h-12 {block.image_url
            ? 'ml-16 mr-16'
            : ''} flex items-center justify-center text-center"
        >
          <h2
            class="line-clamp-4 font-medium text-sm {onActualPage
              ? 'sm:text-base'
              : ''}"
          >
            {block.title}
          </h2>
        </div>
      {/if}
    </slot>
  </div>
</div>

<LockModal
  bind:isOpen={showLockModal}
  {block}
  on:completed={handleLockComplete}
/>

{#if useIframe && !directRedirect}
  <IframeModal
    bind:isOpen={showModal}
    {iframeUrl}
    title={block.title}
    {width}
    {height}
    {klass}
    on:close={() => (showModal = false)}
    goToButtonUrl={block.url}
    {goToButtonText}
  />
{:else if !directRedirect}
  <Modal bind:isOpen={showModal} size={modalSize}>
    <slot name="modal-content" />
  </Modal>
{/if}
