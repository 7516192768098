<script>
  import { onMount, tick } from "svelte";
  import { GridStack } from "gridstack";

  import "gridstack/dist/gridstack.min.css";
  import "gridstack/dist/gridstack-extra.min.css";

  export let blocks;
  export let theme;
  export let hoverEffect;
  export let buttonStyle;
  export let getBlockComponent;
  export let maxColumns = 3;
  export let blockClicked;
  export let blockViewed;
  export let onActualPage;

  let gridElement;
  let nodes = [];
  let maxSize = 1152;
  let gridContainer;
  let isLoaded = false;

  let isFirstBlockHeading = blocks?.[0]?.block_type === "heading";

  function addedElement(node) {
    nodes.push(node);
  }

  function setupGridStack() {
    let sections = [];
    let currentSection = [];

    blocks.forEach((block, index) => {
      const isHeading = block.block_type === "heading";
      if (isHeading && currentSection.length > 0) {
        sections.push(currentSection);
        currentSection = [];
      }
      currentSection.push({ block, node: nodes[index] });
    });

    if (currentSection.length > 0) {
      sections.push(currentSection);
    }

    sections.forEach((section) => {
      const gridDiv = document.createElement("div");
      gridDiv.className = "grid-stack";
      gridDiv.style.width = "100%";
      gridDiv.style.maxWidth = "100%";
      gridElement.appendChild(gridDiv);

      const gridItems = section.map(({ block, node }, index) => {
        const isHeading = block.block_type === "heading";

        // Get actual rendered height
        const computedStyle = window.getComputedStyle(node);
        const marginTop = parseInt(computedStyle.marginTop);
        const marginBottom = parseInt(computedStyle.marginBottom);
        const actualHeight =
          node.getBoundingClientRect().height + marginTop + marginBottom;

        const height = isHeading ? actualHeight + 70 : actualHeight + 16;

        if (isHeading) {
          node.style.paddingTop = "40px";
        }

        return {
          el: node,
          w: isHeading ? maxColumns : 1,
          h: Math.ceil(height),
          id: block.id,
          content: block,
        };
      });

      const grid = GridStack.init(
        {
          column: maxColumns,
          disableResize: true,
          cellHeight: 1,
          staticGrid: true,
          margin: 16,
        },
        gridDiv
      );

      if (gridItems.length === 2) {
        gridDiv.style.marginLeft = "192px";
      } else if (gridItems.length === 1) {
        gridDiv.style.marginLeft = "384px";
      }

      grid.load(gridItems);
    });

    nodes.forEach((node) => {
      node.style.removeProperty("max-width");
    });

    isLoaded = true;
  }

  onMount(async () => {
    await tick();

    if (!gridContainer) {
      return;
    }

    // Wait for images to load to get proper heights
    const images = gridContainer.getElementsByTagName("img");
    const imageLoadPromises = Array.from(images).map((img) => {
      if (img.complete) return Promise.resolve();
      return new Promise((resolve) => {
        img.onload = resolve;
        img.onerror = resolve;
      });
    });

    Promise.all(imageLoadPromises).then(async () => {
      await tick();
      setupGridStack();
    });
  });
</script>

<div class="mt-10 mb-10">
  <div
    class="grid-container layout-container mx-auto"
    style="width: 100%; max-width: {maxSize}px; {isFirstBlockHeading
      ? 'margin-top: -56px'
      : ''}"
    bind:this={gridElement}
  >
    <div
      bind:this={gridContainer}
      class:opacity-0={!isLoaded}
      class:opacity-100={isLoaded}
      class="transition-opacity duration-200"
    >
      {#each blocks as block, index}
        <div
          use:addedElement
          style="padding-left: 8px; padding-right: 8px; max-width: {block.block_type ===
          'heading'
            ? maxSize
            : maxSize / maxColumns}px;"
          class="grid-stack-item"
          data-block-id={block.id}
        >
          <svelte:component
            this={getBlockComponent(block)}
            {block}
            {theme}
            {index}
            {buttonStyle}
            {hoverEffect}
            {blockClicked}
            {blockViewed}
            {onActualPage}
          />
        </div>
      {/each}
    </div>
  </div>
</div>
