import { writable } from "svelte/store";
import Ajax from "../utils/ajax";

const createChatStore = () => {
  const { subscribe, set, update } = writable({
    unread: false,
  });

  // Initialize unread state
  const init = async () => {
    const [error, hasUnread] = await Ajax.get("/dashboard/chat/unread");

    if (!error) {
      set({ unread: hasUnread });
    }
  };

  init();

  return {
    subscribe,
    resetUnread: () =>
      update((state) => ({
        ...state,
        unread: false,
      })),
    setUnread: (hasUnread) =>
      update((state) => ({
        ...state,
        unread: hasUnread,
      })),
  };
};

export const chatStore = createChatStore();
