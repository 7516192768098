<script>
  import { onMount, onDestroy } from "svelte";
  import { chatStore } from "$stores/chat-store";
  import { sidebarStore } from "$stores/sidebar-store";

  import Link from "$components/Link.svelte";
  import Logo from "$components/Logo.svelte";

  let currentPath = "";
  let isSmallScreen = false;

  export let permissions = {};
  export let shouldCollapse;

  export function toggleSidebar() {
    $sidebarStore.isOpen = !$sidebarStore.isOpen;
  }

  function checkScreenSize() {
    isSmallScreen = window.innerWidth < 1350;
  }

  onMount(() => {
    currentPath = window.location.pathname;
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
  });

  onDestroy(() => {
    window.removeEventListener("resize", checkScreenSize);
  });

  $: navItems = [
    {
      href: "/dashboard",
      label: "Blocks",
      icon: "M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z",
      permissionKey: "blocks",
    },
    {
      href: "/dashboard/appearance",
      label: "Appearance",
      icon: "M9.53 16.122a3 3 0 00-5.78 1.128 2.25 2.25 0 01-2.4 2.245 4.5 4.5 0 008.4-2.245c0-.399-.078-.78-.22-1.128zm0 0a15.998 15.998 0 003.388-1.62m-5.043-.025a15.994 15.994 0 011.622-3.395m3.42 3.42a15.995 15.995 0 004.764-4.648l3.876-5.814a1.151 1.151 0 00-1.597-1.597L14.146 6.32a15.996 15.996 0 00-4.649 4.763m3.42 3.42a6.776 6.776 0 00-3.42-3.42",
      permissionKey: "appearance",
    },
    {
      href: "/dashboard/embed",
      label: "Embed",
      icon: "M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14",
      permissionKey: "embed",
    },
    {
      href: "/dashboard/analytics",
      label: "Analytics",
      icon: "M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z",
      permissionKey: "analytics",
    },
    {
      href: "/dashboard/subscribers",
      label: "Subscribers",
      icon: "M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z",
      permissionKey: "subscribers",
    },
    {
      href: "/dashboard/chat",
      label: "Chat",
      icon: "M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z",
      permissionKey: "settings",
      dot: $chatStore.unread,
    },
  ];

  const profileItems = [
    {
      href: "/dashboard/settings",
      label: "Settings",
      icon: "M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z",
      permissionKey: "settings",
    },
  ];

  $: isActive = (href) =>
    currentPath === href ||
    (href !== "/dashboard" && currentPath.startsWith(href + "/"));

  $: shouldCollapse = isSmallScreen && !$sidebarStore.isHovered;
</script>

<!-- Sidebar for desktop -->
<div
  class="hidden lg:fixed lg:inset-y-0 lg:flex lg:flex-col transition-all duration-100 overflow-x-hidden"
  class:lg:w-72={!shouldCollapse}
  class:lg:w-16={shouldCollapse}
  on:mouseenter={() => ($sidebarStore.isHovered = true)}
  on:mouseleave={() => ($sidebarStore.isHovered = false)}
>
  <div
    class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white overflow-x-hidden"
    class:px-6={!shouldCollapse}
    class:px-2={shouldCollapse}
  >
    <div class="flex h-16 shrink-0 items-center mt-4 justify-center">
      {#if shouldCollapse}
        <span class="text-xl font-bold">
          <span class="text-gray-900">B</span><span class="text-purple-600"
            >I</span
          >
        </span>
      {:else}
        <Logo height="24px" />
      {/if}
    </div>
    <nav class="flex flex-1 flex-col">
      <ul role="list" class="flex flex-1 flex-col gap-y-7">
        <li>
          <ul
            role="list"
            class="-mx-2 space-y-1 {profileItems.filter(
              (item) => permissions[item.permissionKey]
            ).length > 0
              ? 'border-b border-gray-200 pb-6'
              : ''}"
          >
            {#each navItems as item}
              {#if permissions[item.permissionKey]}
                <li>
                  <Link
                    to={item.href}
                    variant="primary"
                    klass={`group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 ${isActive(item.href) ? "bg-gray-50 text-purple-600 hover:text-purple-600" : "text-gray-700 hover:bg-gray-50 hover:text-purple-600"} ${shouldCollapse ? "justify-center" : ""}`}
                  >
                    <svg
                      class={`h-6 w-6 shrink-0 ${isActive(item.href) ? "text-purple-600" : "text-gray-400 group-hover:text-purple-600"}`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d={item.icon}
                      ></path>
                    </svg>
                    {#if !shouldCollapse}
                      {item.label}
                      {#if item.dot}
                        <div
                          class="w-2 h-2 scale-90 rounded-full bg-red-400 my-auto"
                        ></div>
                      {/if}
                    {/if}
                  </Link>
                </li>
              {/if}
            {/each}
          </ul>
          <ul role="list" class="-mx-2 space-y-1 pt-6">
            {#each profileItems as item}
              {#if permissions[item.permissionKey]}
                <li>
                  <Link
                    variant="primary"
                    to={item.href}
                    klass={`group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 ${isActive(item.href) ? "bg-gray-50 text-purple-600 hover:text-purple-600" : "text-gray-700 hover:bg-gray-50 hover:text-purple-600"} ${shouldCollapse ? "justify-center" : ""}`}
                  >
                    <svg
                      class={`h-6 w-6 shrink-0 ${isActive(item.href) ? "text-purple-600" : "text-gray-400 group-hover:text-purple-600"}`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d={item.icon}
                      ></path>
                    </svg>
                    {#if !shouldCollapse}
                      {item.label}
                    {/if}
                  </Link>
                </li>
              {/if}
            {/each}
          </ul>
        </li>
      </ul>

      <div class="mt-auto pb-4">
        <a
          href="/login/logout_user"
          class={`group flex items-center py-2 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50 hover:text-purple-900 ${shouldCollapse ? "justify-center px-2" : "px-2"}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class={`w-6 h-6 text-gray-400 group-hover:text-purple-900 ${!shouldCollapse ? "mr-3" : ""}`}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
            ></path>
          </svg>
          {#if !shouldCollapse}
            Logout
          {/if}
        </a>
      </div>
    </nav>
  </div>
</div>
