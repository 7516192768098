<script>
  import DefaultLayout from "$layouts/Default.svelte";

  import Form from "$components/Form.svelte";
  import Input from "$components/Input.svelte";
  import Button from "$components/Button.svelte";
  import Card from "$components/Card.svelte";
  import Link from "$components/Link.svelte";
  import Logo from "$components/Logo.svelte";

  import { goto } from "$utils/goto";
  import { notify } from "$utils/notify";
  import { validateErrors } from "$utils/form";
  import Ajax from "$utils/ajax";

  let errors = {};

  let register = async (event) => {
    const [ajaxErrors, resp] = await Ajax.post("/register", event.detail);

    if (ajaxErrors) {
      errors = validateErrors(errors, event.detail, ajaxErrors);
    } else {
      notify({ message: "Account created successfully", type: "success" });
      goto("/dashboard");
    }
  };

  let validateUsername = async (event) => {
    let [_errors, resp] = await Ajax.post("/register/validate_username", {
      username: event.detail.value,
    });

    if (resp && resp.taken) {
      errors.username = "Username is already taken";
    } else {
      delete errors.username;
      errors = errors;
    }
  };
</script>

<svelte:head>
  <script src="https://accounts.google.com/gsi/client" async></script>

  <div
    id="g_id_onload"
    data-client_id="694987989129-dt5cqift7s9i9ohr6p2qrrf0dl44nbb0.apps.googleusercontent.com"
    data-login_uri={location.href.replace(/\/$/, "").replace("/register", "") +
      "/oauth/google_callback"}
    data-auto_prompt="false" />
</svelte:head>

<DefaultLayout>
  <div class="flex justify-center mb-16">
    <Logo />
  </div>

  <Card size="lg" klass="mx-auto max-w-sm">
    <svelte:fragment slot="title">
      <h2 class="text-2xl font-extrabold text-center mb-6">
        Register for an account
      </h2>
      <p class="text-sm text-gray-600 text-center mb-8">
        Already registered? <Link to={"/login"}>Log in</Link> to your account.
      </p>
    </svelte:fragment>

    <svelte:fragment slot="content">
      <div class="pt-2">
        <Form on:submit={register}>
          <div class="space-y-5 flex flex-col gap-2">
            <Input
              name="email"
              type="email"
              label="Email"
              error={errors.email}
              required
              on:keyup={() => {
                delete errors.email;
                errors = errors;
              }} />
            <Input
              name="password"
              type="password"
              label="Password"
              error={errors.password}
              required
              on:keyup={() => {
                delete errors.password;
                errors = errors;
              }} />
            <Input
              name="username"
              type="text"
              label="Username"
              error={errors.username}
              required
              on:keyup={validateUsername}>
              <svelte:fragment slot="prepend">bio.ink/</svelte:fragment>
            </Input>

            <div>
              <Button
                type="submit"
                enableAnimation={true}
                disabled={Object.keys(errors).length > 0}
                klass="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-md transition mt-2 duration-300">
                Create an account
              </Button>
            </div>

            <div class="relative">
              <div class="absolute inset-0 flex items-center">
                <div class="w-full border-t border-gray-300"></div>
              </div>
              <div class="relative flex justify-center text-sm">
                <span class="px-2 bg-white text-gray-500"
                  >Or continue with</span>
              </div>
            </div>

            <div class="w-full flex justify-center">
              <div
                class="g_id_signin"
                data-type="standard"
                data-size="large"
                data-theme="outline"
                data-text="sign_in_with"
                data-shape="rectangular"
                data-logo_alignment="left"
                data-width={window.innerWidth <= 768 ? "300" : "400"} />
            </div>
          </div>
        </Form>
      </div>
    </svelte:fragment>
  </Card>
</DefaultLayout>
