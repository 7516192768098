<script>
  import Page from "$app-components/blocks/_layouts/Page.svelte";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;
  export let blockClicked = function () {};
  export let blockViewed = function () {};
  export let onActualPage;

  $: url = block.url;
  $: embedUrl = url
    ? `${url}?embed_type=Inline&hide_gdpr_banner=1&hide_event_type_details=1`
    : "";
</script>

<Page
  {block}
  {theme}
  {buttonStyle}
  {hoverEffect}
  iframeUrl={embedUrl}
  width="460px"
  {blockClicked}
  {blockViewed}
  {onActualPage}
/>
