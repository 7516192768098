<script>
  import { createEventDispatcher } from "svelte";
  import Page from "$app-components/blocks/_layouts/Page.svelte";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;
  export let blockClicked = function () {};
  export let blockViewed = function () {};

  const dispatch = createEventDispatcher();

  $: ({ title, fields } = block);
  $: level = fields?.level || "h3";

  $: fontSize = {
    h1: "2.5rem",
    h2: "2rem",
    h3: "1.75rem",
    h4: "1.5rem",
    h5: "1.25rem",
    h6: "1rem",
  }[level];

  $: fontWeight = {
    h1: "800",
    h2: "700",
    h3: "600",
    h4: "600",
    h5: "600",
    h6: "600",
  }[level];
</script>

<Page {block} {blockClicked} {blockViewed} disableStyles={true}>
  <svelte:element
    this={level}
    class="heading-block w-full flex justify-center"
    style="font-size: {fontSize}; font-weight: {fontWeight};"
  >
    {title}
  </svelte:element>
</Page>

<style>
  .heading-block {
    color: var(--textColor);
    text-align: center;
    word-wrap: break-word;
    line-height: 1.2;
  }
</style>
