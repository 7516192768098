<script>
  import { createEventDispatcher } from "svelte";
  import View from "$app-components/blocks/_layouts/View.svelte";

  const dispatch = createEventDispatcher();

  export let block;
  export let updater;

  function handleDelete() {
    dispatch("delete-block");
  }

  $: emails = block?.fields?.emails || [];
  $: phones = block?.fields?.phones || [];
  $: urls = block?.fields?.urls || [];
</script>

<View {block} {updater} on:delete-block={handleDelete}>
  <div
    class="w-12 h-12 flex items-center justify-center text-sm text-gray-500 bg-gray-100 rounded mr-4 shrink-0"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
      class="w-8 h-8 text-gray-500"
    >
      <path
        stroke-width="1.5"
        d="M4 6C4 5.44772 4.44772 5 5 5H19C19.5523 5 20 5.44772 20 6V18C20 18.5523 19.5523 19 19 19H5C4.44772 19 4 18.5523 4 18V6Z"
      />
      <circle cx="9" cy="11" r="2" stroke-width="1.5" />
      <path stroke-width="1.5" d="M14 9H18M14 13H18" />
    </svg>
  </div>
  <div class="flex-1 min-w-0 pl-2">
    <div class="text-gray-900">
      <p class="font-semibold truncate">
        {block.title || "Contact Info"}
      </p>
      <p class="text-sm text-gray-600 truncate">
        {emails.length + phones.length + urls.length} contact method{emails.length +
          phones.length +
          urls.length !==
        1
          ? "s"
          : ""}
      </p>
    </div>
  </div>
</View>
