<script>
  import Page from "$app-components/blocks/_layouts/Page.svelte";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;
  export let blockClicked = function () {};
  export let blockViewed = function () {};
  export let onActualPage;

  function getGoFundMeEmbedUrl(url) {
    // Extract the campaign ID from the URL
    const campaignId = url.split("/").pop();
    // Construct the embed URL
    return `https://www.gofundme.com/f/${campaignId}/widget/large`;
  }

  $: gofundmeEmbedUrl = getGoFundMeEmbedUrl(block.url);
</script>

<Page
  {block}
  {theme}
  {buttonStyle}
  {hoverEffect}
  {blockClicked}
  {blockViewed}
  iframeUrl={gofundmeEmbedUrl}
  goToButtonText="Open in GoFundMe"
  width="80vw"
  height="80vh"
  {onActualPage}
/>
