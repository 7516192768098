<script>
  import { standard, minimalist, fun } from "$utils/themes";
  import { getThemeFromId } from "$utils/themes";

  import CustomAppearance from "./CustomAppearance.svelte";

  export let page;
  export let showCustom = true;
  export let additionalThemes = {};

  let hasCustomTheme =
    page.custom_theme && Object.keys(page.custom_theme).length > 0;

  let selectedTheme = hasCustomTheme
    ? { id: undefined }
    : getThemeFromId(page.theme, additionalThemes);

  let tabsContainer;

  // Set initial category based on custom_theme presence
  let selectedCategory =
    hasCustomTheme && showCustom
      ? "custom"
      : standard.some((theme) => theme.id === selectedTheme.id)
        ? "standard"
        : minimalist.some((theme) => theme.id === selectedTheme.id)
          ? "minimalist"
          : fun.some((theme) => theme.id === selectedTheme.id)
            ? "fun"
            : "standard";

  $: themes =
    selectedCategory === "standard"
      ? [...(additionalThemes.standard || []), ...standard]
      : selectedCategory === "minimalist"
        ? [...(additionalThemes.minimalist || []), ...minimalist]
        : selectedCategory === "fun"
          ? [...(additionalThemes.fun || []), ...fun]
          : standard;

  const handleThemeChanged = (theme) => {
    selectedTheme = theme;
    page.theme = theme.id;
    page.custom_theme = {};
  };

  const handleCategoryChanged = (category) => {
    selectedCategory = category;

    // Scroll the selected tab to center
    if (tabsContainer) {
      const selectedButton = tabsContainer.querySelector(
        `[data-category="${category}"]`
      );
      if (selectedButton) {
        const containerWidth = tabsContainer.offsetWidth;
        const buttonLeft = selectedButton.offsetLeft;
        const buttonWidth = selectedButton.offsetWidth;
        const scrollLeft = buttonLeft - containerWidth / 2 + buttonWidth / 2;

        tabsContainer.scrollTo({
          left: scrollLeft,
          behavior: "smooth",
        });
      }
    }
  };
</script>

<div class="mb-8">
  <div
    bind:this={tabsContainer}
    class="flex overflow-x-auto border-b [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]"
  >
    <div class="flex min-w-max">
      <button
        data-category="standard"
        class="py-2 px-4 whitespace-nowrap {selectedCategory === 'standard'
          ? 'border-b-2 border-purple-500'
          : ''}"
        on:click={() => handleCategoryChanged("standard")}
      >
        Standard
      </button>
      <button
        data-category="minimalist"
        class="py-2 px-4 whitespace-nowrap {selectedCategory === 'minimalist'
          ? 'border-b-2 border-purple-500'
          : ''}"
        on:click={() => handleCategoryChanged("minimalist")}
      >
        Minimalist
      </button>
      <button
        data-category="fun"
        class="py-2 px-4 whitespace-nowrap {selectedCategory === 'fun'
          ? 'border-b-2 border-purple-500'
          : ''}"
        on:click={() => handleCategoryChanged("fun")}
      >
        Fun
      </button>

      {#if showCustom}
        <button
          data-category="custom"
          class="py-2 px-4 whitespace-nowrap {selectedCategory === 'custom'
            ? 'border-b-2 border-purple-500'
            : ''}"
          on:click={() => handleCategoryChanged("custom")}
        >
          Custom
        </button>
      {/if}
    </div>
  </div>
</div>

{#if selectedCategory === "custom"}
  <CustomAppearance bind:page />
{:else}
  <div
    class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 gap-4 overflow-y-auto"
  >
    {#each themes as theme (theme.name)}
      <div
        class="theme-card p-1 border rounded-lg cursor-pointer hover:shadow-md transition-shadow {selectedTheme.id ===
        theme.id
          ? 'border-gray-600'
          : 'border-gray-200'}"
        on:click={() => handleThemeChanged(theme)}
      >
        <div class="theme-preview mb-2 h-60 rounded overflow-hidden">
          <!-- Mini preview of the theme -->
          <div
            class="w-full h-full flex flex-col items-center p-4"
            style="background-color: {theme.theme.backgroundColor}; 
                 background-image: {theme.theme.backgroundImage}; 
                 background-repeat: no-repeat; 
                 background-size: cover; 
                 background-position: center;"
          >
            <div class="w-full flex flex-col gap-2">
              {#each [1, 2, 3] as _}
                <div
                  class="w-full h-8 rounded flex items-center justify-center text-xs"
                  style="background-color: {theme.theme.cardBackgroundColor}; 
                       color: {theme.theme.linkColor}; 
                       box-shadow: 0 2px 4px {theme.theme.cardShadowColor};"
                >
                  Link
                </div>
              {/each}
            </div>
          </div>
        </div>
        <div class="flex items-center justify-center">
          <span class="text-sm font-medium text-center">{theme.name}</span>
        </div>
      </div>
    {/each}
  </div>
{/if}
