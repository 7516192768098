<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { fade, fly } from "svelte/transition";

  export let isOpen = false;
  export let title = "";
  export let message = "";
  export let type = "info"; // 'info', 'success', 'warning', 'error'
  export let showCloseButton = true;
  export let primaryAction = "";
  export let secondaryAction = "";
  export let closeOnEscape = true;
  export let closeOnOutsideClick = true;

  const dispatch = createEventDispatcher();

  function close() {
    isOpen = false;
    dispatch("close");
  }

  function handlePrimaryAction() {
    dispatch("primaryAction");
  }

  function handleSecondaryAction() {
    dispatch("secondaryAction");
  }

  function handleKeydown(event) {
    if (closeOnEscape && event.key === "Escape") {
      close();
    }
  }

  function handleOutsideClick(event) {
    if (closeOnOutsideClick && event.target === event.currentTarget) {
      close();
    }
  }

  onMount(() => {
    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  });

  const typeClasses = {
    info: "bg-blue-100 text-blue-800",
    success: "bg-green-100 text-green-800",
    warning: "bg-orange-100 text-orange-800",
    error: "bg-red-100 text-red-800",
  };

  const primaryButtonClasses = {
    info: "bg-blue-600 hover:bg-blue-700",
    success: "bg-green-600 hover:bg-green-700",
    warning: "bg-orange-600 hover:bg-orange-700",
    error: "bg-red-600 hover:bg-red-700",
  };
</script>

{#if isOpen}
  <div
    class="fixed inset-0 z-30 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
    on:click={handleOutsideClick}
  >
    <div
      class="flex items-center justify-center min-h-screen p-4 text-center sm:block sm:p-0"
    >
      <div
        transition:fade={{ duration: 300 }}
        class="fixed inset-0 bg-black bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true">&#8203;</span
      >

      <div
        transition:fly={{ y: 20, duration: 300 }}
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full"
      >
        <div class="flex items-center bg-white p-4 rounded-t-lg">
          <h3 class="text-xl font-semibold text-gray-900" id="modal-title">
            {title}
          </h3>
          {#if showCloseButton}
            <button
              class="ml-auto text-gray-400 hover:text-gray-500"
              on:click={close}
              aria-label="Close"
            >
              <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          {/if}
        </div>

        <div class="p-6">
          <p class="text-base text-gray-700">{message}</p>
        </div>

        {#if primaryAction || secondaryAction}
          <div class="flex justify-end space-x-2 p-4 bg-gray-50 rounded-b-lg">
            {#if secondaryAction}
              <button
                class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none"
                on:click={handleSecondaryAction}
              >
                {secondaryAction}
              </button>
            {/if}
            {#if primaryAction}
              <button
                class="px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md focus:outline-none {primaryButtonClasses[
                  type
                ]}"
                on:click={handlePrimaryAction}
              >
                {primaryAction}
              </button>
            {/if}
          </div>
        {/if}
      </div>
    </div>
  </div>
{/if}
