<script>
  import { fly } from "svelte/transition";
  import { fade } from "svelte/transition";
  import { onMount, tick } from "svelte";

  import Ajax from "$utils/ajax";
  import { notify } from "$utils/notify";

  export let toggleChat;
  export let page;
  export let userId;

  let messages = [];
  let newMessage = "";
  let inputElement;
  let messagesContainer;
  let showNewMessageIndicator = false;

  let isMobileView = false;

  async function loadMessages() {
    const [error, response] = await Ajax.get(
      `/api/chat_messages?page_id=${page.id}&guest_user_id=${userId}`
    );

    if (error) {
      notify({
        type: "error",
        message: "Failed to load chat messages",
      });
    } else {
      messages = response.messages;
      scrollToBottom();
    }
  }

  function handleKeydown(event) {
    if (event.key === "Escape") {
      toggleChat();
    }
  }

  function isScrolledToBottom() {
    const { scrollHeight, scrollTop, clientHeight } = messagesContainer;
    return Math.abs(scrollHeight - scrollTop - clientHeight) < 10;
  }

  async function scrollToBottom() {
    await tick();

    messagesContainer.scrollTo({
      top: messagesContainer.scrollHeight,
      behavior: "auto",
    });
    showNewMessageIndicator = false;
  }

  function handleScroll(event) {
    // Prevent page scrolling when scrolling in chat window
    event.stopPropagation();

    if (isScrolledToBottom()) {
      showNewMessageIndicator = false;
    }
  }

  function checkMobileView() {
    isMobileView = window.innerWidth < 768;
  }

  async function sendMessage() {
    if (newMessage.trim()) {
      const [error, response] = await Ajax.post("/api/chat_messages", {
        body: newMessage,
        page_id: page.id,
        guest_user_id: userId,
        guest_type: true,
      });

      if (error) {
        notify({
          type: "error",
          message: "Failed to send message",
        });
      } else {
        messages = [...messages, response];
        newMessage = "";

        if (isMobileView) {
          inputElement?.blur();
        }

        scrollToBottom();
      }
    }
  }

  function disableBodyScroll() {
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = `${scrollbarWidth}px`;
  }

  function enableBodyScroll() {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
  }

  onMount(() => {
    checkMobileView();

    if (!isMobileView) {
      inputElement.focus();
    }

    disableBodyScroll();

    loadMessages();
    window.addEventListener("user:realtime:message", loadMessages);

    return () => {
      window.removeEventListener("user:realtime:message", loadMessages);
      enableBodyScroll();
    };
  });
</script>

<div
  class="fixed inset-0 bg-black bg-opacity-50 z-40"
  transition:fade={{ duration: 200 }}
>
  <div
    class="fixed inset-x-4 bottom-4 md:bottom-4 md:right-4 md:left-auto md:w-[350px] h-[450px] bg-white rounded-xl shadow-2xl flex flex-col overflow-hidden z-50"
    in:fly={{ y: "100%", duration: 300 }}
    out:fly={{ y: "100%", duration: 300 }}
    style="clip-path: inset(0 0 0 0 round 12px);"
  >
    <!-- Chat Header -->
    <div class="p-4 bg-gradient-to-r from-purple-600 to-indigo-600">
      <div class="flex justify-between items-center">
        <div class="text-white">
          <h3 class="font-semibold">Chat</h3>
          <p class="text-sm opacity-80">Usually replies within an hour</p>
        </div>
        <button
          class="text-white hover:bg-white/10 p-2 rounded-full transition-colors"
          on:click={toggleChat}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>

    <!-- Chat Messages -->
    <div
      bind:this={messagesContainer}
      class="flex-1 overflow-y-auto p-4 space-y-4 relative"
      on:scroll={handleScroll}
      on:wheel|stopPropagation
    >
      {#each messages as message}
        <div
          class="flex {message.author_type === 'guest'
            ? 'justify-end'
            : 'justify-start'}"
        >
          <div
            class="{message.author_type === 'guest'
              ? 'bg-purple-600 text-white'
              : 'bg-gray-100'} rounded-2xl px-4 py-2 max-w-[80%]"
          >
            <p class="text-sm">{message.body}</p>
            <p class="text-xs opacity-70 mt-1">
              {new Date(message.created_at).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
          </div>
        </div>
      {/each}

      {#if showNewMessageIndicator}
        <button
          class="absolute bottom-0 right-4 bg-purple-600 text-white px-3 py-1 rounded-full text-sm shadow-lg"
          on:click={scrollToBottom}
        >
          New message ↓
        </button>
      {/if}
    </div>

    <!-- Message Input -->
    <div class="p-4 border-t">
      <form on:submit|preventDefault={sendMessage} class="flex gap-2">
        <input
          bind:this={inputElement}
          type="text"
          bind:value={newMessage}
          placeholder="Type your message..."
          class="flex-1 px-4 py-2 rounded-full border-gray-200 border focus:border-purple-300 focus:outline-none text-sm"
          on:keydown={handleKeydown}
        />
      </form>
    </div>
  </div>
</div>
