<script>
  import { createEventDispatcher } from "svelte";
  import View from "$app-components/blocks/_layouts/View.svelte";

  const dispatch = createEventDispatcher();

  export let block;
  export let updater;

  function handleDelete() {
    dispatch("delete-block");
  }
</script>

<View {block} {updater} on:delete-block={handleDelete}>
  <div
    class="w-12 h-12 flex-shrink-0 rounded-md bg-gray-100 flex items-center justify-center"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6 text-gray-500"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
      />
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
      />
    </svg>
  </div>
  <div class="flex-1 min-w-0 pl-2">
    <div class="text-gray-900">
      <p class="font-semibold truncate">
        {block.title || "Untitled Location"}
      </p>
    </div>
  </div>
</View>
