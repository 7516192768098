<script>
  import { createEventDispatcher } from "svelte";
  import View from "$app-components/blocks/_layouts/View.svelte";

  const dispatch = createEventDispatcher();

  export let block;
  export let updater;

  function handleDelete() {
    dispatch("delete-block");
  }

  $: ({ title, fields } = block);
  $: level = fields?.level || "h3";
</script>

<View {block} {updater} on:delete-block={handleDelete}>
  <div class="flex-1 min-w-0 pl-2">
    <div class="flex items-center">
      <span
        class="w-12 h-12 flex items-center justify-center text-sm text-gray-500 bg-gray-100 rounded mr-4 shrink-0"
      >
        {level.toUpperCase()}
      </span>
      <svelte:element
        this={level}
        class="text-lg font-semibold text-gray-900 truncate"
      >
        {title}
      </svelte:element>
    </div>
  </div>
</View>
