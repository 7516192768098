<script>
  import { createEventDispatcher } from "svelte";
  import { fly } from "svelte/transition";
  import Modal from "$components/Modal.svelte";

  const dispatch = createEventDispatcher();

  export let block;
  export let isOpen = false;

  let sensitiveLockCompleted = false;
  let ageLockCompleted = false;
  let codeLockCompleted = false;
  let birthdate = "";
  let accessCode = "";
  let errorMessage = "";
  let currentStep = 0;

  $: locks =
    block.locks?.filter((lock) => lock.lock_type !== "subscribe") || [];

  $: allLocksCompleted = !locks.some((lock) => {
    if (lock.lock_type === "sensitive") return !sensitiveLockCompleted;
    if (lock.lock_type === "age") return !ageLockCompleted;
    if (lock.lock_type === "code") return !codeLockCompleted;
    return false;
  });

  $: if (allLocksCompleted) {
    dispatch("completed");
  }

  function closeModal() {
    isOpen = false;
    // Reset state when modal closes
    sensitiveLockCompleted = false;
    ageLockCompleted = false;
    codeLockCompleted = false;
    birthdate = "";
    accessCode = "";
    errorMessage = "";
    currentStep = 0;
  }

  function handleSensitiveConfirm() {
    sensitiveLockCompleted = true;
    errorMessage = "";
    currentStep++;
  }

  function handleAgeVerification() {
    ageLockCompleted = true;
    errorMessage = "";
    currentStep++;
  }

  function handleCodeVerification() {
    const correctCode = locks.find((lock) => lock.lock_type === "code")?.data
      ?.code;
    if (accessCode === correctCode) {
      codeLockCompleted = true;
      errorMessage = "";
      currentStep++;
    } else {
      errorMessage = "Invalid access code.";
    }
  }
</script>

<Modal bind:isOpen size="md">
  <div slot="header" class="px-4 sm:px-6">
    <h3 class="text-md sm:text-xl font-bold text-gray-900">Access Required</h3>
    {#if locks.length > 1}
      <div class="mt-6 relative">
        <div class="h-2 bg-purple-100 rounded-full">
          <div
            class="h-2 bg-purple-600 rounded-full transition-all duration-300"
            style="width: {(currentStep / locks.length) * 100}%" />
        </div>
        <div class="mt-2 text-sm text-gray-600 text-center">
          Step {currentStep + 1} of {locks.length}
        </div>
      </div>
    {/if}
  </div>

  <div class="mt-8 px-4 sm:px-6 text-black">
    {#each locks as lock, index}
      {#if index === currentStep}
        <div
          class="bg-white rounded-lg p-6 mb-6 border-2 border-purple-200 transform transition-all duration-300"
          in:fly={{ y: 20, duration: 300 }}
          out:fly={{ y: -20, duration: 0 }}>
          {#if lock.lock_type === "sensitive"}
            <div class="flex flex-col items-center text-center">
              <div
                class="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mb-4">
                <svg
                  class="w-8 h-8 text-purple-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>
              <h4 class="text-xl font-semibold mb-3">Content Warning</h4>
              <p class="mb-6 text-gray-600">
                This content may contain sensitive material. Please confirm that
                you wish to proceed.
              </p>
              <button
                class="bg-purple-600 text-white px-6 py-3 rounded-lg hover:bg-purple-700 transform transition hover:scale-105"
                on:click={handleSensitiveConfirm}>
                I understand and wish to proceed
              </button>
            </div>
          {/if}

          {#if lock.lock_type === "age"}
            <div class="flex flex-col items-center text-center">
              <div
                class="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mb-4">
                <svg
                  class="w-8 h-8 text-purple-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              </div>
              <h4 class="text-xl font-semibold mb-3">
                Age Verification Required
              </h4>
              <p class="mb-6 text-gray-600">
                You must be at least {lock.data?.min_age} years old to view this
                content.
              </p>
              <button
                class="bg-purple-600 text-white px-6 py-3 rounded-lg hover:bg-purple-700 transform transition hover:scale-105"
                on:click={handleAgeVerification}>
                I am {lock.data?.min_age} or older
              </button>
            </div>
          {/if}

          {#if lock.lock_type === "code"}
            <div class="flex flex-col items-center text-center">
              <div
                class="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mb-4">
                <svg
                  class="w-8 h-8 text-purple-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
              </div>
              <h4 class="text-xl font-semibold mb-3">Access Code Required</h4>
              <p class="mb-6 text-gray-600">
                Please enter the access code to continue:
              </p>
              <input
                type="text"
                bind:value={accessCode}
                placeholder="Enter access code"
                class="border rounded-lg p-3 text-black mb-4 w-full max-w-xs text-center focus:ring-2 focus:ring-purple-600 focus:border-transparent" />
              <button
                class="bg-purple-600 text-white px-6 py-3 rounded-lg hover:bg-purple-700 transform transition hover:scale-105"
                on:click={handleCodeVerification}>
                Submit Code
              </button>
            </div>
          {/if}
        </div>
      {/if}
    {/each}

    {#if errorMessage}
      <div class="text-red-600 mb-4 text-center animate-bounce">
        {errorMessage}
      </div>
    {/if}
  </div>
</Modal>
