<script>
  import { getBlockStyle, getBorderRadius } from "$utils/block-style";
  import { useHoverEffect } from "$utils/block-hover";
  import Page from "$app-components/blocks/_layouts/Page.svelte";

  import Image from "$components/Image.svelte";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;
  export let blockClicked = function () {};
  export let blockViewed = function () {};
  export let onActualPage;

  $: currentButtonStyle = getBlockStyle(theme, buttonStyle, block);
  $: blockRadius = getBorderRadius(buttonStyle, block);
  $: width = block.fields?.width || 100;
</script>

<Page {block} {blockClicked} {blockViewed} disableStyles={true} {onActualPage}>
  <Image
    src={block.image_url}
    alt={block.title}
    klass="w-full h-auto object-cover mx-auto"
    style={`border-radius: ${blockRadius}; width: ${width}%`}
  />
</Page>
