<script>
  import { onMount } from "svelte";
  import { notify } from "$utils/notify";
  import Ajax from "$utils/ajax";

  import Input from "$components/Input.svelte";
  import Button from "$components/Button.svelte";
  import Modal from "$components/Modal.svelte";

  export let url;

  let editing = false;
  let showQrCode = false;
  let showNotice = true;
  let newUsername = url.split("/").pop();
  let errors = {};
  let qrCodeSvg = "";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);

    notify({
      message: "Link copied to clipboard",
    });
  };

  const toggleEdit = () => {
    editing = !editing;

    if (!editing) {
      newUsername = url.split("/").pop();
      errors = {};
    }
  };

  const toggleQrCode = async () => {
    showQrCode = !showQrCode;
    if (showQrCode && !qrCodeSvg) {
      const response = await fetch("/dashboard/qr_code");
      const data = await response.json();
      qrCodeSvg = data.code;
    }
  };

  const updateUsername = async () => {
    const [ajaxErrors] = await Ajax.post("/dashboard/settings/save_username", {
      username: newUsername,
    });

    if (ajaxErrors) {
      errors = ajaxErrors;
    } else {
      notify({
        message: "URL updated successfully",
        type: "success",
      });

      url = url.replace(/[^/]*$/, newUsername);
      editing = false;
    }
  };

  let removeUrlEditParam = () => {
    const url = new URL(window.location);
    url.searchParams.delete("open_url_edit");
    window.history.replaceState({}, "", url);
  };

  onMount(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.get("open_url_edit") === "true") {
      editing = true;
      showNotice = false;
      setTimeout(() => removeUrlEditParam(), 0);
    }
  });
</script>

<div class="bg-white shadow-md rounded-lg p-4 mb-4 -my-4">
  <div class="flex items-center justify-between">
    <div class="flex items-center space-x-3 min-w-0">
      <div class="flex-shrink-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-purple-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
          ></path>
        </svg>
      </div>
      <div class="min-w-0 flex-1">
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          class="text-sm text-purple-600 hover:text-purple-800 truncate block pr-4"
          title={url.replace("https://", "")}
        >
          {url.replace("https://", "")}
        </a>
      </div>
    </div>
    <div class="flex items-center space-x-4 flex-shrink-0">
      <button on:click={toggleEdit} class="text-gray-400 hover:text-gray-500">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
          />
        </svg>
      </button>
      <button
        on:click|preventDefault={copyToClipboard}
        class="text-gray-400 hover:text-gray-500"
        id="copy-to-clipboard"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"></path>
          <path
            d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z"
          ></path>
        </svg>
      </button>
      <button on:click={toggleQrCode} class="text-gray-400 hover:text-gray-500">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M3 4a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H4a1 1 0 01-1-1V4zm2 2V5h1v1H5zM3 13a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H4a1 1 0 01-1-1v-3zm2 2v-1h1v1H5zM13 3a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1V4a1 1 0 00-1-1h-3zm1 2v1h1V5h-1z"
            clip-rule="evenodd"
          />
          <path
            d="M11 4a1 1 0 10-2 0v1a1 1 0 002 0V4zM10 7a1 1 0 011 1v1h2a1 1 0 110 2h-3a1 1 0 01-1-1V8a1 1 0 011-1zM16 9a1 1 0 100 2 1 1 0 000-2zM9 13a1 1 0 011-1h1a1 1 0 110 2v2a1 1 0 11-2 0v-3zM7 11a1 1 0 100-2H4a1 1 0 100 2h3zM17 13a1 1 0 01-1 1h-2a1 1 0 110-2h2a1 1 0 011 1zM16 17a1 1 0 100-2h-3a1 1 0 100 2h3z"
          />
        </svg>
      </button>
    </div>
  </div>
</div>

<Modal bind:isOpen={editing} size="md">
  <svelte:fragment slot="header">
    {#if showNotice}
      <div
        class="mt-6 bg-amber-50 border border-amber-200 rounded-lg p-5 mb-10"
      >
        <div class="flex gap-3">
          <div class="flex-shrink-0 mt-1">
            <svg
              class="h-5 w-5 text-amber-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div>
            <h3 class="text-sm font-semibold text-amber-800 mb-1">
              Important Notice
            </h3>
            <p class="text-sm text-amber-700 leading-relaxed">
              After changing your URL, the old one will stop working. Remember
              to update any existing links to your profile.
            </p>
          </div>
        </div>
      </div>
    {/if}

    <h2 class="text-xl font-bold text-gray-900">Customize Your URL</h2>
  </svelte:fragment>

  <div class="space-y-8 py-0">
    <div>
      <p class="text-sm text-gray-600 leading-relaxed mt-1 mb-2">
        Choose a custom URL that represents you or your brand. This will be your
        public profile link.
      </p>

      <div class="mt-6">
        <Input
          name="username"
          type="text"
          bind:value={newUsername}
          error={errors.username}
          required
          placeholder="your-username"
          on:keyup={() => {
            delete errors.username;
            errors = errors;
          }}
        >
          <svelte:fragment slot="prepend">
            <span class="text-gray-600 font-medium">bio.ink/</span>
          </svelte:fragment>
        </Input>
      </div>
    </div>

    <div
      class="flex items-center justify-end gap-3 border-t border-gray-200 -mx-6 px-6 pt-4 !-mb-2"
    >
      <button
        on:click={toggleEdit}
        class="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 transition-colors duration-200"
      >
        Cancel
      </button>
      <Button
        on:click={updateUsername}
        klass="bg-purple-600 hover:bg-purple-700 text-white px-5 py-2 rounded-lg text-sm font-medium shadow-sm transition-all duration-200 hover:shadow"
      >
        Save
      </Button>
    </div>
  </div>
</Modal>

<Modal bind:isOpen={showQrCode} size="sm">
  <svelte:fragment slot="header">
    <h2 class="text-xl font-bold text-gray-900">QR Code</h2>
  </svelte:fragment>

  <div class="py-8 px-4">
    {#if qrCodeSvg}
      <div class="flex justify-center">
        <div
          class="bg-white p-6 rounded-lg shadow-sm border border-gray-100 w-[200px] h-[200px]"
        >
          {@html qrCodeSvg}
        </div>
      </div>

      <p class="text-center text-sm text-gray-600 mt-4">
        Scan this code to visit your bio.ink profile
      </p>

      <div class="flex justify-center mt-4">
        <button
          on:click={() => (location.href = "/dashboard/qr_code/download")}
          class="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-lg text-sm font-medium shadow-sm transition-all duration-200 hover:shadow"
        >
          Download QR Code
        </button>
      </div>
    {:else}
      <div class="flex justify-center items-center h-48">
        <div
          class="animate-spin rounded-full h-10 w-10 border-b-2 border-purple-600"
        ></div>
      </div>
    {/if}
  </div>

  <div class="flex justify-end border-t border-gray-200 pt-4 !-mb-2">
    <button
      on:click={toggleQrCode}
      class="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 transition-colors duration-200"
    >
      Close
    </button>
  </div>
</Modal>
