<script>
  import Page from "$app-components/blocks/_layouts/Page.svelte";
  import Button from "$components/Button.svelte";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;
  export let blockClicked = function () {};
  export let blockViewed = function () {};
  export let onActualPage;

  $: emails = block?.fields?.emails || [];
  $: phones = block?.fields?.phones || [];
  $: urls = block?.fields?.urls || [];

  function downloadVCard() {
    const vcard = [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `FN:${block.fields.name || ""}`,
      `TITLE:${block.fields.title || ""}`,
      `ORG:${block.fields.company || ""}`,
      ...emails.map((e) => `EMAIL;TYPE=${e.type.toUpperCase()}:${e.email}`),
      ...phones.map((p) => `TEL;TYPE=${p.type.toUpperCase()}:${p.number}`),
      ...urls.map((u) => `URL:${u.url}`),
      `NOTE:${block.fields.notes || ""}`,
      "END:VCARD",
    ].join("\n");

    const blob = new Blob([vcard], { type: "text/vcard" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${block.fields.name || "contact"}.vcf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
</script>

<Page
  {block}
  {theme}
  {buttonStyle}
  {hoverEffect}
  useIframe={false}
  {blockClicked}
  {blockViewed}
  {onActualPage}
  modalSize="lg"
>
  <div slot="modal-content" class="p-4 sm:p-6">
    <div class="max-w-xl mx-auto">
      {#if block.fields.name || block.fields.title || block.fields.company}
        <div class="text-center mb-8">
          {#if block.fields.name}
            <h2 class="text-xl font-bold text-gray-900 mb-2">
              {block.fields.name}
            </h2>
          {/if}
          {#if block.fields.title}
            <p class="text-lg text-gray-600">{block.fields.title}</p>
          {/if}
          {#if block.fields.company}
            <p class="text-lg font-medium text-purple-600">
              {block.fields.company}
            </p>
          {/if}
        </div>
      {/if}

      <div class="space-y-8 !mt-10">
        {#if emails.length > 0}
          <div class="space-y-3">
            <h3
              class="text-md font-semibold text-gray-900 flex items-center gap-2"
            >
              <i class="fas fa-envelope text-purple-500 w-5"></i>
              Email Addresses
            </h3>
            <div class="space-y-2">
              {#each emails as email}
                <div
                  class="flex items-center gap-4 p-3 bg-gray-50 rounded-lg hover:bg-purple-50 transition duration-200"
                >
                  <span
                    class="px-2 py-1 bg-purple-100 text-purple-700 rounded text-sm font-medium capitalize"
                  >
                    {email.type}
                  </span>
                  <a
                    href="mailto:{email.email}"
                    class="text-purple-600 hover:text-purple-800 transition-colors duration-200 break-all"
                  >
                    {email.email}
                  </a>
                </div>
              {/each}
            </div>
          </div>
        {/if}

        {#if phones.length > 0}
          <div class="space-y-3">
            <h3
              class="text-md font-semibold text-gray-900 flex items-center gap-2"
            >
              <i class="fas fa-phone text-purple-500 w-5"></i>
              Phone Numbers
            </h3>
            <div class="space-y-2">
              {#each phones as phone}
                <div
                  class="flex items-center gap-4 p-3 bg-gray-50 rounded-lg hover:bg-purple-50 transition duration-200"
                >
                  <span
                    class="px-2 py-1 bg-purple-100 text-purple-700 rounded text-sm font-medium capitalize"
                  >
                    {phone.type}
                  </span>
                  <a
                    href="tel:{phone.number}"
                    class="text-purple-600 hover:text-purple-800 transition-colors duration-200"
                  >
                    {phone.number}
                  </a>
                </div>
              {/each}
            </div>
          </div>
        {/if}

        {#if urls.length > 0}
          <div class="space-y-3">
            <h3
              class="text-md font-semibold text-gray-900 flex items-center gap-2"
            >
              <i class="fas fa-globe text-purple-500 w-5"></i>
              Online Presence
            </h3>
            <div class="space-y-2">
              {#each urls as url}
                <a
                  href={url.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  class="flex items-center gap-4 p-3 bg-gray-50 rounded-lg hover:bg-purple-50 transition duration-200 group"
                >
                  <i class="fas fa-link text-purple-500"></i>
                  <span
                    class="text-purple-600 transition-colors duration-200 break-all"
                  >
                    {url.url}
                  </span>
                </a>
              {/each}
            </div>
          </div>
        {/if}

        {#if block.fields.notes}
          <div class="p-4 bg-purple-50 rounded-lg space-y-2">
            <h3
              class="text-md font-semibold text-gray-900 flex items-center gap-2"
            >
              <i class="fas fa-sticky-note text-purple-500 w-5"></i>
              Additional Notes
            </h3>
            <p class="text-gray-700 whitespace-pre-line mt-4 text-md">
              {block.fields.notes}
            </p>
          </div>
        {/if}

        <Button
          on:click={downloadVCard}
          variant="secondary"
          klass="mt-4 w-full"
        >
          <i class="fas fa-address-card mr-2"></i>
          Save to Contacts
        </Button>
      </div>
    </div>
  </div>
</Page>
