import { openDefaultEditor } from "../assets/pintura/pintura.js";
import "../assets/pintura/pintura.css";

export const cropper = {
  openEditor: async (file, options = {}) => {
    const defaultOptions = {
      src: file,
      imageCropAspectRatio: 1,
    };

    const mergedOptions = { ...defaultOptions, ...options };

    return openDefaultEditor(mergedOptions);
  },

  circularCrop: async (file) => {
    return cropper.openEditor(file, {
      willRenderCanvas: (shapes, state) => {
        const { utilVisibility, selectionRect, lineColor, backgroundColor } =
          state;

        if (utilVisibility.crop <= 0) return shapes;

        const { x, y, width, height } = selectionRect;

        return {
          ...shapes,
          interfaceShapes: [
            {
              x: x + width * 0.5,
              y: y + height * 0.5,
              rx: width * 0.5,
              ry: height * 0.5,
              opacity: utilVisibility.crop,
              inverted: true,
              backgroundColor: [...backgroundColor, 0.5],
              strokeWidth: 1,
              strokeColor: [...lineColor],
            },
            ...shapes.interfaceShapes,
          ],
        };
      },
    });
  },

  crop: async (file) => {
    return cropper.openEditor(file);
  },
};
