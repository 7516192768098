<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import { spring } from "svelte/motion";

  import Logo from "$components/Logo.svelte";

  import Nav from "./home/Nav.svelte";
  import ClaimDemo from "./home/ClaimDemo.svelte";
  import CreateAndCustomize from "./home/CreateAndCustomize.svelte";
  import Analyze from "./home/Analyze.svelte";
  import Share from "./home/Share.svelte";
  import Biolinks from "./home/Biolinks.svelte";
  import Faq from "./home/Faq.svelte";
  import ChatPreview from "./home/ChatPreview.svelte";
  import ContentProtection from "./home/ContentProtection.svelte";
  import EmbedBioInk from "./home/EmbedBioInk.svelte";
  import GridView from "./home/GridView.svelte";
  import FormBuilder from "./home/FormBuilder.svelte";

  let isVisible = {
    hero: false,
    create: false,
    share: false,
    analyze: false,
    examples: false,
    advanced: false,
    faq: false,
  };

  let customizeFeatures = [
    {
      text: "Choose from stunning themes",
      icon: "M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z",
    },
    {
      text: "Add custom blocks for links, videos, and more",
      icon: "M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z",
    },
    {
      text: "Personalize with your brand colors",
      icon: "M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z",
    },
    {
      text: "Upload your own images and logos",
      icon: "M4 16l4 4h10M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2zm10-10a2 2 0 11-4 0 2 2 0 014 0zm-8 6l2-2 4 4 2-2 4 4H8z",
    },
  ];

  let analyzeFeatures = [
    {
      text: "Track data in real time.",
      icon: "M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z",
    },
    {
      text: "Understand your audience demographics",
      icon: "M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z",
    },
    {
      text: "Get in-depth analytics on clicks, views and more",
      icon: "M13 10V3L4 14h7v7l9-11h-7z",
    },
    {
      text: "Let visitors subscribe to stay updated on your latest content",
      icon: "M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z",
    },
  ];

  let heroScale = spring(0.8);
  let heroOpacity = spring(0);

  onMount(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            isVisible[entry.target.id] = true;
            if (entry.target.id === "hero") {
              heroScale.set(1);
              heroOpacity.set(1);
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    Object.keys(isVisible).forEach((key) => {
      const element = document.getElementById(key);
      if (element) observer.observe(element);
    });
  });
</script>

<main class="bg-white min-h-screen home-page-content overflow-x-hidden">
  <Nav />

  <!-- Hero Section -->
  <section
    id="hero"
    class="pt-28 sm:pt-32 pb-16 sm:pb-24 px-6 sm:px-6 relative"
  >
    <div class="absolute inset-0 z-0">
      <!-- Animated gradient background -->
      <div
        class="absolute inset-0 bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-800 animate-gradient-shift"
      ></div>
      <!-- Animated particles effect -->
      <div
        class="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(255,255,255,0.15)_1px,transparent_1px)] bg-[length:24px_24px] animate-twinkle"
      ></div>
      <!-- Glowing orbs -->
      <div
        class="absolute top-1/4 left-1/4 w-64 h-64 bg-purple-500/20 rounded-full blur-3xl animate-float"
      ></div>
      <div
        class="absolute bottom-1/4 right-1/4 w-96 h-96 bg-indigo-500/20 rounded-full blur-3xl animate-float-delayed"
      ></div>
    </div>
    {#if isVisible.hero}
      <div
        class="relative z-10 max-w-6xl mx-auto grid md:grid-cols-2 gap-8 md:gap-12 items-center"
      >
        <div class="text-center md:text-left">
          <div class="relative inline-block mb-12">
            <h1
              class="hero-title text-[clamp(2rem,5vw,3rem)] font-[800] text-white tracking-tight leading-tight"
            >
              Claim Your
              <span class="relative inline-block">
                <span class="relative z-10">BioInk</span>
                <div
                  class="absolute -bottom-2 left-0 w-full h-1.5 bg-gradient-to-r from-purple-400 via-pink-400 to-purple-400 animate-shimmer"
                ></div>
              </span>
            </h1>
            <!-- Decorative elements -->
            <div
              class="absolute -top-8 -left-8 w-16 h-16 bg-gradient-to-r from-purple-400/20 to-pink-400/20 rounded-full blur-xl animate-pulse"
            ></div>
            <div
              class="absolute -bottom-8 -right-8 w-20 h-20 bg-gradient-to-r from-indigo-400/20 to-purple-400/20 rounded-full blur-xl animate-pulse delay-200"
            ></div>
          </div>
          <p
            class="font-[600] text-xl md:text-2xl text-purple-50 mb-14 leading-relaxed max-w-[90%] md:max-w-none ml-auto mr-auto"
          >
            One link to rule them all. Showcase your digital world with
            <span
              class="font-[600] bg-gradient-to-r from-purple-200 to-pink-200 text-transparent bg-clip-text"
              >style and simplicity</span
            >.
          </p>
          <div class="text-center md:text-left">
            <a
              href="/register"
              class="group inline-flex items-center bg-white/10 backdrop-blur-sm text-white font-semibold px-10 md:px-12 py-4 rounded-full transition-all duration-300 hover:bg-white/20 relative overflow-hidden shadow-[0_0_12px_rgba(168,85,247,0.25)] hover:shadow-[0_0_20px_rgba(168,85,247,0.4)]"
            >
              <span class="relative z-10 text-lg mr-2">Get started</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 transform group-hover:translate-x-1 transition-transform"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 7l5 5m0 0l-5 5m5-5H6"
                />
              </svg>
              <div
                class="absolute inset-0 -translate-x-full group-hover:translate-x-0 bg-gradient-to-r from-purple-500/40 to-pink-500/40 transition-transform duration-300"
              ></div>
            </a>
          </div>
        </div>
        <div class="relative md:ml-auto -mb-12 md:mb-0">
          <div
            class="absolute -inset-4 bg-gradient-to-r from-purple-500/30 to-pink-500/30 rounded-3xl blur-xl animate-pulse"
          ></div>
          <div
            class="relative scale-[0.8] lg:scale-[0.9] md:scale-[0.8] md:w-[375px]"
          >
            <ClaimDemo />
          </div>
        </div>
      </div>
    {/if}
  </section>

  <!-- Create and Customize Section -->
  <section
    id="create"
    class="relative py-16 sm:py-32 px-6 sm:px-6 overflow-hidden"
  >
    {#if isVisible.create}
      <div
        class="absolute inset-0 bg-gradient-to-br from-purple-50/50 to-transparent"
        style="clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);"
      ></div>

      <div
        class="relative max-w-6xl mx-auto"
        in:fly={{ y: 50, duration: 1000, delay: 200 }}
      >
        <div class="text-center mb-16">
          <h2
            class="text-2xl sm:text-4xl font-[700] text-purple-900 tracking-tight"
          >
            Create and Customize
          </h2>
          <p
            class="mt-4 text-base sm:text-lg text-purple-600/90 max-w-2xl mx-auto"
          >
            Make your BioInk uniquely yours with beautiful themes, custom
            layouts, and personalized styling options.
          </p>
          <div
            class="mt-4 w-16 sm:w-20 h-1 bg-gradient-to-r from-purple-400 to-transparent mx-auto"
          ></div>
        </div>

        <div class="grid md:grid-cols-2 gap-12 sm:gap-20 items-center">
          <div class="relative">
            <div
              class="absolute -inset-4 bg-gradient-to-r from-purple-500/10 to-pink-500/10 rounded-3xl blur-xl"
            ></div>
            <div class="relative">
              <CreateAndCustomize />
            </div>
          </div>

          <div class="space-y-8 sm:space-y-10">
            <p
              class="text-lg sm:text-xl text-purple-700 leading-relaxed text-center md:text-left font-[700]"
            >
              Design your perfect BioInk page.
            </p>
            <ul class="space-y-6 sm:space-y-8">
              {#each customizeFeatures as feature}
                <li class="flex items-center group">
                  <div
                    class="mr-4 p-2 rounded-lg bg-purple-100/50 group-hover:bg-purple-100 transition-colors duration-200"
                  >
                    <svg
                      class="h-5 w-5 text-purple-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        d={feature.icon}
                      />
                    </svg>
                  </div>
                  <span
                    class="text-base sm:text-lg text-purple-700 group-hover:text-purple-900 transition-colors duration-200"
                    >{feature.text}</span
                  >
                </li>
              {/each}
            </ul>
          </div>
        </div>
      </div>
    {/if}
  </section>

  <!-- Advanced Features Section -->
  <section id="advanced" class="py-16 sm:py-32 px-6 sm:px-6 bg-purple-50">
    {#if isVisible.advanced}
      <div class="max-w-6xl mx-auto">
        <div class="text-center mb-16">
          <h2
            class="text-2xl sm:text-4xl font-[700] text-purple-900 tracking-tight"
          >
            Advanced Features
          </h2>
          <p
            class="mt-4 text-base sm:text-lg text-purple-600/90 max-w-2xl mx-auto"
          >
            Powerful tools to engage with your audience and grow your presence
          </p>
          <div
            class="mt-4 w-16 sm:w-20 h-1 bg-gradient-to-r from-purple-400 to-transparent mx-auto"
          ></div>
        </div>

        <div class="grid md:grid-cols-2 gap-8 sm:gap-16 items-center">
          <div class="relative order-2 md:order-1">
            <div
              class="absolute -inset-4 bg-gradient-to-r from-purple-500/10 to-pink-500/10 rounded-3xl blur-xl"
            ></div>
            <div
              class="relative rounded-xl shadow-xl overflow-hidden bg-purple-50"
            >
              <ChatPreview />
            </div>
          </div>

          <div class="space-y-6 sm:space-y-8 order-1 md:order-2">
            <h3
              class="text-xl sm:text-2xl font-semibold text-purple-900 text-center md:text-left"
            >
              Real-time Chat with Visitors
            </h3>
            <ul class="space-y-4 sm:space-y-6">
              <li class="flex items-center space-x-3 sm:space-x-4 group">
                <div
                  class="flex-shrink-0 w-10 h-10 sm:w-12 sm:h-12 rounded-xl bg-gradient-to-br from-purple-500/10 to-purple-600/10 group-hover:from-purple-500/20 group-hover:to-purple-600/20 flex items-center justify-center transition-all duration-300"
                >
                  <svg
                    class="h-5 w-5 sm:h-6 sm:w-6 text-purple-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                    />
                  </svg>
                </div>
                <span
                  class="text-base sm:text-lg text-purple-800 group-hover:text-purple-900 font-medium transition-colors duration-200"
                >
                  Engage with your audience in real-time
                </span>
              </li>
              <li class="flex items-center space-x-3 sm:space-x-4 group">
                <div
                  class="flex-shrink-0 w-10 h-10 sm:w-12 sm:h-12 rounded-xl bg-gradient-to-br from-purple-500/10 to-purple-600/10 group-hover:from-purple-500/20 group-hover:to-purple-600/20 flex items-center justify-center transition-all duration-300"
                >
                  <svg
                    class="h-5 w-5 sm:h-6 sm:w-6 text-purple-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                </div>
                <span
                  class="text-base sm:text-lg text-purple-800 group-hover:text-purple-900 font-medium transition-colors duration-200"
                >
                  Instant notifications for new messages
                </span>
              </li>
              <li class="flex items-center space-x-3 sm:space-x-4 group">
                <div
                  class="flex-shrink-0 w-10 h-10 sm:w-12 sm:h-12 rounded-xl bg-gradient-to-br from-purple-500/10 to-purple-600/10 group-hover:from-purple-500/20 group-hover:to-purple-600/20 flex items-center justify-center transition-all duration-300"
                >
                  <svg
                    class="h-5 w-5 sm:h-6 sm:w-6 text-purple-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    />
                  </svg>
                </div>
                <span
                  class="text-base sm:text-lg text-purple-800 group-hover:text-purple-900 font-medium transition-colors duration-200"
                >
                  Secure and private conversations
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="grid md:grid-cols-2 gap-8 sm:gap-16 items-center mt-20 sm:mt-28"
        >
          <div class="space-y-6 sm:space-y-8 order-1">
            <h3
              class="text-xl sm:text-2xl font-semibold text-purple-900 text-center md:text-left"
            >
              Form Builder
            </h3>
            <ul class="space-y-4 sm:space-y-6">
              <li class="flex items-center space-x-3 sm:space-x-4 group">
                <div
                  class="flex-shrink-0 w-10 h-10 sm:w-12 sm:h-12 rounded-xl bg-gradient-to-br from-purple-500/10 to-purple-600/10 group-hover:from-purple-500/20 group-hover:to-purple-600/20 flex items-center justify-center transition-all duration-300"
                >
                  <svg
                    class="h-5 w-5 sm:h-6 sm:w-6 text-purple-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                    />
                  </svg>
                </div>
                <span
                  class="text-base sm:text-lg text-purple-800 group-hover:text-purple-900 font-medium transition-colors duration-200"
                >
                  Drag-and-drop form builder interface
                </span>
              </li>
              <li class="flex items-center space-x-3 sm:space-x-4 group">
                <div
                  class="flex-shrink-0 w-10 h-10 sm:w-12 sm:h-12 rounded-xl bg-gradient-to-br from-purple-500/10 to-purple-600/10 group-hover:from-purple-500/20 group-hover:to-purple-600/20 flex items-center justify-center transition-all duration-300"
                >
                  <svg
                    class="h-5 w-5 sm:h-6 sm:w-6 text-purple-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                    />
                  </svg>
                </div>
                <span
                  class="text-base sm:text-lg text-purple-800 group-hover:text-purple-900 font-medium transition-colors duration-200"
                >
                  Multiple field types and validations
                </span>
              </li>
              <li class="flex items-center space-x-3 sm:space-x-4 group">
                <div
                  class="flex-shrink-0 w-10 h-10 sm:w-12 sm:h-12 rounded-xl bg-gradient-to-br from-purple-500/10 to-purple-600/10 group-hover:from-purple-500/20 group-hover:to-purple-600/20 flex items-center justify-center transition-all duration-300"
                >
                  <svg
                    class="h-5 w-5 sm:h-6 sm:w-6 text-purple-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    />
                  </svg>
                </div>
                <span
                  class="text-base sm:text-lg text-purple-800 group-hover:text-purple-900 font-medium transition-colors duration-200"
                >
                  Export and download your form responses
                </span>
              </li>
            </ul>
          </div>

          <div class="relative order-2">
            <div
              class="absolute -inset-4 bg-gradient-to-r from-purple-500/10 to-pink-500/10 rounded-3xl blur-xl"
            ></div>
            <div class="relative">
              <FormBuilder />
            </div>
          </div>
        </div>

        <div
          class="grid md:grid-cols-2 gap-8 sm:gap-16 items-center mt-20 sm:mt-28"
        >
          <div class="relative order-2 md:order-1">
            <ContentProtection />
          </div>

          <div class="space-y-6 sm:space-y-8 order-1 md:order-2">
            <h3
              class="text-xl sm:text-2xl font-semibold text-purple-900 text-center md:text-left"
            >
              Content Protection
            </h3>
            <ul class="space-y-4 sm:space-y-6">
              <li class="flex items-center space-x-3 sm:space-x-4 group">
                <div
                  class="flex-shrink-0 w-10 h-10 sm:w-12 sm:h-12 rounded-xl bg-gradient-to-br from-purple-500/10 to-purple-600/10 group-hover:from-purple-500/20 group-hover:to-purple-600/20 flex items-center justify-center transition-all duration-300"
                >
                  <svg
                    class="h-5 w-5 sm:h-6 sm:w-6 text-purple-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33"
                    />
                  </svg>
                </div>
                <span
                  class="text-base sm:text-lg text-purple-800 group-hover:text-purple-900 font-medium transition-colors duration-200"
                >
                  Secure content with custom access codes
                </span>
              </li>
              <li class="flex items-center space-x-3 sm:space-x-4 group">
                <div
                  class="flex-shrink-0 w-10 h-10 sm:w-12 sm:h-12 rounded-xl bg-gradient-to-br from-purple-500/10 to-purple-600/10 group-hover:from-purple-500/20 group-hover:to-purple-600/20 flex items-center justify-center transition-all duration-300"
                >
                  <svg
                    class="h-5 w-5 sm:h-6 sm:w-6 text-purple-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <span
                  class="text-base sm:text-lg text-purple-800 group-hover:text-purple-900 font-medium transition-colors duration-200"
                >
                  Age verification for mature content
                </span>
              </li>
              <li class="flex items-center space-x-3 sm:space-x-4 group">
                <div
                  class="flex-shrink-0 w-10 h-10 sm:w-12 sm:h-12 rounded-xl bg-gradient-to-br from-purple-500/10 to-purple-600/10 group-hover:from-purple-500/20 group-hover:to-purple-600/20 flex items-center justify-center transition-all duration-300"
                >
                  <svg
                    class="h-5 w-5 sm:h-6 sm:w-6 text-purple-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                    />
                  </svg>
                </div>
                <span
                  class="text-base sm:text-lg text-purple-800 group-hover:text-purple-900 font-medium transition-colors duration-200"
                >
                  Content warnings for sensitive material
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="grid md:grid-cols-2 gap-8 sm:gap-16 items-center mt-20 sm:mt-28"
        >
          <div class="relative order-2 md:order-2">
            <div
              class="absolute -inset-4 bg-gradient-to-r from-purple-500/10 to-pink-500/10 rounded-3xl blur-xl"
            ></div>
            <div class="relative">
              <EmbedBioInk />
            </div>
          </div>

          <div class="space-y-6 sm:space-y-8 order-1 md:order-1">
            <h3
              class="text-xl sm:text-2xl font-semibold text-purple-900 text-center md:text-left"
            >
              Embed BioInk Anywhere
            </h3>
            <ul class="space-y-4 sm:space-y-6">
              <li class="flex items-center space-x-3 sm:space-x-4 group">
                <div
                  class="flex-shrink-0 w-10 h-10 sm:w-12 sm:h-12 rounded-xl bg-gradient-to-br from-purple-500/10 to-purple-600/10 group-hover:from-purple-500/20 group-hover:to-purple-600/20 flex items-center justify-center transition-all duration-300"
                >
                  <svg
                    class="h-5 w-5 sm:h-6 sm:w-6 text-purple-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                    />
                  </svg>
                </div>
                <span
                  class="text-base sm:text-lg text-purple-800 group-hover:text-purple-900 font-medium transition-colors duration-200"
                >
                  Simple embed code for any website
                </span>
              </li>
              <li class="flex items-center space-x-3 sm:space-x-4 group">
                <div
                  class="flex-shrink-0 w-10 h-10 sm:w-12 sm:h-12 rounded-xl bg-gradient-to-br from-purple-500/10 to-purple-600/10 group-hover:from-purple-500/20 group-hover:to-purple-600/20 flex items-center justify-center transition-all duration-300"
                >
                  <svg
                    class="h-5 w-5 sm:h-6 sm:w-6 text-purple-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
                    />
                  </svg>
                </div>
                <span
                  class="text-base sm:text-lg text-purple-800 group-hover:text-purple-900 font-medium transition-colors duration-200"
                >
                  <span class="inline"
                    >Responsive design that fits anywhere</span
                  >
                </span>
              </li>
              <li class="flex items-center space-x-3 sm:space-x-4 group">
                <div
                  class="flex-shrink-0 w-10 h-10 sm:w-12 sm:h-12 rounded-xl bg-gradient-to-br from-purple-500/10 to-purple-600/10 group-hover:from-purple-500/20 group-hover:to-purple-600/20 flex items-center justify-center transition-all duration-300"
                >
                  <svg
                    class="h-5 w-5 sm:h-6 sm:w-6 text-purple-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                    />
                  </svg>
                </div>
                <span
                  class="text-base sm:text-lg text-purple-800 group-hover:text-purple-900 font-medium transition-colors duration-200"
                >
                  Customizable embed settings
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="grid md:grid-cols-2 gap-8 sm:gap-16 items-center mt-20 sm:mt-28"
        >
          <div class="space-y-6 sm:space-y-8 md:order-2 order-1">
            <h3
              class="text-xl sm:text-2xl font-semibold text-purple-900 text-center md:text-left"
            >
              Grid View
            </h3>
            <ul class="space-y-4 sm:space-y-6">
              <li class="flex items-center space-x-3 sm:space-x-4 group">
                <div
                  class="flex-shrink-0 w-10 h-10 sm:w-12 sm:h-12 rounded-xl bg-gradient-to-br from-purple-500/10 to-purple-600/10 group-hover:from-purple-500/20 group-hover:to-purple-600/20 flex items-center justify-center transition-all duration-300"
                >
                  <svg
                    class="h-5 w-5 sm:h-6 sm:w-6 text-purple-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                    />
                  </svg>
                </div>
                <span
                  class="text-base sm:text-lg text-purple-800 group-hover:text-purple-900 font-medium transition-colors duration-200"
                >
                  Beautiful grid layout for your content
                </span>
              </li>
              <li class="flex items-center space-x-3 sm:space-x-4 group">
                <div
                  class="flex-shrink-0 w-10 h-10 sm:w-12 sm:h-12 rounded-xl bg-gradient-to-br from-purple-500/10 to-purple-600/10 group-hover:from-purple-500/20 group-hover:to-purple-600/20 flex items-center justify-center transition-all duration-300"
                >
                  <svg
                    class="h-5 w-5 sm:h-6 sm:w-6 text-purple-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
                    />
                  </svg>
                </div>
                <span
                  class="text-base sm:text-lg text-purple-800 group-hover:text-purple-900 font-medium transition-colors duration-200"
                >
                  Flexible sizing and spacing options
                </span>
              </li>
              <li class="flex items-center space-x-3 sm:space-x-4 group">
                <div
                  class="flex-shrink-0 w-10 h-10 sm:w-12 sm:h-12 rounded-xl bg-gradient-to-br from-purple-500/10 to-purple-600/10 group-hover:from-purple-500/20 group-hover:to-purple-600/20 flex items-center justify-center transition-all duration-300"
                >
                  <svg
                    class="h-5 w-5 sm:h-6 sm:w-6 text-purple-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
                    />
                  </svg>
                </div>
                <span
                  class="text-base sm:text-lg text-purple-800 group-hover:text-purple-900 font-medium transition-colors duration-200"
                >
                  Pinterest-like layout for a dynamic grid
                </span>
              </li>
            </ul>
          </div>

          <div class="relative md:order-1 order-2">
            <div
              class="absolute -inset-4 bg-gradient-to-r from-purple-500/10 to-pink-500/10 rounded-3xl blur-xl"
            ></div>
            <div class="relative">
              <GridView />
            </div>
          </div>
        </div>
      </div>
    {/if}
  </section>

  <!-- Share Section -->
  <section id="share" class="py-16 sm:py-24 px-6 sm:px-6">
    {#if isVisible.share}
      <div class="max-w-6xl mx-auto">
        <div class="text-center mb-12 sm:mb-16">
          <h2
            class="text-2xl sm:text-4xl font-[700] text-purple-900 tracking-tight"
          >
            Share Your BioInk
          </h2>
          <div
            class="mt-4 w-16 sm:w-20 h-1 bg-gradient-to-r from-purple-400 to-transparent mx-auto"
          ></div>
        </div>
        <Share />
      </div>
    {/if}
  </section>

  <!-- Analyze Section -->
  <section id="analyze" class="py-16 sm:py-24 px-6 sm:px-6 bg-purple-50">
    {#if isVisible.analyze}
      <div
        class="max-w-6xl mx-auto"
        in:fly={{ y: 50, duration: 1000, delay: 200 }}
      >
        <div class="text-center mb-16">
          <h2
            class="text-2xl sm:text-4xl font-[700] text-purple-900 tracking-tight"
          >
            Analyze your audience
          </h2>
          <h4
            class="mt-4 text-base sm:text-lg text-purple-700/90 max-w-2xl mx-auto leading-relaxed"
          >
            Get real-time insights into visitor behavior, revenue metrics, and
            conversion rates. Use data-driven decisions to optimize your content
            and grow your audience.
          </h4>
          <div
            class="mt-4 w-16 sm:w-20 h-1 bg-gradient-to-r from-purple-400 to-transparent mx-auto"
          ></div>
        </div>

        <div class="grid md:grid-cols-2 gap-12 sm:gap-16 items-center">
          <Analyze />

          <div class="space-y-8 mt-4">
            <h3
              class="text-xl sm:text-2xl font-semibold text-purple-900 text-center md:text-left"
            >
              Powerful Analytics & Engagement Tools
            </h3>
            <ul class="space-y-6">
              {#each analyzeFeatures as feature}
                <li class="flex items-center space-x-4 group">
                  <div
                    class="flex-shrink-0 w-12 h-12 rounded-xl bg-gradient-to-br from-purple-500/10 to-purple-600/10 group-hover:from-purple-500/20 group-hover:to-purple-600/20 flex items-center justify-center transition-all duration-300"
                  >
                    <svg
                      class="h-6 w-6 text-purple-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        d={feature.icon}
                      />
                    </svg>
                  </div>
                  <span
                    class="text-base sm:text-lg text-purple-800 group-hover:text-purple-900 font-medium transition-colors duration-200"
                  >
                    {feature.text}
                  </span>
                </li>
              {/each}
            </ul>
          </div>
        </div>
      </div>
    {/if}
  </section>

  <!-- Example BioInks Section -->
  <section id="examples" class="py-16 sm:py-24 px-6 sm:px-6">
    {#if isVisible.examples}
      <div class="max-w-6xl mx-auto">
        <div class="text-center mb-16">
          <h2
            class="text-2xl sm:text-4xl font-[700] text-purple-900 tracking-tight"
          >
            Featured BioInks
          </h2>
          <p class="mt-4 text-base sm:text-lg text-purple-600">
            Get inspired by these stunning examples
          </p>
          <div
            class="mt-4 w-16 sm:w-20 h-1 bg-gradient-to-r from-purple-400 to-transparent mx-auto"
          ></div>
        </div>

        <Biolinks />
      </div>
    {/if}
  </section>

  <!-- FAQ Section -->
  <section id="faq" class="py-16 sm:py-24 px-6 sm:px-6 bg-purple-50">
    {#if isVisible.faq}
      <div class="max-w-4xl mx-auto">
        <div class="text-center mb-16">
          <h2
            class="text-2xl sm:text-4xl font-[700] text-purple-900 tracking-tight"
          >
            Frequently Asked Questions
          </h2>
          <div
            class="mt-4 w-16 sm:w-20 h-1 bg-gradient-to-r from-purple-400 to-transparent mx-auto"
          ></div>
        </div>

        <div class="space-y-4">
          <Faq />
        </div>
      </div>
    {/if}
  </section>

  <!-- Call to Action -->
  <section class="py-20 sm:py-16 px-6 sm:px-6 text-center bg-purple-900">
    <div class="max-w-3xl mx-auto">
      <h2
        class="text-2xl sm:text-3xl font-[700] text-white mb-8 tracking-tight"
      >
        Ready to Create Your BioInk?
      </h2>
      <p class="text-base sm:text-lg text-purple-200 mb-10 sm:mb-12">
        Join thousands of creators and start sharing your digital world today.
        It's time to make your mark online!
      </p>
      <a
        href="/register"
        class="inline-flex bg-white text-purple-900 font-medium px-6 sm:px-8 py-3 rounded-full hover:bg-purple-50 transition-colors duration-200"
      >
        Get Started for Free
      </a>
    </div>
  </section>

  <!-- Footer -->
  <footer class="bg-purple-50">
    <div class="max-w-7xl mx-auto px-6 py-6">
      <div class="flex flex-col sm:flex-row justify-center items-center gap-2">
        <p class="text-sm text-purple-600 text-center">
          © {new Date().getFullYear()} BioInk. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</main>

<style>
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");

  .home-page-content {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
  }

  .hero-title {
    font-weight: 800;
  }

  .hero-subtitle {
    font-weight: 800;
  }
</style>
