<script>
  import Page from "$app-components/blocks/_layouts/Page.svelte";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;
  export let blockClicked = function () {};
  export let blockViewed = function () {};
  export let onActualPage;

  function getTiktokEmbedUrl(url) {
    // Handle both profile and video URLs from TikTok
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/@?([\w.-]+)(?:\/video\/(\d+))?/
    );

    if (!match) return null;

    const [, username, videoId] = match;

    if (videoId) {
      return `https://www.tiktok.com/embed/v2/${videoId}`;
    }

    return `https://www.tiktok.com/embed/@${username}`;
  }

  $: tiktokEmbedUrl = getTiktokEmbedUrl(block.url);
  $: tiktokType = tiktokEmbedUrl?.includes("v2") ? "video" : "profile";
</script>

<Page
  {block}
  {theme}
  {buttonStyle}
  {hoverEffect}
  {blockClicked}
  {blockViewed}
  iframeUrl={tiktokEmbedUrl}
  width={tiktokType === "video" ? "325px" : "778px"}
  height={tiktokType === "video" ? "740px" : "500px"}
  goToButtonText="Open in TikTok"
  {onActualPage}
/>
