<script>
  import { createEventDispatcher } from "svelte";

  export let name = "";
  export let value = "";
  export let placeholder = "";
  export let required = false;
  export let disabled = false;
  export let error = null;
  export let klass = "";
  export let label = "";
  export let hint = "";
  export let rows = 3;
  export let containerClass = "w-full";
  export let self;
  export let maxLength = null; // New prop for maximum length

  const dispatch = createEventDispatcher();

  const baseClasses =
    "block w-full rounded-lg text-zinc-900 focus:ring-0 sm:text-sm sm:leading-6 border-zinc-300 focus:border-zinc-400";

  const stateClasses = {
    default: "",
    error: "ring-red-300 text-red-900 focus:ring-red-500",
    disabled: "bg-gray-50 text-gray-500 cursor-not-allowed",
  };

  $: textareaClasses = `
    ${baseClasses}
    ${error ? stateClasses.error : stateClasses.default}
    ${disabled ? stateClasses.disabled : ""}
    ${klass}
  `;

  function handleInput(event) {
    if (maxLength !== null) {
      value = event.target.value.slice(0, maxLength);
    } else {
      value = event.target.value;
    }
    dispatch("input", { value });
  }

  function handleFocus(event) {
    dispatch("focus", event);
  }

  function handleBlur(event) {
    dispatch("blur", event);
  }

  function handleKeydown(event) {
    dispatch("keydown", { event, value, data: { [name]: value } });
  }

  function handleKeyup(event) {
    dispatch("keyup", { event, value, data: { [name]: value } });
  }

  function handleKeypress(event) {
    dispatch("keypress", { event, value, data: { [name]: value } });
  }
</script>

<div class={containerClass}>
  <slot name="label">
    {#if label}
      <label
        for={name}
        class="block text-sm font-medium leading-6 text-gray-900 mb-2"
      >
        <slot name="label-content">
          {label}
          {#if required}
            <span class="text-red-500 ml-1">*</span>
          {/if}
        </slot>
      </label>
    {/if}
  </slot>

  <div class="rounded-md shadow-sm">
    <textarea
      {name}
      {placeholder}
      {required}
      {disabled}
      {rows}
      id={name}
      class={textareaClasses}
      bind:value
      bind:this={self}
      maxlength={maxLength}
      on:input={handleInput}
      on:focus={handleFocus}
      on:blur={handleBlur}
      on:keydown={handleKeydown}
      on:keyup={handleKeyup}
      on:keypress={handleKeypress}
    ></textarea>
  </div>

  <slot name="error">
    {#if error}
      <div class="flex items-center mt-2">
        <svg
          class="h-5 w-5 text-red-500 mr-2"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
        <p class="text-sm text-red-600">{error}</p>
      </div>
    {:else if hint}
      <p class="mt-2 text-sm text-gray-500">{hint}</p>
    {/if}
  </slot>
</div>
