<script>
  import { createEventDispatcher } from "svelte";
  import View from "$app-components/blocks/_layouts/View.svelte";

  const dispatch = createEventDispatcher();

  export let block;
  export let updater;

  function handleDelete() {
    dispatch("delete-block");
  }

  $: ({ fields } = block);
  $: url = block.url;
  $: title = block.title;
</script>

<View {block} {updater} on:delete-block={handleDelete}>
  <div
    class="w-12 h-12 mr-4 flex items-center justify-center flex-shrink-0 bg-gray-100 rounded-lg">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="w-6 h-6 text-gray-600">
      <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
      <line x1="16" y1="2" x2="16" y2="6"></line>
      <line x1="8" y1="2" x2="8" y2="6"></line>
      <line x1="3" y1="10" x2="21" y2="10"></line>
    </svg>
  </div>
  <div class="flex-1 min-w-0 pl-2">
    <div class="flex flex-col">
      <span class="text-md font-semibold text-gray-800 truncate">
        {title}
      </span>
      <span class="text-sm text-gray-500 truncate">{url}</span>
    </div>
  </div>
</View>
