export default {
  facebook: {
    icon: "fa-brands fa-facebook-f",
    baseUrl: "facebook.",
    color: "#1877F2",
  },
  twitter: {
    icon: "fa-brands fa-x-twitter",
    baseUrl: "twitter.",
    color: "#1DA1F2",
  },
  x: {
    icon: "fa-brands fa-x-twitter",
    baseUrl: "x.com",
    color: "#000000",
  },
  instagram: {
    icon: "fa-brands fa-instagram",
    baseUrl: "instagram.",
    color: "#E4405F",
  },
  linkedin: {
    icon: "fa-brands fa-linkedin-in",
    baseUrl: "linkedin.",
    color: "#0A66C2",
  },
  github: {
    icon: "fa-brands fa-github-alt",
    baseUrl: "github.",
    color: "#181717",
  },
  youtube: {
    icon: "fa-brands fa-youtube",
    baseUrl: "youtube.",
    color: "#FF0000",
  },
  tiktok: {
    icon: "fa-brands fa-tiktok",
    baseUrl: "tiktok.",
    color: "#000000",
  },
  pinterest: {
    icon: "fa-brands fa-pinterest",
    baseUrl: "pinterest.",
    color: "#BD081C",
  },
  snapchat: {
    icon: "fa-brands fa-snapchat",
    baseUrl: "snapchat.",
    color: "#FFFC00",
  },
  reddit: {
    icon: "fa-brands fa-reddit-alien",
    baseUrl: "reddit.",
    color: "#FF4500",
  },
  whatsapp: {
    icon: "fa-brands fa-whatsapp",
    baseUrl: "wa.me",
    color: "#25D366",
  },
  telegram: {
    icon: "fa-brands fa-telegram",
    baseUrl: "t.me",
    color: "#0088cc",
  },
  medium: {
    icon: "fa-brands fa-medium",
    baseUrl: "medium.",
    color: "#00AB6C",
  },
  dribbble: {
    icon: "fa-brands fa-dribbble",
    baseUrl: "dribbble.",
    color: "#EA4C89",
  },
  behance: {
    icon: "fa-brands fa-behance",
    baseUrl: "behance.",
    color: "#1769FF",
  },
  vimeo: {
    icon: "fa-brands fa-vimeo",
    baseUrl: "vimeo.",
    color: "#1AB7EA",
  },
  twitch: {
    icon: "fa-brands fa-twitch",
    baseUrl: "twitch.",
    color: "#9146FF",
  },
  soundcloud: {
    icon: "fa-brands fa-soundcloud",
    baseUrl: "soundcloud.",
    color: "#FF3300",
  },
  spotify: {
    icon: "fa-brands fa-spotify",
    baseUrl: "spotify.",
    color: "#1DB954",
  },
  tumblr: {
    icon: "fa-brands fa-tumblr",
    baseUrl: "tumblr.",
    color: "#35465C",
  },
  flickr: {
    icon: "fa-brands fa-flickr",
    baseUrl: "flickr.",
    color: "#0063DC",
  },
  quora: {
    icon: "fa-brands fa-quora",
    baseUrl: "quora.",
    color: "#B92B27",
  },
  vk: {
    icon: "fa-brands fa-vk",
    baseUrl: "vk.com",
    color: "#4A76A8",
  },
  weibo: {
    icon: "fa-brands fa-weibo",
    baseUrl: "weibo.",
    color: "#DF2029",
  },
  line: {
    icon: "fa-brands fa-line",
    baseUrl: "line.me",
    color: "#00C300",
  },
  discord: {
    icon: "fa-brands fa-discord",
    baseUrl: "discord.",
    color: "#7289DA",
  },
  stackoverflow: {
    icon: "fa-brands fa-stack-overflow",
    baseUrl: "stackoverflow.",
    color: "#F48024",
  },
  deviantart: {
    icon: "fa-brands fa-deviantart",
    baseUrl: "deviantart.",
    color: "#05CC47",
  },
  goodreads: {
    icon: "fa-brands fa-goodreads",
    baseUrl: "goodreads.",
    color: "#553B08",
  },
  patreon: {
    icon: "fa-brands fa-patreon",
    baseUrl: "patreon.",
    color: "#FF424D",
  },
  amazon: {
    icon: "fa-brands fa-amazon",
    baseUrl: "amazon.",
    color: "#FF9900",
  },
  mailto: {
    icon: "fa-solid fa-envelope",
    baseUrl: "mailto:",
    color: "#808080",
  },
  other: {
    icon: "fa-solid fa-link",
    baseUrl: "",
    color: "#000000",
  },
};
