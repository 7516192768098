<script>
  import { fade } from "svelte/transition";

  export let selectedLinks;
  export let links;

  function isLinkSelected(selectedLinks, link) {
    return selectedLinks.some((l) => l.id === link.id);
  }

  function toggleLink(link) {
    const index = selectedLinks.findIndex((l) => l.id === link.id);

    if (index === -1) {
      selectedLinks = [...selectedLinks, link];
    } else {
      selectedLinks = selectedLinks.filter((l) => l.id !== link.id);
    }
  }
</script>

<div in:fade>
  <h2 class="text-xl font-bold text-gray-800 mb-8">Select content to import</h2>
  <div class="space-y-4">
    {#if links.length === 0}
      <div
        class="flex flex-col items-center justify-center p-8 bg-gray-50 rounded-lg border border-gray-200"
      >
        <svg
          class="w-12 h-12 text-gray-400 mb-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M13 10V3L4 14h7v7l9-11h-7z"
          />
        </svg>
        <p class="text-lg text-gray-600 text-center">
          No links found to import
        </p>
      </div>
    {/if}

    {#each links as link}
      <div
        class={`p-4 rounded-lg transition-all duration-100 hover:shadow-lg ${
          isLinkSelected(selectedLinks, link)
            ? "bg-purple-50 border-2 border-purple-300"
            : "bg-gray-50 border border-gray-200"
        }`}
      >
        <label class="flex items-center justify-between cursor-pointer">
          <div class="flex flex-col flex-grow mr-4 overflow-hidden">
            <span class="text-lg font-medium truncate">{link.title}</span>
            <span
              class="text-sm text-gray-500 truncate md:whitespace-normal md:overflow-ellipsis md:overflow-hidden md:max-h-6"
              >{link.url}</span
            >
          </div>
          <input
            type="checkbox"
            checked={isLinkSelected(selectedLinks, link)}
            on:change={() => toggleLink(link)}
            class="w-5 h-5 text-purple-600 rounded focus:ring-purple-500 flex-shrink-0"
          />
        </label>
      </div>
    {/each}
  </div>
</div>
