<script>
  import Page from "$app-components/blocks/_layouts/Page.svelte";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;
  export let blockClicked = function () {};
  export let blockViewed = function () {};
  export let onActualPage;

  function getAudiomackEmbedUrl(url) {
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?audiomack\.com\/([^\/]+)\/(?:song|album|playlist)\/([^\/]+)(?:\/([^\/]+))?/
    );

    if (match) {
      const [, artist, slug] = match;
      const type = url.includes("song")
        ? "song"
        : url.includes("album")
          ? "album"
          : "playlist";
      return `https://audiomack.com/embed/${type}/${artist}/${slug}`;
    }

    return null;
  }

  $: audiomackEmbedUrl = getAudiomackEmbedUrl(block.url);
</script>

<Page
  {block}
  {theme}
  {buttonStyle}
  {hoverEffect}
  {blockClicked}
  {blockViewed}
  iframeUrl={audiomackEmbedUrl}
  goToButtonText="Open in Audiomack"
  {onActualPage}
/>
