<script>
  import { onMount } from "svelte";

  import Dashboard from "$layouts/Dashboard.svelte";
  import Sortable, {
    AutoScroll,
  } from "sortablejs/modular/sortable.core.esm.js";

  import Ajax from "$utils/ajax";

  import IphonePreview from "./IphonePreview.svelte";
  import AddBlockModal from "$app-components/block-builder/AddBlockModal.svelte";
  import ViewBlock from "$app-components/block-builder/ViewBlock.svelte";
  import MobilePreview from "./MobilePreview.svelte";
  import UrlCard from "./blocks/UrlCard.svelte";
  import DirectRedirect from "./blocks/DirectRedirect.svelte";

  export let blocks;
  export let currentUser;
  export let page;
  export let permissions;

  let updater = function (fn) {
    const data = fn({ blocks, currentUser });

    if (data.blocks) {
      blocks = data.blocks.sort((a, b) => a.position - b.position);
    }

    if (data.currentUser) {
      currentUser = data.currentUser;
    }
  };

  let blocksContainer;
  let isAddBlockModalOpen = false;
  let isPreviewModalOpen = false;

  onMount(() => {
    try {
      Sortable.mount(new AutoScroll());
    } catch {}

    new Sortable(blocksContainer, {
      animation: 150,
      ghostClass: "bg-gray-100",
      direction: "vertical",
      handle: ".cursor-move",
      scroll: true,
      scrollSensitivity: 100,
      scrollSpeed: 10,
      forceFallback: true,
      onStart: () => {
        document.body.style.userSelect = "none";
        document.body.style.webkitUserSelect = "none";
        document.body.style.mozUserSelect = "none";
        document.body.style.msUserSelect = "none";
      },
      onEnd: async (evt) => {
        const newOrder = Array.from(blocksContainer.children).map((child) =>
          parseInt(child.dataset.blockId)
        );

        const [errors, resp] = await Ajax.post("/dashboard/blocks/reorder", {
          block_ids: newOrder,
        });

        if (resp && resp.length > 0) {
          blocks = resp;
        }
      },
    });
  });

  function closeAddBlockModal() {
    isAddBlockModalOpen = false;
  }

  function togglePreviewModal() {
    isPreviewModalOpen = !isPreviewModalOpen;
  }
</script>

<Dashboard {currentUser} {permissions} {page}>
  <div class="flex flex-col lg:flex-row gap-4 sm:gap-8 pb-24 lg:pb-0">
    <div class="w-full lg:w-3/5 p-2">
      <UrlCard url={page.url} />
      <DirectRedirect {blocks} />

      <div class="mt-10 space-y-4">
        <div class="flex justify-start">
          <AddBlockModal
            {updater}
            isOpen={isAddBlockModalOpen}
            onClose={closeAddBlockModal}
          />
        </div>
      </div>

      <div class="mt-6 space-y-4" bind:this={blocksContainer}>
        {#if blocks.length === 0}
          <div
            class="text-center py-12 bg-white border-2 border-dashed border-gray-300 rounded-lg shadow-sm mt-12"
          >
            <svg
              class="mx-auto h-16 w-16 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
              />
            </svg>
            <h3 class="mt-4 text-md font-semibold text-gray-900">
              No blocks yet
            </h3>
            <p class="mt-2 text-base text-gray-500">
              Get started by creating a new block.
            </p>
          </div>
        {:else}
          {#each blocks as block (block)}
            <div data-block-id={block.id}>
              <ViewBlock {block} {updater} />
            </div>
          {/each}
        {/if}
      </div>
    </div>

    <div
      class="hidden lg:block p-4 lg:fixed lg:top-6 lg:right-0 lg:mr-4 mt-8 lg:mt-0"
    >
      <IphonePreview {blocks} {page} />
    </div>
  </div>

  <!-- Mobile preview button -->
  <div
    class="lg:hidden fixed bottom-16 mb-2 left-0 right-0 z-10 flex justify-center"
  >
    <button
      on:click={togglePreviewModal}
      class="px-4 py-2 rounded-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white shadow-lg hover:from-purple-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition duration-300 ease-in-out transform hover:scale-105 flex items-center space-x-2"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
        />
      </svg>
      <span class="font-semibold text-sm">Preview</span>
    </button>
  </div>

  <!-- Mobile preview modal -->
  <MobilePreview bind:isOpen={isPreviewModalOpen} {blocks} {page} />
</Dashboard>
