<script>
  import Modal from "$components/Modal.svelte";
  import { notify } from "$utils/notify";
  import { goto } from "$utils/goto";

  export let isOpen = false;
  export let selectedLinks;
  export let selectedTheme;
  export let token;

  async function handleClose() {
    isOpen = false;
  }

  async function startImporting() {
    try {
      const response = await fetch(`/import/import?token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          links: selectedLinks.map((link) => link.id),
          theme: selectedTheme,
        }),
      });

      if (!response.ok) {
        throw new Error("Import failed");
      }

      notify({
        message: "We have successfully imported your Linktree",
        type: "success",
      });
    } catch (error) {
      notify({
        message:
          "Unfortunately, we couldn't import your Linktree. Redirecting to your dashboard.",
        type: "error",
      });
    }

    goto("/dashboard");
  }

  $: if (isOpen) {
    startImporting();
  }
</script>

<Modal
  {isOpen}
  on:close={handleClose}
  size="md"
  closeOnEscape={false}
  closeOnOutsideClick={false}
>
  <div class="p-6">
    <div class="text-center">
      <div class="mb-6">
        <div class="w-20 h-20 mx-auto mb-10">
          <svg
            class="animate-spin text-indigo-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
        <h3 class="text-2xl font-bold text-gray-900 mb-2">
          Importing Your Linktree
        </h3>
        <p class="text-gray-600">
          Please wait while we set everything up for you
        </p>
      </div>
      <p class="text-sm text-gray-500 animate-pulse mt-6">
        Please don't close this window
      </p>
    </div>
  </div>
</Modal>
