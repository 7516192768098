<script>
  import { createEventDispatcher } from "svelte";
  import View from "$app-components/blocks/_layouts/View.svelte";
  import Video from "$components/Video.svelte";

  const dispatch = createEventDispatcher();

  export let block;
  export let updater;

  function handleDelete() {
    dispatch("delete-block");
  }
</script>

<View {block} {updater} on:delete-block={handleDelete}>
  <div class="flex items-center space-x-3 flex-grow overflow-hidden">
    <div
      class="w-12 h-12 flex-shrink-0 flex items-center bg-black overflow-hidden rounded-lg">
      <Video src={block.url} allowPlay={false} />
    </div>
    <div class="flex-1 min-w-0 pl-2">
      <h3 class="text-md font-semibold text-gray-800 truncate">
        {block.title}
      </h3>
    </div>
  </div>
</View>
