<script>
  import { createEventDispatcher } from "svelte";
  import Toggle from "$components/Toggle.svelte";
  import Ajax from "$utils/ajax";
  import { notify } from "$utils/notify";
  import EditBlockModal from "$app-components/block-builder/EditBlockModal.svelte";
  import { fly } from "svelte/transition";

  const dispatch = createEventDispatcher();

  export let block;
  export let updater;

  let showEditModal = false;
  let showMobileActions = false;

  async function toggleBlockEnabled(event) {
    block.enabled = event.detail;

    const [ajaxErrors, resp] = await Ajax.put(`/dashboard/blocks/${block.id}`, {
      enabled: block.enabled,
    });

    if (ajaxErrors) {
      notify({
        message: "Error updating block",
        type: "error",
        duration: 1000,
      });
    } else {
      notify({
        message: `Block has been ${block.enabled ? "enabled" : "disabled"}.`,
        type: "success",
        duration: 1000,
      });

      updater(({ blocks }) => {
        blocks.find((b) => b.id === block.id).enabled = block.enabled;
        return { blocks };
      });
    }
  }

  function openEditModal() {
    showEditModal = true;
    showMobileActions = false;
  }

  function closeEditModal() {
    showEditModal = false;
  }

  function handleDelete() {
    dispatch("delete-block");
    showMobileActions = false;
  }

  function toggleMobileActions() {
    showMobileActions = !showMobileActions;
  }

  $: ({ enabled } = block);
</script>

<div class="flex items-center space-x-4 flex-shrink-0 ml-4">
  <div class="hidden md:block">
    <Toggle on:change={toggleBlockEnabled} checked={enabled} />
  </div>
  <div class="hidden md:flex items-center space-x-2">
    <button
      on:click={openEditModal}
      class="pl-2 text-gray-400 hover:text-blue-500"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
        />
      </svg>
    </button>
    <button on:click={handleDelete} class="text-gray-400 hover:text-red-500">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
  </div>
  <button
    on:click={toggleMobileActions}
    class="md:hidden text-gray-400 hover:text-blue-500"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
      />
    </svg>
  </button>
</div>

{#if showMobileActions}
  <div
    class="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-end justify-center"
    on:click={toggleMobileActions}
  >
    <div
      class="bg-white rounded-t-xl w-full max-w-md p-4 space-y-2 mx-4"
      transition:fly={{ y: 300, duration: 300, easing: (t) => t * (2 - t) }}
      on:click|stopPropagation
    >
      <div
        class="flex items-center justify-between p-2 rounded-lg transition duration-150"
      >
        <div class="flex items-center">
          <svg
            class="h-5 w-5 mr-3 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              clip-rule="evenodd"
            />
          </svg>
          <span class="font-medium text-sm">Enable/Disable</span>
        </div>
        <Toggle on:change={toggleBlockEnabled} checked={enabled} />
      </div>

      <button
        on:click={openEditModal}
        class="w-full text-left text-gray-700 flex items-center p-2 rounded-lg transition duration-150"
      >
        <svg
          class="h-5 w-5 mr-3 text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
          />
        </svg>
        <span class="font-medium text-sm">Edit Block</span>
      </button>
      <button
        on:click={handleDelete}
        class="w-full text-left text-red-600 flex items-center p-2 rounded-lg transition duration-150"
      >
        <svg
          class="h-5 w-5 mr-3 text-red-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
        <span class="font-medium text-sm">Delete Block</span>
      </button>
    </div>
  </div>
{/if}

<EditBlockModal
  bind:isOpen={showEditModal}
  {block}
  {updater}
  on:close={closeEditModal}
/>
