<script>
  import { fade } from "svelte/transition";
  import { formatCurrency } from "$utils/currency";

  import Carousel from "$components/Carousel.svelte";
  import Image from "$components/Image.svelte";
  import Page from "$app-components/blocks/_layouts/Page.svelte";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;
  export let blockClicked = function () {};
  export let blockViewed = function () {};
  export let onActualPage;

  let images = block.fields?.images || [];
  let isHovered = false;

  $: formattedPrice =
    block.fields?.price && parseFloat(block.fields?.price) !== 0
      ? formatCurrency(block.fields?.price, block.fields?.currency)
      : null;

  function handleMouseEnter() {
    isHovered = true;
  }

  function handleMouseLeave() {
    isHovered = false;
  }
</script>

<Page
  {block}
  {theme}
  {buttonStyle}
  {hoverEffect}
  {blockClicked}
  {blockViewed}
  directRedirect={true}
  padding="p-0"
  let:handleClick
  {onActualPage}>
  <div class="flex flex-col w-full h-full max-h-[400px]">
    <div class="w-full aspect-square overflow-hidden relative">
      {#if images.length > 0}
        <Carousel
          items={images}
          showArrows={images.length > 1}
          showDots={images.length > 1}
          autoplay={false}>
          <div slot="item" let:index let:item={image} class="w-full h-full">
            <Image
              src={image}
              alt={block.title}
              klass="w-full h-full"
              objectFit="contain" />

            {#if formattedPrice && isHovered && index === 0}
              <div
                in:fade={{ duration: 100 }}
                out:fade={{ duration: 100 }}
                class="absolute top-3 right-3 bg-emerald-700 text-white px-3 py-1.5 rounded-full text-sm font-bold transition-opacity duration-200 ease-in-out"
                style="opacity: {isHovered ? 1 : 0};">
                {formattedPrice}
              </div>
            {/if}
          </div>
        </Carousel>
      {:else}
        <Image
          src=""
          alt={block.title}
          klass="w-full h-full"
          objectFit="contain" />
      {/if}
    </div>
    <div class="p-4">
      <h2 class="text-center line-clamp-3 font-medium text-sm @md:text-base">
        {block.title}
      </h2>
    </div>
  </div>
</Page>
