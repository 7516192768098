<script>
  export let title = "";
  export let subtitle = "";
  export let variant = "default";
  export let klass = "";
  export let size = "md";
  export let shadow = true;

  const SLOTS = $$props.$$slots || {};
  const baseClasses = "overflow-hidden rounded-md";

  const variantClasses = {
    default: "bg-white",
    primary: "bg-indigo-600 text-white",
    secondary: "bg-gray-100",
  };

  const sizeClasses = {
    sm: "!max-w-sm",
    md: "!max-w-md",
    lg: "!max-w-[30rem]", // Changed to a fixed max-width of 32rem for lg size
    xl: "!max-w-xl",
    custom: "!max-w-2xl", // Added a custom size between md and xl
  };

  $: classes = `
    ${baseClasses}
    ${variantClasses[variant]}
    ${sizeClasses[size]}
    ${shadow ? "shadow" : ""}
    ${klass}
  `;

  $: hasHeaderContent = title || subtitle || SLOTS.title || SLOTS.subtitle;
</script>

<div class={classes}>
  <div class="px-4 py-5 sm:p-6">
    <slot name="title">
      {#if title}
        <h3 class="text-lg font-medium leading-6 text-gray-900">
          {title}
        </h3>
      {/if}
    </slot>

    <slot name="subtitle">
      {#if subtitle}
        <div class="mt-1 max-w-2xl text-sm text-gray-500">
          {subtitle}
        </div>
      {/if}
    </slot>

    <div class:mt-4={hasHeaderContent}>
      <slot name="content">
        <!-- Default content goes here -->
      </slot>
    </div>
  </div>

  <slot name="footer">
    <!-- Footer content goes here -->
  </slot>
</div>
