<script>
  import { getBlockStyle, getBorderRadius } from "$utils/block-style";
  import Page from "$app-components/blocks/_layouts/Page.svelte";

  export let block;
  export let buttonStyle;
  export let blockClicked = function () {};
  export let blockViewed = function () {};
  export let onActualPage;

  $: blockRadius = getBorderRadius(buttonStyle, block);
  $: blockHeight = block.fields?.height || "300px";

  function getGoogleMapsEmbedUrl(address) {
    const encodedAddress = encodeURIComponent(address);
    return `https://www.google.com/maps/embed/v1/place?key=AIzaSyDfkg15HWH32GHdBPsVzP-dTl8bSGvF7BU&q=${encodedAddress}`;
  }

  $: mapEmbedUrl = getGoogleMapsEmbedUrl(block.title);
</script>

<Page {block} {blockClicked} {blockViewed} disableStyles={true} {onActualPage}>
  <div
    class="w-full max-w-4xl mx-auto overflow-hidden"
    style={`border-radius: ${blockRadius}; height: ${blockHeight};`}
  >
    <iframe
      title="Google Maps"
      width="100%"
      style={`height: ${blockHeight}; border: 0;`}
      frameborder="0"
      src={mapEmbedUrl}
      allowfullscreen
    />
  </div>
</Page>
