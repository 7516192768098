<script>
  import { createEventDispatcher } from "svelte";
  import View from "$app-components/blocks/_layouts/View.svelte";
  import Image from "$components/Image.svelte";

  const dispatch = createEventDispatcher();

  export let block;
  export let updater;
</script>

<View {block} {updater} on:delete-block={() => dispatch("delete-block")}>
  <div class="w-12 h-12 flex-shrink-0 rounded-md">
    <Image
      src={block.image_url}
      alt="Threads post thumbnail"
      klass="w-full h-full object-cover rounded-md"
    />
  </div>
  <div class="flex-1 min-w-0 pl-2">
    <div class="text-gray-900">
      <p class="font-semibold truncate">
        {block.title || "Untitled Threads Post"}
      </p>
      <p class="text-sm text-gray-600 truncate">{block.url}</p>
    </div>
  </div>
</View>
