<script>
  import { showUploader } from "$utils/upload";
  import { getThemeFromId } from "$utils/themes";

  export let page;

  let theme = getThemeFromId(page).theme;
  let customTheme = page.custom_theme || {};

  let backgroundType = "";
  let backgroundColor = customTheme.backgroundColor || "#cbd5e1";
  let backgroundImage = "";
  let textColor = customTheme.textColor || theme.textColor || "#000000";
  let cardBackgroundColor =
    customTheme.cardBackgroundColor || theme.cardBackgroundColor || "#ffffff";
  let linkColor = customTheme.linkColor || theme.linkColor || "#000";
  let gradientStart = "#f8fafc";
  let gradientEnd = "#94a3b8";
  let backgroundImageBlur = customTheme.backgroundImageBlur || 0;
  let backgroundImageOpacity = customTheme.backgroundImageOpacity || 100;

  if (customTheme.backgroundImage) {
    if (customTheme.backgroundImage.includes("linear-gradient")) {
      backgroundType = "gradient";
    } else if (customTheme.backgroundImage.includes("url")) {
      backgroundType = "image";
      backgroundImage = customTheme.backgroundImage.replace(
        /^url\((.*?)\)$/,
        "$1"
      );
    }
  }

  if (customTheme.backgroundColor) {
    backgroundType = "color";
  }

  if (backgroundType === "gradient" && customTheme.backgroundImage) {
    const gradientMatch = customTheme.backgroundImage.match(
      /linear-gradient\((.*?),(.*?)\)/
    );
    if (gradientMatch) {
      gradientStart = gradientMatch[1].trim();
      gradientEnd = gradientMatch[2].trim();
    }
  }

  $: {
    page.custom_theme = {};

    if (backgroundType === "color") {
      page.custom_theme["backgroundColor"] = backgroundColor;
    } else if (backgroundType === "gradient") {
      page.custom_theme["backgroundImage"] =
        `linear-gradient(${gradientStart}, ${gradientEnd})`;
    } else if (backgroundType === "image") {
      page.custom_theme["backgroundImage"] = `url(${backgroundImage})`;
      page.custom_theme["backgroundImageBlur"] = backgroundImageBlur;
      page.custom_theme["backgroundImageOpacity"] = backgroundImageOpacity;
    }

    page.custom_theme["textColor"] = textColor;
    page.custom_theme["buttonColor"] = cardBackgroundColor;
    page.custom_theme["cardBackgroundColor"] = cardBackgroundColor;
    page.custom_theme["linkColor"] = linkColor;
    page.custom_theme["buttonTextColor"] = linkColor;
  }

  async function handleImageUpload() {
    try {
      const url = await showUploader({
        allowedFileTypes: ["image/*"],
      });
      backgroundImage = url;
    } catch (error) {
      console.error("Upload failed:", error);
    }
  }
</script>

<div class="w-full">
  <div class="bg-white rounded-xl shadow-lg p-4 sm:p-8">
    <div>
      <label
        class="block text-base sm:text-lg font-medium text-gray-900 mb-3 sm:mb-4"
        >Background Type</label
      >
      <div class="grid grid-cols-3 gap-2 sm:gap-6 mb-6 sm:mb-8">
        <button
          class="relative p-2 sm:p-6 rounded-xl transition-all duration-200 {backgroundType ===
          'color'
            ? 'bg-purple-100 ring-2 ring-purple-500 shadow-lg scale-105'
            : 'bg-gray-50 hover:bg-gray-100'}"
          on:click={() =>
            (backgroundType = backgroundType === "color" ? "" : "color")}
        >
          <div class="flex flex-col items-center gap-1 sm:gap-4">
            <div
              class="w-8 h-8 sm:w-12 sm:h-12 rounded-full bg-purple-500"
            ></div>
            <span class="font-medium text-gray-900 text-xs sm:text-base"
              >Solid Color</span
            >
          </div>
          {#if backgroundType === "color"}
            <div
              class="absolute -top-1 -right-1 sm:-top-2 sm:-right-2 bg-purple-500 text-white p-0.5 sm:p-1 rounded-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-3 w-3 sm:h-4 sm:w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          {/if}
        </button>

        <button
          class="relative p-2 sm:p-6 rounded-xl transition-all duration-200 {backgroundType ===
          'gradient'
            ? 'bg-purple-100 ring-2 ring-purple-500 shadow-lg scale-105'
            : 'bg-gray-50 hover:bg-gray-100'}"
          on:click={() =>
            (backgroundType = backgroundType === "gradient" ? "" : "gradient")}
        >
          <div class="flex flex-col items-center gap-1 sm:gap-4">
            <div
              class="w-8 h-8 sm:w-12 sm:h-12 rounded-full bg-gradient-to-br from-blue-500 via-purple-500 to-pink-500"
            ></div>
            <span class="font-medium text-gray-900 text-xs sm:text-base"
              >Gradient</span
            >
          </div>
          {#if backgroundType === "gradient"}
            <div
              class="absolute -top-1 -right-1 sm:-top-2 sm:-right-2 bg-purple-500 text-white p-0.5 sm:p-1 rounded-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-3 w-3 sm:h-4 sm:w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          {/if}
        </button>

        <button
          class="relative p-2 sm:p-6 rounded-xl transition-all duration-200 {backgroundType ===
          'image'
            ? 'bg-purple-100 ring-2 ring-purple-500 shadow-lg scale-105'
            : 'bg-gray-50 hover:bg-gray-100'}"
          on:click={() =>
            (backgroundType = backgroundType === "image" ? "" : "image")}
        >
          <div class="flex flex-col items-center gap-1 sm:gap-4">
            <div
              class="w-8 h-8 sm:w-12 sm:h-12 rounded-full bg-gradient-to-br from-indigo-500 to-purple-500 flex items-center justify-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 sm:h-6 sm:w-6 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <span class="font-medium text-gray-900 text-xs sm:text-base"
              >Image</span
            >
          </div>
          {#if backgroundType === "image"}
            <div
              class="absolute -top-1 -right-1 sm:-top-2 sm:-right-2 bg-purple-500 text-white p-0.5 sm:p-1 rounded-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-3 w-3 sm:h-4 sm:w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          {/if}
        </button>
      </div>
    </div>

    <div class="space-y-4 sm:space-y-6">
      {#if backgroundType === "color"}
        <div class="bg-gray-50 p-4 sm:p-6 rounded-xl">
          <label
            class="block text-base sm:text-lg font-medium text-gray-900 mb-3 sm:mb-4"
            >Background Color</label
          >
          <input
            type="color"
            bind:value={backgroundColor}
            class="h-12 sm:h-16 w-full rounded-lg cursor-pointer"
          />
        </div>
      {:else if backgroundType === "gradient"}
        <div class="bg-gray-50 p-4 sm:p-6 rounded-xl">
          <label
            class="block text-base sm:text-lg font-medium text-gray-900 mb-3 sm:mb-4"
            >Gradient Colors</label
          >
          <div class="grid grid-cols-2 gap-4 sm:gap-6">
            <div>
              <span class="block text-sm font-medium text-gray-600 mb-2"
                >Start Color</span
              >
              <input
                type="color"
                bind:value={gradientStart}
                class="h-10 sm:h-12 w-full rounded-lg cursor-pointer"
              />
            </div>
            <div>
              <span class="block text-sm font-medium text-gray-600 mb-2"
                >End Color</span
              >
              <input
                type="color"
                bind:value={gradientEnd}
                class="h-10 sm:h-12 w-full rounded-lg cursor-pointer"
              />
            </div>
          </div>
        </div>
      {:else if backgroundType === "image"}
        <div class="bg-gray-50 p-4 sm:p-6 rounded-xl">
          <label
            class="block text-base sm:text-lg font-medium text-gray-900 mb-3 sm:mb-4"
            >Background Image</label
          >
          {#if backgroundImage}
            <div class="space-y-3 sm:space-y-4">
              <div class="relative rounded-xl overflow-hidden shadow-lg">
                <img
                  src={backgroundImage}
                  alt="Background"
                  class="w-full h-36 sm:h-48 object-cover"
                  style="filter: blur({backgroundImageBlur}px); opacity: {backgroundImageOpacity}%;"
                />
                <div
                  class="absolute inset-0 bg-black bg-opacity-40 opacity-0 hover:opacity-100 transition-opacity duration-200 flex items-center justify-center"
                >
                  <button
                    on:click={() => (backgroundImage = "")}
                    class="p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transform hover:scale-110 transition-all duration-200"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div class="space-y-4">
                <div>
                  <label class="block text-sm font-medium text-gray-600 mb-2">
                    Blur ({backgroundImageBlur}px)
                  </label>
                  <input
                    type="range"
                    bind:value={backgroundImageBlur}
                    min="0"
                    max="30"
                    class="w-full h-2 bg-purple-200 rounded-lg appearance-none cursor-pointer"
                  />
                </div>

                <div>
                  <label class="block text-sm font-medium text-gray-600 mb-2">
                    Opacity ({backgroundImageOpacity}%)
                  </label>
                  <input
                    type="range"
                    bind:value={backgroundImageOpacity}
                    min="0"
                    max="100"
                    class="w-full h-2 bg-purple-200 rounded-lg appearance-none cursor-pointer"
                  />
                </div>
              </div>

              <button
                on:click={handleImageUpload}
                class="!mt-8 w-full py-2 sm:py-3 px-3 sm:px-4 bg-white border-2 border-dashed border-purple-200 rounded-xl hover:border-purple-500 transition-colors duration-200 flex items-center justify-center gap-2 sm:gap-3 group"
              >
                <div
                  class="p-1.5 sm:p-2 bg-purple-50 rounded-full group-hover:bg-purple-100 transition-colors"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 sm:h-5 sm:w-5 text-purple-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"
                    />
                    <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                  </svg>
                </div>
                <span
                  class="font-medium text-sm sm:text-base text-gray-600 group-hover:text-purple-600 transition-colors"
                  >Upload new image</span
                >
              </button>
            </div>
          {:else}
            <button
              on:click={handleImageUpload}
              class="w-full h-24 border-2 border-purple-200 rounded-xl hover:border-purple-500 transition-all duration-200 flex items-center justify-center gap-4 sm:gap-3 group bg-white hover:bg-purple-50"
            >
              <div
                class="p-2 sm:p-3 bg-purple-50 rounded-full group-hover:bg-purple-100 transition-colors"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 sm:h-8 sm:w-8 text-purple-500"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"
                  />
                  <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                </svg>
              </div>
              <span
                class="text-sm sm:text-base font-medium text-gray-600 group-hover:text-purple-600"
                >Click to upload an image</span
              >
            </button>
          {/if}
        </div>
      {/if}

      <div class="bg-gray-50 p-4 sm:p-6 rounded-xl">
        <label
          class="block text-base sm:text-lg font-medium text-gray-900 mb-3 sm:mb-4"
          >Text Color</label
        >
        <input
          type="color"
          bind:value={textColor}
          class="h-12 sm:h-16 w-full rounded-lg cursor-pointer"
        />
      </div>
      <div class="bg-gray-50 p-4 sm:p-6 rounded-xl">
        <label
          class="block text-base sm:text-lg font-medium text-gray-900 mb-3 sm:mb-4"
          >Button Colors</label
        >
        <div class="grid grid-cols-2 gap-4">
          <div>
            <span class="block text-sm font-medium text-gray-600 mb-2"
              >Background Color</span
            >
            <input
              type="color"
              bind:value={cardBackgroundColor}
              class="h-12 sm:h-16 w-full rounded-lg cursor-pointer"
            />
          </div>
          <div>
            <span class="block text-sm font-medium text-gray-600 mb-2"
              >Text Color</span
            >
            <input
              type="color"
              bind:value={linkColor}
              class="h-12 sm:h-16 w-full rounded-lg cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
