<script>
  import Modal from "$components/Modal.svelte";
  import Ajax from "$utils/ajax";
  import { notify } from "$utils/notify";

  export let block;

  let isModalOpen = false;
  let formResponses = [];
  let isLoading = false;

  let setFormResponses = function (resp) {
    formResponses = resp.data.map((response) => {
      let allFields = [...new Set(resp.data.flatMap((r) => Object.keys(r)))];

      // Move submitted_date and submitted_time to end of array
      const dateFields = ["submitted_date", "submitted_time"];

      allFields = [
        ...allFields.filter((field) => !dateFields.includes(field)),
        ...dateFields,
      ];

      const filledResponse = {};

      allFields.forEach((field) => {
        filledResponse[field] = response[field] || "";
      });

      return filledResponse;
    });
  };

  async function loadFormResponses() {
    isLoading = true;

    const [error, resp] = await Ajax.get(
      `/api/form_responses?block_id=${block.id}`
    );

    if (error) {
      notify({
        type: "error",
        message: error?.data?.[0] || "An error occurred loading responses.",
      });
    } else {
      setFormResponses(resp);
    }

    isLoading = false;
  }

  function closeModal() {
    isModalOpen = false;
  }

  function downloadResponses() {
    window.location.href = `/api/form_responses?block_id=${block.id}&download=true`;
  }

  $: if (isModalOpen) {
    loadFormResponses();
  }
</script>

<Modal bind:isOpen={isModalOpen} size="2xl" allowScrollCloseOnMobile={false}>
  <div slot="header" class="flex justify-between items-center">
    <h3 class="text-lg font-bold text-gray-900">
      Form Responses <span class="text-gray-500 text-sm"
        >({formResponses.length})</span
      >
    </h3>
  </div>

  <div class="mt-8">
    <div class="p-0">
      {#if isLoading}
        <div class="flex justify-center py-8">
          <svg
            class="animate-spin h-8 w-8 text-purple-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      {:else if formResponses.length === 0}
        <div class="text-center py-8 text-gray-500">No responses yet</div>
      {:else}
        <div class="flex justify-end mb-6 -mt-6">
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 text-xs font-medium text-purple-600 bg-white border border-purple-300 rounded-md hover:bg-purple-50 hover:text-purple-700 focus:outline-none transition-colors duration-200"
            on:click={downloadResponses}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
            Download CSV
          </button>
        </div>

        <div class="overflow-x-auto min-h-[calc(100vh-300px)] sm:min-h-0">
          <table class="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                {#each Object.keys(formResponses[0]) as header}
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center"
                  >
                    {header.replace(/_/g, " ")}
                  </th>
                {/each}
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              {#each formResponses as response}
                <tr>
                  {#each Object.keys(formResponses[0]) as key}
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-center"
                    >
                      {response[key]}
                    </td>
                  {/each}
                </tr>
              {/each}
            </tbody>
          </table>
        </div>
      {/if}
    </div>

    <div class="mt-4 flex justify-end space-x-2 border-t pt-4">
      <button
        type="button"
        class="px-3 py-2 text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none transition-colors duration-200"
        on:click={closeModal}
      >
        Close
      </button>
    </div>
  </div>
</Modal>

{#if block.block_type === "form"}
  <button
    on:click={() => (isModalOpen = true)}
    class="text-gray-400 hover:text-purple-500 transition-colors duration-200 ease-in-out relative group flex items-center mr-4"
    title="View Data"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-4 w-4"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="M4 22h14a2 2 0 002-2V7.5L14.5 2H6a2 2 0 00-2 2v4" />
      <path d="M14 2v6h6" />
      <path d="M2 15h10" />
      <path d="M2 19h10" />
      <path d="M2 11h10" />
    </svg>
    <span
      class="absolute bottom-[140%] left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap"
    >
      View Data
    </span>
  </button>
{/if}
