<script>
  import { createEventDispatcher } from "svelte";
  import View from "$app-components/blocks/_layouts/View.svelte";
  import Image from "$components/Image.svelte";

  const dispatch = createEventDispatcher();

  export let block;
  export let updater;

  function handleDelete() {
    dispatch("delete-block");
  }

  $: ({ fields } = block);

  $: title = block.title;
  $: imageUrl = block.image_url;
</script>

<View {block} {updater} on:delete-block={handleDelete}>
  <div class="w-12 h-12 flex-shrink-0">
    <Image
      src={block.image_url}
      alt="Facebook thumbnail"
      klass="w-full h-full object-cover rounded-md"
    />
  </div>
  <div class="flex-1 min-w-0 pl-2">
    <div class="text-gray-900">
      <p class="font-semibold truncate">
        {block.title || "Untitled Form"}
      </p>
      <p class="text-sm text-gray-600 truncate">
        {fields?.form_fields?.length || 0} field{fields?.form_fields?.length !==
        1
          ? "s"
          : ""}
      </p>
    </div>
  </div>
</View>
