<script>
  import { createEventDispatcher, onMount } from "svelte";
  import Button from "$components/Button.svelte";
  import Input from "$components/Input.svelte";
  import Form from "$components/Form.svelte";
  import Textarea from "$components/Textarea.svelte";
  import Image from "$components/Image.svelte";
  import Ajax from "$utils/ajax";
  import { notify } from "$utils/notify";
  import { showUploader } from "$utils/upload";
  import { cropper } from "$utils/cropper";
  import Sortable, {
    AutoScroll,
  } from "sortablejs/modular/sortable.core.esm.js";
  import { getValidationRules } from "./_validation";

  const dispatch = createEventDispatcher();

  export let block = null;

  let formFields = block?.fields?.form_fields || [];

  let formTitle = block?.title || "Contact Form";
  let imageUrl = block?.image_url || "";

  let formDescription = block?.fields?.description || "";
  let submitButtonText = block?.fields?.submit_button_text || "Submit";
  let thankYouMessage =
    block?.fields?.thank_you_message || "Thank you for your submission!";

  let saving = false;
  let errors = {};
  let fieldsContainer;
  let isUploading = false;

  const fieldTypes = [
    { value: "text", label: "Single Line Text" },
    { value: "textarea", label: "Multi-line Text" },
    { value: "email", label: "Email Address" },
    { value: "tel", label: "Phone Number" },
    { value: "select", label: "Dropdown Menu" },
    { value: "checkbox", label: "Multiple Choice (Checkboxes)" },
    { value: "radio", label: "Single Choice (Radio Buttons)" },
    { value: "number", label: "Number" },
    { value: "url", label: "URL" },
    { value: "date", label: "Date" },
  ];

  onMount(() => {
    try {
      Sortable.mount(new AutoScroll());
    } catch {}

    new Sortable(fieldsContainer, {
      animation: 150,
      handle: ".drag-handle",
      ghostClass: "opacity-50",
      onEnd: (evt) => {
        const newFields = [...formFields];
        const movedField = newFields.splice(evt.oldIndex, 1)[0];
        newFields.splice(evt.newIndex, 0, movedField);
        formFields = newFields;
      },
    });
  });

  async function handleFileSelected() {
    isUploading = true;

    try {
      const uploadedUrl = await showUploader({
        fileType: "image",
        crop: cropper.crop,
      });
      imageUrl = uploadedUrl;
    } catch (error) {
      notify({
        message: "Upload failed. Please try again.",
        type: "error",
      });
    } finally {
      isUploading = false;
    }
  }

  function clearImage() {
    imageUrl = "";
  }

  function addField() {
    formFields = [
      ...formFields,
      {
        type: "text",
        label: "",
        placeholder: "",
        required: true,
        validations: ["required"],
        validationValues: {
          minLength: 0,
          maxLength: 100,
          min: 0,
          max: 100,
          step: 1,
          domain: "",
          minDate: "",
          maxDate: "",
        },
        options: [],
      },
    ];

    // Scroll to the newly added field
    setTimeout(() => {
      const lastField = fieldsContainer.lastElementChild;
      lastField?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  }

  function removeField(index) {
    formFields = formFields.filter((_, i) => i !== index);
  }

  function addOption(fieldIndex) {
    const field = formFields[fieldIndex];
    field.options = [...field.options, { label: "" }];
    formFields = [...formFields];
  }

  function removeOption(fieldIndex, optionIndex) {
    const field = formFields[fieldIndex];
    field.options = field.options.filter((_, i) => i !== optionIndex);
    formFields = [...formFields];
  }

  function toggleValidation(fieldIndex, rule) {
    const field = formFields[fieldIndex];
    const index = field.validations.indexOf(rule);

    if (index === -1) {
      field.validations = [...field.validations, rule];
    } else {
      field.validations = field.validations.filter((v) => v !== rule);
    }

    formFields = [...formFields];
  }

  const saveForm = async (event) => {
    event.preventDefault();

    if (saving) return;
    saving = true;

    let hasErrors = false;
    formFields.forEach((field, index) => {
      if (!field.label) {
        errors[`field_${index}`] = "Please add a label for this field";
        hasErrors = true;
      }
    });

    if (hasErrors) {
      saving = false;
      return;
    }

    // Generate field names from labels
    formFields = formFields.map((field) => ({
      ...field,
      name: field.label.toLowerCase().replace(/[^a-z0-9]/g, "_"),
    }));

    const data = {
      block_type: "form",
      title: formTitle,
      image_url: imageUrl,
      fields: {
        form_fields: formFields,
        description: formDescription,
        submit_button_text: submitButtonText,
        thank_you_message: thankYouMessage,
      },
    };

    const [ajaxErrors, resp] = block
      ? await Ajax.put(`/dashboard/blocks/${block.id}`, data)
      : await Ajax.post("/dashboard/blocks", data);

    if (ajaxErrors) {
      errors = ajaxErrors;

      notify({
        message: `Failed to save form:<br />${Object.entries(errors)
          .map(([field, messages]) =>
            messages.map((msg) => `• ${field} ${msg}`).join("\n")
          )
          .join("\n")}`,
        type: "error",
        duration: 3000,
      });
    } else {
      notify({
        message: `Form ${block ? "updated" : "created"} successfully!`,
        type: "success",
      });

      if (block) {
        dispatch("edited-block", resp);
      } else {
        dispatch("added-block", resp);
      }
      dispatch("close");
    }

    saving = false;
  };
</script>

<h2 class="text-xl font-bold text-gray-800 mb-10 text-center">
  {block ? "Edit" : "Create"} Form
</h2>

<Form>
  <div class="flex flex-col gap-6 max-w-2xl mx-auto">
    <div class="bg-white p-6 rounded-lg border-2 border-gray-200">
      <div class="flex flex-col lg:flex-row lg:gap-8 space-y-6 lg:space-y-0">
        <div class="flex-1 space-y-6">
          <div>
            <Input
              label="Form Title"
              type="text"
              bind:value={formTitle}
              placeholder="e.g. Contact Form, Newsletter Signup"
              required
              error={errors.title}
            />
          </div>
          <div>
            <Textarea
              label="Description"
              bind:value={formDescription}
              placeholder="Explain what this form is for (not required)..."
              rows={4}
            />
          </div>
        </div>

        <div class="lg:w-32">
          <label class="block text-sm font-medium text-gray-700 mb-2"
            >Image <span class="text-gray-500 text-[11px]">(optional)</span
            ></label
          >
          <div class="relative flex-shrink-0">
            <Image
              src={imageUrl || "/images/placeholder-image.png"}
              alt="Preview"
              klass="w-full h-32 object-cover rounded-lg border border-gray-200 bg-gray-50"
            />
            {#if imageUrl}
              <button
                type="button"
                on:click={clearImage}
                class="absolute -top-1.5 -right-1.5 bg-white rounded-full p-1 shadow-sm hover:bg-gray-50 border border-gray-200 transition-colors duration-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-3 w-3 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            {/if}
            <button
              type="button"
              on:click={handleFileSelected}
              class="w-full mt-2 text-xs py-1.5 px-3 bg-white hover:bg-gray-50 rounded-md border border-gray-300 text-gray-600 hover:text-gray-800 font-medium shadow-sm transition-all duration-200 flex items-center justify-center gap-1"
            >
              Change
            </button>
          </div>
        </div>
      </div>

      <div class="mt-6">
        <Input
          label="Submit Button Text"
          type="text"
          bind:value={submitButtonText}
          placeholder="e.g. Submit, Send Message, Sign Up"
        />
      </div>

      <div class="mt-6">
        <Textarea
          label="Thank You Message"
          bind:value={thankYouMessage}
          placeholder="Message to show after form submission..."
          rows={2}
        />
      </div>
    </div>

    <div class="space-y-4" bind:this={fieldsContainer}>
      {#each formFields as field, fieldIndex}
        <div
          class="bg-white p-6 rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-shadow duration-200 group"
        >
          <div class="flex justify-between items-center mb-6">
            <div class="flex items-center gap-3">
              <div class="drag-handle cursor-move">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 8h16M4 16h16"
                  />
                </svg>
              </div>
              <h3 class="text-lg font-medium text-gray-900">
                Field {fieldIndex + 1}
              </h3>
            </div>
            <button
              type="button"
              on:click={() => removeField(fieldIndex)}
              class="text-red-600 hover:bg-red-100 flex items-center gap-1 p-1.5 rounded-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </button>
          </div>

          <div class="space-y-4">
            <Input
              label="Field Label"
              type="text"
              bind:value={field.label}
              placeholder="What should this field be called?"
              error={errors[`field_${fieldIndex}`]}
            />

            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1"
                >Field Type</label
              >
              <select
                bind:value={field.type}
                class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500"
              >
                {#each fieldTypes as type}
                  <option value={type.value}>{type.label}</option>
                {/each}
              </select>
            </div>

            <Input
              label="Help Text (Optional)"
              type="text"
              bind:value={field.placeholder}
              placeholder="Add some helper text for this field"
            />

            {#if ["select", "radio", "checkbox"].includes(field.type)}
              <div class="bg-gray-50 p-4 rounded-lg">
                <label class="block text-sm font-medium text-gray-700 mb-2"
                  >Options</label
                >
                <div class="space-y-2">
                  {#each field.options as option, optionIndex}
                    <div class="flex gap-2">
                      <Input
                        type="text"
                        bind:value={option.label}
                        placeholder="Enter an option"
                        klass="flex-1"
                      />
                      <button
                        type="button"
                        on:click={() => removeOption(fieldIndex, optionIndex)}
                        class="text-red-600 hover:text-red-800 px-2"
                      >
                        ×
                      </button>
                    </div>
                  {/each}
                  <Button
                    type="button"
                    variant="secondary"
                    on:click={() => addOption(fieldIndex)}
                    klass="w-full mt-2"
                  >
                    Add Option
                  </Button>
                </div>
              </div>
            {/if}

            <div class="bg-gray-50 p-4 rounded-lg">
              <label class="block text-sm font-medium text-gray-700 mb-2"
                >Field Settings</label
              >
              <table class="w-full">
                <tbody>
                  {#each getValidationRules(field.type) as rule}
                    <tr>
                      <td class="py-2">
                        <label class="flex items-center">
                          <input
                            type="checkbox"
                            checked={field.validations.includes(rule.value)}
                            on:change={() =>
                              toggleValidation(fieldIndex, rule.value)}
                            class="rounded border-gray-300 text-purple-600 focus:ring-purple-500 mr-3 h-4 w-4"
                          />
                          <span class="text-sm text-gray-700">{rule.label}</span
                          >
                        </label>
                      </td>
                      <td class="py-2 pl-4 flex justify-end">
                        {#if rule.type === "number" && field.validations.includes(rule.value)}
                          <Input
                            type="number"
                            bind:value={field.validationValues[rule.value]}
                            klass="text-center h-8 !w-20"
                            containerClass=""
                            min="0"
                          />
                        {/if}
                        {#if rule.type === "text" && field.validations.includes(rule.value)}
                          <Input
                            type="text"
                            bind:value={field.validationValues[rule.value]}
                            klass="h-8 !w-40"
                            containerClass=""
                          />
                        {/if}
                        {#if rule.type === "date" && field.validations.includes(rule.value)}
                          <Input
                            type="date"
                            bind:value={field.validationValues[rule.value]}
                            klass="h-8 !w-40"
                            containerClass=""
                          />
                        {/if}
                      </td>
                    </tr>
                  {/each}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      {/each}
    </div>

    <Button
      type="button"
      variant="secondary"
      on:click={addField}
      klass="w-full flex items-center justify-center gap-2"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
          clip-rule="evenodd"
        />
      </svg>
      Add New Field
    </Button>

    <div class="mt-6">
      <Button
        type="submit"
        klass="w-full h-12"
        disabled={saving}
        on:click={saveForm}
      >
        {#if saving}
          <span class="inline-block animate-spin mr-2">&#8635;</span>
        {/if}
        {block ? "Save Changes" : "Create Form"}
      </Button>
    </div>
  </div>
</Form>
