<script>
  import { createEventDispatcher } from "svelte";

  import Button from "$components/Button.svelte";
  import Form from "$components/Form.svelte";
  import Input from "$components/Input.svelte";
  import Image from "$components/Image.svelte";

  import Ajax from "$utils/ajax";
  import { showUploader } from "$utils/upload";
  import { notify } from "$utils/notify";
  import { cropper } from "$utils/cropper";

  const dispatch = createEventDispatcher();

  export let block = null;

  let saving = false;
  let url = block?.url || "";
  let title = block?.title || "";
  let imageUrl = block?.image_url || "";
  let errors = {};
  let isUploading = false;

  const saveTikTok = async (event) => {
    event.preventDefault();
    if (saving) return;
    saving = true;

    const data = {
      block_type: "tiktok",
      title: title,
      image_url: imageUrl,
    };

    if (!block) {
      data.url = url;
    }

    const [ajaxErrors, resp] = block
      ? await Ajax.put(`/dashboard/blocks/${block.id}`, data)
      : await Ajax.post("/dashboard/blocks", data);

    if (ajaxErrors) {
      errors = ajaxErrors;
    } else {
      if (block) {
        dispatch("edited-block", resp);
      } else {
        dispatch("added-block", resp);
      }

      dispatch("close");
    }

    saving = false;
  };

  async function handleFileSelected() {
    isUploading = true;

    try {
      const uploadedUrl = await showUploader({
        fileType: "image",
        crop: cropper.crop,
      });
      imageUrl = uploadedUrl;
    } catch (error) {
      notify({
        message: "Upload failed. Please try again.",
        type: "error",
      });
    } finally {
      isUploading = false;
    }
  }

  function clearImage() {
    imageUrl = "";
  }
</script>

<h2 class="text-xl font-bold text-gray-800 mb-10 text-center">
  {block ? "Edit" : "Add"} TikTok
</h2>

<Form>
  <div class="flex flex-col gap-6 max-w-lg mx-auto">
    {#if block}
      <div class="bg-gray-50 rounded-lg p-4 border border-gray-200">
        <h3 class="text-sm font-medium text-gray-700 mb-2">
          Current TikTok URL
        </h3>
        <a
          href={block.url}
          target="_blank"
          rel="noopener noreferrer"
          class="text-blue-600 hover:underline truncate block text-sm"
          >{block.url}</a
        >
      </div>
    {:else}
      <Input
        type="url"
        bind:value={url}
        placeholder="Paste TikTok URL here"
        required
        error={errors.url}
        klass="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-purple-300 transition duration-200 ease-in-out !text-lg"
      />
    {/if}

    {#if block}
      <div class="space-y-6">
        <Input
          label="Title"
          type="text"
          size="sm"
          bind:value={title}
          placeholder="Enter a title for this TikTok embed"
          required
          error={errors.title}
        />

        <div class="space-y-3">
          <label
            class="block text-sm font-medium leading-6 text-gray-900 mb-1.5"
            >Thumbnail Image</label
          >
          <div class="flex items-start space-x-4">
            <div class="relative flex-shrink-0">
              <Image
                src={imageUrl || "/images/placeholder-image.png"}
                alt="Preview"
                klass="w-32 h-32 object-cover rounded-lg border border-gray-200 bg-gray-50"
              />
              {#if imageUrl}
                <button
                  type="button"
                  on:click={clearImage}
                  class="absolute -top-2 -right-2 bg-white rounded-full p-1.5 shadow-sm hover:bg-gray-50 border border-gray-200 transition-colors duration-200"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              {/if}
            </div>
            <div class="flex-grow">
              <Button
                type="button"
                on:click={handleFileSelected}
                variant="secondary"
                klass="w-full justify-center"
              >
                Change Thumbnail
              </Button>
            </div>
          </div>
        </div>
      </div>
    {/if}

    <div class="mt-4">
      <Button
        type="submit"
        klass="w-full h-12"
        disabled={saving}
        on:click={saveTikTok}
      >
        {#if saving}
          <span class="inline-block animate-spin mr-2">&#8635;</span>
        {/if}
        {block ? "Update" : "Add"} TikTok
      </Button>
    </div>
  </div>
</Form>
