<script>
  import { fade, slide } from "svelte/transition";
  import Ajax from "$utils/ajax";

  import knownSocialLinks from "$utils/social_links";

  import Input from "$components/Input.svelte";
  import Button from "$components/Button.svelte";
  import Modal from "$components/Modal.svelte";

  export let page;

  let showModal = false;
  let newSocialLink = "";
  let editingIndex = -1;

  function handleAddSocialLink() {
    if (newSocialLink) {
      if (editingIndex === -1) {
        page.social_links = [...page.social_links, { url: newSocialLink }];
      } else {
        page.social_links[editingIndex] = { url: newSocialLink };
        editingIndex = -1;
      }

      newSocialLink = "";
      showModal = false;
      handleSocialLinksSave();
    }
  }

  function handleRemoveSocialLink(index) {
    page.social_links = page.social_links.filter((_, i) => i !== index);
    handleSocialLinksSave();
  }

  function handleEditSocialLink(index) {
    editingIndex = index;
    newSocialLink = page.social_links[index].url;
    showModal = true;
  }

  function getSocialLinkInfo(url) {
    const lowerUrl = url.toLowerCase();
    for (const [platform, info] of Object.entries(knownSocialLinks)) {
      if (lowerUrl.includes(info.baseUrl)) {
        return {
          icon: info.icon,
          displayText: url,
          color: info.color,
        };
      }
    }
    return { icon: "fas fa-globe", displayText: url, color: "#718096" };
  }

  function handlePositionChange(position) {
    page.social_links_placement = position;
  }

  const handleSocialLinksSave = async () => {
    const [errors, _resp] = await Ajax.post(
      "/dashboard/appearance/update_social_links",
      { social_links: page.social_links }
    );

    if (errors) {
      notify({ message: alertErrors(errors), type: "error" });
    }
  };
</script>

<div>
  <div class="flex justify-between items-center mb-8">
    <h4 class="text-lg font-bold text-gray-800">Social Links</h4>
    <div class="relative inline-block text-left">
      <div class="flex items-center space-x-2">
        <span class="text-sm text-gray-600 hidden md:block">Show at:</span>
        <div class="relative z-0 inline-flex shadow-sm rounded-md">
          <button
            type="button"
            class="{page.social_links_placement === 'top'
              ? 'bg-purple-600 text-white'
              : 'bg-white text-purple-600'} relative inline-flex items-center px-3 py-1 rounded-l-md border border-purple-300 text-xs font-medium"
            on:click={() => handlePositionChange("top")}
          >
            Top
          </button>
          <button
            type="button"
            class="{page.social_links_placement === 'bottom'
              ? 'bg-purple-600 text-white'
              : 'bg-white text-purple-600'} -ml-px relative inline-flex items-center px-3 py-1 rounded-r-md border border-purple-300 text-xs font-medium"
            on:click={() => handlePositionChange("bottom")}
          >
            Bottom
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-1 gap-4">
    {#each page.social_links as link, index}
      <div
        in:fade={{ duration: 300 }}
        out:slide={{ duration: 300 }}
        class="relative bg-gray-50 rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow duration-200 w-full"
        style="border-left: 4px solid {getSocialLinkInfo(link.url).color}"
      >
        <div class="flex items-center">
          <i
            class="{getSocialLinkInfo(link.url).icon} text-2xl mr-4"
            style="color: {getSocialLinkInfo(link.url).color}"
          ></i>
          <a
            href={link.url}
            target="_blank"
            class="text-blue-600 hover:underline font-medium flex-grow overflow-hidden whitespace-nowrap text-ellipsis"
            style="max-width: calc(100% - 6rem);"
          >
            {getSocialLinkInfo(link.url).displayText}
          </a>
          <div class="flex space-x-4 ml-2">
            <button
              class="text-gray-500 hover:text-blue-500 transition-colors duration-200 w-5 h-8 flex items-center justify-center"
              on:click={() => handleEditSocialLink(index)}
            >
              <i class="fas fa-edit"></i>
            </button>
            <button
              class="text-gray-500 hover:text-red-500 transition-colors duration-200 w-5 h-8 flex items-center justify-center"
              on:click={() => handleRemoveSocialLink(index)}
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </div>
    {/each}
    <div
      class="flex items-center justify-center bg-gray-100 rounded-lg p-4 cursor-pointer hover:bg-gray-200 transition-colors duration-200 w-full"
      on:click={() => {
        editingIndex = -1;
        newSocialLink = "";
        showModal = true;
      }}
    >
      <i class="fas fa-plus text-2xl text-gray-500 mr-4"></i>
      <span class="text-gray-700 font-medium">Add Social Link</span>
    </div>
  </div>
</div>

<Modal bind:isOpen={showModal}>
  <h3 class="text-xl font-bold text-gray-800 mb-8">
    {editingIndex === -1 ? "Add" : "Edit"} Social Link
  </h3>
  <form on:submit|preventDefault={handleAddSocialLink} class="space-y-8">
    <Input
      type="url"
      label="Social Link URL"
      bind:value={newSocialLink}
      placeholder="Enter the URL (e.g., https://twitter.com/username)"
      required
    />
    <div class="flex justify-end space-x-3">
      <Button
        type="button"
        variant="secondary"
        on:click={() => {
          showModal = false;
          editingIndex = -1;
          newSocialLink = "";
        }}
      >
        Cancel
      </Button>
      <Button type="submit" variant="primary">
        {editingIndex === -1 ? "Add" : "Update"}
      </Button>
    </div>
  </form>
</Modal>
