<script>
  import { createEventDispatcher } from "svelte";
  import View from "$app-components/blocks/_layouts/View.svelte";
  import Image from "$components/Image.svelte";

  const dispatch = createEventDispatcher();

  export let block;
  export let updater;

  function handleDelete() {
    dispatch("delete-block");
  }
</script>

<View {block} {updater} on:delete-block={handleDelete}>
  <div class="flex items-center space-x-3 flex-grow overflow-hidden">
    <div class="w-12 h-12 flex-shrink-0">
      <Image
        src={block.image_url}
        alt="TikTok thumbnail"
        klass="w-full h-full object-cover rounded-md" />
    </div>
    <div class="flex-1 min-w-0 pl-2">
      <div class="text-gray-900">
        <p class="font-semibold truncate">
          {block.title || "Untitled TikTok"}
        </p>
        <p class="text-sm text-gray-600 truncate">{block.url}</p>
      </div>
    </div>
  </div>
</View>
