<script>
  import Page from "$app-components/blocks/_layouts/Page.svelte";
  import Image from "$components/Image.svelte";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;
  export let blockClicked = function () {};
  export let blockViewed = function () {};
  export let onActualPage;

  // Remove "| Pinterest" from the title
  $: cleanTitle = block.title.replace(/\s*\|\s*Pinterest$/, "");
</script>

<Page
  {block}
  {theme}
  {buttonStyle}
  {hoverEffect}
  {blockClicked}
  {blockViewed}
  useIframe={false}
  directRedirect={true}
  {onActualPage}
/>
