<script>
  import { onMount } from "svelte";
  import { quintOut } from "svelte/easing";
  import { sendEvent } from "$utils/event";
  import { getThemeFromId } from "$utils/themes";
  import { notify } from "$utils/notify";
  import Modal from "$components/Modal.svelte";
  import Ajax from "$utils/ajax";
  import Image from "$components/Image.svelte";

  export let page;

  let showModal = false;
  let email = "";
  let isSubmitting = false;
  let subscriptionSuccess = false;
  let buttonLeft = 0;
  let theme = getThemeFromId(page).theme;
  let userFont = page.font;
  let subscribeButton;

  function toggleModal() {
    showModal = !showModal;
    if (showModal) {
      sendEvent("subscribe_button:click", { page_id: page.id });
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (!email || isSubmitting) return;
    isSubmitting = true;

    const data = {
      email: email,
    };

    const [ajaxErrors, resp] = await Ajax.post(
      `/page/${page.username}/subscribe`,
      data
    );

    if (ajaxErrors) {
      notify({
        message: "Failed to subscribe. Please try again.",
        type: "error",
      });
    } else {
      subscriptionSuccess = true;
      sendEvent("subscribe_button:submit", {
        page_id: page.id,
      });
    }

    isSubmitting = false;

    setTimeout(() => {
      showModal = false;
      subscriptionSuccess = false;
      email = "";
    }, 3000);
  }

  function updateButtonPosition() {
    const firstBlockComponent = document.querySelector(".layout-container");
    if (firstBlockComponent) {
      const rect = firstBlockComponent.getBoundingClientRect();
      buttonLeft = rect.left;
    }
  }

  function handleScroll() {
    if (subscribeButton) {
      if (window.scrollY > 150) {
        subscribeButton.classList.add("sb-hidden");
      } else {
        subscribeButton.classList.remove("sb-hidden");
      }
    }
  }

  function openAutomatically() {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get("subscribe") !== null) {
      toggleModal();
    }
  }

  onMount(() => {
    updateButtonPosition();
    openAutomatically();

    window.addEventListener("resize", updateButtonPosition);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", updateButtonPosition);
      window.removeEventListener("scroll", handleScroll);
    };
  });
</script>

<button
  bind:this={subscribeButton}
  on:click={toggleModal}
  class="fixed top-4 z-2 text-white p-2 rounded-full transition-all duration-300 ease-in-out focus:outline-none flex items-center overflow-hidden subscribe-button opacity-80 shadow-sm"
  style="left: {buttonLeft}px; background-color: {theme.cardBackgroundColor}; color: {theme.linkColor}; font-family: Montserrat; font-size: 14px; font-weight: 500;"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="h-6 w-6 flex-shrink-0"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
    />
  </svg>
  <span class="ml-2 whitespace-nowrap subscribe-text">Subscribe</span>
</button>

<Modal bind:isOpen={showModal} size="sm">
  <div class="text-center">
    <div
      class="mx-auto flex items-center justify-center h-20 w-20 sm:h-24 sm:w-24 rounded-full mb-4 sm:mb-8 mt-2 sm:mt-4"
    >
      <Image
        src={page.profile_image_url}
        alt={page.profile_name}
        klass="h-16 w-16 sm:h-20 sm:w-20 rounded-full"
      />
    </div>
    <h3 class="text-lg sm:text-xl font-bold mb-1 text-black">
      Subscribe to {page.profile_name}
    </h3>
    <p class="text-sm sm:text-base mb-6 sm:mb-10 text-gray-700">
      Stay updated with the latest content
    </p>
    {#if !subscriptionSuccess}
      <form on:submit|preventDefault={handleSubmit} class="mt-4 sm:mt-6">
        <div class="input-wrapper mb-4">
          <svg
            class="input-icon h-5 w-5"
            fill="none"
            stroke="black"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            ></path>
          </svg>
          <input
            type="email"
            bind:value={email}
            placeholder="Enter your email"
            class="w-full px-4 py-3 rounded-lg subscribe-input text-sm sm:text-base"
            style="color: black; background-color: white; font-family: {userFont}; outline: none; box-shadow: none;"
            required
          />
        </div>
        <button
          type="submit"
          class="w-full font-bold py-3 px-4 rounded-lg transition duration-300 ease-in-out subscribe-btn text-sm sm:text-base bg-purple-700 text-white {isSubmitting
            ? 'opacity-50 cursor-not-allowed'
            : ''}"
          style="font-family: {userFont};"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Subscribing..." : "Subscribe"}
        </button>
      </form>
    {:else}
      <p class="text-lg sm:text-xl font-semibold text-green-700">
        Successfully subscribed!
      </p>
    {/if}
  </div>
</Modal>

<style>
  .subscribe-button {
    width: 41px;

    transition:
      width 0.3s ease-in-out,
      opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out;
    transform: translateY(0);
  }

  :global(.subscribe-button.sb-hidden) {
    opacity: 0;
    transform: translateY(-100%);
    pointer-events: none;
    transition:
      opacity 0.1s ease-out,
      transform 0.1s ease-out;
  }

  .subscribe-button:hover {
    opacity: 1;
  }

  .subscribe-text {
    opacity: 0;
    width: 0;
    transition:
      opacity 0.3s ease-in-out,
      width 0.3s ease-in-out;
  }

  @keyframes subscribeAnimation {
    0%,
    100% {
      width: 41px;
    }
    20%,
    80% {
      width: 130px;
    }
  }

  @keyframes textAnimation {
    0%,
    100% {
      opacity: 0;
      width: 0;
    }
    20%,
    80% {
      opacity: 1;
      width: auto;
    }
  }

  .subscribe-button {
    animation: subscribeAnimation 3s ease-in-out 0.2s forwards;
  }

  .subscribe-text {
    animation: textAnimation 3s ease-in-out 0.2s forwards;
  }

  .input-wrapper {
    position: relative;
  }

  .input-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .subscribe-input {
    padding-left: 40px;
  }

  .subscribe-btn {
    transition: all 0.3s ease;
  }

  .subscribe-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
</style>
