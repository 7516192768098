import { notify } from "./notify";

const ajax = {
  request: async function (url, options = {}) {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute("content");

    const defaultOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    };

    const mergedOptions = { ...defaultOptions, ...options };

    if (mergedOptions.body && typeof mergedOptions.body === "object") {
      mergedOptions.body = JSON.stringify(mergedOptions.body);
    }

    try {
      const response = await fetch(url, mergedOptions);
      const data = await response.json();

      if (!response.ok) {
        if (response.status === 422) {
          return [data.errors, null];
        } else {
          throw new Error("Request failed");
        }
      }

      return [null, data];
    } catch (error) {
      notify({
        type: "error",
        message: "An error occurred. Try refreshing the page.",
      });
    }
  },

  get: function (url, options = {}) {
    return this.request(url, { ...options, method: "GET" });
  },

  post: function (url, data = {}, options = {}) {
    return this.request(url, { ...options, method: "POST", body: data });
  },

  put: function (url, data = {}, options = {}) {
    return this.request(url, { ...options, method: "PUT", body: data });
  },

  delete: function (url, options = {}) {
    return this.request(url, { ...options, method: "DELETE" });
  },
};

export default ajax;
