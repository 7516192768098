const standard = [
  {
    id: "classic-blue",
    name: "Classic Blue",
    theme: {
      backgroundColor: "#f0f8ff",
      textColor: "#1a365d", // Darker blue text color
      linkColor: "#003366", // Darker link color
      buttonColor: "#0066cc",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "0, 48, 97",
      imagePlaceholderColor: "#cbd5e0",
      imagePlaceholderTextColor: "#718096",
    },
  },
  {
    id: "forest-mist",
    name: "Forest Mist",
    theme: {
      backgroundColor: "#f1f8e9",
      textColor: "#0d3311", // Darkened from #1b5e20
      linkColor: "#1b4b1e", // Darkened from #2e7d32
      buttonColor: "#43a047",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "46, 125, 50",
      imagePlaceholderColor: "#dcedc8",
      imagePlaceholderTextColor: "#2e7d32",
    },
  },
  {
    id: "lavender-dreams",
    name: "Lavender Dreams", 
    theme: {
      backgroundColor: "#e6e6fa",
      textColor: "#4b0082", // Dark purple text color
      linkColor: "#663399", // Darker purple link color
      buttonColor: "#9b59b6",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff", 
      cardShadowColor: "155, 89, 182",
      imagePlaceholderColor: "#e8daef",
      imagePlaceholderTextColor: "#9b59b6",
    },
  },
  {
    id: "ocean-breeze",
    name: "Ocean Breeze",
    theme: {
      backgroundColor: "#e0f7fa",
      textColor: "#013d6b", // Darkened from #01579b
      linkColor: "#01579b", // Darkened from #0288d1
      buttonColor: "#00bcd4",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "2, 136, 209",
      imagePlaceholderColor: "#e0f7fa",
      imagePlaceholderTextColor: "#0288d1",
    },
  },
  {
    id: "forest-retreat",
    name: "Forest Retreat", 
    theme: {
      backgroundColor: "#e8f5e9",
      textColor: "#0d2f10", // Darkened from #1b5e20
      linkColor: "#1b4b1e", // Darkened from #388e3c
      buttonColor: "#66bb6a",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "56, 142, 60",
      imagePlaceholderColor: "#e8f5e9",
      imagePlaceholderTextColor: "#388e3c",
    },
  },
  {
    id: "cherry-blossom",
    name: "Cherry Blossom",
    theme: {
      backgroundColor: "#fff0f5",
      textColor: "#5d001e",
      linkColor: "#e91e63",
      buttonColor: "#f06292",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "233, 30, 99",
      imagePlaceholderColor: "#fce4ec",
      imagePlaceholderTextColor: "#e91e63",
    },
  },
  {
    id: "golden-hour",
    name: "Golden Hour", 
    theme: {
      backgroundColor: "#fff8e1",
      textColor: "#3e2723",
      linkColor: "#bf4400", // Darkened from #e65100
      buttonColor: "#ef6c00",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff", 
      cardShadowColor: "230, 81, 0",
      imagePlaceholderColor: "#fff8e1",
      imagePlaceholderTextColor: "#e65100",
    },
  },
  {
    id: "desert-sunset",
    name: "Desert Sunset", 
    theme: {
      backgroundColor: "#fbe9e7",
      textColor: "#8f2809", // Darkened from #bf360c
      linkColor: "#a33410", // Darkened from #d84315
      buttonColor: "#ff5722",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "216, 67, 21",
      imagePlaceholderColor: "#ffccbc",
      imagePlaceholderTextColor: "#d84315",
    },
  },
  {
    id: "monochrome",
    name: "Monochrome",
    theme: {
      backgroundColor: "#f5f5f5",
      textColor: "#000000",
      linkColor: "#404040",
      buttonColor: "#666666",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "45, 45, 45",
      imagePlaceholderColor: "#eeeeee",
      imagePlaceholderTextColor: "#404040",
    },
  },
  {
    id: "oreo",
    name: "Oreo",
    theme: {
      backgroundColor: "#ffffff",
      textColor: "#000000",
      linkColor: "#ffffff",
      buttonColor: "#2A3235", 
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#2A3235",
      cardShadowColor: "180, 180, 180",
      imagePlaceholderColor: "#000000",
      imagePlaceholderTextColor: "#ffffff",
    },
  },
  {
    id: "bronze",
    name: "Bronze",
    theme: {
      backgroundColor: "#f3e5d8", // Light bronze background
      textColor: "#3d2a22", // Darker bronze text
      linkColor: "#8b4513", // Darker bronze link color
      buttonColor: "#cd7f32", // Traditional bronze
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#fff8f0", // Warm white
      cardShadowColor: "184, 115, 51", // Bronze shadow
      imagePlaceholderColor: "#dab49d", // Muted bronze
      imagePlaceholderTextColor: "#8b4513", // Saddle brown
    },
  },
  {
    id: "modern-gray",
    name: "Modern Gray", 
    theme: {
      backgroundColor: "#e0e0e0",
      textColor: "#1a1a1a",
      linkColor: "#404040",
      buttonColor: "#4d4d4d",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#f0f0f0",
      cardShadowColor: "77, 77, 77",
      imagePlaceholderColor: "#cccccc", 
      imagePlaceholderTextColor: "#666666",
    },
  },
  {
    id: "blue-accent",
    name: "Blue Accent",
    theme: {
      backgroundColor: "#ffffff",
      textColor: "#000000",
      linkColor: "#0277bd", // Darker blue for link color
      buttonColor: "#42a5f5", 
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#f1f8fe",
      cardShadowColor: "173, 216, 230", // Lighter blue shadow
      imagePlaceholderColor: "#e1f5fe",
      imagePlaceholderTextColor: "#0288d1",
    },
  },
  {
    id: "midnight-aurora",
    name: "Midnight Aurora",
    theme: {
      backgroundColor: "#1a1b4b",
      textColor: "#ffffff",
      linkColor: "#e0e7ff", 
      buttonColor: "#818cf8",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#2a2b6b",
      cardShadowColor: "79, 70, 229",
      imagePlaceholderColor: "#312e81",
      imagePlaceholderTextColor: "#818cf8",
    },
  },
  {
    id: "panda",
    name: "Panda", 
    theme: {
      backgroundColor: "#212121",
      textColor: "#ffffff",
      linkColor: "#ffffff",
      buttonColor: "#ffffff", 
      buttonTextColor: "#000000",
      cardBackgroundColor: "#333333",
      cardShadowColor: "200, 200, 200",
      imagePlaceholderColor: "#ffffff",
      imagePlaceholderTextColor: "#ffffff",
    },
  },
  {
    id: "deep-ocean",
    name: "Deep Ocean",
    theme: {
      backgroundColor: "#1a2634",
      textColor: "#ffffff",
      linkColor: "#ffffff",
      buttonColor: "#0288d1",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#243447",
      cardShadowColor: "2, 136, 209",
      imagePlaceholderColor: "#0d1b2a",
      imagePlaceholderTextColor: "#64b5f6",
    },
  },
  {
    id: "dark-forest",
    name: "Dark Forest",
    theme: {
      backgroundColor: "#1e3b1e", // Slightly brighter base
      textColor: "#ffffff",
      linkColor: "#ffffff",
      buttonColor: "#2ecc71", // More vibrant green
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#2d502d", // Slightly brighter card
      cardShadowColor: "46, 204, 113", // Matching shadow
      imagePlaceholderColor: "#0d2b0d",
      imagePlaceholderTextColor: "#4cd964", // Brighter accent
    },
  },
  {
    id: "volcanic",
    name: "Volcanic",
    theme: {
      backgroundColor: "#2d1418",
      textColor: "#ffffff",
      linkColor: "#ffffff",
      buttonColor: "#c62828",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#3e1c21",
      cardShadowColor: "198, 40, 40",
      imagePlaceholderColor: "#1a0c0e",
      imagePlaceholderTextColor: "#ff8a80",
    },
  },
  {
    id: "dark-purple",
    name: "Dark Purple",
    theme: {
      backgroundColor: "#2d1b3a",
      textColor: "#ffffff",
      linkColor: "#ffffff", 
      buttonColor: "#9c27b0",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#432752",
      cardShadowColor: "156, 39, 176",
      imagePlaceholderColor: "#1a0f21",
      imagePlaceholderTextColor: "#ce93d8",
    },
  },
  {
    id: "dark-amber",
    name: "Dark Amber",
    theme: {
      backgroundColor: "#2d1d0f",
      textColor: "#ffffff",
      linkColor: "#ffffff",
      buttonColor: "#ff8f00",
      buttonTextColor: "#ffffff", 
      cardBackgroundColor: "#3d2815",
      cardShadowColor: "255, 143, 0",
      imagePlaceholderColor: "#1a1008",
      imagePlaceholderTextColor: "#ffcc80",
    },
  },
  {
    id: "dark-teal",
    name: "Dark Teal",
    theme: {
      backgroundColor: "#1a2c2c",
      textColor: "#ffffff",
      linkColor: "#ffffff",
      buttonColor: "#00897b",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#243d3d",
      cardShadowColor: "0, 137, 123",
      imagePlaceholderColor: "#0f1a1a",
      imagePlaceholderTextColor: "#80cbc4",
    },
  },
];

const minimalist = [
  {
    id: "marble-elegance",
    name: "Marble Elegance",
    theme: {
      backgroundImage:
        "url('/images/theme-backgrounds/marble-elegance.jpg')",
      textColor: "#333333",
      linkColor: "#0077b6",
      buttonColor: "#0077b6",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "120, 120, 120",
      imagePlaceholderColor: "#e0e0e0",
      imagePlaceholderTextColor: "#757575",
    },
  },
  {
    id: "minimalist-white",
    name: "Minimalist White",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/minimalist-white.jpg')",
      textColor: "#333333",
      linkColor: "#007acc",
      buttonColor: "#007acc",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "120, 120, 120",
      imagePlaceholderColor: "#e2e8f0",
      imagePlaceholderTextColor: "#a0aec0",
    },
  },
  {
    id: "strings",
    name: "Strings",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/strings.jpg')",
      textColor: "#006400",
      linkColor: "#006400",
      buttonColor: "#8fbc8f",
      buttonTextColor: "#ffffff", 
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "214, 140, 69",
      imagePlaceholderColor: "#e0d5c4",
      imagePlaceholderTextColor: "#a89b8a",
    },
  },
  {
    "id": "modern-gray",
    "name": "Modern Gray",
    "theme": {
      "backgroundImage": "url('/images/theme-backgrounds/modern-gray.jpg')",
      "textColor": "#333333",
      "linkColor": "#555555",
      "buttonColor": "#555555",
      "buttonTextColor": "#ffffff",
      "cardBackgroundColor": "#ffffff",
      "cardShadowColor": "85, 85, 85",
      "imagePlaceholderColor": "#e2e8f0",
      "imagePlaceholderTextColor": "#a0aec0"
    }
  },
  {
    id: "serene-nature",
    name: "Serene Nature",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/serene-nature.jpg')",
      textColor: "#4a4a4a",
      linkColor: "#4c6b15", 
      buttonColor: "#8fbc8f",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "107, 142, 35",
      imagePlaceholderColor: "#dcdcdc",
      imagePlaceholderTextColor: "#a9a9a9",
    },
  },
  {
    id: "minimal-blush",
    name: "Minimal Blush",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/minimal-blush.jpg')",
      textColor: "#5a3e36",
      linkColor: "#c85f6c",
      buttonColor: "#b5838d",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "181, 131, 141",
      imagePlaceholderColor: "#f0e1dd",
      imagePlaceholderTextColor: "#a68a7c",
    },
  },
  {
    id: "pastel-harmony",
    name: "Pastel Harmony",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/pastel-harmony.jpg')",
      textColor: "#4a4a4a",
      linkColor: "#e65d50",
      buttonColor: "#ff6f61",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "255, 111, 97",
      imagePlaceholderColor: "#e0e0e0",
      imagePlaceholderTextColor: "#9e9e9e",
    },
  },
  {
    id: "serene-mountains",
    name: "Serene Mountains",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/serene-mountains.jpg')",
      textColor: "#ffffff",
      linkColor: "#1a73e8",
      buttonColor: "#1a73e8",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#eeeeee",
      cardShadowColor: "26, 115, 232",
      imagePlaceholderColor: "#e0f7ff",
      imagePlaceholderTextColor: "#4a5568",
    },
  },
  {
    id: "minimal-elegance",
    name: "Minimal Elegance",
    theme: {
      backgroundImage:
        "url('/images/theme-backgrounds/minimal-elegance.jpg')",
      textColor: "#4a4a4a",
      linkColor: "#8b5e3c",
      buttonColor: "#8b5e3c",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "139, 94, 60",
      imagePlaceholderColor: "#e2e8f0",
      imagePlaceholderTextColor: "#a0aec0",
    },
  },
  {
    id: "pastel-colors",
    name: "Pastel Colors",
    theme: {
      backgroundImage:
        "url('/images/theme-backgrounds/pastel-colors.jpg')",
      textColor: "#4a4a4a",
      linkColor: "#8b4c56",
      buttonColor: "#b5838d",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "181, 131, 141",
      imagePlaceholderColor: "#e8e8e8",
      imagePlaceholderTextColor: "#9e9e9e",
    },
  },
  {
    id: "serene-white",
    name: "Serene White",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/serene-white.jpg')",
      textColor: "#333333",
      linkColor: "#444444",
      buttonColor: "#999999",
      buttonTextColor: "#333333",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "140, 140, 140",
      imagePlaceholderColor: "#e0e0e0",
      imagePlaceholderTextColor: "#999999",
    },
  },
  {
    id: "minimal-arch",
    name: "Minimal Arch",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/minimal-arch.jpg')",
      textColor: "#333333",
      linkColor: "#444444",
      buttonColor: "#999999",
      buttonTextColor: "#333333",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "140, 140, 140",
      imagePlaceholderColor: "#e0e0e0",
      imagePlaceholderTextColor: "#999999",
    },
  },
  {
    id: "soft-sand",
    name: "Soft Sand", 
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/soft-sand.jpg')",
      textColor: "#3d3d3d",
      linkColor: "#805b1b",
      buttonColor: "#c69c3f",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "198, 156, 63",
      imagePlaceholderColor: "#f2e6d3",
      imagePlaceholderTextColor: "#8a7055",
    },
  },
  {
    id: "serene-sky",
    name: "Serene Sky",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/serene-sky.jpg')",
      textColor: "#333333",
      linkColor: "#005f99",
      buttonColor: "#005f99",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "0, 95, 153",
      imagePlaceholderColor: "#e0f7ff",
      imagePlaceholderTextColor: "#5a5a5a",
    },
  },
  {
    id: "minimalist-serenity",
    name: "Minimalist Serenity", 
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/minimalist-serenity.jpg')",
      textColor: "#333333",
      linkColor: "#666666",
      buttonColor: "#666666",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "102, 102, 102",
      imagePlaceholderColor: "#f8f8f8",
      imagePlaceholderTextColor: "#666666",
    },
  },
  {
    id: "soft-blush",
    name: "Soft Blush",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/soft-blush.jpg')",
      textColor: "#333333",
      linkColor: "#b15c6a",
      buttonColor: "#d17b88",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "209, 123, 136",
      imagePlaceholderColor: "#f0e6e9",
      imagePlaceholderTextColor: "#a8a8a8",
    },
  },
  {
    "id": "serene-green",
    "name": "Serene Green",
    "theme": {
      "backgroundImage": "url('/images/theme-backgrounds/serene-green.jpg')",
      "textColor": "#4a4a4a",
      "linkColor": "#3d5a3d",
      "buttonColor": "#5c785c",
      "buttonTextColor": "#ffffff",
      "cardBackgroundColor": "#f8f8f8",
      "cardShadowColor": "92, 120, 92",
      "imagePlaceholderColor": "#f0f0f0",
      "imagePlaceholderTextColor": "#4a4a4a",
    }
  }
];

const fun = [
  {
    "id": "serene-strokes",
    "name": "Serene Strokes",
    "theme": {
      "backgroundImage": "url('/images/theme-backgrounds/serene-strokes.jpg')",
      "textColor": "#5a5a5a", 
      "linkColor": "#b25454",
      "buttonColor": "#b0c4b1",
      "buttonTextColor": "#ffffff",
      "cardBackgroundColor": "#ffffff",
      "cardShadowColor": "176, 196, 177",
      "imagePlaceholderColor": "#f0f4f8",
      "imagePlaceholderTextColor": "#7a7e85"
    }
  },
  {
    id: "mystic-shadow",
    name: "Mystic Shadow",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/mystic-shadow.jpg')",
      textColor: "#e0e0e0",
      linkColor: "#ffffff",
      buttonColor: "#333333",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#444444",
      cardShadowColor: "0, 0, 0",
      imagePlaceholderColor: "#666666",
      imagePlaceholderTextColor: "#cccccc",
    },
  },
  {
    id: "vintage-brown",
    name: "Vintage Brown",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/vintage-brown.jpg')",
      textColor: "#ffffff",
      linkColor: "#8b4513",
      buttonColor: "#8b4513",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#f5f5dc",
      cardShadowColor: "119, 59, 16",
      imagePlaceholderColor: "#d3c4a4",
      imagePlaceholderTextColor: "#6b4f3f",
    },
  },
  {
    id: "mint-breeze",
    name: "Mint Breeze",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/mint-breeze.jpg')",
      textColor: "#2c3e50",
      linkColor: "#16a085",
      buttonColor: "#16a085",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "22, 160, 133",
      imagePlaceholderColor: "#ecf0f1",
      imagePlaceholderTextColor: "#95a5a6",
    },
  },
  {
    id: "sunset-bliss",
    name: "Sunset Bliss",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/sunset-bliss.jpg')",
      textColor: "#333333",
      linkColor: "#e65c4f",
      buttonColor: "#ff6f61",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "255, 111, 97",
      imagePlaceholderColor: "#e2e8f0",
      imagePlaceholderTextColor: "#4a5568",
    },
  },
  {
    "id": "midnight-memphis",
    "name": "Midnight Memphis",
    "theme": {
      "backgroundImage": "url('/images/theme-backgrounds/midnight-memphis.jpg')",
      "textColor": "#f0e7d8",
      "linkColor": "#f0c674",
      "buttonColor": "#3b3b3b",
      "buttonTextColor": "#f0e7d8",
      "cardBackgroundColor": "#1f1f1f",
      "cardShadowColor": "240, 231, 216",
      "imagePlaceholderColor": "#2a2a2a",
      "imagePlaceholderTextColor": "#b0b0b0"
    }
  },
  {
    id: "vintage-parchment",
    name: "Vintage Parchment",
    theme: {
      backgroundImage:
        "url('/images/theme-backgrounds/vintage-parchment.jpeg')",
      textColor: "#ffffff",
      linkColor: "#6b4e2c",
      buttonColor: "#8b5e3c",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#f5f0e6",
      cardShadowColor: "139, 94, 60",
      imagePlaceholderColor: "#d3c4a8",
      imagePlaceholderTextColor: "#7a6a52",
    },
  },
  {
    id: "night-bokeh",
    name: "Night Bokeh",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/night-bokeh.jpeg')",
      textColor: "#ffffff",
      linkColor: "#ff6347",
      buttonColor: "#ff6347",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#1a1a1a",
      cardShadowColor: "255, 99, 71",
      imagePlaceholderColor: "#333333",
      imagePlaceholderTextColor: "#aaaaaa",
    },
  },
  {
    id: "dreamy-swirl",
    name: "Dreamy Swirl",
    theme: {
      backgroundImage:
        "url('/images/theme-backgrounds/dreamy-swirl.jpg')",
      textColor: "#333333",
      linkColor: "#ff4500",
      buttonColor: "#0099cc",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "0, 153, 204",
      imagePlaceholderColor: "#e0f7fa",
      imagePlaceholderTextColor: "#006064",
    },
  },
  {
    id: "cosmic-dusk",
    name: "Cosmic Dusk", 
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/cosmic-dusk.jpeg')",
      textColor: "#ffffff",
      linkColor: "#ffb38a",
      buttonColor: "#4a90e2",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#1e2b3d",
      cardShadowColor: "74, 144, 226",
      imagePlaceholderColor: "#2d3e50",
      imagePlaceholderTextColor: "#8ca6c3",
    },
  },
  {
    id: "neon-brick",
    name: "Neon Brick",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/neon-brick.jpg')",
      textColor: "#ffffff",
      linkColor: "#ffffff",
      buttonColor: "#8a2be2",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "rgba(20, 10, 30, 0.85)",
      cardShadowColor: "138, 43, 226",
      imagePlaceholderColor: "#1a0f2e",
      imagePlaceholderTextColor: "#00ffff"
    }
  },
  {
    "id": "pastel-dream",
    "name": "Pastel Dream",
    "theme": {
      "backgroundImage": "url('/images/theme-backgrounds/pastel-dream.jpg')",
      "textColor": "#4a4a4a",
      "linkColor": "#e65982",
      "buttonColor": "#ff6f91",
      "buttonTextColor": "#ffffff",
      "cardBackgroundColor": "#ffffff",
      "cardShadowColor": "255, 111, 145",
      "imagePlaceholderColor": "#e0e7ff",
      "imagePlaceholderTextColor": "#6b7280"
    }
  },
  {
    "id": "neon-mist",
    "name": "Neon Mist",
    "theme": {
      "backgroundImage": "url('/images/theme-backgrounds/neon-mist.jpg')",
      "textColor": "#ffffff",
      "linkColor": "#8b0046",
      "buttonColor": "#33ccff",
      "buttonTextColor": "#ffffff",
      "cardBackgroundColor": "rgba(255, 255, 255, 0.8)",
      "cardShadowColor": "51, 204, 255",
      "imagePlaceholderColor": "#e0e0e0",
      "imagePlaceholderTextColor": "#757575"
    }
  },
  {
    "id": "vintage-dawn",
    "name": "Vintage Dawn",
    "theme": {
      "backgroundImage": "url('/images/theme-backgrounds/vintage-dawn.jpg')",
      "textColor": "#3a3a3a",
      "linkColor": "#d47fa6",
      "buttonColor": "#f7b2b7",
      "buttonTextColor": "#ffffff",
      "cardBackgroundColor": "#ffffff",
      "cardShadowColor": "247, 178, 183",
      "imagePlaceholderColor": "#d3e0f0",
      "imagePlaceholderTextColor": "#5a5a5a"
    }
  },
  {
    "id": "ethereal-mist",
    "name": "Ethereal Mist",
    "theme": {
      "backgroundImage": "url('/images/theme-backgrounds/ethereal-mist.jpg')",
      "textColor": "#3c3c3c",
      "linkColor": "#d47db5",
      "buttonColor": "#f7a8d4",
      "buttonTextColor": "#ffffff",
      "cardBackgroundColor": "#ffffff",
      "cardShadowColor": "247, 168, 212",
      "imagePlaceholderColor": "#f0f4ff",
      "imagePlaceholderTextColor": "#7a7a7a"
    }
  },
  {
    id: "pastel-bokeh",
    name: "Pastel Bokeh",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/pastel-bokeh.jpg')",
      textColor: "#1a1a1a",
      linkColor: "#cc5a4e",
      buttonColor: "#ff6f61",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "255, 111, 97",
      imagePlaceholderColor: "#e0f7fa",
      imagePlaceholderTextColor: "#00796b",
    },
  },
  {
    "id": "neon-future",
    "name": "Neon Future",
    "theme": {
      "backgroundImage": "url('/images/theme-backgrounds/neon-future.jpg')",
      "textColor": "#e0e0e0",
      "linkColor": "#ff4d9e",
      "buttonColor": "#00bfff",
      "buttonTextColor": "#ffffff",
      "cardBackgroundColor": "#1a1a1a",
      "cardShadowColor": "0, 191, 255",
      "imagePlaceholderColor": "#333333",
      "imagePlaceholderTextColor": "#cccccc",
      "backgroundImageBlur": 10
    }
  },
  {
    "id": "sunset-serenity", 
    "name": "Sunset Serenity",
    "theme": {
      "backgroundImage": "url('/images/theme-backgrounds/sunset-serenity.jpg')",
      "textColor": "#ffffff",
      "linkColor": "#ff6200",
      "buttonColor": "#ff9966", 
      "buttonTextColor": "#ffffff",
      "cardBackgroundColor": "#ffffff",
      "cardShadowColor": "255, 153, 102",
      "imagePlaceholderColor": "#f0e6d6",
      "imagePlaceholderTextColor": "#4a4a4a",
    }
  }
];

const all = [...standard, ...minimalist, ...fun];

const getThemeFromId = (page, additionalThemes = {}) => {
  const allThemes = [
    ...Object.values(additionalThemes).flat(),
    ...all
  ];

  let theme = allThemes.find((t) => t.id === page.theme || t.id === page) || all[0];

  if (page.custom_theme) {
    theme = {
      ...theme,
      theme: {
        ...theme.theme,
        ...page.custom_theme
      }
    };
  }

  return theme;
};

export { standard, minimalist, fun, all, getThemeFromId };
