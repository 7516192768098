<script>
  import Page from "$app-components/blocks/_layouts/Page.svelte";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;
  export let blockClicked = function () {};
  export let blockViewed = function () {};
  export let onActualPage;

  function getYoutubeId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  }

  $: youtubeId = getYoutubeId(block.url);
  $: directRedirect = !youtubeId;
  $: iframeUrl = youtubeId
    ? `https://www.youtube.com/embed/${youtubeId}`
    : block.url;
</script>

<Page
  {block}
  {theme}
  {buttonStyle}
  {hoverEffect}
  {blockClicked}
  {iframeUrl}
  {directRedirect}
  {blockViewed}
  goToButtonText="Watch Video"
  width="800px"
  height="450px"
  {onActualPage}
/>
