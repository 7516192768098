<script>
  import Dashboard from "$layouts/Dashboard.svelte";
  import { onMount } from "svelte";

  import Seo from "./settings/Seo.svelte";
  import AnalyticsIntegration from "./settings/AnalyticsIntegration.svelte";
  import Subscribe from "./settings/Subscribe.svelte";
  import SharePage from "./settings/SharePage.svelte";
  import Members from "./settings/Members.svelte";

  import { writable } from "svelte/store";

  export let currentUser;
  export let page;
  export let permissions;

  let activeTab = writable("members");
  let isMobile = false;

  const tabs = [
    { id: "members", label: "Members" },
    { id: "seo", label: "SEO" },
    { id: "analytics", label: "Analytics Integration" },
    { id: "subscribe", label: "Subscribe" },
    { id: "share", label: "Share Page" },
  ];

  let updater = function (fn) {
    const data = fn({ currentUser });

    if (data.currentUser) {
      currentUser = data.currentUser;
    }
  };

  function setActiveTab(tabId) {
    activeTab.set(tabId);
  }

  onMount(() => {
    const checkMobile = () => {
      isMobile = window.innerWidth < 768;
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  });
</script>

<Dashboard {currentUser} {permissions} {page}>
  <div class="flex flex-col md:flex-row">
    {#if isMobile}
      <div class="w-full bg-white p-4 mb-6 shadow-md rounded-lg">
        <div class="relative">
          <select
            class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md appearance-none"
            bind:value={$activeTab}
            on:change={(e) => setActiveTab(e.target.value)}
          >
            {#each tabs as tab}
              <option value={tab.id}>{tab.label}</option>
            {/each}
          </select>
        </div>
      </div>
    {:else}
      <aside class="w-64 bg-white shadow-md h-[calc(100vh-80px)]">
        <nav class="h-full flex flex-col">
          <h2 class="text-xl font-semibold text-gray-800 px-6 py-6">
            Settings
          </h2>
          <ul class="flex-grow overflow-y-auto">
            {#each tabs as tab}
              <li>
                <button
                  class="w-full text-left py-3 px-6 transition-colors duration-200 {$activeTab ===
                  tab.id
                    ? 'bg-purple-50 text-purple-700 border-l-4 border-purple-700'
                    : 'text-gray-600 hover:bg-gray-100'}"
                  on:click={() => setActiveTab(tab.id)}
                >
                  {tab.label}
                </button>
              </li>
            {/each}
          </ul>
        </nav>
      </aside>
    {/if}
    <main class="flex-1 p-0 md:p-0 bg-gray-50">
      {#if $activeTab === "seo"}
        <Seo bind:currentUser bind:page />
      {:else if $activeTab === "analytics"}
        <AnalyticsIntegration bind:currentUser bind:page />
      {:else if $activeTab === "subscribe"}
        <Subscribe bind:currentUser bind:page />
      {:else if $activeTab === "share"}
        <SharePage bind:currentUser bind:page />
      {:else if $activeTab === "members"}
        <Members />
      {/if}
    </main>
  </div>
</Dashboard>
