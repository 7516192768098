<script>
  import { createEventDispatcher } from "svelte";
  import View from "$app-components/blocks/_layouts/View.svelte";
  import Image from "$components/Image.svelte";

  const dispatch = createEventDispatcher();

  export let block;
  export let updater;

  function handleDelete() {
    dispatch("delete-block");
  }
</script>

<View {block} {updater} on:delete-block={handleDelete}>
  <Image
    src={block.image_url}
    alt={block.title}
    klass="w-12 h-12 rounded-md object-cover flex-shrink-0" />
  <div class="flex-1 min-w-0 pl-2">
    <span class="text-md font-semibold text-gray-900 truncate block">
      {block.title}
    </span>
    <a
      href={block.url}
      target="_blank"
      rel="noopener noreferrer"
      class="text-sm text-gray-500 hover:text-gray-700 truncate block">
      {block.url}
    </a>
  </div>
</View>
