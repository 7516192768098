<script>
  import Iphone from "$app-components/Iphone.svelte";
  import BlockViewer from "$app-components/BlockViewer.svelte";
  import ProfileHeader from "$app-components/ProfileHeader.svelte";

  export let blocks;
  export let page;

  export let width;
  export let height;
</script>

<Iphone {width} {height}>
  <BlockViewer {blocks} {page}>
    <svelte:fragment slot="header">
      <ProfileHeader {page} />
    </svelte:fragment>
  </BlockViewer>
</Iphone>
