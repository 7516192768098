<script>
  export let width = "375px";
  export let height = "calc(100vh - 80px)";
  export let maxWidth = "375px";
  export let maxHeight = "740px";
  export let klass = "bg-gray-100";
  export let color = "#D1D5DB";

  $: frameFrom =
    color === "black" ? "#2D2F33" : color === "white" ? "#E8E8E8" : color;
  $: frameTo =
    color === "black" ? "#1A1B1D" : color === "white" ? "#D1D1D1" : color;
  $: buttonColor =
    color === "black" ? "#3A3B3D" : color === "white" ? "#CCCCCC" : color;
  $: borderColor =
    color === "black" ? "#2D2F33" : color === "white" ? "#E8E8E8" : color;
</script>

<div class="w-full flex justify-center items-center" style="height: {height};">
  <div
    class="relative rounded-[60px] shadow-2xl overflow-hidden border-8"
    style="
      width: {width}; 
      height: 100%; 
      max-height: {maxHeight}; 
      max-width: {maxWidth};
      background: linear-gradient(to bottom right, {frameFrom}, {frameTo});
      border-color: {borderColor};
    "
  >
    <!-- Side Buttons -->
    <div
      class="absolute -right-[2px] top-24 h-12 w-[4px] rounded-l-lg"
      style="background-color: {buttonColor};"
    ></div>
    <div
      class="absolute -left-[2px] top-20 h-16 w-[4px] rounded-r-lg"
      style="background-color: {buttonColor};"
    ></div>
    <div
      class="absolute -left-[2px] top-44 h-16 w-[4px] rounded-r-lg"
      style="background-color: {buttonColor};"
    ></div>
    <!-- Dynamic Island -->
    <div
      class="absolute top-0 left-1/2 transform -translate-x-1/2 w-[30%] h-[25px] bg-black rounded-b-[18px] z-10"
    >
      <div
        class="absolute left-[8px] top-[7px] w-[10px] h-[10px] bg-[#1a1a1a] rounded-full"
      ></div>
      <div
        class="absolute right-[8px] top-[7px] w-[10px] h-[10px] bg-[#1a1a1a] rounded-full"
      ></div>
    </div>
    <!-- Screen -->
    <div
      class="absolute inset-0 bg-white rounded-[50px] overflow-hidden flex flex-col"
    >
      <!-- Status Bar -->
      <div
        class="h-8 bg-white flex justify-between items-center px-6 text-xs font-medium pt-2"
      >
        <span>9:41</span>
        <div class="flex items-center space-x-1.5">
          <div class="flex space-x-[2px]">
            <div class="w-[5px] h-[10px] bg-black rounded-sm"></div>
            <div class="w-[5px] h-[8px] bg-black rounded-sm"></div>
            <div class="w-[5px] h-[6px] bg-black rounded-sm"></div>
            <div class="w-[5px] h-[4px] bg-black rounded-sm"></div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path
              d="M1.5 6.5v11h3v-11h-3zM6.5 4.5v15h3v-15h-3zM11.5 2.5v17h3v-17h-3zM16.5 0.5v19h3v-19h-3z"
            />
          </svg>
          <div class="relative w-6 h-4">
            <div
              class="absolute inset-0 border-2 border-black rounded-md"
            ></div>
            <div class="absolute inset-[2px] bg-black rounded-sm"></div>
          </div>
        </div>
      </div>
      <!-- App Content -->
      <div class="flex-grow {klass} overflow-y-auto">
        <div class="h-full">
          <slot />
        </div>
      </div>
    </div>
    <!-- Home Indicator -->
    <div
      class="absolute bottom-1 left-1/2 transform -translate-x-1/2 w-32 h-1 bg-black rounded-full opacity-20"
    ></div>
  </div>
</div>

<style>
  /* iOS-like scrollbar styling */
  div::-webkit-scrollbar {
    width: 6px;
  }

  div::-webkit-scrollbar-track {
    background: transparent;
  }

  div::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    transition: background 0.2s ease;
  }

  div::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }
</style>
