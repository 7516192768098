export function getBorderRadius(roundness, block = null) {
  const featuredBlock = block?.layout_style === "featured";
  const productBlock = block?.block_type === "product";
  const textBlock = block?.block_type === "text";
  const lessRounded = featuredBlock || productBlock || textBlock;

  switch (roundness) {
    case "fill-square":
    case "outline-square":
    case "softshadow-square":
    case "hardshadow-square":
      return "0";
    case "fill-pill":
    case "outline-pill":
    case "softshadow-pill":
    case "hardshadow-pill":
      return lessRounded ? "2rem" : "4rem";
    case "fill-rounded":
    case "outline-rounded":
    case "softshadow-rounded":
    case "hardshadow-rounded":
    default:
      return "0.4rem";
  }
}

export function getBlockStyle(theme, roundness, block = null) {
  let style = `
    background-color: var(--cardBackgroundColor);
    border-radius: ${getBorderRadius(roundness, block)};
  `;

  switch (roundness) {
    case "fill-square":
    case "fill-rounded":
    case "fill-pill":
      style += `
        background-color: var(--buttonColor);
        color: var(--buttonTextColor);
      `;
      break;
    case "outline-square":
    case "outline-rounded":
    case "outline-pill":
      style += `
        border-style: solid;
        border-color: var(--buttonColor);
        border-width: ${theme.cardBorderWidth || "1px"};
        color: var(--buttonColor);
      `;
      break;
    case "softshadow-square":
    case "softshadow-rounded":
    case "softshadow-pill":
      style += `
        box-shadow: 0 6px 6px rgba(var(--cardShadowColor), 0.1);
      `;
      break;
    case "hardshadow-square":
    case "hardshadow-rounded":
    case "hardshadow-pill":
      style += `
        box-shadow: 8px 8px 0 0 rgba(var(--cardShadowColor), 0.3);
        border-style: solid;
        border-color: var(--buttonColor);
        border-width: ${theme.cardBorderWidth || "2px"};
      `;
      break;
    default:
      style += `
        box-shadow: 0 6px 6px rgba(var(--cardShadowColor), 0.1);
      `;
  }

  return style;
}
