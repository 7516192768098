<script>
  import { onMount } from "svelte";

  export let devices;

  let mounted = false;

  onMount(() => {
    mounted = true;
  });

  function getDeviceIcon(deviceName) {
    if (deviceName.toLowerCase().includes("iphone")) {
      return "M18 10a1 1 0 0 1-1-1V5a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v4a1 1 0 0 1-2 0V5a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v4a1 1 0 0 1-1 1zm-6 11a1 1 0 0 1-1-1v-4a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm-4-5h8a3 3 0 0 1 3 3v2a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3v-2a3 3 0 0 1 3-3zm0 2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H8z";
    } else if (deviceName.toLowerCase().includes("android")) {
      return "M5 16l-2-2V6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v8l-2 2H5zm14-2V6H5v8h14zm-7 4a1 1 0 0 1-1-1h-4a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-1h18v1a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1h-4a1 1 0 0 1-1 1z";
    } else if (
      deviceName.toLowerCase().includes("mac") ||
      deviceName.toLowerCase().includes("macbook")
    ) {
      return "M4 5a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V5zm14 0H6v10h12V5zM2 19a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1z";
    } else {
      return "M4 5a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V5zm14 0H6v10h12V5zM2 19a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1z";
    }
  }

  function getProgressBarWidth(views, maxViews) {
    return (views / maxViews) * 100;
  }

  $: maxViews =
    devices.length > 0 ? Math.max(...devices.map((d) => d.views)) : 0;
</script>

<div class="bg-white p-4 sm:p-6 rounded-lg shadow-lg">
  <div class="space-y-4">
    {#each devices as device, index}
      {#if mounted}
        <div
          class="flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0 sm:space-x-4"
        >
          <div class="flex items-center sm:w-1/4">
            <svg
              class="w-8 h-8 text-gray-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d={getDeviceIcon(device.name)}
              ></path>
            </svg>
            <span class="ml-4 font-semibold text-gray-700 truncate"
              >{device.name}</span
            >
          </div>
          <div class="flex-grow sm:flex sm:justify-between sm:items-center">
            <div
              class="bg-gray-200 h-2 rounded-full overflow-hidden sm:w-1/2 hidden sm:block"
            >
              <div
                class="bg-purple-600 h-full rounded-full transition-all duration-500 ease-out"
                style="width: {getProgressBarWidth(device.views, maxViews)}%"
              ></div>
            </div>
            <div
              class="flex flex-wrap items-center space-x-3 sm:space-x-4 text-sm sm:w-1/2 sm:justify-end"
            >
              <span
                class="font-semibold text-blue-600 bg-blue-100 px-2 py-1 rounded"
              >
                {device.views.toLocaleString()} views
              </span>
              <span
                class="font-semibold text-green-600 bg-green-100 px-2 py-1 rounded"
              >
                {device.clicks.toLocaleString()} clicks
              </span>
              <span
                class="font-semibold text-purple-600 bg-purple-100 px-2 py-1 rounded"
              >
                {device.clickRate}% click rate
              </span>
            </div>
          </div>
        </div>
      {/if}
    {/each}
  </div>
  {#if devices.length === 0}
    <p class="text-center text-gray-500 mt-4">No device data available yet.</p>
  {/if}
</div>

<style>
  /* Add any additional styles here if needed */
</style>
