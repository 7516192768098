<script>
  import Modal from "$components/Modal.svelte";
  import Ajax from "$utils/ajax";
  import { notify } from "$utils/notify";

  export let block;
  export let updater;

  let isModalOpen = false;
  let isSaving = false;
  let addDateTime = block.schedule_add || "";
  let hideDateTime = block.schedule_hide || "";
  let timezone =
    block.schedule_timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const excludedTypes = ["heading"];

  const timezones = Intl.supportedValuesOf("timeZone");

  function closeModal() {
    isModalOpen = false;
  }

  async function saveAndClose() {
    isSaving = true;

    // Validate dates
    if (
      addDateTime &&
      hideDateTime &&
      new Date(addDateTime) >= new Date(hideDateTime)
    ) {
      notify({
        type: "error",
        message: "Hide time must be after add time",
      });
      isSaving = false;
      return;
    }

    const [error, resp] = await Ajax.put(
      `/dashboard/blocks/${block.id}/update_schedule`,
      {
        schedule_add: addDateTime || null,
        schedule_hide: hideDateTime || null,
        schedule_timezone: timezone,
      }
    );

    if (error) {
      notify({
        type: "error",
        message: error?.data?.[0] || "An error occurred. Please try again.",
      });
    } else {
      block = resp;

      updater(({ blocks }) => {
        return { blocks: blocks.map((b) => (b.id === block.id ? block : b)) };
      });

      closeModal();
    }

    isSaving = false;
  }

  function isScheduled() {
    return addDateTime || hideDateTime;
  }

  function formatDate(date) {
    if (!date) return "";

    const d = new Date(date);

    const datePart = d.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });

    const timePart = d.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

    return `${datePart} at ${timePart}`;
  }

  function clearAddDateTime() {
    addDateTime = "";
  }

  function clearHideDateTime() {
    hideDateTime = "";
  }
</script>

<Modal bind:isOpen={isModalOpen} size="4xl">
  <div slot="header">
    <h3 class="text-md sm:text-xl font-bold text-gray-900">Schedule Link</h3>
  </div>

  <div class="mt-6">
    <div
      class="bg-gradient-to-br from-purple-50 to-indigo-50 rounded-lg p-4 sm:p-6 mb-6 sm:mb-8 border border-purple-100 shadow-sm"
    >
      <div class="flex items-center mb-6 sm:mb-8">
        <p
          class="text-xs sm:text-sm text-purple-700 leading-relaxed hidden sm:block"
        >
          Schedule your content to automatically appear and disappear at
          specific times. Perfect for time-sensitive promotions, limited-time
          offers, or managing seasonal content.
        </p>
        <p class="text-xs sm:text-sm text-purple-700 leading-relaxed sm:hidden">
          Schedule your content to automatically appear and disappear at
          specific times.
        </p>
      </div>

      <div class="space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6">
        <div
          class="bg-white rounded-lg p-4 sm:p-6 border border-gray-200 shadow-sm hover:shadow-md transition-shadow duration-200"
        >
          <div class="flex items-center justify-between mb-3 sm:mb-4">
            <div class="flex items-center">
              <div class="bg-green-100 rounded-full p-1.5 sm:p-2 mr-2 sm:mr-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 sm:h-5 sm:w-5 text-green-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <label class="text-xs sm:text-sm font-medium text-gray-700"
                >Add Link At</label
              >
            </div>
            {#if addDateTime}
              <span class="text-[10px] sm:text-xs text-green-600 font-medium">
                {formatDate(addDateTime)}
              </span>
            {/if}
          </div>
          <p class="text-xs sm:text-sm text-gray-500 mb-3 sm:mb-5">
            Set when your link should become visible
          </p>
          <div class="flex items-center">
            <input
              type="datetime-local"
              placeholder="Select date and time"
              bind:value={addDateTime}
              class="flex-grow text-black text-sm rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 transition-colors duration-200 [color-scheme:light]"
            />

            {#if addDateTime}
              <button
                on:click={clearAddDateTime}
                class="ml-2 p-2 text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            {/if}
          </div>
        </div>

        <div
          class="bg-white rounded-lg p-4 sm:p-6 border border-gray-200 shadow-sm hover:shadow-md transition-shadow duration-200"
        >
          <div class="flex items-center justify-between mb-3 sm:mb-4">
            <div class="flex items-center">
              <div class="bg-red-100 rounded-full p-1.5 sm:p-2 mr-2 sm:mr-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 sm:h-5 sm:w-5 text-red-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <label class="text-xs sm:text-sm font-medium text-gray-700"
                >Hide Link At</label
              >
            </div>
            {#if hideDateTime}
              <span class="text-[10px] sm:text-xs text-red-600 font-medium">
                {formatDate(hideDateTime)}
              </span>
            {/if}
          </div>
          <p class="text-xs sm:text-sm text-gray-500 mb-3 sm:mb-5">
            Set when your link should be hidden
          </p>
          <div class="flex items-center">
            <input
              type="datetime-local"
              placeholder="Select date and time"
              bind:value={hideDateTime}
              class="flex-grow text-black text-sm rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 transition-colors duration-200 [color-scheme:light]"
            />

            {#if hideDateTime}
              <button
                on:click={clearHideDateTime}
                class="ml-2 p-2 text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            {/if}
          </div>
        </div>

        <div
          class="bg-white rounded-lg p-4 sm:p-6 border border-gray-200 shadow-sm hover:shadow-md transition-shadow duration-200 sm:col-span-2"
        >
          <div class="flex items-center mb-3 sm:mb-4">
            <div class="bg-blue-100 rounded-full p-1.5 sm:p-2 mr-2 sm:mr-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 sm:h-5 sm:w-5 text-blue-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <label class="text-xs sm:text-sm font-medium text-gray-700"
              >Timezone</label
            >
          </div>
          <p class="text-xs sm:text-sm text-gray-500 mb-3 sm:mb-5">
            Select the timezone for your schedule settings
          </p>
          <select
            bind:value={timezone}
            class="w-full text-sm rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 transition-colors duration-200"
          >
            {#each timezones as tz}
              <option value={tz}>{tz.replace(/_/g, " ")}</option>
            {/each}
          </select>
        </div>
      </div>
    </div>

    <div class="mt-6 sm:mt-8 flex justify-end space-x-3 border-t pt-4 sm:pt-6">
      <button
        type="button"
        class="px-3 sm:px-4 py-1.5 sm:py-2 text-xs sm:text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none transition-colors duration-200"
        on:click={closeModal}
        disabled={isSaving}
      >
        Cancel
      </button>
      <button
        type="button"
        class="inline-flex justify-center rounded-md border border-transparent bg-purple-600 px-4 sm:px-6 py-1.5 sm:py-2 text-xs sm:text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none disabled:opacity-50 transition-colors duration-200"
        on:click={saveAndClose}
        disabled={isSaving}
      >
        {#if isSaving}
          <svg
            class="animate-spin -ml-1 mr-2 h-3 w-3 sm:h-4 sm:w-4 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            />
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
          Saving...
        {:else}
          Save
        {/if}
      </button>
    </div>
  </div></Modal
>

{#if excludedTypes.includes(block.block_type) === false}
  <button
    on:click={() => (isModalOpen = true)}
    class="{isScheduled()
      ? 'text-purple-500'
      : 'text-gray-400'} hover:text-purple-500 transition-colors duration-200 ease-in-out relative group flex items-center"
    title="Schedule"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-4 w-4"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <polyline points="12 6 12 12 16 14" />
    </svg>
    {#if block.hidden}
      <span
        class="hidden md:block ml-2.5 text-xs font-medium bg-red-50 text-red-600 px-2 py-0.5 rounded-full border border-red-100 backdrop-blur-sm"
        >currently hidden</span
      >
    {/if}
    <span
      class="absolute bottom-[140%] left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
    >
      Schedule
    </span>
  </button>
{/if}
