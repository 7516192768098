<script>
  import { onMount, tick } from "svelte";
  import { notify } from "$utils/notify";
  import { chatStore } from "$stores/chat-store";
  import ChatMessages from "./ChatMessages.svelte";
  import Ajax from "$utils/ajax";

  export let page;

  let chats = [];
  let chatThreads = [];
  let selectedThread = null;
  let isMobileView = false;
  let showMobileModal = false;
  let loadingPromise = Promise.resolve(false);

  function checkMobileView() {
    isMobileView = window.innerWidth < 768;
  }

  async function selectThread(thread) {
    selectedThread = thread;

    if (isMobileView) {
      showMobileModal = true;
      document.body.style.overflow = "hidden";
    }

    markThreadAsRead(thread);
  }

  function goBack() {
    selectedThread = null;
    showMobileModal = false;
    document.body.style.overflow = "auto";
  }

  function markThreadAsRead(thread) {
    Ajax.post("/dashboard/chat/mark_as_read", {
      guest_user_id: thread.user_id,
    });

    const threadIndex = chatThreads.findIndex(
      (t) => t.user_id === thread.user_id
    );

    if (threadIndex >= 0) {
      chatThreads[threadIndex].messages.forEach((message) => {
        if (!message.seen_at) {
          message.seen_at = new Date().toISOString();
        }
      });

      chatThreads = [...chatThreads];
    }

    updateChatThreads();
  }

  async function loadMessages() {
    loadingPromise = new Promise(async (resolve) => {
      await tick();
      chatStore.setUnread(false);

      const [error, response] = await Ajax.get("/dashboard/chat/messages");

      if (error) {
        notify({
          message: "Failed to load chat messages",
          type: "error",
        });
      } else {
        chats = response;
        updateChatThreads();
      }

      resolve(false);
    });
  }

  function updateChatThreads() {
    chatThreads = Object.entries(chats)
      .filter(([_, messages]) => messages.length > 0)
      .map(([userId, messages]) => {
        return {
          user_id: userId,
          ip_address: messages[0].ip_address,
          location: messages[0].location,
          messages,
          lastMessage: messages[messages.length - 1],
          hasUnreadMessages: !messages[messages.length - 1].seen_at,
        };
      });

    if (selectedThread) {
      const updatedThread = chatThreads.find(
        (t) => t.user_id === selectedThread.user_id
      );
      if (updatedThread) {
        selectedThread = updatedThread;
        if (updatedThread.hasUnreadMessages) {
          markThreadAsRead(updatedThread);
        }
      }
    }
  }

  function formatDate(dateString) {
    return new Date(dateString).toLocaleString([], {
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  onMount(() => {
    loadMessages();
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    window.addEventListener("refresh-chat", loadMessages);

    return () => {
      window.removeEventListener("resize", checkMobileView);
      window.removeEventListener("refresh-chat", loadMessages);
    };
  });
</script>

<div
  class="flex sm:h-[calc(100vh-200px)] bg-gray-50 sm:rounded-lg overflow-hidden sm:border border-gray-200 -m-3 sm:m-0"
>
  <!-- Chat threads list -->
  <div
    class="w-full md:w-1/3 bg-white shrink-0 {isMobileView
      ? 'border-b'
      : 'border-r'} border-gray-200 overflow-y-auto"
  >
    {#each chatThreads as thread}
      <button
        class="w-full p-4 text-left hover:bg-gray-50 {isMobileView
          ? 'border-t'
          : 'border-b border-gray-100'} transition-colors duration-0 relative"
        class:bg-purple-50={selectedThread?.user_id === thread.user_id}
        on:click={() => selectThread(thread)}
      >
        <div class="flex flex-col p-1">
          <div class="flex items-center space-x-4 min-w-0">
            <div
              class="w-10 h-10 rounded-full bg-gradient-to-br from-purple-500 to-purple-700 flex items-center justify-center flex-shrink-0 shadow-sm"
            >
              <span class="text-sm font-semibold text-white">
                {thread.user_id.slice(0, 2).toUpperCase()}
              </span>
            </div>
            <div class="flex-1 min-w-0">
              <div class="flex items-center justify-between">
                <p class="font-semibold text-gray-900 truncate">
                  {thread.user_id.slice(0, 10)}...
                </p>
                <div class="flex items-center space-x-4 flex-shrink-0">
                  {#if thread.hasUnreadMessages}
                    <div class="w-2 h-2 bg-purple-500 rounded-full"></div>
                  {/if}
                  <span
                    class="text-xs font-medium text-gray-400 whitespace-nowrap"
                  >
                    {formatDate(thread.lastMessage.created_at)}
                  </span>
                </div>
              </div>
              <div class="text-xs text-gray-500 truncate mt-1">
                {#if thread.location}
                  <span>{thread.location}</span>
                {/if}
              </div>
            </div>
          </div>
        </div>
        <p class="text-sm text-gray-600 truncate mt-2">
          {thread.lastMessage.body}
        </p>
      </button>
    {/each}
  </div>

  {#if selectedThread}
    {#if isMobileView}
      <!-- Mobile Modal -->
      {#if showMobileModal}
        <div class="fixed inset-0 z-50 bg-white">
          <ChatMessages
            messages={selectedThread.messages}
            thread={selectedThread}
            {isMobileView}
            {page}
            {loadingPromise}
            onBack={goBack}
          />
        </div>
      {/if}
    {:else}
      <!-- Desktop Chat Messages -->
      {#key selectedThread}
        <ChatMessages
          messages={selectedThread.messages}
          thread={selectedThread}
          {isMobileView}
          {page}
          onBack={goBack}
        />
      {/key}
    {/if}
  {:else if !isMobileView}
    <div class="flex-1 flex items-center justify-center bg-white">
      <div class="text-center p-8">
        <div
          class="w-16 h-16 mx-auto mb-4 rounded-full bg-purple-100 flex items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-8 w-8 text-purple-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
            />
          </svg>
        </div>
        <h3 class="text-xl font-semibold text-gray-900 mb-2">
          {chatThreads.length === 0
            ? "No conversations yet"
            : "Select a conversation"}
        </h3>
        <p class="text-gray-500 max-w-sm">
          {chatThreads.length === 0
            ? "When visitors start chatting, their messages will appear here."
            : "Choose a chat thread from the left to start viewing the conversation."}
        </p>
      </div>
    </div>
  {:else if isMobileView && chatThreads.length === 0}
    <div class="fixed inset-0 flex items-center justify-center top-24">
      <div class="text-center p-8 -mt-24">
        <div
          class="w-16 h-16 mx-auto mb-6 rounded-full bg-purple-100 flex items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-8 w-8 text-purple-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
            />
          </svg>
        </div>
        <h3 class="text-xl font-semibold text-gray-900 mb-2">
          No conversations yet
        </h3>
        <p class="text-gray-500 max-w-sm">
          When visitors start chatting, their messages will appear here.
        </p>
      </div>
    </div>
  {/if}
</div>
