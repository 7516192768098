<script>
  import Page from "$app-components/blocks/_layouts/Page.svelte";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;
  export let blockClicked = function () {};
  export let blockViewed = function () {};
  export let onActualPage;

  function getTypeformEmbedUrl(url) {
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:[^.]+\.)?typeform\.com\/to\/([a-zA-Z0-9]+)/
    );
    if (match) {
      const id = match[1];
      return `https://form.typeform.com/to/${id}`;
    }
    return null;
  }

  $: typeformEmbedUrl = getTypeformEmbedUrl(block.url);
  $: directRedirect = !typeformEmbedUrl;
</script>

<Page
  {block}
  {theme}
  {buttonStyle}
  {hoverEffect}
  {blockClicked}
  {blockViewed}
  iframeUrl={typeformEmbedUrl}
  goToButtonText="Open Typeform"
  width="90vw"
  height="90vh"
  {directRedirect}
  {onActualPage}
/>
