<script>
  import { createEventDispatcher } from "svelte";

  export let id = "";
  export let checked = false;
  export let disabled = false;
  export let label = "";

  const SLOTS = $$props.$$slots || {};
  const dispatch = createEventDispatcher();

  function toggle() {
    if (!disabled) {
      checked = !checked;
      dispatch("change", checked);
    }
  }
</script>

<label
  class="relative inline-flex items-center cursor-pointer"
  on:click|preventDefault|stopPropagation={toggle}
>
  <input
    type="checkbox"
    {id}
    bind:checked
    {disabled}
    on:change|preventDefault|stopPropagation={toggle}
    class="sr-only peer"
  />
  <div
    class="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-500"
  ></div>

  {#if (label && label !== "") || SLOTS.label}
    <span class="ml-3 text-sm">
      <slot name="label">{label}</slot>
    </span>
  {/if}
</label>
