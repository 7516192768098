<script>
  import Design from "./_bottom_actions/Design.svelte";
  import Prioritize from "./_bottom_actions/Priortize.svelte";
  import Lock from "./_bottom_actions/Lock.svelte";
  import Schedule from "./_bottom_actions/Schedule.svelte";
  import Redirect from "./_bottom_actions/Redirect.svelte";
  import Analytics from "./_bottom_actions/Analytics.svelte";
  import Data from "./_bottom_actions/Data.svelte";
  export let block;
  export let updater;
</script>

<div class="flex items-center px-6 py-2 gap-3 bg-gray-50">
  <Data {block} />
  <Design {block} {updater} />
  <Redirect {block} {updater} />
  <Prioritize {block} {updater} />
  <Analytics {block} {updater} />
  <Lock {block} {updater} />
  <Schedule {block} {updater} />
</div>
