<script>
  import { createEventDispatcher } from "svelte";
  import Modal from "$components/Modal.svelte";
  import Input from "$components/Input.svelte";

  import { notify } from "$utils/notify";
  import { validateErrors } from "$utils/form";
  import Ajax from "$utils/ajax";

  export let isOpen = false;
  export let username;
  export let linktreeEmail;

  let email = linktreeEmail;
  let password = "";
  let errors = {};

  const dispatch = createEventDispatcher();

  function handleClose() {
    isOpen = false;
    dispatch("close");
  }

  function handleInput(field) {
    return () => {
      if (errors[field]) {
        delete errors[field];
        errors = errors;
      }
    };
  }

  async function handleSubmit() {
    errors = {};

    if (!email || email === "") {
      errors.email = "is required";
    }

    if (!password || password === "") {
      errors.password = "is required";
    }

    if (Object.keys(errors).length === 0) {
      const [ajaxErrors, resp] = await Ajax.post("/register", {
        email,
        password,
        username,
      });

      if (ajaxErrors) {
        errors = validateErrors(
          errors,
          { email, password, username },
          ajaxErrors
        );
      } else {
        dispatch("account-created");
      }
    }
  }

  function handleKeydown(e) {
    if (e.detail.event.key === "Enter") {
      handleSubmit();
    }
  }
</script>

<Modal {isOpen} on:close={handleClose} size="md">
  <div slot="header">
    <h3 class="text-2xl font-bold text-gray-900">Almost there!</h3>
    <p class="mt-3 text-sm text-gray-600 leading-6 mb-6">
      Just enter your email and password to import your Linktree profile.
    </p>
    <div class="w-full h-px bg-gray-200"></div>
  </div>

  <div class="mt-6 space-y-6">
    <Input
      type="email"
      name="email"
      label="Email address"
      placeholder="you@example.com"
      required
      bind:value={email}
      error={errors.email}
      on:input={handleInput("email")}
      on:keydown={handleKeydown}
    />

    <Input
      type="password"
      name="password"
      label="Password"
      placeholder="Enter your password"
      required
      bind:value={password}
      error={errors.password}
      hint="Password must be at least 3 characters long"
      on:input={handleInput("password")}
      on:keydown={handleKeydown}
    />

    <div class="flex flex-col space-y-4">
      <button
        on:click={handleSubmit}
        class="w-full mt-3 px-4 py-3 text-white font-medium bg-gradient-to-r from-purple-600 to-indigo-600 rounded-lg hover:from-purple-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-colors duration-300"
      >
        Import Linktree
      </button>
    </div>
  </div>
</Modal>
