<script>
  import Page from "$app-components/blocks/_layouts/Page.svelte";

  import { notify } from "$utils/notify";
  import Ajax from "$utils/ajax";
  import { validateField } from "./_validation";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;
  export let blockClicked = function () {};
  export let blockViewed = function () {};
  export let onActualPage;

  let showForm = true;
  let formData = {};
  let submitting = false;
  let errors = {};

  let hideModal;

  $: formFields = block?.fields?.form_fields || [];

  async function handleSubmit(e) {
    e.preventDefault();
    if (submitting) return;

    submitting = true;
    errors = {};

    // Validate all fields
    let hasErrors = false;

    formFields.forEach((field) => {
      const error = validateField(field, formData[field.name]);
      if (error) {
        errors[field.name] = error;
        hasErrors = true;
      }
    });

    if (hasErrors) {
      submitting = false;
      return;
    }

    const [ajaxErrors, response] = await Ajax.post(`/api/form_responses`, {
      block_id: block.id,
      data: formData,
    });

    if (ajaxErrors) {
      errors = ajaxErrors;
      notify({
        message:
          "Failed to submit form. Please check the errors and try again.",
        type: "error",
      });
    } else {
      showForm = false;
    }

    submitting = false;
  }
</script>

<Page
  {block}
  {theme}
  {buttonStyle}
  {hoverEffect}
  useIframe={false}
  {blockClicked}
  {blockViewed}
  {onActualPage}
  modalSize="xl"
  bind:hideModal
>
  <div slot="modal-content">
    {#if showForm}
      <h2 class="text-xl text-black font-medium mb-8">
        {block.title}
      </h2>

      {#if block.fields?.description}
        <p
          class="text-gray-600 leading-relaxed mb-8 text-sm max-w-2xl mx-auto bg-slate-50 p-6 rounded-xl shadow-sm border border-purple-100 font-medium animate-fade-in"
        >
          {block.fields.description}
        </p>
      {/if}

      <form on:submit|preventDefault={handleSubmit} class="w-full">
        <div class="space-y-6">
          {#each formFields as field (field.name)}
            <div>
              {#if field.type === "text"}
                <div class="form-control">
                  <label
                    class="block text-sm font-semibold text-gray-700 mb-2"
                    for={field.name}
                  >
                    {field.label}
                  </label>
                  <input
                    id={field.name}
                    type="text"
                    bind:value={formData[field.name]}
                    required={field.validations?.includes("required")}
                    class="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-purple-500/20 focus:border-purple-500 transition duration-200 text-black"
                  />
                  {#if errors[field.name]}
                    <p class="text-red-500 text-sm mt-2">
                      {errors[field.name]}
                    </p>
                  {:else if field.placeholder}
                    <p class="text-gray-500 text-sm mt-2">
                      {field.placeholder}
                    </p>
                  {/if}
                </div>
              {:else if field.type === "email"}
                <div class="form-control">
                  <label
                    class="block text-sm font-semibold text-gray-700 mb-2"
                    for={field.name}
                  >
                    {field.label}
                  </label>
                  <input
                    id={field.name}
                    type="email"
                    bind:value={formData[field.name]}
                    required={field.validations?.includes("required")}
                    class="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-purple-500/20 focus:border-purple-500 transition duration-200 text-black"
                  />
                  {#if errors[field.name]}
                    <p class="text-red-500 text-sm mt-2">
                      {errors[field.name]}
                    </p>
                  {:else if field.placeholder}
                    <p class="text-gray-500 text-sm mt-2">
                      {field.placeholder}
                    </p>
                  {/if}
                </div>
              {:else if field.type === "tel"}
                <div class="form-control">
                  <label
                    class="block text-sm font-semibold text-gray-700 mb-2"
                    for={field.name}
                  >
                    {field.label}
                  </label>
                  <input
                    id={field.name}
                    type="tel"
                    bind:value={formData[field.name]}
                    required={field.validations?.includes("required")}
                    class="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-purple-500/20 focus:border-purple-500 transition duration-200 text-black"
                  />
                  {#if errors[field.name]}
                    <p class="text-red-500 text-sm mt-2">
                      {errors[field.name]}
                    </p>
                  {:else if field.placeholder}
                    <p class="text-gray-500 text-sm mt-2">
                      {field.placeholder}
                    </p>
                  {/if}
                </div>
              {:else if field.type === "number"}
                <div class="form-control">
                  <label
                    class="block text-sm font-semibold text-gray-700 mb-2"
                    for={field.name}
                  >
                    {field.label}
                  </label>
                  <input
                    id={field.name}
                    type="number"
                    bind:value={formData[field.name]}
                    required={field.validations?.includes("required")}
                    class="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-purple-500/20 focus:border-purple-500 transition duration-200 text-black"
                  />
                  {#if errors[field.name]}
                    <p class="text-red-500 text-sm mt-2">
                      {errors[field.name]}
                    </p>
                  {:else if field.placeholder}
                    <p class="text-gray-500 text-sm mt-2">
                      {field.placeholder}
                    </p>
                  {/if}
                </div>
              {:else if field.type === "url"}
                <div class="form-control">
                  <label
                    class="block text-sm font-semibold text-gray-700 mb-2"
                    for={field.name}
                  >
                    {field.label}
                  </label>
                  <input
                    id={field.name}
                    type="url"
                    bind:value={formData[field.name]}
                    required={field.validations?.includes("required")}
                    class="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-purple-500/20 focus:border-purple-500 transition duration-200 text-black"
                  />
                  {#if errors[field.name]}
                    <p class="text-red-500 text-sm mt-2">
                      {errors[field.name]}
                    </p>
                  {:else if field.placeholder}
                    <p class="text-gray-500 text-sm mt-2">
                      {field.placeholder}
                    </p>
                  {/if}
                </div>
              {:else if field.type === "date"}
                <div class="form-control">
                  <label
                    class="block text-sm font-semibold text-gray-700 mb-2"
                    for={field.name}
                  >
                    {field.label}
                  </label>
                  <input
                    id={field.name}
                    type="date"
                    bind:value={formData[field.name]}
                    required={field.validations?.includes("required")}
                    class="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-purple-500/20 focus:border-purple-500 transition duration-200 text-black"
                  />
                  {#if errors[field.name]}
                    <p class="text-red-500 text-sm mt-2">
                      {errors[field.name]}
                    </p>
                  {:else if field.placeholder}
                    <p class="text-gray-500 text-sm mt-2">
                      {field.placeholder}
                    </p>
                  {/if}
                </div>
              {:else if field.type === "textarea"}
                <div class="form-control">
                  <label
                    class="block text-sm font-semibold text-gray-700 mb-2"
                    for={field.name}
                  >
                    {field.label}
                  </label>
                  <textarea
                    id={field.name}
                    bind:value={formData[field.name]}
                    required={field.validations?.includes("required")}
                    rows="4"
                    class="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-purple-500/20 focus:border-purple-500 transition duration-200 text-black"
                  ></textarea>
                  {#if errors[field.name]}
                    <p class="text-red-500 text-sm mt-2">
                      {errors[field.name]}
                    </p>
                  {:else if field.placeholder}
                    <p class="text-gray-500 text-sm mt-2">
                      {field.placeholder}
                    </p>
                  {/if}
                </div>
              {:else if field.type === "select"}
                <div class="form-control">
                  <label class="block text-sm font-semibold text-gray-700 mb-2">
                    {field.label}
                  </label>
                  <div class="relative">
                    <select
                      class="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-purple-500/20 focus:border-purple-500 transition duration-200 bg-white appearance-none text-black"
                      bind:value={formData[field.name]}
                    >
                      <option value="">Select an option</option>
                      {#each field.options as option}
                        <option value={option.label}>{option.label}</option>
                      {/each}
                    </select>
                  </div>
                  {#if errors[field.name]}
                    <p
                      class="text-red-500 text-sm mt-2 flex items-center gap-1"
                    >
                      <svg
                        class="w-4 h-4"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      {errors[field.name]}
                    </p>
                  {:else if field.placeholder}
                    <p class="text-gray-500 text-sm mt-2">
                      {field.placeholder}
                    </p>
                  {/if}
                </div>
              {:else if field.type === "checkbox"}
                <div class="form-control">
                  <label class="block text-sm font-semibold text-gray-700 mb-3">
                    {field.label}
                  </label>
                  <div class="space-y-1">
                    {#each field.options as option}
                      <label
                        class="flex items-center gap-3 cursor-pointer group p-1.5 rounded-lg hover:bg-purple-50 transition duration-200"
                      >
                        <input
                          type="checkbox"
                          bind:group={formData[field.name]}
                          value={option.label}
                          class="w-5 h-5 rounded border-gray-300 text-purple-600 focus:ring-purple-500 transition duration-200"
                        />
                        <span
                          class="text-gray-700 group-hover:text-purple-700 transition duration-200"
                          >{option.label}</span
                        >
                      </label>
                    {/each}
                  </div>
                  {#if errors[field.name]}
                    <p
                      class="text-red-500 text-sm mt-2 flex items-center gap-1"
                    >
                      <svg
                        class="w-4 h-4"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      {errors[field.name]}
                    </p>
                  {:else if field.placeholder}
                    <p class="text-gray-500 text-sm mt-2">
                      {field.placeholder}
                    </p>
                  {/if}
                </div>
              {:else if field.type === "radio"}
                <div class="form-control">
                  <label class="block text-sm font-semibold text-gray-700 mb-3"
                    >{field.label}</label
                  >
                  <div class="space-y-1">
                    {#each field.options as option}
                      <label
                        class="flex items-center gap-3 cursor-pointer group p-1.5 rounded-lg hover:bg-purple-50 transition duration-200"
                      >
                        <input
                          type="radio"
                          bind:group={formData[field.name]}
                          value={option.label}
                          class="w-5 h-5 border-gray-300 text-purple-600 focus:ring-purple-500 transition duration-200"
                        />
                        <span
                          class="text-gray-700 group-hover:text-purple-700 transition duration-200"
                          >{option.label}</span
                        >
                      </label>
                    {/each}
                  </div>
                  {#if errors[field.name]}
                    <p
                      class="text-red-500 text-sm mt-2 flex items-center gap-1"
                    >
                      <svg
                        class="w-4 h-4"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      {errors[field.name]}
                    </p>
                  {:else if field.placeholder}
                    <p class="text-gray-500 text-sm mt-2">
                      {field.placeholder}
                    </p>
                  {/if}
                </div>
              {/if}
            </div>
          {/each}

          <button
            type="submit"
            class="w-full !mt-12 py-4 text-md font-medium rounded-xl shadow-lg bg-gradient-to-r from-purple-600 to-indigo-600 text-white hover:from-purple-700 hover:to-indigo-700 transform hover:scale-[1.02] hover:shadow-xl transition-all duration-300 disabled:opacity-70 disabled:cursor-not-allowed disabled:hover:scale-100"
            disabled={submitting}
          >
            {#if submitting}
              <span class="flex items-center justify-center">
                <svg
                  class="w-5 h-5 animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            {:else}
              <span class="flex items-center justify-center gap-2">
                {block.fields?.submit_button_text || "Submit"}
              </span>
            {/if}
          </button>
        </div>
      </form>
    {:else}
      <div class="text-center py-16 px-4 animate-fade-in">
        <div class="mb-8">
          <svg
            class="w-20 h-20 mx-auto text-green-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
        </div>
        <p class="text-xl text-gray-600 max-w-md mx-auto text-black">
          {block.fields?.thank_you_message || "Thank you for your submission!"}
        </p>
      </div>
    {/if}
  </div>
</Page>
