<script>
  import { createEventDispatcher } from "svelte";
  import Button from "$components/Button.svelte";
  import Form from "$components/Form.svelte";
  import Input from "$components/Input.svelte";
  import Ajax from "$utils/ajax";
  import { onMount } from "svelte";

  const dispatch = createEventDispatcher();

  export let block = null;

  let saving = false;
  let address = block?.title || "";
  let errors = {};
  let autocomplete;
  let addressInput;
  let mapsLoaded = false;
  let height = block?.fields?.height || "300px";
  let heightPx = height === "auto" ? 300 : parseInt(height); // Default pixel value when auto

  function initAutocomplete() {
    // Wait for both the input element and Google Maps to be ready
    if (!addressInput || !window.google?.maps) return;

    // Create the autocomplete instance only if it hasn't been created yet
    if (!autocomplete) {
      autocomplete = new google.maps.places.Autocomplete(addressInput, {
        types: ["address"],
      });

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        if (place.formatted_address) {
          address = place.formatted_address;
        }
      });
    }
  }

  onMount(() => {
    // If Google Maps is already loaded
    if (window.google?.maps) {
      mapsLoaded = true;
      initAutocomplete();
    } else {
      // If Google Maps needs to be loaded
      window.initMap = () => {
        mapsLoaded = true;
        // Add a small delay to ensure DOM is ready
        setTimeout(initAutocomplete, 100);
      };
    }
  });

  const saveMap = async (event) => {
    event.preventDefault();
    if (saving) return;
    saving = true;

    const [ajaxErrors, resp] = block
      ? await Ajax.put(`/dashboard/blocks/${block.id}`, {
          fields: { height: height },
          block_type: "map",
          title: address,
        })
      : await Ajax.post("/dashboard/blocks", {
          fields: { height: height },
          block_type: "map",
          title: address,
        });

    if (ajaxErrors) {
      errors = ajaxErrors;
    } else {
      if (block) {
        dispatch("edited-block", resp);
      } else {
        dispatch("added-block", resp);
      }

      dispatch("close");
    }

    saving = false;
  };

  function handleHeightChange(e) {
    heightPx = parseInt(e.target.value);
    height = heightPx + "px";
  }
</script>

<svelte:head>
  <script
    async
    src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDfkg15HWH32GHdBPsVzP-dTl8bSGvF7BU&loading=async&libraries=places&callback=initMap"
  >
  </script>
</svelte:head>

<h2 class="text-xl font-bold text-gray-800 mb-12 text-center">
  {block ? "Edit" : "Add"} Map
</h2>

<Form>
  <div class="flex flex-col gap-6 max-w-lg mx-auto">
    <Input
      type="text"
      bind:value={address}
      bind:self={addressInput}
      placeholder="Enter location address"
      required
      error={errors.title}
      klass="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-purple-300 transition duration-200 ease-in-out !text-lg"
    />

    <div class="space-y-2 mt-4">
      <div class="flex justify-between items-center">
        <label class="text-sm font-medium text-gray-700">Map Height</label>
        <span class="text-sm text-gray-500">{heightPx}px</span>
      </div>
      <div class="relative">
        <input
          type="range"
          min="100"
          max="500"
          step="5"
          value={heightPx}
          on:input={handleHeightChange}
          class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-purple-500"
        />
        <div
          class="absolute -top-2 left-0 right-0 flex justify-between text-xs text-gray-400 px-1"
        >
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <Button
        type="submit"
        klass="w-full h-12"
        disabled={saving}
        on:click={saveMap}
      >
        {#if saving}
          <span class="inline-block animate-spin mr-2">&#8635;</span>
        {/if}
        {block ? "Update" : "Add"} Map
      </Button>
    </div>
  </div>
</Form>
