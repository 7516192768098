<script>
  import { createEventDispatcher } from "svelte";
  import { router } from "@inertiajs/svelte";

  export let to = "";
  export let method = "get";
  export let replace = false;
  export let klass = "";
  export let variant = "default";

  const dispatch = createEventDispatcher();

  function handleClick(event) {
    event.preventDefault();

    if (to) {
      router.visit(to, { method, replace });
    } else {
      dispatch("click", { method, replace });
    }
  }

  const variantClasses = {
    default: "text-purple-600 hover:text-purple-800 font-semibold",
    primary: "text-blue-600 hover:text-blue-800 font-semibold",
    secondary: "text-gray-600 hover:text-gray-800 font-semibold",
    success: "text-green-600 hover:text-green-800 font-semibold",
    danger: "text-red-600 hover:text-red-800 font-semibold",
  };

  $: classes = `${variantClasses[variant]} ${klass}`;
</script>

<a href={to} class={classes} on:click={handleClick}>
  <slot></slot>
</a>
