<script>
  import { createEventDispatcher } from "svelte";

  export let type = "button";
  export let disabled = false;
  export let variant = "primary";
  export let size = "medium";
  export let fullWidth = false;
  export let klass = "";
  export let enableAnimation = false;

  const dispatch = createEventDispatcher();

  const baseClasses =
    "inline-flex items-center justify-center rounded-md font-semibold shadow-sm focus:outline-none transition-all duration-100 active:scale-98";

  const variantClasses = {
    primary: "bg-purple-600 text-white hover:bg-purple-700",
    secondary: "bg-white text-gray-700 hover:bg-gray-50 border border-gray-300",
    danger: "bg-red-600 text-white hover:bg-red-700",
  };

  const sizeClasses = {
    small: "px-2.5 py-1.5 text-xs",
    medium: "px-4 py-2 text-sm",
    large: "px-6 py-3 text-base",
  };

  $: classes = `
    ${baseClasses}
    ${variantClasses[variant]}
    ${sizeClasses[size]}
    ${fullWidth ? "w-full" : ""}
    ${disabled ? "opacity-50 cursor-not-allowed" : ""}
    ${klass}
  `;

  function handleClick(event) {
    if (!disabled && enableAnimation) {
      event.target.classList.add("animate-click");
      setTimeout(() => {
        event.target.classList.remove("animate-click");
      }, 200);
    }
    dispatch("click", event);
  }

  function handleMouseEnter(event) {
    dispatch("mouseenter", event);
  }

  function handleMouseLeave(event) {
    dispatch("mouseleave", event);
  }

  function handleFocus(event) {
    dispatch("focus", event);
  }

  function handleBlur(event) {
    dispatch("blur", event);
  }
</script>

<button
  {type}
  {disabled}
  class={classes}
  on:click={handleClick}
  on:mouseenter={handleMouseEnter}
  on:mouseleave={handleMouseLeave}
  on:focus={handleFocus}
  on:blur={handleBlur}
>
  <slot>Button</slot>
</button>

<style>
  @keyframes click {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.98);
    }
  }
  :global(.animate-click) {
    animation: click 200ms ease-in-out;
  }
</style>
