<script>
  import { onMount } from "svelte";
  import Ajax from "$utils/ajax";

  import Dashboard from "$layouts/Dashboard.svelte";

  import Lifetime from "./analytics/Lifetime.svelte";
  import TopBlocks from "./analytics/TopBlocks.svelte";
  import Locations from "./analytics/Locations.svelte";
  import Devices from "./analytics/Devices.svelte";
  import SocialLinks from "./analytics/SocialLinks.svelte";
  import Realtime from "./analytics/Realtime.svelte";
  import Referrers from "./analytics/Referrers.svelte";
  import BrowserBreakdown from "./analytics/BrowserBreakdown.svelte";
  import MonthlyStats from "./analytics/MonthlyStats.svelte";

  import "chartkick/chart.js";

  export let page;
  export let lifetimeStats;
  export let dailyViewsChart;
  export let dailyClicksChart;
  export let permissions;
  export let currentUser;

  let parsedCharts = {};
  let topBlocks = [];
  let locations = [];
  let devices = [];
  let socialLinks = [];
  let referrers = [];
  let browserBreakdown = [];
  let monthlyStats = {};
  let loading = false;

  let currentDate = new Date();
  let currentMonthName = currentDate.toLocaleString("default", {
    month: "long",
  });
  let currentYear = currentDate.getFullYear();

  async function fetchMonthlyData() {
    loading = true;
    const [errors, response] = await Ajax.post(
      "/dashboard/analytics/monthly_data",
      {
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear(),
      }
    );

    monthlyStats = response.monthlyStats;
    topBlocks = response.topBlocks;
    locations = response.locations;
    devices = response.devices;
    socialLinks = response.socialLinks;
    referrers = response.referrers;
    browserBreakdown = response.browserBreakdown;
    loading = false;
  }

  async function prevMonth() {
    currentDate.setMonth(currentDate.getMonth() - 1);
    currentDate = new Date(currentDate); // Trigger reactivity
    await fetchMonthlyData();
  }

  async function nextMonth() {
    const now = new Date();
    if (
      currentDate.getMonth() === now.getMonth() &&
      currentDate.getFullYear() === now.getFullYear()
    ) {
      return; // Don't allow going past current month
    }
    currentDate.setMonth(currentDate.getMonth() + 1);
    currentDate = new Date(currentDate); // Trigger reactivity
    await fetchMonthlyData();
  }

  $: currentMonthName = currentDate.toLocaleString("default", {
    month: "long",
  });
  $: currentYear = currentDate.getFullYear();
  $: isCurrentMonth =
    currentDate.getMonth() === new Date().getMonth() &&
    currentDate.getFullYear() === new Date().getFullYear();

  function parseChart(chartHtml) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(chartHtml, "text/html");

    return {
      html: doc.body.innerHTML,
      script: doc.querySelector("script")?.textContent || "",
    };
  }

  function executeChartScript(script) {
    const scriptElement = document.createElement("script");
    scriptElement.textContent = script;
    document.body.appendChild(scriptElement);
  }

  function drawCharts() {
    Object.values(parsedCharts).forEach((chart) => {
      if (chart.script) {
        executeChartScript(chart.script);
      }
    });
  }

  onMount(async () => {
    parsedCharts.dailyViewsChart = parseChart(dailyViewsChart);
    parsedCharts.dailyClicksChart = parseChart(dailyClicksChart);

    // Execute the scripts after the component has mounted and the elements exist
    setTimeout(drawCharts, 0);

    // Add event listener for window resize
    window.addEventListener("resize", drawCharts);

    // Fetch initial monthly data
    await fetchMonthlyData();

    // Clean up the event listener when the component is destroyed
    return () => {
      window.removeEventListener("resize", drawCharts);
    };
  });
</script>

<Dashboard {currentUser} {permissions} {page}>
  <div class="mb-10">
    <Lifetime {lifetimeStats} />
  </div>

  <div class="mb-10">
    <Realtime {page} />
  </div>

  <div class="mb-12">
    <h2 class="text-xl font-bold mb-4">Activity</h2>
    <div class="flex flex-col md:flex-row gap-4">
      <div class="flex-1 bg-white p-4 rounded-lg shadow">
        <h3 class="text-lg font-semibold mb-6">Daily Views</h3>
        {@html parsedCharts.dailyViewsChart?.html || ""}
      </div>

      <div class="flex-1 bg-white p-4 rounded-lg shadow">
        <h3 class="text-lg font-semibold mb-6">Daily Clicks</h3>
        {@html parsedCharts.dailyClicksChart?.html || ""}
      </div>
    </div>
  </div>

  <hr class="border-t-2 border-gray-200 my-14" />

  <div
    class="flex flex-col sm:flex-row items-center justify-between mb-12 gap-4"
  >
    <h2 class="text-xl font-bold">Monthly Statistics</h2>
    <div class="flex items-center bg-white rounded-lg shadow-sm p-1">
      <button
        on:click={prevMonth}
        disabled={loading}
        class="p-3 text-gray-600 hover:text-gray-900 disabled:opacity-50 hover:bg-gray-100 rounded"
      >
        <i class="fas fa-chevron-left"></i>
      </button>
      <span class="text-base sm:text-lg font-medium w-36 sm:w-44 text-center">
        {#if loading}
          <i class="fas fa-spinner fa-spin"></i>
        {:else}
          {currentMonthName} {currentYear}
        {/if}
      </span>
      <button
        on:click={nextMonth}
        disabled={isCurrentMonth || loading}
        class="p-3 text-gray-600 hover:text-gray-900 disabled:opacity-25 hover:bg-gray-100 rounded"
      >
        <i class="fas fa-chevron-right"></i>
      </button>
    </div>
  </div>

  <div class="mb-12">
    <h2 class="text-lg md:text-xl font-bold mb-4">Overview</h2>
    <MonthlyStats {monthlyStats} />
  </div>

  <div class="mb-12">
    <h2 class="text-lg md:text-xl font-bold mb-4">Top blocks</h2>
    <TopBlocks {topBlocks} {loading} />
  </div>

  <div class="mb-12">
    <h2 class="text-lg md:text-xl font-bold mb-4 flex items-center">
      Locations
    </h2>
    <Locations {locations} {loading} />
  </div>

  <div class="mb-12">
    <h2 class="text-lg md:text-xl font-bold mb-4">Devices</h2>
    <Devices {devices} {loading} />
  </div>

  <div class="mb-12">
    <h2 class="text-lg md:text-xl font-bold mb-4">Referrers</h2>
    <Referrers {referrers} {loading} />
  </div>

  <div class="mb-12">
    <h2 class="text-lg md:text-xl font-bold mb-4">Browsers</h2>
    <BrowserBreakdown {browserBreakdown} {loading} />
  </div>

  <div class="mb-12">
    <h2 class="text-lg md:text-xl font-bold mb-4">Social links</h2>
    <SocialLinks links={socialLinks} {loading} />
  </div>
</Dashboard>
