<script>
  import { createEventDispatcher } from "svelte";
  import Button from "$components/Button.svelte";
  import Input from "$components/Input.svelte";
  import Textarea from "$components/Textarea.svelte";
  import Image from "$components/Image.svelte";
  import { notify } from "$utils/notify";
  import Ajax from "$utils/ajax";
  import { currencies, formatCurrency } from "$utils/currency";
  import { showUploader } from "$utils/upload";
  import Sortable, {
    AutoScroll,
  } from "sortablejs/modular/sortable.core.esm.js";

  const dispatch = createEventDispatcher();

  export let block = null;

  let step = block ? 2 : 1;
  let productUrl = "";
  let productTitle = block?.title || "";
  let productDescription = block?.fields?.description || "";
  let productPrice = block?.fields?.price
    ? formatCurrency(block.fields.price, false)
    : "";
  let productCurrency = block?.fields?.currency || "USD";
  let productImages = block?.fields?.images || [];
  let buyLink = block?.url || "";
  let isLoading = false;
  let saving = false;
  let errors = {};

  let setupSortable = function (node) {
    try {
      Sortable.mount(new AutoScroll());
    } catch {}

    Sortable.create(node, {
      animation: 150,
      scroll: true,
      forceFallback: true,
      filter: ".remove-image-btn", // Ignore remove button for dragging
      onEnd: (evt) => {
        const newOrder = Array.from(node.children).map(
          (child) => child.dataset.src
        );

        productImages = [...newOrder];
      },
    });

    // Prevent text selection during drag
    node.addEventListener("mousedown", (e) => {
      if (!e.target.closest(".remove-image-btn")) {
        e.preventDefault();
      }
    });
  };

  async function importProduct() {
    isLoading = true;
    const [error, data] = await Ajax.get(
      `/dashboard/import/product_info?url=${encodeURIComponent(productUrl)}`
    );
    isLoading = false;

    if (error) {
      notify({
        type: "error",
        text: "Failed to import product. Please try again.",
      });
    } else {
      productTitle = data.title;
      productDescription = data.description;
      productPrice = data.price ? formatCurrency(data.price, false) : "";
      productCurrency = data.currency || "USD";
      productImages = data.image_urls || [];
      buyLink = productUrl;
      step = 2;
    }
  }

  async function addImageField() {
    try {
      const uploadedUrl = await showUploader({
        fileType: "image",
      });
      productImages = [...productImages, uploadedUrl];
    } catch (error) {
      notify({
        type: "error",
        text: "Failed to upload image. Please try again.",
      });
    }
  }

  function removeImageField(index, event) {
    event.preventDefault();
    event.stopPropagation();
    productImages = productImages.filter((_, i) => i !== index);
  }

  async function saveProduct(event) {
    event.preventDefault();
    if (saving) return;
    saving = true;

    let sharedData = {
      block_type: "product",
      title: productTitle,
      url: buyLink,
      fields: {
        description: productDescription,
        price: productPrice,
        currency: productCurrency,
        images: productImages,
      },
    };

    const [ajaxErrors, resp] = block
      ? await Ajax.put(`/dashboard/blocks/${block.id}`, sharedData)
      : await Ajax.post("/dashboard/blocks", sharedData);

    if (ajaxErrors) {
      errors = ajaxErrors;

      notify({
        type: "error",
        message:
          "Unable to save product. Please check the form for errors and try again.",
      });
    } else {
      if (block) {
        dispatch("edited-block", resp);
      } else {
        dispatch("added-block", resp);
      }

      dispatch("close");
    }

    saving = false;
  }

  function handleCancel() {
    dispatch("close");
  }
</script>

<div class="w-full max-w-3xl mx-auto sm:px-6">
  <h2 class="text-xl font-bold mb-8 sm:mb-12 mt-4 text-center text-gray-800">
    {block ? "Edit" : "Add"} Product
  </h2>

  {#if !block && step === 1}
    <div
      class="bg-white p-4 sm:p-6 rounded-xl shadow-sm border border-purple-100">
      <div
        class="flex flex-col sm:flex-row items-start sm:items-center mb-6 sm:mb-8 bg-purple-50 p-4 rounded-lg">
        <div class="mb-3 sm:mb-0 sm:mr-4 text-purple-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 sm:h-8 sm:w-8"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
        <p class="text-sm text-gray-700 leading-relaxed">
          Enter a product URL to auto-import details, or skip this step to add
          details manually.
        </p>
      </div>

      <Input
        label="Product URL"
        placeholder="https://..."
        bind:value={productUrl}
        klass="mb-6 sm:mb-8"
        error={errors.url} />

      <div class="flex flex-col sm:flex-row gap-3 sm:gap-4">
        <Button
          on:click={importProduct}
          variant="primary"
          klass="w-full sm:flex-1 py-3"
          disabled={isLoading}>
          {#if isLoading}
            <span class="inline-block animate-spin mr-2">&#8635;</span>
          {/if}
          {isLoading ? "Importing..." : "Import Product"}
        </Button>
        <Button
          on:click={() => (step = 2)}
          variant="secondary"
          klass="w-full sm:flex-1 py-3">
          Skip Import
        </Button>
      </div>
    </div>
  {:else}
    <div class="space-y-6 sm:space-y-8">
      <div
        class="bg-white p-4 sm:p-6 rounded-xl shadow-sm border border-gray-100">
        <h3 class="text-md font-semibold mb-4 sm:mb-6 text-gray-800">
          Basic Details
        </h3>
        <div class="space-y-4 sm:space-y-6">
          <Input
            label="Product Title"
            placeholder="Enter product title"
            bind:value={productTitle}
            error={errors.title} />

          <Textarea
            label="Product Description"
            placeholder="Describe your product"
            bind:value={productDescription}
            error={errors.description} />
        </div>
      </div>

      <div
        class="bg-white p-4 sm:p-6 rounded-xl shadow-sm border border-gray-100">
        <h3 class="text-md font-semibold mb-4 sm:mb-6 text-gray-800">
          Pricing
        </h3>
        <div class="flex flex-col sm:flex-row gap-4">
          <Input
            label="Product Price"
            type="number"
            placeholder="Enter product price"
            bind:value={productPrice}
            klass="w-full sm:flex-grow"
            error={errors.price} />
          <div class="w-full sm:w-1/3">
            <label class="block text-sm font-medium text-gray-700 mb-2"
              >Currency</label>
            <select
              class="w-full h-[42px] rounded-lg border-gray-300 focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
              bind:value={productCurrency}>
              {#each currencies as currency}
                <option value={currency.code}
                  >{currency.code} ({currency.symbol})</option>
              {/each}
            </select>
          </div>
        </div>
      </div>

      <div
        class="bg-white p-4 sm:p-6 rounded-xl shadow-sm border border-gray-100">
        <div
          class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 sm:mb-8">
          <h3 class="text-md font-semibold text-gray-800 mb-3 sm:mb-0">
            Product Images (drag to reorder)
          </h3>

          <Button
            on:click={addImageField}
            variant="secondary"
            klass="w-full sm:w-auto !py-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 4v16m8-8H4" />
            </svg>
            Add Image
          </Button>
        </div>

        <div
          use:setupSortable
          class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3">
          {#each productImages as image, index (image)}
            <div class="relative group" data-src={image}>
              <div
                class="aspect-square rounded-lg overflow-hidden border border-gray-200 cursor-move touch-none select-none">
                <Image
                  src={image}
                  alt="Product"
                  klass="w-full h-full object-cover pointer-events-none" />
              </div>
              <button
                on:click={(e) => removeImageField(index, e)}
                class="remove-image-btn absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1.5 shadow-lg hover:bg-red-600 transition-colors">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-3 w-3"
                  viewBox="0 0 20 20"
                  fill="currentColor">
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd" />
                </svg>
              </button>
            </div>
          {/each}
        </div>
      </div>

      <div
        class="bg-white p-4 sm:p-6 rounded-xl shadow-sm border border-gray-100">
        <h3 class="text-md font-semibold mb-4 sm:mb-6 text-gray-800">
          Purchase Link
        </h3>
        <Input
          label="Where can customers buy this product?"
          placeholder="https://..."
          bind:value={buyLink}
          error={errors.url} />
      </div>

      <div
        class="flex flex-col-reverse sm:flex-row justify-end gap-3 sm:gap-4 pt-4">
        <Button
          on:click={handleCancel}
          variant="secondary"
          klass="w-full sm:w-auto px-6">Cancel</Button>
        <Button
          on:click={saveProduct}
          variant="primary"
          disabled={saving}
          klass="w-full sm:w-auto px-6">
          {#if saving}
            <span class="inline-block animate-spin mr-2">&#8635;</span>
          {/if}
          {saving ? "Saving..." : block ? "Update Product" : "Create Product"}
        </Button>
      </div>
    </div>
  {/if}
</div>
