<script>
  import { createEventDispatcher } from "svelte";
  import Actions from "$app-components/blocks/Actions.svelte";
  import ViewBottomActions from "$app-components/blocks/ViewBottomActions.svelte";

  const dispatch = createEventDispatcher();

  export let block;
  export let updater;
</script>

<div class="flex flex-col bg-white rounded-lg shadow overflow-hidden">
  <div class="flex items-center justify-between p-4">
    <div class="flex items-center space-x-3 flex-grow overflow-hidden">
      <div class="cursor-move">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </div>
      <slot />
    </div>
    <Actions
      {block}
      {updater}
      on:delete-block={() => dispatch("delete-block")}
    />
  </div>

  <ViewBottomActions {block} {updater} />
</div>
