<script>
  import { onMount } from "svelte";
  import { fade, fly } from "svelte/transition";

  let messages = [
    {
      type: "received",
      text: "Hey! Just discovered your page. Love your latest collection! Quick question about the Moonstone Ring - does it come in size 7? 💍",
      avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=Aneka",
    },
    {
      type: "sent",
      text: "Hi! Thanks so much! 😊 Yes, the Moonstone Ring is available in size 7. It's one of our most popular pieces - the stone catches light beautifully and each one is unique.",
      avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=Felix",
    },
    {
      type: "received",
      text: "Perfect! I've been looking for something special for my sister's birthday",
      avatar: "https://api.dicebear.com/6.x/avataaars/svg?seed=Aneka",
    },
  ];

  let visibleMessages = [];
  let isTyping = false;
  let observer;

  onMount(() => {
    observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        startChat();
        observer.disconnect();
      }
    });

    observer.observe(document.querySelector("#chat-container"));

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  });

  function startChat() {
    messages.forEach((msg, i) => {
      setTimeout(
        () => {
          if (msg.type === "received") {
            isTyping = true;
            setTimeout(() => {
              isTyping = false;
              visibleMessages = [...visibleMessages, msg];
            }, 400);
          } else {
            visibleMessages = [...visibleMessages, msg];
          }
        },
        800 * (i + 1)
      );
    });
  }
</script>

<div
  id="chat-container"
  class="h-full min-h-[364px] w-full p-4 sm:p-4 flex flex-col text-xs sm:text-sm">
  <div class="flex-1 overflow-y-auto pb-4 space-y-3 sm:space-y-4 px-1 sm:px-2">
    {#each visibleMessages as message}
      <div
        class="flex items-end gap-1.5 sm:gap-2 {message.type === 'sent'
          ? 'justify-end'
          : 'justify-start'}"
        in:fly={{ y: 20, duration: 400 }}
        out:fade>
        {#if message.type === "received"}
          <img
            src={message.avatar}
            alt="Avatar"
            class="w-5 h-5 sm:w-6 sm:h-6 rounded-full" />
        {/if}

        <div
          class="{message.type === 'sent'
            ? 'bg-gradient-to-br from-purple-600 to-purple-700 text-white'
            : 'bg-white text-gray-800'} 
            rounded-2xl px-3 sm:px-4 py-1.5 sm:py-2 max-w-[85%] sm:max-w-[80%] shadow-md backdrop-blur-sm
            {message.type === 'received'
            ? 'rounded-bl-none'
            : 'rounded-br-none'}">
          {message.text}
        </div>

        {#if message.type === "sent"}
          <img
            src={message.avatar}
            alt="Avatar"
            class="w-5 h-5 sm:w-6 sm:h-6 rounded-full" />
        {/if}
      </div>
    {/each}

    {#if isTyping}
      <div class="flex items-center gap-1.5 sm:gap-2" in:fade>
        <img
          src="https://api.dicebear.com/6.x/avataaars/svg?seed=Felix"
          alt="Avatar"
          class="w-5 h-5 sm:w-6 sm:h-6 rounded-full" />
        <div
          class="bg-white rounded-2xl px-3 sm:px-4 py-1.5 sm:py-2 shadow-md rounded-bl-none">
          <div class="flex gap-1">
            <div
              class="w-1.5 h-1.5 sm:w-2 sm:h-2 rounded-full bg-gray-400 animate-bounce"
              style="animation-delay: 0ms">
            </div>
            <div
              class="w-1.5 h-1.5 sm:w-2 sm:h-2 rounded-full bg-gray-400 animate-bounce"
              style="animation-delay: 150ms">
            </div>
            <div
              class="w-1.5 h-1.5 sm:w-2 sm:h-2 rounded-full bg-gray-400 animate-bounce"
              style="animation-delay: 300ms">
            </div>
          </div>
        </div>
      </div>
    {/if}
  </div>

  <div class="mt-3 sm:mt-4 flex gap-1.5 sm:gap-2">
    <input
      type="text"
      placeholder="Type a message..."
      class="flex-1 rounded-full px-3 sm:px-4 py-1.5 sm:py-2 text-xs sm:text-sm bg-white/80 backdrop-blur-sm border border-purple-100 focus:outline-none focus:border-purple-400 focus:ring-2 focus:ring-purple-400/20 transition-all shadow-sm"
      disabled />
    <button
      class="w-8 h-8 sm:w-10 sm:h-10 rounded-full bg-gradient-to-br from-purple-600 to-purple-700 text-white flex items-center justify-center disabled:opacity-50 shadow-md hover:shadow-lg transform hover:scale-105 transition-all"
      disabled>
      <svg
        class="w-4 h-4 sm:w-5 sm:h-5"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5 12h14M12 5l7 7-7 7" />
      </svg>
    </button>
  </div>
</div>
