<script>
  import { slide } from "svelte/transition";

  let expandedQuestion = null;

  const questions = [
    {
      question: "What is BioInk?",
      answer:
        "<p>BioInk is a modern platform that allows you to create beautiful, customizable landing pages to showcase all your online content and links in one place.</p><p style='margin-top: 1rem;'>You can personalize the design, add your branding, organize your links into categories, and track engagement with built-in analytics. It's the perfect solution for creators, entrepreneurs, and professionals who want to create a stunning online presence.</p>",
    },
    {
      question: "Is BioInk free to use?",
      answer:
        "Yes, BioInk is currently completely free to use. We may in the future introduce a premium plan with additional features.",
    },
    {
      question: "Can I use my own domain with BioInk?",
      answer: "Absolutely! You can use any domain you want.",
    },
    {
      question: "How do I get started with BioInk?",
      answer:
        "Simply sign up for an account, choose a username, and start customizing your page. It's that easy!",
    },
    {
      question: "Can I change my BioInk design later?",
      answer:
        "Of course! You can update your BioInk design, links, and content anytime you want.",
    },
    {
      question: "How can I drive more traffic to my BioInk?",
      answer:
        "<p>There are several effective strategies to increase traffic to your BioInk:</p><ul style='list-style-type: disc; margin-left: 1.5rem; margin-top: 1rem;'><li>Share your BioInk link prominently in all your social media profiles</li><li>Include your BioInk URL in your email signatures and business cards</li><li>Create engaging content that encourages followers to visit your BioInk</li><li>Use relevant hashtags when promoting your BioInk on social platforms</li><li>Cross-promote your BioInk in your newsletters and blog posts</li><li>Collaborate with other creators to expand your reach</li><li>Optimize your BioInk with relevant keywords for better search visibility</li></ul>",
    },
    {
      question: "How many links should I have on my BioInk?",
      answer:
        "<p>While there's no strict limit, we recommend following these best practices:</p><ul style='list-style-type: disc; margin-left: 1.5rem; margin-top: 1rem;'><li>Keep your most important 5-7 links at the top</li><li>Organize additional links into clear categories if you have more</li><li>Focus on quality over quantity - each link should provide value</li><li>Regularly update and remove outdated links</li><li>Consider your audience's attention span - too many links can be overwhelming</li></ul><p style='margin-top: 1rem;'>The ideal number varies based on your needs, but aim to keep it focused and organized rather than including every possible link.</p>",
    },
    {
      question: "Do I need a website to use BioInk?",
      answer:
        "No, you don't need a website to use BioInk! BioInk provides you with a complete web presence without needing your own website. You get a customizable page that works like a mini-website where you can showcase all your content, links, and social media profiles in one place. It's perfect for creators and professionals who want an online presence without the complexity of building and maintaining a full website.",
    },
    {
      question: "Is my BioInk page mobile-friendly?",
      answer:
        "<p>Yes, absolutely! BioInk pages are fully responsive and optimized for all devices. Your page will look great and function perfectly whether visitors are viewing it on:</p><ul style='list-style-type: disc; margin-left: 1.5rem; margin-top: 1rem;'><li>Smartphones</li><li>Tablets</li><li>Desktop computers</li><li>Any screen size or device</li></ul>",
    },
    {
      question: "Can I see who visits my BioInk page?",
      answer:
        "<p>Yes! BioInk provides detailed analytics including:</p><ul style='list-style-type: disc; margin-left: 1.5rem; margin-top: 1rem;'><li>Number of page views</li><li>Click-through rates on your links</li><li>Visitor geographic locations</li><li>Traffic sources</li><li>Visit duration</li><li>Device types used</li></ul><p style='margin-top: 1rem;'>This data helps you understand your audience and optimize your page for better engagement.</p>",
    },
  ];
</script>

{#each questions as faq, i}
  <div
    class="bg-white border border-purple-100 rounded-lg md:rounded-xl shadow-sm">
    <button
      class="w-full px-4 md:px-8 py-3 md:py-5 text-left flex justify-between items-center"
      on:click={() => (expandedQuestion = expandedQuestion === i ? null : i)}>
      <h3 class="text-base md:text-lg font-semibold text-purple-900 pr-2">
        {@html faq.question}
      </h3>
      <div class="flex-shrink-0">
        <div
          class={`p-1.5 md:p-2 rounded-full ${expandedQuestion === i ? "bg-purple-100" : ""}`}>
          <svg
            class="w-4 h-4 md:w-5 md:h-5 text-purple-600 transform transition-transform duration-300 ease-in-out {expandedQuestion ===
            i
              ? 'rotate-180'
              : ''}"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </div>
    </button>
    {#if expandedQuestion === i}
      <div transition:slide={{ duration: 300 }}>
        <div class="h-px bg-purple-100 w-full"></div>
        <div
          class="px-4 md:px-8 py-4 md:py-6 text-purple-700 prose prose-purple max-w-none prose-p:leading-relaxed prose-li:text-purple-700">
          {@html faq.answer}
        </div>
      </div>
    {/if}
  </div>
{/each}
