export const validateErrors = (errors, data, resp) => {
  Object.keys(data).forEach((key) => {
    if (resp && resp[key]) {
      errors[key] = resp[key];
    } else {
      delete errors[key];
    }
  });

  return errors;
};

export const alertErrors = (errors) => {
  const errorMessages = Object.entries(errors)
    .map(
      ([key, value]) => {
        const formattedKey = key.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
        return `${formattedKey} ${value}`;
      }
    )
    .join(", ");

  return errorMessages;
};
