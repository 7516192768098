<script>
  import Image from "$components/Image.svelte";
  import { onMount } from "svelte";
  import { tweened } from "svelte/motion";
  import { cubicOut } from "svelte/easing";

  const themes = [
    {
      name: "Mystic Shadow",
      image: "/images/theme-backgrounds/mystic-shadow.jpg",
      color: "from-purple-500/80 to-indigo-900/90",
    },
    {
      name: "Soft Blush",
      image: "/images/theme-backgrounds/soft-blush.jpg",
      color: "from-pink-400/80 to-rose-900/90",
    },
    {
      name: "Night Bokeh",
      image: "/images/theme-backgrounds/night-bokeh.jpeg",
      color: "from-blue-500/80 to-slate-900/90",
    },
    {
      name: "Pastel Harmony",
      image: "/images/theme-backgrounds/pastel-harmony.jpg",
      color: "from-rose-300/80 to-pink-600/90",
    },
    {
      name: "Serene Mountains",
      image: "/images/theme-backgrounds/serene-mountains.jpg",
      color: "from-blue-400/80 to-indigo-800/90",
    },
    {
      name: "Soft Sand",
      image: "/images/theme-backgrounds/soft-sand.jpg",
      color: "from-amber-400/80 to-yellow-900/90",
    },
    {
      name: "Vintage Brown",
      image: "/images/theme-backgrounds/vintage-brown.jpg",
      color: "from-amber-700/80 to-yellow-900/90",
    },
  ];

  let currentIndex = 0;
  let hoveredIndex = null;
  let rotationProgress = tweened(0, {
    duration: 800,
    easing: cubicOut,
  });

  const nextTheme = () => {
    currentIndex = (currentIndex + 1) % themes.length;
    rotationProgress.set(currentIndex);
  };

  let interval;
  onMount(() => {
    interval = setInterval(nextTheme, 3000);
    return () => clearInterval(interval);
  });

  const handleMouseEnter = (index) => {
    hoveredIndex = index;
  };

  const handleMouseLeave = () => {
    hoveredIndex = null;
  };
</script>

<style>
  .carousel {
    perspective: 1500px;
    transform-style: preserve-3d;
  }

  .card {
    position: absolute;
    width: 280px;
    height: 400px;
    transition: all 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
    transform-style: preserve-3d;
    backface-visibility: hidden;
  }

  @media (max-width: 640px) {
    .card {
      width: 240px;
      height: 340px;
    }
  }

  .card-content {
    transform-style: preserve-3d;
    box-shadow: 0 15px 30px -8px rgba(0, 0, 0, 0.3);
  }

  .card:hover .theme-name {
    opacity: 1;
    transform: translateY(0);
  }
</style>

<div
  class="relative h-[500px] sm:h-[500px] w-full flex items-center justify-center">
  <div class="carousel relative w-[240px] sm:w-[280px] h-[340px] sm:h-[400px]">
    {#each themes as theme, i}
      {@const offset = (i - $rotationProgress + themes.length) % themes.length}
      {@const isHovered = hoveredIndex === i}
      <div
        class="card"
        on:mouseenter={() => handleMouseEnter(i)}
        on:mouseleave={handleMouseLeave}
        style="
          transform: translateX({Math.sin((offset * Math.PI) / 2) *
          (window.innerWidth < 640 ? 60 : 100)}px)
                   translateY({Math.cos((offset * Math.PI) / 2) *
          (window.innerWidth < 640 ? 30 : 50)}px)
                   translateZ({-offset * (window.innerWidth < 640 ? 30 : 50)}px)
                   rotate({offset * (window.innerWidth < 640 ? 10 : 15)}deg)
                   scale({isHovered ? 1.05 : 1});
          opacity: {1 - offset * 0.2};
          z-index: {themes.length - offset};
        ">
        <div
          class="card-content w-full h-full rounded-xl overflow-hidden relative group">
          <Image
            src={theme.image}
            alt="{theme.name} Theme"
            klass="absolute inset-0 w-full h-full object-cover transition-transform duration-500 group-hover:scale-105" />

          <div
            class="absolute inset-0 bg-gradient-to-t {theme.color} opacity-60 transition-opacity duration-300 group-hover:opacity-40">
          </div>

          <div class="absolute inset-0 flex flex-col justify-end p-4 sm:p-6">
            <div>
              <h3
                class="text-lg sm:text-xl font-bold text-white tracking-tight">
                {theme.name}
              </h3>
            </div>
          </div>
        </div>
      </div>
    {/each}
  </div>
</div>
