<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import Iphone from "$app-components/Iphone.svelte";
  import BlockViewer from "$app-components/BlockViewer.svelte";
  import ProfileHeader from "$app-components/ProfileHeader.svelte";

  let currentIndex = 0;
  let visible = true;

  const pages = [
    {
      theme: "mystic-shadow",
      hover_effect: "shadow",
      button_style: "hardshadow-rounded",
      layout_style: "list",
      font: "Montserrat",
      profile_image_url: "/images/user.png",
      social_links: [
        { url: "https://twitter.com", id: 1 },
        { url: "https://instagram.com", id: 2 },
        { url: "https://youtube.com", id: 3 },
      ],
      social_links_placement: "top",
      profile_name: "BioInk Creator",
      bio: "Welcome to my creative space!",
    },
    {
      theme: "minimal-arch",
      hover_effect: "scale",
      button_style: "softshadow-rounded",
      layout_style: "list",
      font: "Montserrat",
      profile_image_url: "/images/user.png",
      social_links: [
        { url: "https://linkedin.com", id: 1 },
        { url: "https://github.com", id: 2 },
      ],
      social_links_placement: "top",
      profile_name: "Developer Portfolio",
      bio: "Full Stack Developer",
    },
    {
      theme: "neon-mist",
      hover_effect: "glow",
      button_style: "default",
      layout_style: "grid",
      font: "Montserrat",
      profile_image_url: "/images/user.png",
      social_links: [
        { url: "https://twitch.tv", id: 1 },
        { url: "https://youtube.com", id: 2 },
      ],
      social_links_placement: "bottom",
      profile_name: "Gaming Profile",
      bio: "Pro Gamer & Content Creator",
    },
  ];

  const blockSets = [
    [
      {
        id: 1,
        position: 0,
        title: "Watch my Latest Video",
        url: "https://youtube.com/watch?v=dQw4w9WgXcQ",
        content: "",
        image_url: "",
        styles: {},
        fields: {},
        enabled: true,
        block_type: "youtube",
        data: {},
      },
      {
        id: 2,
        position: 1,
        title: "Connect on LinkedIn",
        url: "https://linkedin.com",
        content: "",
        image_url: "",
        styles: {},
        fields: {},
        enabled: true,
        block_type: "link",
        data: {},
      },
    ],
    [
      {
        id: 1,
        position: 0,
        title: "View My Projects",
        url: "https://github.com",
        content: "",
        image_url: "",
        styles: {},
        fields: {},
        enabled: true,
        block_type: "link",
        data: {},
      },
      {
        id: 2,
        position: 1,
        title: "Technical Blog",
        url: "https://dev.to",
        content: "",
        image_url: "",
        styles: {},
        fields: {},
        enabled: true,
        block_type: "link",
        data: {},
      },
      {
        id: 3,
        position: 2,
        title: "Download Resume",
        url: "https://resume.com",
        content: "",
        image_url: "",
        styles: {},
        fields: {},
        enabled: true,
        block_type: "link",
        data: {},
      },
    ],
    [
      {
        id: 1,
        position: 0,
        title: "Watch me Live on Twitch",
        url: "https://twitch.tv",
        content: "",
        image_url: "",
        styles: {},
        fields: {},
        enabled: true,
        block_type: "link",
        data: {},
      },
      {
        id: 2,
        position: 1,
        title: "Gaming Highlights",
        url: "https://youtube.com",
        content: "",
        image_url: "",
        styles: {},
        fields: {},
        enabled: true,
        block_type: "youtube",
        data: {},
      },
    ],
  ];

  onMount(() => {
    const interval = setInterval(() => {
      visible = false;

      setTimeout(() => {
        currentIndex = (currentIndex + 1) % pages.length;
        visible = true;
      }, 100);
    }, 5000);

    return () => clearInterval(interval);
  });
</script>

<div class="transition-opacity duration-100">
  <Iphone width="100%" height="720px" klass="bg-black">
    {#if visible}
      <div class="w-full h-full" transition:fade={{ duration: 100 }}>
        <BlockViewer
          blocks={blockSets[currentIndex]}
          page={pages[currentIndex]}>
          <svelte:fragment slot="header">
            <ProfileHeader page={pages[currentIndex]} />
          </svelte:fragment>
        </BlockViewer>
      </div>
    {/if}
  </Iphone>
</div>
