<script>
  import { createEventDispatcher } from "svelte";
  import Button from "$components/Button.svelte";
  import Input from "$components/Input.svelte";
  import Textarea from "$components/Textarea.svelte";
  import Form from "$components/Form.svelte";
  import Ajax from "$utils/ajax";
  import { slide } from "svelte/transition";

  const dispatch = createEventDispatcher();

  export let block = null;

  let saving = false;
  let errors = {};
  let activeSection = null;

  // Contact details state
  let title = block?.title || "Contact Me";

  let fields = {
    name: block?.fields?.name || "",
    title: block?.fields?.title || "",
    company: block?.fields?.company || "",
    emails: block?.fields?.emails || [{ type: "work", email: "" }],
    phones: block?.fields?.phones || [{ type: "cell", number: "" }],
    urls: block?.fields?.urls || [{ type: "website", url: "" }],
    notes: block?.fields?.notes || "",
  };

  const emailTypes = ["work", "personal", "other"];
  const phoneTypes = ["cell", "work", "home", "other"];

  const sections = [
    { id: "basic", label: "Basic Info", icon: "fa-user" },
    { id: "contact", label: "Contact Methods", icon: "fa-address-book" },
    { id: "links", label: "Online Presence", icon: "fa-globe" },
    { id: "additional", label: "Additional Info", icon: "fa-circle-info" },
  ];

  function toggleSection(sectionId) {
    activeSection = activeSection === sectionId ? null : sectionId;
  }

  function addEmail() {
    fields.emails = [...fields.emails, { type: "work", email: "" }];
  }

  function removeEmail(index) {
    fields.emails = fields.emails.filter((_, i) => i !== index);
  }

  function addPhone() {
    fields.phones = [...fields.phones, { type: "cell", number: "" }];
  }

  function removePhone(index) {
    fields.phones = fields.phones.filter((_, i) => i !== index);
  }

  function addUrl() {
    fields.urls = [...fields.urls, { type: "website", url: "" }];
  }

  function removeUrl(index) {
    fields.urls = fields.urls.filter((_, i) => i !== index);
  }

  const saveContactDetails = async (event) => {
    event.preventDefault();

    if (saving) return;
    saving = true;

    const data = {
      block_type: "contactinfo",
      title,
      fields: {
        name: fields.name,
        title: fields.title,
        company: fields.company,
        emails: fields.emails.filter((e) => e.email),
        phones: fields.phones.filter((p) => p.number),
        urls: fields.urls.filter((u) => u.url),
        notes: fields.notes,
      },
    };

    const [ajaxErrors, resp] = block
      ? await Ajax.put(`/dashboard/blocks/${block.id}`, data)
      : await Ajax.post("/dashboard/blocks", data);

    if (ajaxErrors) {
      errors = ajaxErrors;
    } else {
      if (!block) {
        title = "Contact Me";
        fields = {
          name: "",
          title: "",
          company: "",
          emails: [{ type: "work", email: "" }],
          phones: [{ type: "cell", number: "" }],
          urls: [{ type: "website", url: "" }],
          notes: "",
        };
      }

      if (block) {
        dispatch("edited-block", resp);
      } else {
        dispatch("added-block", resp);
      }
      dispatch("close");
    }

    saving = false;
  };
</script>

<div class="bg-white w-full max-w-2xl mx-auto p-0 mt-2 sm:mt-0 sm:p-6">
  <h2
    class="text-lg sm:text-xl font-bold text-gray-800 mb-4 sm:mb-8 text-center"
  >
    {block ? "Edit" : "Add"} Contact Details
  </h2>

  <Form>
    <div class="mt-8 sm:mt-0 mb-6 sm:mb-8">
      <Input
        label="Button Text"
        type="text"
        bind:value={title}
        placeholder="Contact Me"
        required
        error={errors.title}
        klass="text-base sm:text-lg"
      />
      <p class="text-xs sm:text-sm text-gray-500 mt-2">
        This text will appear on the button that opens your contact information
      </p>
    </div>

    <div class="space-y-3 sm:space-y-4">
      {#each sections as section}
        <div class="border border-gray-200 rounded-lg overflow-hidden">
          <button
            class="w-full px-3 sm:px-4 py-2.5 sm:py-3 flex items-center justify-between bg-gray-50 hover:bg-gray-100 transition-colors duration-200"
            on:click={() => toggleSection(section.id)}
          >
            <div class="flex items-center space-x-2 sm:space-x-3">
              <i class="fas {section.icon} text-purple-500 w-4 sm:w-5"></i>
              <span class="font-medium text-gray-700 text-sm sm:text-base"
                >{section.label}</span
              >
            </div>
            <i
              class="fas fa-chevron-{activeSection === section.id
                ? 'up'
                : 'down'} text-gray-400"
            ></i>
          </button>

          {#if activeSection === section.id}
            <div
              class="p-3 sm:p-4 space-y-3 sm:space-y-4 bg-white"
              transition:slide
            >
              {#if section.id === "basic"}
                <Input
                  label="Name"
                  type="text"
                  bind:value={fields.name}
                  placeholder="Full Name"
                  error={errors["fields.name"]}
                />
                <Input
                  label="Title"
                  type="text"
                  bind:value={fields.title}
                  placeholder="Job Title"
                  error={errors["fields.title"]}
                />
                <Input
                  label="Company"
                  type="text"
                  bind:value={fields.company}
                  placeholder="Company Name"
                  error={errors["fields.company"]}
                />
              {:else if section.id === "contact"}
                <div class="space-y-3 sm:space-y-4">
                  <div
                    class="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-2"
                  >
                    <label class="block text-sm font-medium text-gray-700"
                      >Email Addresses</label
                    >
                    <Button
                      type="button"
                      variant="secondary"
                      size="small"
                      on:click={addEmail}
                      klass="w-full sm:w-auto"
                    >
                      <i class="fas fa-plus mr-2"></i> Add Email
                    </Button>
                  </div>
                  {#each fields.emails as email, i}
                    <div class="flex flex-col sm:flex-row gap-2">
                      <div class="flex items-center gap-2 w-full sm:w-auto">
                        <select
                          bind:value={email.type}
                          class="w-full sm:!w-[120px] rounded-md border-gray-300 shadow-sm focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                        >
                          {#each emailTypes as type}
                            <option value={type}>{type}</option>
                          {/each}
                        </select>
                        <Button
                          type="button"
                          variant="secondary"
                          size="small"
                          on:click={() => removeEmail(i)}
                          klass="w-8 flex-shrink-0 flex items-center justify-center transition-colors duration-200 opacity-80 hover:opacity-100 sm:hidden"
                        >
                          <i class="fas fa-trash text-sm text-red-500"></i>
                        </Button>
                      </div>
                      <div class="flex items-center gap-2 flex-1">
                        <Input
                          type="email"
                          bind:value={email.email}
                          placeholder="Email address"
                          error={errors[`fields.emails.${i}.email`]}
                          klass="flex-1"
                        />
                        <Button
                          type="button"
                          variant="secondary"
                          size="small"
                          on:click={() => removeEmail(i)}
                          klass="hidden sm:flex w-8 h-8 items-center justify-center transition-colors duration-200 opacity-80 hover:opacity-100"
                        >
                          <i class="fas fa-trash text-sm text-red-500"></i>
                        </Button>
                      </div>
                    </div>
                  {/each}
                </div>

                <div class="space-y-3 sm:space-y-4 !mt-8">
                  <div
                    class="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-2"
                  >
                    <label class="block text-sm font-medium text-gray-700"
                      >Phone Numbers</label
                    >
                    <Button
                      type="button"
                      variant="secondary"
                      size="small"
                      on:click={addPhone}
                      klass="w-full sm:w-auto"
                    >
                      <i class="fas fa-plus mr-2"></i> Add Phone
                    </Button>
                  </div>
                  {#each fields.phones as phone, i}
                    <div class="flex flex-col sm:flex-row gap-2 items-center">
                      <div class="flex items-center gap-2 w-full sm:w-auto">
                        <select
                          bind:value={phone.type}
                          class="w-full sm:!w-[120px] rounded-md border-gray-300 shadow-sm focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                        >
                          {#each phoneTypes as type}
                            <option value={type}>{type}</option>
                          {/each}
                        </select>
                        <Button
                          type="button"
                          variant="secondary"
                          size="small"
                          on:click={() => removePhone(i)}
                          klass="w-8 flex-shrink-0 flex items-center justify-center transition-colors duration-200 opacity-80 hover:opacity-100 sm:hidden"
                        >
                          <i class="fas fa-trash text-sm text-red-500"></i>
                        </Button>
                      </div>
                      <Input
                        type="tel"
                        bind:value={phone.number}
                        placeholder="Phone number"
                        error={errors[`fields.phones.${i}.number`]}
                        klass="flex-1"
                      />
                      <Button
                        type="button"
                        variant="secondary"
                        size="small"
                        on:click={() => removePhone(i)}
                        klass="hidden sm:flex w-8 h-8 items-center justify-center transition-colors duration-200 opacity-80 hover:opacity-100"
                      >
                        <i class="fas fa-trash text-sm text-red-500"></i>
                      </Button>
                    </div>
                  {/each}
                </div>
              {:else if section.id === "links"}
                <div class="space-y-3 sm:space-y-4">
                  <div
                    class="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-2"
                  >
                    <label class="block text-sm font-medium text-gray-700"
                      >URLs</label
                    >
                    <Button
                      type="button"
                      variant="secondary"
                      size="small"
                      on:click={addUrl}
                      klass="w-full sm:w-auto px-4 text-sm"
                    >
                      <i class="fas fa-plus mr-2"></i> Add URL
                    </Button>
                  </div>
                  {#each fields.urls as url, i}
                    <div class="flex flex-col sm:flex-row gap-2 items-center">
                      <div class="flex w-full gap-2 items-center">
                        <Input
                          type="url"
                          bind:value={url.url}
                          placeholder="URL"
                          error={errors[`fields.urls.${i}.url`]}
                          klass="flex-1"
                        />
                        <Button
                          type="button"
                          variant="secondary"
                          size="small"
                          on:click={() => removeUrl(i)}
                          klass="w-8 h-8 flex-shrink-0 flex items-center justify-center transition-colors duration-200 opacity-80 hover:opacity-100 sm:hidden"
                        >
                          <i class="fas fa-trash text-sm text-red-500"></i>
                        </Button>
                      </div>
                      <Button
                        type="button"
                        variant="secondary"
                        size="small"
                        on:click={() => removeUrl(i)}
                        klass="hidden sm:flex w-8 items-center justify-center transition-colors duration-200 opacity-80 hover:opacity-100"
                      >
                        <i class="fas fa-trash text-sm text-red-500"></i>
                      </Button>
                    </div>
                  {/each}
                </div>
              {:else if section.id === "additional"}
                <Textarea
                  label="Notes"
                  type="textarea"
                  bind:value={fields.notes}
                  placeholder="Additional Notes"
                  error={errors["fields.notes"]}
                />
              {/if}
            </div>
          {/if}
        </div>
      {/each}
    </div>

    <div class="mt-6 sm:mt-8">
      <Button
        type="submit"
        klass="w-full py-3 sm:py-4 text-base font-medium"
        disabled={saving}
        on:click={saveContactDetails}
      >
        {#if saving}
          <span class="inline-block animate-spin mr-2">&#8635;</span>
        {/if}
        {block ? "Update" : "Add"} Contact Details
      </Button>
    </div>
  </Form>
</div>
