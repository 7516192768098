<script>
  import Modal from "$components/Modal.svelte";
  import Ajax from "$utils/ajax";
  import { notify } from "$utils/notify";
  import { onMount, onDestroy } from "svelte";

  export let block;
  export let updater;

  let isModalOpen = false;
  let isSaving = false;
  let expiresAt = new Date(Date.now() + 24 * 60 * 60 * 1000); // 24 hours from now
  let updatedExpiresAt;
  let timeLeft = "";
  let countdownInterval;

  const excludedTypes = [
    "text",
    "heading",
    "image",
    "form",
    "video",
    "map",
    "contactinfo",
  ];
  const MIN_EXPIRY_MINUTES = 5;

  onMount(() => {
    if (block.redirect_datetime_expires_at) {
      expiresAt = new Date(block.redirect_datetime_expires_at);
      updateCountdown();
      startCountdown();
    }
  });

  onDestroy(() => {
    if (countdownInterval) clearInterval(countdownInterval);
  });

  function startCountdown() {
    countdownInterval = setInterval(updateCountdown, 1000);
  }

  function updateCountdown() {
    const now = new Date();
    const diff = expiresAt instanceof Date ? expiresAt - now : 0;

    if (diff <= 0) {
      timeLeft = "Expired";
      clearInterval(countdownInterval);
      return;
    }

    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    timeLeft = `${hours}h ${minutes}m ${seconds}s`;
  }

  function formatDateTime(date) {
    if (!(date instanceof Date) || isNaN(date)) {
      return { date: "", time: "" };
    }

    return {
      date: date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      }),
      time: date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
      }),
    };
  }

  function isValidExpiryTime(date) {
    const now = new Date();
    const diffInMinutes = (date - now) / (1000 * 60);
    return diffInMinutes >= MIN_EXPIRY_MINUTES;
  }

  function updateAllBlocks(block) {
    updater(({ blocks }) => {
      return {
        blocks: blocks.map((b) => {
          if (b.id === block.id) {
            return block;
          }
          return {
            ...b,
            direct_redirect: undefined,
            redirect_datetime_expires_at: undefined,
          };
        }),
      };
    });
  }

  async function saveAndClose() {
    isSaving = true;

    try {
      if (!block.direct_redirect && !isValidExpiryTime(expiresAt)) {
        notify({
          type: "error",
          message: `Expiration time must be at least ${MIN_EXPIRY_MINUTES} minutes in the future`,
        });
        isSaving = false;
        return;
      }

      const [error] = await Ajax.put(
        `/dashboard/blocks/${block.id}/update_redirect`,
        {
          redirect_datetime_expires_at: block.direct_redirect
            ? null
            : expiresAt instanceof Date
              ? expiresAt.toISOString()
              : null,
        }
      );

      if (error) {
        notify({
          type: "error",
          message: error?.data?.[0] || "An error occurred. Please try again.",
        });
        return;
      }

      block.direct_redirect = !block.direct_redirect;
      block.redirect_datetime_expires_at = block.direct_redirect
        ? expiresAt instanceof Date
          ? expiresAt.toISOString()
          : null
        : null;

      if (block.direct_redirect) {
        startCountdown();
      } else {
        clearInterval(countdownInterval);
      }

      updateAllBlocks(block);
    } catch (err) {
      notify({
        type: "error",
        message: "An unexpected error occurred. Please try again.",
      });
    } finally {
      isSaving = false;
    }
  }

  async function updateExpiration() {
    if (!(updatedExpiresAt instanceof Date)) {
      notify({
        type: "error",
        message: "Please select a valid date and time",
      });
      return;
    }

    if (!isValidExpiryTime(updatedExpiresAt)) {
      notify({
        type: "error",
        message: `Expiration time must be at least ${MIN_EXPIRY_MINUTES} minutes in the future`,
      });
      return;
    }

    try {
      const [error] = await Ajax.put(
        `/dashboard/blocks/${block.id}/update_redirect`,
        {
          redirect_datetime_expires_at: updatedExpiresAt.toISOString(),
        }
      );

      if (error) {
        notify({
          type: "error",
          message: error?.data?.[0] || "An error occurred. Please try again.",
        });
        return;
      }

      block.redirect_datetime_expires_at = updatedExpiresAt.toISOString();
      expiresAt = updatedExpiresAt;
      updateCountdown();
      updateAllBlocks(block);

      notify({
        type: "success",
        message: "Expiration time updated successfully",
      });
    } catch (err) {
      notify({
        type: "error",
        message: "An unexpected error occurred. Please try again.",
      });
    }
  }

  function closeModal() {
    isModalOpen = false;
  }

  $: formattedExpiry = formatDateTime(expiresAt);
</script>

<Modal bind:isOpen={isModalOpen} size="md">
  <div slot="header">
    <h3 class="text-md sm:text-xl font-bold text-gray-900">Direct Redirect</h3>
  </div>

  <div class="mt-6">
    <div
      class="bg-gradient-to-br from-purple-50 to-indigo-50 rounded-lg p-6 mb-8 border border-purple-100 shadow-sm"
    >
      {#if !block.direct_redirect}
        <ul class="text-sm text-purple-700 space-y-3">
          <li class="flex items-start">
            <svg
              class="h-5 w-5 text-purple-400 mr-4 flex-shrink-0"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              />
            </svg>
            <span
              >Redirect link bypasses your BioInk and sends visitors directly to
              a chosen link for a set period</span
            >
          </li>
          <li class="flex items-start">
            <svg
              class="h-5 w-5 text-purple-400 mr-4 flex-shrink-0"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Only one link can be set as a redirect at a time</span>
          </li>
          <li class="flex items-start">
            <svg
              class="h-5 w-5 text-purple-400 mr-4 flex-shrink-0"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              />
            </svg>
            <span
              >Perfect for temporarily directing all traffic to a specific
              destination</span
            >
          </li>
        </ul>
      {:else}
        <div class="mt-4 space-y-4">
          <div class="bg-purple-100 rounded-md p-3 flex items-center">
            <svg
              class="h-5 w-5 text-purple-600 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clip-rule="evenodd"
              />
            </svg>
            <p class="text-sm text-purple-700 font-medium">
              This link is currently set as your direct redirect
            </p>
          </div>

          <div
            class="text-center py-8 bg-purple-50 rounded-lg shadow-sm border border-purple-100"
          >
            <div class="text-3xl font-bold text-purple-600 tracking-tight mb-2">
              {timeLeft}
            </div>
            <div class="text-sm text-gray-600 mb-1.5 font-semibold">
              {formattedExpiry.date} at {formattedExpiry.time}
            </div>
            <div
              class="text-xs uppercase tracking-wide text-purple-500 font-medium"
            >
              until redirect expires
            </div>
          </div>

          <div class="bg-white rounded-md p-4 border border-purple-200">
            <input
              type="datetime-local"
              class="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
              min={new Date().toISOString().slice(0, 16)}
              on:change={(e) => (updatedExpiresAt = new Date(e.target.value))}
            />
            <button
              on:click={updateExpiration}
              class="mt-2 w-full px-4 py-2 bg-purple-100 text-purple-700 rounded-md hover:bg-purple-200 transition-colors duration-200"
            >
              Update Expiration Time
            </button>
          </div>
        </div>
      {/if}
    </div>

    <div class="flex justify-center">
      <button
        class="relative px-6 py-3 rounded-lg border-2 transition-all duration-200 hover:shadow-md text-center
          {block.direct_redirect
          ? 'border-purple-500 bg-purple-50'
          : 'border-gray-200 hover:border-purple-300'}"
        on:click={saveAndClose}
        disabled={isSaving}
      >
        <span class="text-lg font-medium text-gray-900">
          {block.direct_redirect ? "Disable" : "Enable"} Redirect
        </span>
      </button>
    </div>

    <div
      class="flex sm:hidden mt-6 sm:mt-8 justify-end space-x-3 border-t pt-4 sm:pt-6"
    >
      <button
        type="button"
        class="text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none"
        on:click={closeModal}
        disabled={isSaving}
      >
        Cancel
      </button>
    </div>
  </div>
</Modal>

{#if excludedTypes.includes(block.block_type) === false}
  <button
    on:click={() => (isModalOpen = true)}
    class="{block.direct_redirect
      ? 'text-purple-500'
      : 'text-gray-400'} hover:text-purple-500 transition-colors duration-200 ease-in-out relative group mr-4"
    title="Redirect"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-4 w-4"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      shape-rendering="geometricPrecision"
    >
      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
      <polyline points="15 3 21 3 21 9" />
      <line x1="10" y1="14" x2="21" y2="3" />
    </svg>
    <span
      class="absolute bottom-[140%] left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
    >
      Redirect
    </span>
  </button>
{/if}
