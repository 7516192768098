import { UAParser } from "ua-parser-js";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

let FINGERPRINT;

const initializeFingerprint = async () => {
  FINGERPRINT = await FingerprintJS.load();
};

const getFingerprint = async () => {
  if (!FINGERPRINT) {
    await initializeFingerprint();
  }

  try {
    const result = await FINGERPRINT.get();
    return result.visitorId;
  } catch (error) {
    return null;
  }
};

const getDeviceInfo = () => {
  const parser = new UAParser();

  return {
    browser: parser.getBrowser(),
    device: parser.getDevice(),
    os: parser.getOS(),
    ua: parser.getUA(),
  };
};

const sendEvent = async (eventType, data) => {
  try {
    const deviceInfo = getDeviceInfo();
    const fingerprint = await getFingerprint();

    const response = await fetch("/api/events", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({
        e: eventType,
        d: deviceInfo,
        fp: fingerprint,
        data: data,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return true;
  } catch (error) {
    console.error("Error sending event:", error);
    return false;
  }
};

export { sendEvent, getDeviceInfo };
