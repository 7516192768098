<script>
  import { onMount, afterUpdate } from "svelte";

  export let src;
  export let alt = "";
  export let objectFit = "cover";
  export let lazy = true;
  export let placeholderColor = "#f0f0f0";
  export let klass = "w-full h-full";
  export let style = "";
  export let height = null;
  export let width = null;

  let loaded = false;
  let error = false;
  let imageElement;
  let currentSrc = src;

  $: imageStyle = `
    ${style}
    ${height ? `height: ${height}px;` : ""}
    ${width ? `width: ${width}px;` : ""}
  `;

  $: {
    if (!src) {
      error = true;
      loaded = false;
    } else {
      error = false;
      loaded = false;
      currentSrc = src;
    }
  }

  onMount(() => {
    if (lazy && currentSrc) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            imageElement.src = currentSrc;
            observer.unobserve(imageElement);
          }
        },
        { rootMargin: "200px" } // Increased rootMargin to load images earlier
      );
      observer.observe(imageElement);
    } else if (!lazy && currentSrc) {
      imageElement.src = currentSrc;
    }
  });

  afterUpdate(() => {
    if (imageElement && currentSrc !== imageElement.src) {
      imageElement.src = currentSrc;
    }
  });

  function handleLoad() {
    loaded = true;
  }

  function handleError() {
    error = true;
  }
</script>

<style>
  .image-container {
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .placeholder svg {
    width: 50%;
    height: 50%;
    color: #999;
  }

  img {
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: none;
  }

  img.loaded {
    opacity: 1;
  }

  img.error {
    display: none;
  }
</style>

<div class="image-container {klass}" style={imageStyle}>
  {#if error}
    <div class="placeholder" style:background-color={placeholderColor}>
      <slot name="placeholder">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round">
          <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
          <circle cx="8.5" cy="8.5" r="1.5" />
          <polyline points="21 15 16 10 5 21" />
        </svg>
      </slot>
    </div>
  {/if}

  {#if currentSrc}
    <img
      bind:this={imageElement}
      class:loaded
      class:error
      {alt}
      src={lazy ? "" : currentSrc}
      on:load={handleLoad}
      on:error={handleError}
      style:object-fit={objectFit} />
  {/if}
</div>
