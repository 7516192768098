<script>
  import Modal from "$components/Modal.svelte";
  import Ajax from "$utils/ajax";
  import { notify } from "$utils/notify";

  export let block;
  export let isModalOpen = false;
  export let showButton = true;

  let isSaving = false;
  let isLoading = false;
  let windowWidth;

  let analyticsData = {
    views: 0,
    uniqueViews: 0,
    clicks: 0,
    uniqueClicks: 0,
    conversionRate: "0%",
    lastWeekViews: [],
    lastWeekClicks: [],
    deviceBreakdown: [],
    browserBreakdown: [],
    topCountries: [],
  };

  const excludedTypes = [];

  async function loadStats() {
    isLoading = true;
    const [error, response] = await Ajax.get(
      `/dashboard/blocks/${block.id}/stats`
    );

    if (error) {
      notify({
        type: "error",
        message: "Failed to load analytics data",
      });
      isLoading = false;
      return;
    }

    analyticsData = {
      ...analyticsData,
      views: response.views,
      uniqueViews: response.unique_views,
      clicks: response.clicks,
      uniqueClicks: response.unique_clicks,
      conversionRate: `${response.conversion_rate}%`,
      lastWeekViews: response.last_week_views,
      lastWeekClicks: response.last_week_clicks,
      topCountries: response.top_countries,
      deviceBreakdown: response.device_breakdown,
      browserBreakdown: response.browser_breakdown,
    };
    isLoading = false;
  }

  function closeModal() {
    isModalOpen = false;
  }

  $: if (isModalOpen) {
    loadStats();
  }

  let daysToShow;

  $: isMobile = windowWidth < 640;
  $: daysToShow = isMobile ? 3 : 7;
</script>

<svelte:window bind:innerWidth={windowWidth} />

<Modal bind:isOpen={isModalOpen} size="3xl">
  <div slot="header" class="px-3 sm:px-6">
    <h3 class="text-md sm:text-xl font-bold text-gray-900">Block Analytics</h3>
  </div>

  <div class="mt-3 px-2 sm:mt-6 sm:px-6">
    {#if isLoading}
      <!-- Stats Cards -->
      <div class="hidden sm:grid sm:grid-cols-3 gap-6 mb-8">
        {#each Array(3) as _}
          <div class="bg-white p-6 rounded-xl border-2 border-gray-200">
            <div class="flex items-center justify-between mb-4">
              <div class="h-4 w-20 bg-gray-200 rounded animate-pulse"></div>
              <div class="h-9 w-9 bg-gray-200 rounded-lg animate-pulse"></div>
            </div>
            <div class="flex items-baseline gap-2">
              <div class="h-8 w-16 bg-gray-200 rounded animate-pulse"></div>
              <div class="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
            </div>
          </div>
        {/each}
      </div>

      <!-- 7 Days Activity -->
      <div class="bg-white p-6 rounded-xl border-2 border-gray-200 mb-8">
        <div class="flex items-center justify-between mb-6">
          <div class="h-6 w-40 bg-gray-200 rounded animate-pulse"></div>
        </div>
        <div class="h-48 bg-gray-200 rounded animate-pulse"></div>
      </div>

      <!-- Device Breakdown -->
      <div class="bg-white p-6 rounded-xl border-2 border-gray-200 mb-8">
        <div class="flex items-center justify-between mb-6">
          <div class="h-6 w-40 bg-gray-200 rounded animate-pulse"></div>
        </div>
        <div class="flex flex-col sm:flex-row sm:items-center gap-6">
          {#each Array(3) as _}
            <div class="flex-1">
              <div
                class="h-4 w-20 bg-gray-200 rounded mb-2 animate-pulse"
              ></div>
              <div class="h-3 w-full bg-gray-200 rounded animate-pulse"></div>
            </div>
          {/each}
        </div>
      </div>

      <!-- Browser Breakdown -->
      <div class="bg-white p-6 rounded-xl border-2 border-gray-200 mb-8">
        <div class="flex items-center justify-between mb-6">
          <div class="h-6 w-40 bg-gray-200 rounded animate-pulse"></div>
        </div>
        <div class="flex flex-col sm:flex-row sm:items-center gap-6">
          {#each Array(3) as _}
            <div class="flex-1">
              <div
                class="h-4 w-20 bg-gray-200 rounded mb-2 animate-pulse"
              ></div>
              <div class="h-3 w-full bg-gray-200 rounded animate-pulse"></div>
            </div>
          {/each}
        </div>
      </div>
    {:else}
      <div class="hidden sm:grid sm:grid-cols-3 gap-6 mb-8">
        <div class="bg-white p-6 rounded-xl border-2 border-gray-200">
          <div class="flex items-center justify-between mb-4">
            <div class="text-sm font-medium text-gray-500">Views</div>
            <div class="p-2 bg-purple-50 rounded-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-purple-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                <path
                  fill-rule="evenodd"
                  d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
          <div class="flex items-baseline gap-2">
            <div class="text-3xl font-bold text-gray-900">
              {analyticsData.views}
            </div>
            <div class="text-sm text-gray-500">
              ({analyticsData.uniqueViews} unique)
            </div>
          </div>
        </div>

        <div class="bg-white p-6 rounded-xl border-2 border-gray-200">
          <div class="flex items-center justify-between mb-4">
            <div class="text-sm font-medium text-gray-500">Clicks</div>
            <div class="p-2 bg-indigo-50 rounded-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-indigo-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
          <div class="flex items-baseline gap-2">
            <div class="text-3xl font-bold text-gray-900">
              {analyticsData.clicks}
            </div>
            <div class="text-sm text-gray-500">
              ({analyticsData.uniqueClicks} unique)
            </div>
          </div>
        </div>

        <div class="bg-white p-6 rounded-xl border-2 border-gray-200">
          <div class="flex items-center justify-between mb-4">
            <div class="text-sm font-medium text-gray-500">Conversion Rate</div>
            <div class="p-2 bg-green-50 rounded-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-green-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z"
                />
              </svg>
            </div>
          </div>
          <div class="text-3xl font-bold text-gray-900">
            {analyticsData.conversionRate}
          </div>
        </div>
      </div>

      <!-- Mobile view -->
      <div class="grid grid-cols-1 gap-3 mb-4 sm:hidden">
        <div class="bg-white p-3 rounded-xl border-2 border-gray-200">
          <div class="flex items-center justify-between mb-2">
            <div class="text-sm font-medium text-gray-500">Views</div>
            <div class="p-1.5 bg-purple-50 rounded-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 text-purple-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                <path
                  fill-rule="evenodd"
                  d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
          <div class="flex items-baseline gap-2">
            <div class="text-xl font-bold text-gray-900">
              {analyticsData.views}
            </div>
            <div class="text-xs text-gray-500">
              ({analyticsData.uniqueViews} unique)
            </div>
          </div>
        </div>

        <div class="bg-white p-3 rounded-xl border-2 border-gray-200">
          <div class="flex items-center justify-between mb-2">
            <div class="text-sm font-medium text-gray-500">Clicks</div>
            <div class="p-1.5 bg-indigo-50 rounded-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 text-indigo-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
          <div class="flex items-baseline gap-2">
            <div class="text-xl font-bold text-gray-900">
              {analyticsData.clicks}
            </div>
            <div class="text-xs text-gray-500">
              ({analyticsData.uniqueClicks} unique)
            </div>
          </div>
        </div>

        <div class="bg-white p-3 rounded-xl border-2 border-gray-200">
          <div class="flex items-center justify-between mb-2">
            <div class="text-sm font-medium text-gray-500">Conversion Rate</div>
            <div class="p-1.5 bg-green-50 rounded-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 text-green-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z"
                />
              </svg>
            </div>
          </div>
          <div class="text-xl font-bold text-gray-900">
            {analyticsData.conversionRate}
          </div>
        </div>
      </div>

      <div
        class="bg-white p-3 sm:p-6 rounded-xl border-2 border-gray-200 mb-4 sm:mb-8"
      >
        <h5
          class="text-base sm:text-lg font-semibold text-gray-900 mb-3 sm:mb-6"
        >
          {daysToShow} Days Activity
        </h5>
        {#if analyticsData.lastWeekViews.length === 0}
          <p class="text-center text-gray-500 py-8">
            No activity data available for this period
          </p>
        {:else}
          <div class="relative h-30 sm:h-80">
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 1000 400"
              preserveAspectRatio="xMidYMid meet"
            >
              <!-- Grid lines -->
              {#each Array(5) as _, i}
                <line
                  x1="0"
                  y1={i * 20 + 5 + "%"}
                  x2="100%"
                  y2={i * 20 + 5 + "%"}
                  stroke="#e5e7eb"
                  stroke-width="1"
                />
              {/each}

              <!-- Bars -->
              {#each analyticsData.lastWeekViews.slice(-daysToShow) as value, index}
                {@const maxValue =
                  Math.max(
                    ...analyticsData.lastWeekViews.slice(-daysToShow),
                    ...analyticsData.lastWeekClicks.slice(-daysToShow)
                  ) || 1}
                {@const viewHeight = value === 0 ? 2 : (value / maxValue) * 80}
                {@const clickValue =
                  analyticsData.lastWeekClicks.slice(-daysToShow)[index]}
                {@const clickHeight =
                  clickValue === 0 ? 2 : (clickValue / maxValue) * 80}
                {@const barWidth = 100 / (daysToShow * 3)}
                {@const xPos = index * (barWidth * 3) + barWidth * 0.5}
                {@const date = new Date()}
                {@const displayDate = new Date(
                  date.setDate(date.getDate() - (daysToShow - 1 - index))
                ).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                })}

                <!-- Views bar -->
                <rect
                  x="{xPos}%"
                  y="{85 - viewHeight}%"
                  width="{barWidth * 0.8}%"
                  height="{viewHeight}%"
                  fill="#8b5cf6"
                  opacity="0.9"
                />

                <!-- Views count -->
                {#if value >= 1}
                  <text
                    x="{xPos + barWidth * 0.4}%"
                    y="{85 - viewHeight + viewHeight / 2}%"
                    text-anchor="middle"
                    fill="#FFFFFF"
                    font-size={isMobile ? 36 : 18}
                    class="font-medium"
                    dominant-baseline="middle"
                  >
                    {value}
                  </text>
                {/if}

                <!-- Clicks bar -->
                <rect
                  x="{xPos + barWidth}%"
                  y="{85 - clickHeight}%"
                  width="{barWidth * 0.8}%"
                  height="{clickHeight}%"
                  fill="#6366f1"
                  opacity="0.9"
                />

                <!-- Clicks count -->
                {#if clickValue >= 1}
                  <text
                    x="{xPos + barWidth + barWidth * 0.4}%"
                    y="{85 - clickHeight + clickHeight / 2}%"
                    text-anchor="middle"
                    fill="#FFFFFF"
                    font-size={isMobile ? 36 : 18}
                    class="font-medium"
                    dominant-baseline="middle"
                  >
                    {clickValue}
                  </text>
                {/if}

                <!-- Date label -->
                <text
                  x="{xPos + barWidth}%"
                  y={isMobile ? "99%" : "93%"}
                  text-anchor="middle"
                  fill="#6B7280"
                  font-size={isMobile ? 36 : 18}
                  class="font-medium"
                >
                  {displayDate}
                </text>
              {/each}
            </svg>
          </div>
          <div class="flex justify-center gap-6 {isMobile ? 'mt-6' : '-mt-4'}">
            <div class="flex items-center">
              <div class="w-3 h-3 rounded-full bg-purple-600 mr-2"></div>
              <span class="text-sm sm:text-base text-gray-600">Views</span>
            </div>
            <div class="flex items-center">
              <div class="w-3 h-3 rounded-full bg-indigo-600 mr-2"></div>
              <span class="text-sm sm:text-base text-gray-600">Clicks</span>
            </div>
          </div>
        {/if}
      </div>

      <div
        class="bg-white p-3 sm:p-6 rounded-xl border-2 border-gray-200 mb-4 sm:mb-8"
      >
        <div
          class="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-3 sm:mb-6"
        >
          <h5
            class="text-base sm:text-lg font-semibold text-gray-900 mb-4 sm:mb-0"
          >
            Device Breakdown
          </h5>
          {#if Object.keys(analyticsData.deviceBreakdown).length > 0}
            <div class="flex flex-wrap gap-1.5 sm:gap-2">
              {#each Object.entries(analyticsData.deviceBreakdown) as [device, percentage]}
                <div
                  class="px-2 py-0.5 sm:px-3 sm:py-1 rounded-full text-xs font-medium"
                  class:bg-purple-100={device === "mobile"}
                  class:text-purple-700={device === "mobile"}
                  class:bg-indigo-100={device === "desktop"}
                  class:text-indigo-700={device === "desktop"}
                  class:bg-blue-100={device === "tablet"}
                  class:text-blue-700={device === "tablet"}
                >
                  {device}: {percentage}%
                </div>
              {/each}
            </div>
          {/if}
        </div>
        {#if Object.keys(analyticsData.deviceBreakdown).length === 0}
          <p class="text-center text-gray-500 py-8">No device data available</p>
        {:else}
          <div class="flex flex-col sm:flex-row sm:items-center gap-3 sm:gap-6">
            {#each Object.entries(analyticsData.deviceBreakdown) as [device, percentage]}
              <div class="flex-1">
                <div class="text-xs sm:text-sm text-gray-500 capitalize mb-1.5">
                  {device}
                </div>
                <div class="w-full bg-gray-100 rounded-full h-2 sm:h-3">
                  <div
                    class="h-2 sm:h-3 rounded-full transition-all duration-500 ease-out"
                    class:bg-purple-600={device === "mobile"}
                    class:bg-indigo-600={device === "desktop"}
                    class:bg-blue-600={device === "tablet"}
                    style="width: {percentage}%"
                  ></div>
                </div>
              </div>
            {/each}
          </div>
        {/if}
      </div>

      <div
        class="bg-white p-3 sm:p-6 rounded-xl border-2 border-gray-200 mb-4 sm:mb-8"
      >
        <div
          class="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-3 sm:mb-6"
        >
          <h5
            class="text-base sm:text-lg font-semibold text-gray-900 mb-4 sm:mb-0"
          >
            Browser Breakdown
          </h5>
          {#if Object.keys(analyticsData.browserBreakdown).length > 0}
            <div class="flex flex-wrap gap-1.5 sm:gap-2">
              {#each Object.entries(analyticsData.browserBreakdown).slice(0, isMobile ? 3 : 5) as [browser, percentage]}
                <div
                  class="px-2 py-0.5 sm:px-3 sm:py-1 rounded-full text-xs font-medium bg-emerald-100 text-emerald-700"
                >
                  {browser}: {percentage}%
                </div>
              {/each}
            </div>
          {/if}
        </div>
        {#if Object.keys(analyticsData.browserBreakdown).length === 0}
          <p class="text-center text-gray-500 py-8">
            No browser data available
          </p>
        {:else}
          <div class="flex flex-col gap-3">
            {#each Object.entries(analyticsData.browserBreakdown).slice(0, isMobile ? 3 : 5) as [browser, percentage]}
              <div class="w-full">
                <div class="text-xs sm:text-sm text-gray-500 capitalize mb-1.5">
                  {browser}
                </div>
                <div class="w-full bg-gray-100 rounded-full h-2 sm:h-3">
                  <div
                    class="h-2 sm:h-3 rounded-full transition-all duration-500 ease-out bg-emerald-600"
                    style="width: {percentage}%"
                  ></div>
                </div>
              </div>
            {/each}
          </div>
        {/if}
      </div>

      <div
        class="bg-white p-3 sm:p-6 rounded-xl border-2 border-gray-200 sm:mb-4"
      >
        <div
          class="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-3 sm:mb-6"
        >
          <h5
            class="text-base sm:text-lg font-semibold text-gray-900 mb-4 sm:mb-0"
          >
            Geographic Distribution
          </h5>
          {#if analyticsData.topCountries.length > 0}
            <div class="flex flex-wrap gap-1.5 sm:gap-2">
              {#each analyticsData.topCountries.slice(0, isMobile ? 3 : 5) as country}
                <div
                  class="px-2 py-0.5 sm:px-3 sm:py-1 rounded-full text-xs font-medium bg-purple-100 text-purple-700"
                >
                  {country.name}: {country.percentage}%
                </div>
              {/each}
            </div>
          {/if}
        </div>
        {#if analyticsData.topCountries.length === 0}
          <p class="text-center text-gray-500 py-8">
            No geographic data available
          </p>
        {:else}
          <div class="flex flex-col gap-3">
            {#each analyticsData.topCountries.slice(0, isMobile ? 3 : 5) as country}
              <div class="w-full">
                <div class="text-xs sm:text-sm text-gray-500 mb-1.5">
                  {country.name}
                </div>
                <div class="w-full bg-gray-100 rounded-full h-2 sm:h-3">
                  <div
                    class="bg-gradient-to-r from-purple-600 to-indigo-600 h-2 sm:h-3 rounded-full transition-all duration-500 ease-out"
                    style="width: {country.percentage}%"
                  ></div>
                </div>
              </div>
            {/each}
          </div>
        {/if}
      </div>
    {/if}
  </div>

  <div class="flex sm:hidden mt-4 px-3 justify-end space-x-3 border-t pt-3">
    <button
      type="button"
      class="px-3 py-1.5 text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 rounded-md"
      on:click={closeModal}
      disabled={isSaving}
    >
      Close
    </button>
  </div>
</Modal>

{#if showButton && excludedTypes.includes(block.block_type) === false}
  <button
    on:click={() => (isModalOpen = true)}
    class="text-gray-400 hover:text-purple-500 relative group mr-4"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="M3 3v18h18"></path>
      <path d="M18.7 8l-5.1 5.2-2.8-2.7L7 14.3"></path>
    </svg>
    <span
      class="absolute bottom-[140%] left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded-lg py-1.5 px-3 opacity-0 group-hover:opacity-100 transition-all duration-300 whitespace-nowrap shadow-lg"
    >
      View Analytics
    </span>
  </button>
{/if}
