<script>
  import { goto } from "$utils/goto";
  export let size = "text-4xl"; // Default size

  export let width;
  export let height = "32px";
  export let href = "/";
  export let klass;
  export let clickable = true; // Add option to disable click
</script>

<div
  class="{size} font-bold {clickable ? 'cursor-pointer' : ''}"
  on:click={() => clickable && goto(href)}
>
  <img
    src="/images/logo-small.png"
    srcset="/images/logo-small.png 1x, /images/logo-small@2x.png 2x"
    alt="BioInk Logo"
    style:height
    style:width
    class="h-8 w-auto {klass}"
  />
</div>
