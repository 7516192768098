<script>
  import { createEventDispatcher } from "svelte";

  export let value = "";
  export let options = [];
  export let label = "";
  export let klass = "";
  export let placeholder = "Select an option";

  const dispatch = createEventDispatcher();

  function handleChange(event) {
    value = event.target.value;
    dispatch("change", value);
  }
</script>

{#if label}
  <label class="block text-sm font-medium text-gray-700 mb-2">{label}</label>
{/if}
<select
  bind:value
  on:change={handleChange}
  class={`block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${klass}`}
>
  <option value="" disabled selected>{placeholder}</option>
  {#each options as option}
    <option value={option.value}>{option.label}</option>
  {/each}
</select>
