<script>
  import { getThemeFromId } from "$utils/themes";
  import Image from "$components/Image.svelte";
  import Verified from "./Verified.svelte";

  export let page;
  export let klass = "";

  let theme = getThemeFromId(page);
</script>

<div
  class="flex flex-col items-center text-center max-w-[580px] mx-auto py-8 mt-8 {klass}"
>
  <Image
    src={page.profile_image_url}
    alt="{page.profile_name}'s logo"
    klass="w-24 h-24 rounded-full mb-4 border-1 border-gray-200 shadow-lg"
  />

  {#if page.profile_name}
    <h1
      class="text-lg @md:!text-xl mb-2 font-bold flex items-center gap-2"
      style="color: var(--textColor);"
    >
      {page.profile_name}

      {#if page.verified}
        <Verified />
      {/if}
    </h1>
  {/if}

  {#if page.bio}
    <p
      class="text-sm @md:!text-[15px] font-medium tracking-wide"
      style="color: var(--textColor); opacity: 0.8;"
    >
      {page.bio}
    </p>
  {/if}
</div>
