export function getValidationRules(fieldType) {
  const rules = [{ value: "required", label: "Make this field required" }];

  switch (fieldType) {
    case "text":
    case "textarea":
      rules.push(
        {
          value: "minLength",
          label: "Minimum text length", 
          type: "number",
          defaultValue: 3,
        },
        {
          value: "maxLength",
          label: "Maximum text length",
          type: "number", 
          defaultValue: 100,
        },
      );
      break;
    case "email":
      rules.push(
        {
          value: "domain",
          label: "Restrict to specific domain",
          type: "text",
          defaultValue: "",
        }
      );
      break;
    case "tel":
      break;
    case "number":
      rules.push(
        {
          value: "min",
          label: "Minimum value",
          type: "number",
          defaultValue: 0,
        },
        {
          value: "max", 
          label: "Maximum value",
          type: "number",
          defaultValue: 100,
        },
      );
      break;
    case "url":
      rules.push(
        { value: "protocol", label: "Require HTTPS" }
      );
      break;
    case "date":
      rules.push(
        { value: "minDate", label: "Earliest allowed date", type: "date" },
        { value: "maxDate", label: "Latest allowed date", type: "date" },
        { value: "futureDate", label: "Must be a future date" },
        { value: "pastDate", label: "Must be a past date" }
      );
      break;
  }

  return rules;
}

export function validateField(field, value) {
  const { type: fieldType, validations, validationValues } = field;
  if (!validations) return null;

  // Required check
  if (validations.includes("required") && !value) {
    return "This field is required";
  }

  if (!value) return null; // Skip other validations if empty

  switch (fieldType) {
    case "text":
    case "textarea":
      if (validations.includes("minLength")) {
        const min = parseInt(validationValues.minLength);
        if (value.length < min) {
          return `Minimum length is ${min} characters`;
        }
      }
      if (validations.includes("maxLength")) {
        const max = parseInt(validationValues.maxLength);
        if (value.length > max) {
          return `Maximum length is ${max} characters`;
        }
      }
      break;

    case "email":
      if (validations.includes("domain") && validationValues.domain) {
        const domain = value.split("@")[1];
        if (domain !== validationValues.domain) {
          return `Email must be from domain ${validationValues.domain}`;
        }
      }
      break;

    case "number":
      const num = Number(value);
      if (validations.includes("min")) {
        const min = parseInt(validationValues.min);
        if (num < min) {
          return `Minimum value is ${min}`;
        }
      }
      if (validations.includes("max")) {
        const max = parseInt(validationValues.max);
        if (num > max) {
          return `Maximum value is ${max}`;
        }
      }
      break;

    case "url":
      if (validations.includes("protocol") && !value.startsWith("https://")) {
        return "URL must use HTTPS";
      }
      break;

    case "date":
      const date = new Date(value);
      const now = new Date();
      
      if (validations.includes("minDate") && validationValues.minDate) {
        if (date < new Date(validationValues.minDate)) {
          return `Date must be after ${validationValues.minDate}`;
        }
      }
      if (validations.includes("maxDate") && validationValues.maxDate) {
        if (date > new Date(validationValues.maxDate)) {
          return `Date must be before ${validationValues.maxDate}`;
        }
      }
      if (validations.includes("futureDate") && date <= now) {
        return "Date must be in the future";
      }
      if (validations.includes("pastDate") && date >= now) {
        return "Date must be in the past";
      }
      break;
  }

  return null;
}
