<script>
  import { fade, fly } from "svelte/transition";
  import BlockViewer from "$app-components/BlockViewer.svelte";
  import ProfileHeader from "$app-components/ProfileHeader.svelte";

  export let isOpen = false;

  export let blocks = [];
  export let page;

  function closeModal() {
    isOpen = false;
  }
</script>

{#if isOpen}
  <div
    transition:fade={{ duration: 200 }}
    class="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center"
  >
    <div
      transition:fly={{ y: 200, duration: 300, easing: (t) => t * (2 - t) }}
      class="bg-white w-full h-full max-w-md shadow-xl flex flex-col"
    >
      <div class="flex-grow flex flex-col overflow-hidden">
        <div class="flex-grow overflow-y-auto relative">
          <BlockViewer {page} {blocks}>
            <svelte:fragment slot="header">
              <ProfileHeader {page} />
            </svelte:fragment>
          </BlockViewer>
        </div>
      </div>
      <div class="border-t border-gray-200 px-4 py-2 bg-purple-100">
        <button
          type="button"
          on:click={closeModal}
          class="w-full py-1.5 px-4 bg-white text-purple-700 font-semibold rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition duration-150 ease-in-out flex items-center justify-center space-x-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
          <span>Close Preview</span>
        </button>
      </div>
    </div>
  </div>
{/if}
