<script>
  import { onMount } from "svelte";
  import videojs from "video.js";
  import "video.js/dist/video-js.css";

  export let src;
  export let poster = "";
  export let width = "100%";
  export let height = "100%";
  export let allowPlay = true;

  let videoElement;
  let player;

  onMount(() => {
    if (allowPlay) {
      player = videojs(videoElement, {
        controls: true,
        preload: "auto",
        responsive: true,
        fluid: true,
        sources: [{ src, type: "video/mp4" }],
      });

      // Disable vjs-poster
      player.addClass("vjs-no-poster");

      return () => {
        if (player) {
          player.dispose();
        }
      };
    }
  });
</script>

<div class="video-container">
  {#if allowPlay}
    <video
      bind:this={videoElement}
      class="video-js vjs-purple-theme vjs-no-poster"
      {width}
      {height}
      {poster}
    >
      <source {src} type="video/mp4" />
      <p class="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a
        web browser that <a
          href="https://videojs.com/html5-video-support/"
          target="_blank">supports HTML5 video</a
        >
      </p>
    </video>
  {:else}
    <video {src} {width} {height} preload="metadata">
      <track kind="captions" />
    </video>
  {/if}
</div>

<style>
  .video-container {
    max-width: 100%;
    margin: 0 auto;
  }
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  :global(.vjs-no-poster .vjs-poster) {
    display: none;
  }
</style>
