<script>
  import { onMount } from "svelte";

  import Logo from "$components/Logo.svelte";
  import Step0 from "./linktree/Step0.svelte";
  import Step2 from "./linktree/Step2.svelte";
  import CreateAccountModal from "./linktree/CreateAccountModal.svelte";
  import ImportingModal from "./linktree/ImportingModal.svelte";
  import Ajax from "$utils/ajax";

  export let links;
  export let token;
  export let username;
  export let linktreeEmail;

  let step = 0;
  let selectedTheme;
  let selectedLinks = links;
  let showCreateAccountModal = false;
  let showImportingModal = false;
  let usernameError = "";

  const steps = [
    {
      title: "Confirm Username",
      description: "Is this the username you want to use?",
    },
    {
      title: "Choose Theme",
      description: "Select a theme for your BioInk profile",
    },
  ];

  async function validateUsername() {
    let [_errors, resp] = await Ajax.post("/register/validate_username", {
      username: username,
    });

    if (resp && resp.taken) {
      usernameError = "Username is already taken. Please select another one.";
      return false;
    } else {
      usernameError = "";
      return true;
    }
  }

  async function nextStep() {
    if (step === 0) {
      const isValid = await validateUsername();
      if (!isValid) return;
    }

    if (step < steps.length - 1) {
      step++;
      window.scrollTo(0, 0);
    }
  }

  function prevStep() {
    if (step > 0) {
      step--;
      window.scrollTo(0, 0);
    }
  }

  function goToStep(index) {
    step = index;
    window.scrollTo(0, 0);
  }

  function handleFinish() {
    showCreateAccountModal = true;
  }

  function handleModalClose() {
    showCreateAccountModal = false;
  }

  function handleAccountCreated() {
    showCreateAccountModal = false;
    showImportingModal = true;
  }
</script>

<div class="container mx-auto px-4 py-8 max-w-4xl">
  <div class="flex justify-center mb-8">
    <Logo height="24px" clickable={false} />
  </div>

  <div
    class="bg-gradient-to-r from-purple-600 to-indigo-600 rounded-lg shadow-lg p-8 mb-8">
    <h1 class="text-2xl font-semibold text-white mb-4">Import Your Linktree</h1>
    <p class="text-white text-lg">
      Follow the steps below to set up your BioInk profile
    </p>
  </div>

  <div class="bg-white rounded-lg shadow-lg p-4 sm:p-8 mb-8">
    <ol class="flex items-center justify-between">
      {#each steps as s, i}
        <li class="flex flex-col items-center w-full">
          <button
            on:click={() => goToStep(i)}
            class={`w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center rounded-full border-2 ${i === step ? "border-purple-600 bg-purple-600 text-white" : "border-gray-300 text-gray-500"} mb-1 sm:mb-2 cursor-pointer focus:outline-none text-sm sm:text-base`}>
            {i + 1}
          </button>
          <span
            class={`text-xs sm:text-sm font-medium ${i === step ? "text-purple-600" : "text-gray-500"} cursor-pointer truncate max-w-[80px] sm:max-w-full text-center`}
            on:click={() => goToStep(i)}>
            {#if window.innerWidth < 640}
              {s.title.split(" ").pop()}
            {:else}
              {s.title}
            {/if}
          </span>
          {#if i < steps.length}
            <div class="w-full bg-gray-200 h-0.5 sm:h-1 mt-1 sm:mt-2"></div>
          {/if}
        </li>
      {/each}
    </ol>
  </div>

  <div class="bg-white rounded-lg shadow-lg p-8 mb-8">
    {#if step === 0}
      <Step0 bind:username bind:usernameError {nextStep} />
    {:else if step === 1}
      <Step2 bind:selectedTheme />
    {/if}
  </div>

  <div class="flex justify-between items-center">
    {#if step > 0}
      <button
        on:click={prevStep}
        class="px-8 py-3 bg-gray-200 text-gray-700 rounded-full font-semibold hover:bg-gray-300 transition-colors duration-300">
        Previous
      </button>
    {:else}
      <div></div>
    {/if}
    <button
      on:click={step === steps.length - 1 ? handleFinish : nextStep}
      class="px-8 py-3 bg-gradient-to-r from-purple-600 to-indigo-600 text-white rounded-full font-semibold hover:from-purple-700 hover:to-indigo-700 transition-colors duration-300">
      {step === steps.length - 1 ? "Finish" : "Next"}
    </button>
  </div>
</div>

<CreateAccountModal
  isOpen={showCreateAccountModal}
  {username}
  {linktreeEmail}
  on:close={handleModalClose}
  on:account-created={handleAccountCreated} />

<ImportingModal
  {selectedLinks}
  {selectedTheme}
  isOpen={showImportingModal}
  {token} />
