<script>
  import Image from "$components/Image.svelte";
  import { onMount } from "svelte";

  const examples = [
    {
      username: "theswingmannn_",
      src: "https://bioink.s3.amazonaws.com/uploads/external-images/2/570cdf42-4865-47cb-8958-995c7b72414c.heic",
      url: "https://bio.ink/theswingmannn_",
      description:
        "6 Year Professional Baseball player, current Professional Long Drive Competitor",
    },
    {
      username: "ytmusic",
      src: "https://bioink.s3.amazonaws.com/uploads%2F1%2F1e2a2de8-5558-4ecc-988c-057612aa24df%2Fbeautiful-fall-nature-scenery-free-image.jpeg%3Fw%3D2210%26quality%3D70",
      url: "https://bio.ink/ytmusic",
      description:
        "Top music videos on youtube, featuring trending artists and new releases",
    },
    {
      username: "sweetbuzzzgifts",
      src: "https://bioink.s3.amazonaws.com/uploads/external-images/3/f4e93169-d790-4991-b0d4-0dfcf1bbbd63.png",
      url: "https://bio.ink/sweetbuzzzgifts",
      objectFit: "contain",
      description:
        "Small business specializing in personalized gifts and unboxing content",
    },
  ];
</script>

<div class="grid md:grid-cols-3 gap-4 md:gap-8">
  {#each examples as example, i}
    <div
      class="group bg-white rounded-2xl overflow-hidden border-2 border-gray-100 hover:border-gray-300 cursor-pointer"
    >
      <!-- Mobile layout -->
      <div class="md:hidden" on:click={() => window.open(example.url)}>
        <div class="relative h-48">
          <Image
            src={example.src}
            klass="w-full h-full bg-white border-b"
            objectFit={example.objectFit}
          />
          <div class="absolute inset-0 bg-transparent"></div>
          <div class="absolute bottom-0 left-0 right-0">
            <h3
              class="text-sm font-semibold text-white drop-shadow-lg bg-purple-900/30 px-3 py-1.5 backdrop-blur-md"
            >
              @{example.username}
            </h3>
          </div>
        </div>
        <div class="p-4">
          <p class="text-sm text-purple-600 mb-3">
            {example.description}
          </p>
          <button
            class="w-full py-2.5 px-4 rounded-xl bg-purple-50 text-purple-600 font-medium hover:bg-purple-100 transition-colors flex items-center justify-center gap-2 group"
          >
            View BioInk
            <svg
              class="w-4 h-4 transform group-hover:translate-x-1 transition-transform"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>

      <!-- Desktop layout -->
      <div class="hidden md:block" on:click={() => window.open(example.url)}>
        <div class="relative h-72 overflow-hidden">
          <div
            class="absolute inset-0 bg-gradient-to-b from-transparent via-purple-900/10 to-purple-900/30 group-hover:opacity-0 transition-opacity duration-300"
          ></div>
          <Image
            src={example.src}
            alt="BioInk Example - {example.username}"
            objectFit={example.objectFit}
            klass="w-full h-full transform transition-transform duration-700 bg-white border-b"
          />
        </div>
        <div class="p-8">
          <div class="flex items-center justify-between mb-4">
            <div>
              <h3 class="text-xl font-semibold text-purple-900">
                @{example.username}
              </h3>
            </div>
            <div
              class="h-10 w-10 rounded-full bg-purple-100 flex items-center justify-center group-hover:bg-purple-200 transition-colors"
            >
              <svg
                class="w-5 h-5 text-purple-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 12h14M12 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>
          <p class="text-purple-600 leading-relaxed">
            {example.description}
          </p>
          <button
            class="mt-6 w-full py-3 px-6 rounded-xl bg-purple-50 text-purple-600 font-medium hover:bg-purple-100 transition-colors flex items-center justify-center gap-2 group"
          >
            View BioInk
            <svg
              class="w-4 h-4 transform group-hover:translate-x-1 transition-transform"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  {/each}
</div>
