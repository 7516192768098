<script>
  import { createEventDispatcher } from "svelte";
  import View from "$app-components/blocks/_layouts/View.svelte";
  import Image from "$components/Image.svelte";

  const dispatch = createEventDispatcher();

  export let block;
  export let updater;

  function handleDelete() {
    dispatch("delete-block", { id: block.id });
  }

  $: ({ enabled } = block);
</script>

<View {block} {updater} on:delete-block={handleDelete}>
  <div class="h-24 flex items-center justify-start overflow-hidden">
    <Image
      src={block.image_url}
      alt={block.alt_text || "Image"}
      objectFit="contain"
      klass="h-full w-auto" />
  </div>
</View>
