<script>
  export let blocks;
  export let theme;
  export let hoverEffect;
  export let buttonStyle;
  export let getBlockComponent;
  export let blockClicked;
  export let blockViewed;
  export let onActualPage;
</script>

<ul
  class="flex flex-col items-center mt-6 w-full max-w-[580px] mb-6 mx-auto layout-container"
>
  {#each blocks as block, index (block)}
    <li
      class="w-full block-component"
      class:mt-8={index > 0 && block.block_type === "heading"}
      class:mb-6={block.block_type === "heading"}
      class:mb-4={block.block_type !== "heading"}
    >
      <div class="flex justify-center mb-0.5">
        <svelte:component
          this={getBlockComponent(block)}
          {block}
          {theme}
          {index}
          {buttonStyle}
          {hoverEffect}
          {blockClicked}
          {blockViewed}
          {onActualPage}
        />
      </div>
    </li>
  {/each}
</ul>
