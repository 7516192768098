<script>
  import { onMount } from "svelte";
  import { initializeAbly, presenceData } from "$utils/ably";

  export let page;

  let realtimeData = [];
  let error = false;

  $: activeVisitors = realtimeData.length || 0;

  $: averageTimeOnPage = (() => {
    if (!realtimeData.length) return "0:00";

    const currentTime = Date.now();
    const totalSeconds =
      realtimeData.reduce((sum, item) => {
        return sum + (currentTime - item.timestamp) / 1000;
      }, 0) / realtimeData.length;

    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);

    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  })();

  async function getRealTimeData() {
    try {
      realtimeData = await presenceData(page.realtime_token);
    } catch (error) {
      error = true;
    }
  }

  onMount(async () => {
    await initializeAbly();

    getRealTimeData();
    const interval = setInterval(getRealTimeData, 3000);

    return () => {
      clearInterval(interval);
    };
  });
</script>

<h2 class="text-xl font-bold mb-4">Real-time</h2>
{#if error}
  <div class="bg-white p-4 rounded-lg shadow">
    <div class="text-center text-red-600 py-2">
      An error occurred fetching real-time data. We're working to resolve this
      issue.
    </div>
  </div>
{:else}
  <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
    <div class="bg-white p-4 rounded-lg shadow">
      <div class="flex items-center justify-between sm:flex-col sm:items-start">
        <div class="flex items-center">
          <svg
            class="w-5 h-5 mr-2 text-teal-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            />
          </svg>
          <h3 class="text-sm font-semibold text-gray-600">Active Visitors</h3>
        </div>
        <p class="text-xl font-bold sm:mt-2">
          {activeVisitors}
        </p>
      </div>
    </div>

    <div class="bg-white p-4 rounded-lg shadow">
      <div class="flex items-center justify-between sm:flex-col sm:items-start">
        <div class="flex items-center">
          <svg
            class="w-5 h-5 mr-2 text-indigo-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <h3 class="text-sm font-semibold text-gray-600">Avg. Time on Page</h3>
        </div>
        <p class="text-xl font-bold sm:mt-2">
          {averageTimeOnPage}
        </p>
      </div>
    </div>
  </div>
{/if}
