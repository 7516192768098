<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { fade, fly } from "svelte/transition";

  const SLOTS = $$props.$$slots;

  export let isOpen = false;
  export let size = "md"; // 'sm', 'md', 'lg', 'xl', 'full', '2x', '3x', '4x'
  export let closeOnEscape = true;
  export let closeOnOutsideClick = true;
  export let showBackButton = false;
  export let removePadding = false;
  export let allowScrollCloseOnMobile = true;

  const dispatch = createEventDispatcher();

  const sizes = {
    sm: "sm:max-w-sm",
    md: "sm:max-w-md",
    lg: "sm:max-w-lg",
    xl: "sm:max-w-xl",
    full: "max-w-full",
    "2xl": "sm:max-w-2xl",
    "3xl": "sm:max-w-3xl",
    "4xl": "sm:max-w-4xl",
  };

  let scrollbarWidth;
  let innerWidth;
  let modalElement;
  let startY = 0;
  let currentY = 0;
  let isDragging = false;

  function handleResize() {
    innerWidth = window.innerWidth;
  }

  function close() {
    isOpen = false;
    dispatch("close");
  }

  function handleKeydown(event) {
    if (closeOnEscape && event.key === "Escape") {
      close();
    }
  }

  function handleOutsideClick(event) {
    if (closeOnOutsideClick && event.target === event.currentTarget) {
      close();
    }
  }

  function handleBack() {
    dispatch("back");
  }

  function handleTouchStart(event) {
    if (!allowScrollCloseOnMobile) return;
    if (innerWidth >= 640) return; // Only handle touch on mobile
    if (modalElement.scrollTop > 0) return; // Don't handle if not at top

    startY = event.touches[0].clientY;
    currentY = startY;
    isDragging = true;
  }

  function handleTouchMove(event) {
    if (!isDragging) return;
    currentY = event.touches[0].clientY;
    const deltaY = currentY - startY;

    if (deltaY < 0) return; // Don't allow dragging upwards

    modalElement.style.transform = `translateY(${deltaY}px)`;
    modalElement.style.transition = "none";
  }

  function handleTouchEnd() {
    if (!isDragging) return;
    isDragging = false;

    const deltaY = currentY - startY;
    modalElement.style.transition = "transform 0.3s ease-out";

    if (deltaY > 250) {
      // Threshold to close modal
      modalElement.style.transform = `translateY(100%)`;

      setTimeout(() => {
        modalElement.style.display = "none";
        close();
      }, 200);
    } else {
      modalElement.style.transform = "translateY(0)";
    }
  }

  function disableBodyScroll() {
    scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = `${scrollbarWidth}px`;
  }

  function enableBodyScroll() {
    document.body.style.overflow = "";
    document.body.style.paddingRight = "";
  }

  onMount(() => {
    document.addEventListener("keydown", handleKeydown);
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      document.removeEventListener("keydown", handleKeydown);
      window.removeEventListener("resize", handleResize);
    };
  });

  $: if (isOpen) {
    disableBodyScroll();
  } else {
    setTimeout(enableBodyScroll, 200);
  }
</script>

{#if isOpen}
  <div
    class="fixed top-0 left-0 bottom-0 right-0 inset-0 z-[20] overflow-y-auto bg-black bg-opacity-75 !m-0"
    transition:fade={{ duration: 200 }}
    on:click={handleOutsideClick}
  >
    <div
      class="flex min-h-screen items-end sm:items-center justify-center text-center py-0 sm:py-8"
    >
      <div
        bind:this={modalElement}
        on:touchstart={handleTouchStart}
        on:touchmove={handleTouchMove}
        on:touchend={handleTouchEnd}
        transition:fly={{
          y: innerWidth >= 640 ? "10%" : "100%",
          duration: innerWidth >= 640 ? 200 : 300,
        }}
        class="relative overflow-y-auto bg-white text-left shadow-xl transition-all w-full rounded-t-xl sm:rounded-lg sm:w-full {sizes[
          size
        ]} {removePadding
          ? ''
          : 'px-4 pb-4 pt-5 sm:p-6'} max-h-[95vh] sm:max-h-full"
      >
        <div class="absolute top-0 right-0 pt-3 pr-3">
          <button
            type="button"
            class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            on:click={close}
          >
            <span class="sr-only">Close</span>
            <svg
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        {#if showBackButton}
          <div class="absolute top-0 left-0 pt-4 pl-4">
            <button
              type="button"
              class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              on:click={handleBack}
            >
              {#if SLOTS.backButton}
                <slot name="backButton">
                  <span class="sr-only">Back</span>
                  <svg
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 19l-7-7m0 0l7-7m-7 7h18"
                    />
                  </svg>
                </slot>
              {:else}
                <span class="sr-only">Back</span>
                <svg
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10 19l-7-7m0 0l7-7m-7 7h18"
                  />
                </svg>
              {/if}
            </button>
          </div>
        {/if}
        {#if SLOTS.header}
          <div class={removePadding ? "p-4" : ""}>
            <slot name="header">
              <h3
                class="text-lg sm:text-xl font-semibold leading-6 text-gray-900"
                id="modal-title"
              >
                Modal Title
              </h3>
            </slot>
          </div>
        {/if}
        <div class={removePadding ? "" : "mt-3"}>
          <slot>
            <p class="text-sm sm:text-base text-gray-500">
              Modal content goes here.
            </p>
          </slot>
        </div>
      </div>
    </div>
  </div>
{/if}
