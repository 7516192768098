<script>
  import { createEventDispatcher } from "svelte";
  import View from "$app-components/blocks/_layouts/View.svelte";

  const dispatch = createEventDispatcher();

  export let block;
  export let updater;

  function handleDelete() {
    dispatch("delete-block");
  }
</script>

<View {block} {updater} on:delete-block={handleDelete}>
  <div class="flex-1 min-w-0 pl-2">
    <div class="text-gray-900 tiptap">
      {@html block?.content?.replaceAll("<p></p>", "<p><br /></p>")}
    </div>
  </div>
</View>
