<script>
  import Page from "$app-components/blocks/_layouts/Page.svelte";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;
  export let blockClicked = function () {};
  export let blockViewed = function () {};
  export let onActualPage;

  function getTwitchEmbedUrl(url) {
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?twitch\.tv\/(?:videos\/(\d+)|([a-zA-Z0-9_]+)(?:\/clip\/([a-zA-Z0-9-]+))?)/
    );
    if (match) {
      const videoId = match[1];
      const channelName = match[2];
      const clipId = match[3];

      if (videoId) {
        return `https://player.twitch.tv/?video=${videoId}&parent=${window.location.hostname}`;
      } else if (clipId) {
        return `https://clips.twitch.tv/embed?clip=${clipId}&parent=${window.location.hostname}`;
      } else if (channelName) {
        return `https://player.twitch.tv/?channel=${channelName}&parent=${window.location.hostname}`;
      }
    }
    return null;
  }

  $: twitchEmbedUrl = getTwitchEmbedUrl(block.url);
  $: directRedirect = !twitchEmbedUrl;
</script>

<Page
  {block}
  {theme}
  {buttonStyle}
  {hoverEffect}
  {blockClicked}
  {blockViewed}
  iframeUrl={twitchEmbedUrl}
  goToButtonText="Open in Twitch"
  width="1000px"
  height="600px"
  {directRedirect}
  {onActualPage}
/>
