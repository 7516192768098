<script>
  import { createEventDispatcher } from "svelte";
  import Card from "$components/Card.svelte";
  import Modal from "$components/Modal.svelte";
  import blockTypes from "../blocks/_blocks.js";

  const dispatch = createEventDispatcher();

  export let block;
  export let isOpen = false;
  export let updater;

  let saving = false;
  let BlockComponent = blockTypes[block.block_type]?.addEditComponent;

  const closeModal = () => {
    isOpen = false;
    dispatch("close");
  };

  const handleEditedBlock = (event) => {
    const block = event.detail;

    updater(({ blocks }) => {
      return { blocks: blocks.map((b) => (b.id === block.id ? block : b)) };
    });
  };

  $: if (!isOpen) {
    saving = false;
  }
</script>

{#if BlockComponent}
  <Modal bind:isOpen size="2xl" closeOnOutsideClick={false}>
    <Card size="full" shadow={false}>
      <svelte:fragment slot="content">
        <svelte:component
          this={BlockComponent}
          {block}
          on:close={closeModal}
          on:edited-block={handleEditedBlock}
        />
      </svelte:fragment>
    </Card>
  </Modal>
{/if}
