<script>
  import { onMount } from "svelte";
  import Toggle from "$components/Toggle.svelte";
  import Ajax from "$utils/ajax";
  import { notify } from "$utils/notify";

  export let page;

  let shareEnabled = page.share_page_enabled;
  let saving = false;

  async function toggleShare() {
    saving = true;
    const [error, response] = await Ajax.post(
      "/dashboard/settings/save_share",
      {
        share_page_enabled: shareEnabled,
      }
    );

    if (error) {
      notify({
        message: "Failed to update share settings",
        type: "error",
      });

      shareEnabled = !shareEnabled; // Revert the toggle
    } else {
      notify({
        message: "Share settings updated successfully",
        type: "success",
      });

      page.share_page_enabled = shareEnabled;
    }

    saving = false;
  }

  onMount(() => {
    shareEnabled = page.share_page_enabled;
  });
</script>

<div class="sm:px-6 lg:px-8">
  <div class="bg-white shadow rounded-lg p-4 sm:p-6">
    <h1 class="text-xl font-bold mb-6 sm:mb-12">Share Page</h1>

    <div
      class="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-4"
    >
      <div class="mb-4 sm:mb-0">
        <h2 class="text-lg font-semibold">Allow Visitors to Share Your Page</h2>
        <p class="text-gray-600 mt-1">
          Enable this option to display share options for visitors
        </p>
      </div>
      <Toggle
        bind:checked={shareEnabled}
        on:change={toggleShare}
        disabled={saving}
      />
    </div>

    <p class="text-sm text-gray-500 mt-4 sm:mt-6 leading-relaxed">
      When enabled, a share button (three dots) will appear in the top right
      corner of your page. This allows visitors to easily share your page on
      various social media platforms or copy the link. Enabling this feature can
      help increase your visibility and reach a wider audience.
    </p>

    {#if shareEnabled}
      <div
        class="mt-8 sm:mt-12 p-4 bg-purple-50 rounded-lg border border-purple-200"
      >
        <h3 class="text-lg font-semibold text-purple-700 mb-2">
          Share Options Enabled
        </h3>
        <p class="text-sm text-purple-600">
          Visitors can now share your page using the share button in the top
          right corner.
        </p>
      </div>
    {/if}
  </div>
</div>
