<script>
  import Dashboard from "$layouts/Dashboard.svelte";
  import Ajax from "$utils/ajax";
  import md5 from "md5";
  import Image from "$components/Image.svelte";
  import { notify } from "$utils/notify";

  export let subscribers;
  export let page;
  export let permissions;
  export let currentUser;

  let subscriptionsEnabled = page.subscriptions_enabled;

  async function toggleSubscriptions() {
    try {
      const response = await Ajax.post(
        "/dashboard/settings/save_subscription",
        {
          subscriptions_enabled: !subscriptionsEnabled,
        }
      );
      subscriptionsEnabled = !subscriptionsEnabled;
      notify({
        message: `Subscriptions ${subscriptionsEnabled ? "enabled" : "disabled"} successfully!`,
        type: "success",
      });
    } catch (error) {
      notify({
        message: "Failed to update subscription settings",
        type: "error",
      });
    }
  }

  function getGravatarUrl(email) {
    const hash = md5(email.toLowerCase().trim());
    return `https://www.gravatar.com/avatar/${hash}?d=identicon&s=40`;
  }

  async function downloadSubscribers() {
    try {
      const response = await fetch("/dashboard/subscribers/download", {
        method: "GET",
        headers: {
          Accept: "text/csv",
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `subscribers-${new Date().toISOString().split("T")[0]}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error("Download failed");
      }
    } catch (error) {
      notify({
        message: "Failed to download subscribers",
        type: "error",
      });
    }
  }
</script>

<Dashboard {currentUser} {permissions} {page}>
  <div class="bg-white shadow-lg rounded-xl p-4 md:p-8 space-y-6 md:space-y-10">
    <div
      class="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0"
    >
      <div class="flex items-center justify-between w-full md:w-auto">
        <h1 class="text-xl font-bold text-gray-800">Subscribers</h1>

        {#if permissions.settings}
          <div class="flex items-center space-x-3 md:hidden">
            <span class="text-sm font-medium text-gray-600">
              {subscriptionsEnabled ? "Enabled" : "Disabled"}
            </span>
            <button
              on:click={toggleSubscriptions}
              class="relative inline-flex items-center h-7 rounded-full w-14 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-colors duration-200 ease-in-out"
              class:bg-purple-600={subscriptionsEnabled}
              class:bg-gray-300={!subscriptionsEnabled}
            >
              <span
                class="inline-block w-5 h-5 transform transition ease-in-out duration-200 bg-white rounded-full shadow-md"
                class:translate-x-8={subscriptionsEnabled}
                class:translate-x-1={!subscriptionsEnabled}
              ></span>
            </button>
          </div>
        {:else}
          <div class="flex items-center mt-10 space-x-3 md:hidden"></div>
        {/if}
      </div>
      <div
        class="flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-6 w-full md:w-auto"
      >
        {#if permissions.settings}
          <div class="hidden md:flex items-center space-x-3 w-full md:w-auto">
            <span class="text-sm font-medium text-gray-600">
              {subscriptionsEnabled ? "Enabled" : "Disabled"}
            </span>
            <button
              on:click={toggleSubscriptions}
              class="relative inline-flex items-center h-7 rounded-full w-14 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-colors duration-200 ease-in-out"
              class:bg-purple-600={subscriptionsEnabled}
              class:bg-gray-300={!subscriptionsEnabled}
            >
              <span
                class="inline-block w-5 h-5 transform transition ease-in-out duration-200 bg-white rounded-full shadow-md"
                class:translate-x-8={subscriptionsEnabled}
                class:translate-x-1={!subscriptionsEnabled}
              ></span>
            </button>
          </div>
        {/if}
        <button
          on:click={downloadSubscribers}
          class="w-full md:w-auto px-4 py-2 bg-gradient-to-r from-purple-600 to-indigo-600 text-white text-sm font-medium rounded-lg shadow-md hover:from-purple-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 flex items-center justify-center space-x-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
          <span>Download</span>
        </button>
      </div>
    </div>

    {#if subscribers.length > 0}
      <div class="overflow-x-auto rounded-lg shadow">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-purple-50">
            <tr>
              <th
                class="px-4 md:px-6 py-3 text-left text-xs font-semibold text-purple-600 uppercase tracking-wider"
                >User</th
              >
              <th
                class="px-4 md:px-6 py-3 text-left text-xs font-semibold text-purple-600 uppercase tracking-wider"
                >Email</th
              >
              <th
                class="px-4 md:px-6 py-3 text-left text-xs font-semibold text-purple-600 uppercase tracking-wider hidden md:table-cell"
                >Subscribed On</th
              >
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            {#each subscribers as subscriber}
              <tr class="hover:bg-purple-50 transition-colors duration-150">
                <td class="px-4 md:px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 h-8 w-8 md:h-10 md:w-10">
                      <Image
                        klass="h-8 w-8 md:h-10 md:w-10 rounded-full object-cover border-2 border-purple-200"
                        src={getGravatarUrl(subscriber.email)}
                        alt=""
                      />
                    </div>
                  </div>
                </td>
                <td
                  class="px-4 md:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >{subscriber.email}</td
                >
                <td
                  class="px-4 md:px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden md:table-cell"
                >
                  {new Date(subscriber.created_at).toLocaleDateString(
                    undefined,
                    { year: "numeric", month: "long", day: "numeric" }
                  )}
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
    {:else}
      <div
        class="text-center py-8 md:py-12 bg-purple-50 rounded-lg border-2 border-purple-200"
      >
        <svg
          class="mx-auto h-12 w-12 md:h-16 md:w-16 text-purple-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
          />
        </svg>
        <h3 class="mt-4 text-lg font-semibold text-gray-900">
          No subscribers yet
        </h3>
        <p
          class="mt-2 text-sm text-gray-600 max-w-md mx-auto leading-relaxed px-4"
        >
          {#if !subscriptionsEnabled}
            To start collecting subscribers, you need to enable the subscription
            feature. Use the toggle above to enable subscriptions.
          {:else if subscriptionsEnabled}
            You haven't received any subscribers yet. Share your profile to
            start growing your audience!
          {/if}
        </p>
      </div>
    {/if}
  </div>
</Dashboard>
