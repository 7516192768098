<script>
  import { getBlockStyle, getBorderRadius } from "$utils/block-style";
  import Page from "$app-components/blocks/_layouts/Page.svelte";

  export let block;
  export let theme;
  export let buttonStyle;
  export let blockClicked = function () {};
  export let blockViewed = function () {};

  $: currentButtonStyle = getBlockStyle(theme, buttonStyle, block);
  $: blockRadius = getBorderRadius(buttonStyle, block);
</script>

<Page {block} {blockClicked} {blockViewed} disableStyles={true}>
  <div
    class="overflow-hidden transition-all ease-in-out w-full"
    style="{currentButtonStyle} border-radius: {blockRadius}; box-shadow: none !important;">
    <div
      class="p-4 transition-colors ease-in-out"
      style="
      color: {buttonStyle && buttonStyle.startsWith('fill')
        ? 'var(--buttonTextColor)'
        : 'var(--linkColor)'};
      border-radius: {blockRadius};
    ">
      <div class="flex-grow">
        <div
          class="tiptap"
          style="
        font-weight: var(--textFontWeight);
        font-size: var(--textFontSize);
      ">
          {@html block?.content?.replaceAll("<p></p>", "<p><br /></p>")}
        </div>
      </div>
    </div>
  </div>
</Page>
