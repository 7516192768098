<script>
  import { onMount } from "svelte";

  import SocialLinks from "$app-components/SocialLinks.svelte";

  import GridLayout from "$app-components/layouts/Grid.svelte";
  import ListLayout from "$app-components/layouts/List.svelte";
  import blockTypes from "$app-components/blocks/_blocks.js";
  import { getThemeFromId } from "$utils/themes";

  const SLOTS = $$slots;

  export let blocks;
  export let page;
  export let onActualPage = false;

  export let klass;
  export let staticGrid = true;
  export let blockClicked;
  export let blockViewed;
  export let socialLinkClicked;

  $: theme = getThemeFromId(page).theme;
  $: hoverEffect = page.hover_effect;
  $: buttonStyle = page.button_style;
  $: layoutStyle = page.layout_style;
  $: font = page.font;
  $: gridData = page.grid_data;

  $: blocks = (blocks ?? []).filter((block) => block.enabled && !block.hidden);

  let container;
  let backgroundContainer;
  let mounted = false;
  let containerWidth = 0;
  let containerHeight = 0;
  let isMobile = false;
  let height = "h-screen";

  $: if (backgroundContainer && mounted) {
    Object.entries(theme).forEach(([key, value]) => {
      if (key === "backgroundImage") {
        if (containerWidth >= 768 && containerHeight >= 500) {
          backgroundContainer.style.backgroundImage = value;
          backgroundContainer.style.backgroundSize = "cover";
          backgroundContainer.style.backgroundPosition = "center";
          backgroundContainer.style.backgroundRepeat = "no-repeat";
          backgroundContainer.style.backgroundAttachment = "fixed";
        } else {
          backgroundContainer.style.backgroundImage = value;
          backgroundContainer.style.backgroundSize = "cover";
          backgroundContainer.style.backgroundPosition = "center";
          backgroundContainer.style.backgroundRepeat = "no-repeat";
          backgroundContainer.style.backgroundAttachment = "scroll";
        }

        if (theme.backgroundImageBlur) {
          backgroundContainer.style.filter = `blur(${theme.backgroundImageBlur}px)`;
        } else {
          backgroundContainer.style.filter = "none";
        }

        if (theme.backgroundImageOpacity) {
          backgroundContainer.style.opacity =
            theme.backgroundImageOpacity / 100;
        } else {
          backgroundContainer.style.opacity = "1";
        }
      } else if (key === "backgroundColor") {
        backgroundContainer.style.backgroundColor = value;
        backgroundContainer.style.backgroundImage = "none";
      }
    });
  }

  $: if (container && mounted) {
    Object.entries(theme).forEach(([key, value]) => {
      if (key !== "backgroundImage" && key !== "backgroundColor") {
        container.style.setProperty(`--${key}`, value);
      }
    });
  }

  function getBlockComponent(block) {
    return blockTypes[block.block_type]?.pageComponent || null;
  }

  function handleResize() {
    if (container) {
      containerWidth = container.offsetWidth;
      containerHeight = container.offsetHeight;
      isMobile = containerWidth < 810;

      // Compare viewport height with container height and use h-screen only if container is smaller
      const viewportHeight = window.innerHeight;
      const fullHeight = container.scrollHeight;
      height = fullHeight <= viewportHeight ? "h-screen" : "h-full";
    }
  }

  onMount(() => {
    mounted = true;

    if (font !== "inherit") {
      const link = document.createElement("link");
      link.href = `https://fonts.googleapis.com/css?family=${encodeURIComponent(font)}:400,500,700`;
      link.rel = "stylesheet";
      document.head.appendChild(link);
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
</script>

<div
  bind:this={backgroundContainer}
  class="{onActualPage ? 'fixed' : 'absolute'} inset-0 w-full {height}"
></div>

<div
  bind:this={container}
  class="@container block-viewer w-full h-full relative overflow-x-hidden flex-grow {klass}"
  style="
      color: var(--textColor);
      font-family: {font}, sans-serif;
      {onActualPage
    ? ''
    : 'scrollbar-width: thin; scrollbar-color: rgba(0, 0, 0, 0.6) transparent; -webkit-overflow-scrolling: touch;'}
    "
>
  <div class="mx-auto py-0 px-4 relative z-1 mb-16">
    {#if SLOTS.header}
      <slot name="header" />
    {/if}

    {#if page.social_links.length > 0 && page.social_links_placement === "top"}
      <div class="{page.bio && page.bio != '' ? '-mt-8' : '-mt-10'} mb-12">
        <SocialLinks {page} {socialLinkClicked} />
      </div>
    {/if}

    {#if !isMobile && layoutStyle === "grid"}
      <GridLayout
        {blocks}
        {theme}
        {hoverEffect}
        {buttonStyle}
        {getBlockComponent}
        {font}
        {gridData}
        {staticGrid}
        {blockClicked}
        {blockViewed}
        {onActualPage}
      />
    {:else}
      <ListLayout
        {blocks}
        {theme}
        {hoverEffect}
        {buttonStyle}
        {getBlockComponent}
        {font}
        {blockClicked}
        {blockViewed}
        {onActualPage}
      />
    {/if}

    {#if page.social_links.length > 0 && page.social_links_placement === "bottom"}
      <SocialLinks {page} {socialLinkClicked} />
    {/if}

    {#if SLOTS.footer}
      <slot name="footer" />
    {/if}
  </div>
</div>
