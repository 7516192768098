<script>
  let demoFields = [
    {
      type: "text",
      label: "Full Name",
      placeholder: "Enter your name",
      required: true,
    },
    {
      type: "select",
      label: "Role",
      placeholder: "Select your role",
      required: true,
      options: ["Content Creator", "Marketing Professional", "Other"],
    },
    {
      type: "email",
      label: "Email Address",
      placeholder: "Enter your email",
      required: true,
    },
  ];

  function cycleFieldType(index) {
    const types = ["text", "email", "select", "number"];
    const currentIndex = types.indexOf(demoFields[index].type);
    demoFields[index].type = types[(currentIndex + 1) % types.length];
    demoFields = demoFields;
  }
</script>

<div class="bg-white rounded-2xl shadow-xl p-4 sm:p-6 border border-purple-100">
  <div class="flex flex-col sm:flex-row gap-4">
    <!-- Sidebar -->
    <div class="w-full sm:w-2/6">
      <div
        class="bg-gradient-to-br from-purple-50 to-purple-100/50 p-4 rounded-xl shadow-sm border border-purple-100"
      >
        <h3 class="text-sm font-semibold text-purple-800 mb-3">Field Types</h3>
        <div class="flex flex-wrap gap-2">
          <span
            class="text-xs bg-white px-3 py-1.5 rounded-full shadow-sm border border-purple-100 text-purple-700 hover:bg-purple-50 transition-colors duration-150 cursor-pointer"
          >
            Single Line Text
          </span>
          <span
            class="text-xs bg-white px-3 py-1.5 rounded-full shadow-sm border border-purple-100 text-purple-700 hover:bg-purple-50 transition-colors duration-150 cursor-pointer"
          >
            Multi-line Text
          </span>
          <span
            class="text-xs bg-white px-3 py-1.5 rounded-full shadow-sm border border-purple-100 text-purple-700 hover:bg-purple-50 transition-colors duration-150 cursor-pointer"
          >
            Email Address
          </span>
          <span
            class="text-xs bg-white px-3 py-1.5 rounded-full shadow-sm border border-purple-100 text-purple-700 hover:bg-purple-50 transition-colors duration-150 cursor-pointer"
          >
            Phone Number
          </span>
          <span
            class="text-xs bg-white/70 px-3 py-1.5 rounded-full shadow-sm border border-purple-100/50 text-purple-500 hover:bg-purple-50 transition-colors duration-150 cursor-pointer"
          >
            + 6 more
          </span>
        </div>
      </div>
    </div>

    <!-- Form Fields -->
    <div class="w-full sm:w-4/6 space-y-3 sm:space-y-4">
      {#each demoFields as field}
        <div
          class="bg-white rounded-lg p-3 sm:p-4 border border-gray-200 shadow-sm hover:border-purple-200 transition-colors duration-150"
        >
          <label class="block text-sm font-medium text-gray-700 mb-2 sm:mb-3">
            {field.label}
            {#if field.required}<span class="text-red-500">*</span>{/if}
          </label>

          {#if field.type === "select"}
            <select
              class="w-full p-2 sm:p-2.5 border border-gray-200 rounded-md bg-gray-50 text-gray-500 cursor-not-allowed text-sm sm:text-base"
            >
              <option value="">{field.placeholder}</option>
              {#each field.options as option}
                <option>{option}</option>
              {/each}
            </select>
          {:else}
            <input
              type={field.type}
              class="w-full p-2 sm:p-2.5 border border-gray-200 rounded-md bg-gray-50 text-gray-500 cursor-not-allowed text-sm sm:text-base"
              placeholder={field.placeholder}
              disabled
            />
          {/if}
        </div>
      {/each}
    </div>
  </div>
</div>
