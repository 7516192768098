<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { tweened } from "svelte/motion";
  import { cubicOut } from "svelte/easing";
  import { fade } from "svelte/transition";
  import { spring } from "svelte/motion";

  export let showArrows = true;
  export let showDots = true;
  export let autoplay = false;
  export let autoplayInterval = 5000;
  export let transitionDuration = 100;
  export let items = [];

  let currentIndex = 0;
  let container;
  let isHovered = false;
  let dotsContainer;
  let dotWidth = 6;
  let dotGap = 8;
  let visibleDots = 5;
  let touchStartX = 0;
  let touchStartY = 0;
  let isDragging = false;
  let startDragX = 0;
  let dragX = spring(0, {
    stiffness: 0.2,
    damping: 0.8,
  });

  const dispatch = createEventDispatcher();

  const dotsPosition = tweened(0, {
    duration: transitionDuration,
    easing: cubicOut,
  });

  $: if (items && items.length > 0 && dotsContainer) {
    const containerWidth = dotsContainer.offsetWidth;
    const totalDotsWidth = items.length * (dotWidth + dotGap) - dotGap;
    const maxScroll = Math.max(0, totalDotsWidth - containerWidth);

    if (items.length <= visibleDots) {
      dotsPosition.set((containerWidth - totalDotsWidth) / 2);
    } else {
      let centerIndex = Math.floor(visibleDots / 2);
      let scrollIndex = Math.min(
        Math.max(0, currentIndex - centerIndex),
        Math.max(0, items.length - visibleDots)
      );

      const scrollAmount = scrollIndex * (dotWidth + dotGap);
      dotsPosition.set(-scrollAmount);
    }
  }

  onMount(() => {
    if (autoplay) startAutoplay();
  });

  function next() {
    if (items && items.length > 0) {
      if (currentIndex < items.length - 1) {
        currentIndex++;
      } else {
        currentIndex = 0;
      }
      dragX.set(0);
      dispatch("change", { index: currentIndex });
    }
  }

  function prev() {
    if (items && items.length > 0) {
      if (currentIndex > 0) {
        currentIndex--;
      } else {
        currentIndex = items.length - 1;
      }
      dragX.set(0);
      dispatch("change", { index: currentIndex });
    }
  }

  function goTo(index) {
    if (items && items.length > 0) {
      currentIndex = index;
      dragX.set(0);
      dispatch("change", { index });
    }
  }

  function startAutoplay() {
    setInterval(() => {
      next();
    }, autoplayInterval);
  }

  function handleMouseEnter() {
    isHovered = true;
  }

  function handleMouseLeave() {
    isHovered = false;
  }

  function handleTouchStart(event) {
    isDragging = true;
    touchStartX = event.touches[0].clientX;
    touchStartY = event.touches[0].pageY;
    startDragX = $dragX;
  }

  function handleTouchMove(event) {
    if (!isDragging) return;

    const currentX = event.touches[0].clientX;
    const currentY = event.touches[0].pageY;
    const deltaX = currentX - touchStartX;
    const deltaY = currentY - touchStartY;

    // If vertical scrolling is dominant, don't prevent default
    if (Math.abs(deltaY) > Math.abs(deltaX)) {
      return;
    }

    // Only prevent default for horizontal swipes
    event.preventDefault();
    dragX.set(startDragX + deltaX);
  }

  function handleTouchEnd(event) {
    if (!isDragging) return;
    isDragging = false;
    const threshold = container.offsetWidth * 0.15; // Reduced threshold to make it easier to swipe

    if (Math.abs($dragX) > threshold) {
      if ($dragX > 0) {
        prev();
      } else {
        next();
      }
    }
    dragX.set(0);
  }
</script>

<style>
  .carousel-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    touch-action: pan-y pinch-zoom; /* Allow vertical scrolling */
  }

  .carousel-track {
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform 0.2s ease-out;
  }

  .carousel-item {
    position: absolute;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    transition: transform 0.2s ease-out;
  }

  .carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.9);
    border: none;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition:
      background 0.3s ease,
      opacity 0.3s ease;
    opacity: 0.8;
    color: #000;
    z-index: 2;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .carousel-arrow:hover {
    background: rgba(255, 255, 255, 1);
    opacity: 1;
  }

  .carousel-arrow-left {
    left: 10px;
  }

  .carousel-arrow-right {
    right: 10px;
  }

  .carousel-dots-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.3));
    pointer-events: none;
    z-index: 2;
  }

  .carousel-dots-container {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    width: 100px;
    pointer-events: auto;
  }

  .carousel-dots {
    display: flex;
    gap: 4px;
    transition: transform 0.3s ease;
  }

  .carousel-dot {
    width: 10px;
    height: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    position: relative;
    flex-shrink: 0;
    margin-left: 2px;
    margin-right: 2px;
  }

  .dot-indicator {
    display: block;
    width: 6px;
    height: 6px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    transition: all 0.3s ease;
  }

  .carousel-dot.active .dot-indicator,
  .carousel-dot:hover .dot-indicator {
    background: #fff;
    transform: scale(1.5);
  }
</style>

<div
  class="carousel-container"
  bind:this={container}
  on:mouseenter={handleMouseEnter}
  on:mouseleave={handleMouseLeave}
  on:touchstart|passive={handleTouchStart}
  on:touchmove|passive={handleTouchMove}
  on:touchend|passive={handleTouchEnd}>
  {#if items && items.length > 0}
    <div class="carousel-track" style="transform: translateX({$dragX}px)">
      {#each items as item, index}
        <div
          class="carousel-item"
          class:active={currentIndex === index}
          style="transform: translateX({100 * (index - currentIndex)}%)">
          <slot name="item" {item} {index}></slot>
        </div>
      {/each}
    </div>
  {/if}

  {#if showArrows && items && items.length > 1 && (isHovered || !window.matchMedia("(hover: hover)").matches)}
    <button
      class="carousel-arrow carousel-arrow-left"
      on:click|preventDefault|stopPropagation={prev}
      aria-label="Previous">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        width="18"
        height="18">
        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
      </svg>
    </button>
    <button
      class="carousel-arrow carousel-arrow-right"
      on:click|preventDefault|stopPropagation={next}
      aria-label="Next">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        width="18"
        height="18">
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </button>
  {/if}

  {#if showDots && items && items.length > 1}
    <div class="carousel-dots-overlay">
      <div class="carousel-dots-container" bind:this={dotsContainer}>
        <div
          class="carousel-dots"
          style="transform: translateX({$dotsPosition}px);">
          {#each items as _, dotIndex}
            <button
              class="carousel-dot"
              class:active={currentIndex === dotIndex}
              on:click|preventDefault|stopPropagation={() => goTo(dotIndex)}
              aria-label="Go to slide {dotIndex + 1}">
              <span class="dot-indicator"></span>
            </button>
          {/each}
        </div>
      </div>
    </div>
  {/if}
</div>
