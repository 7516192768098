<script>
  import { notify } from "$utils/notify";
  import Ajax from "$utils/ajax";

  export let page;

  let facebookPixelId = page.facebook_pixel_id || "";
  let googleMeasurementId = page.google_analytics_id || "";

  let isSaving = false;
  let errors = {};

  async function handleSubmit() {
    if (isSaving) return;
    isSaving = true;
    errors = {};

    const data = {
      facebook_pixel_id: facebookPixelId,
      google_analytics_id: googleMeasurementId,
    };

    const [ajaxErrors, resp] = await Ajax.post(
      "/dashboard/settings/save_analytics",
      data
    );

    if (ajaxErrors) {
      errors = ajaxErrors;
      notify({
        message:
          "Failed to update analytics settings. Please check the errors and try again.",
        type: "error",
      });
    } else {
      notify({
        message: "Analytics settings saved successfully",
        type: "success",
      });

      page.facebook_pixel_id = facebookPixelId;
      page.google_analytics_id = googleMeasurementId;
    }

    isSaving = false;
  }
</script>

<div class="sm:px-6 lg:px-8">
  <div class="bg-white shadow rounded-lg p-4 sm:p-6">
    <h3 class="text-xl font-bold mb-5">Analytics Integration</h3>

    <div class="mt-4 max-w-xl text-sm text-gray-500">
      <p>Configure your analytics integrations to track user behavior.</p>
    </div>

    <form on:submit|preventDefault={handleSubmit} class="mt-12 space-y-6">
      <div class="space-y-6 sm:space-y-5">
        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
          <label
            for="facebookPixelId"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Facebook Pixel ID
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              bind:value={facebookPixelId}
              class="max-w-full w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md {errors.facebook_pixel_id
                ? 'border-red-500'
                : ''}"
              placeholder="e.g., 1234567890"
            />
            {#if errors.facebook_pixel_id}
              <p class="mt-2 text-sm text-red-600">
                {errors.facebook_pixel_id}
              </p>
            {/if}
          </div>
        </div>

        <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
        >
          <label
            for="googleMeasurementId"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Google Measurement ID
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              id="googleMeasurementId"
              bind:value={googleMeasurementId}
              class="max-w-full w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md {errors.google_measurement_id
                ? 'border-red-500'
                : ''}"
              placeholder="e.g., G-XXXXXXXXXX"
            />
            {#if errors.google_measurement_id}
              <p class="mt-2 text-sm text-red-600">
                {errors.google_measurement_id}
              </p>
            {/if}
          </div>
        </div>
      </div>
      <div class="pt-5">
        <div class="flex justify-center">
          <button
            type="submit"
            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none"
            disabled={isSaving}
          >
            {#if isSaving}
              <span class="inline-flex items-center">
                <svg
                  class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Saving...
              </span>
            {:else}
              Save settings
            {/if}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
