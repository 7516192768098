<script>
  import Dashboard from "$layouts/Dashboard.svelte";
  import { onMount } from "svelte";
  import { fade, slide } from "svelte/transition";

  import IphonePreview from "./IphonePreview.svelte";
  import ProfileSettings from "./appearance/ProfileSettings.svelte";
  import LayoutSettings from "./appearance/LayoutSettings.svelte";
  import ThemeSelector from "./appearance/ThemeSelector.svelte";
  import CustomizeSettings from "./appearance/CustomizeSettings.svelte";
  import MobilePreview from "./MobilePreview.svelte";

  import { notify } from "$utils/notify";
  import { alertErrors } from "$utils/form.js";
  import Ajax from "$utils/ajax";

  export let blocks;
  export let permissions;
  export let page;
  export let currentUser;

  let activeTab = "Profile";
  let saving = false;
  let isPreviewModalOpen = false;
  let hasChanges = false;
  let initialPageState = JSON.stringify(page);
  let showFixedBar = false;
  let topBarElement;

  onMount(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        showFixedBar = !entry.isIntersecting;
      },
      { threshold: 0 }
    );

    if (topBarElement) {
      observer.observe(topBarElement);
    }

    return () => {
      if (topBarElement) {
        observer.unobserve(topBarElement);
      }
    };
  });

  // Watch for changes to page object
  $: {
    const currentPageState = JSON.stringify(page);
    hasChanges = currentPageState !== initialPageState;
  }

  const handleSave = async () => {
    if (saving) return;
    saving = true;

    const [errors, resp] = await Ajax.put("/dashboard/appearance/me", page);

    if (errors) {
      notify({ message: alertErrors(errors), type: "error" });
    } else {
      notify({
        message: "Appearance updated",
        type: "success",
        duration: 1000,
      });
      initialPageState = JSON.stringify(page);
      hasChanges = false;
    }

    saving = false;
  };

  const togglePreviewModal = () => {
    isPreviewModalOpen = !isPreviewModalOpen;
  };
</script>

<Dashboard {currentUser} {permissions} {page}>
  <div class="flex flex-col lg:flex-row gap-4 sm:gap-8 -mt-10 pb-20">
    <div class="w-full lg:w-3/5 p-2">
      <div class="mb-5 mt-4 -ml-1" bind:this={topBarElement}>
        <div
          class="flex flex-col sm:flex-row justify-between items-center bg-gray-100 rounded-lg p-2"
        >
          <div
            class="flex flex-wrap justify-center sm:justify-start mb-2 sm:mb-0"
          >
            <button
              class={`py-2 px-4 rounded-md mb-2 sm:mb-0 ${activeTab === "Profile" ? "bg-white shadow-md" : "hover:bg-gray-200"}`}
              on:click={() => (activeTab = "Profile")}
            >
              Profile
            </button>
            <button
              class={`py-2 px-4 rounded-md mb-2 sm:mb-0 sm:ml-2 ${activeTab === "Layout" ? "bg-white shadow-md" : "hover:bg-gray-200"}`}
              on:click={() => (activeTab = "Layout")}
            >
              Layout
            </button>
            <button
              class={`py-2 px-4 rounded-md mb-2 sm:mb-0 sm:ml-2 ${activeTab === "Themes" ? "bg-white shadow-md" : "hover:bg-gray-200"}`}
              on:click={() => (activeTab = "Themes")}
            >
              Themes
            </button>
            <button
              class={`py-2 px-4 rounded-md mb-2 sm:mb-0 sm:ml-2 ${activeTab === "Customize" ? "bg-white shadow-md" : "hover:bg-gray-200"}`}
              on:click={() => (activeTab = "Customize")}
            >
              Customize
            </button>
          </div>
          <button
            class="bg-purple-600 hover:bg-purple-700 text-white py-2 px-4 rounded-md w-full sm:w-auto flex items-center justify-center disabled:opacity-50 disabled:hover:bg-purple-600"
            on:click={handleSave}
            disabled={saving || !hasChanges}
          >
            {#if saving}
              <svg
                class="animate-spin h-5 w-5 mr-3 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8H4z"
                ></path>
              </svg>
              Saving...
            {:else}
              Save Changes
            {/if}
          </button>
        </div>
      </div>

      {#if showFixedBar && hasChanges}
        <div
          class="fixed sm:hidden top-0 left-0 right-0 bg-white shadow-md z-50 transition-all duration-100 ease-in-out"
          transition:slide={{ duration: 50 }}
        >
          <div
            class="container mx-auto px-4 py-2 flex justify-between items-center"
          >
            <button
              class="w-full sm:w-auto bg-purple-600 hover:bg-purple-700 text-white py-1.5 sm:py-2 px-4 rounded-md flex items-center justify-center disabled:opacity-50 disabled:hover:bg-purple-600"
              on:click={handleSave}
              disabled={saving || !hasChanges}
            >
              {saving ? "Saving..." : "Save Changes"}
            </button>
          </div>
        </div>
      {/if}

      <div class="mt-6 space-y-4">
        <div class={activeTab === "Profile" ? "" : "hidden"}>
          <ProfileSettings bind:page />
        </div>
        <div class={activeTab === "Layout" ? "" : "hidden"}>
          <LayoutSettings bind:page {blocks} />
        </div>
        <div class={activeTab === "Themes" ? "" : "hidden"}>
          <ThemeSelector bind:page />
        </div>
        <div class={activeTab === "Customize" ? "" : "hidden"}>
          <CustomizeSettings bind:page />
        </div>
      </div>
    </div>
    <div
      class="hidden lg:block p-4 lg:fixed lg:top-6 lg:right-0 lg:mr-4 mt-8 lg:mt-0"
    >
      {#key page.font}
        <IphonePreview {blocks} {page} />
      {/key}
    </div>
  </div>

  <!-- Mobile preview button -->
  <div
    class="lg:hidden fixed bottom-16 mb-2 left-0 right-0 z-10 flex justify-center"
  >
    <button
      on:click={togglePreviewModal}
      class="px-4 py-2 rounded-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white shadow-lg hover:from-purple-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition duration-300 ease-in-out transform hover:scale-105 flex items-center space-x-2"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
        />
      </svg>
      <span class="font-semibold text-sm">Preview</span>
    </button>
  </div>

  <!-- Mobile preview modal -->
  <MobilePreview bind:isOpen={isPreviewModalOpen} {blocks} {page} />
</Dashboard>
