<script>
  import Modal from "$components/Modal.svelte";
  import Ajax from "$utils/ajax";
  import { notify } from "$utils/notify";

  export let block;
  export let updater;

  let isModalOpen = false;
  let isSaving = false;
  let selectedStyle = block.layout_style || "classic";
  let previewImage = null;

  const excludedTypes = ["text", "image", "heading"];

  const displayStyles = [
    {
      id: "classic",
      label: "Classic",
      description: "Clean and elegant layout",
      icon: `<path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />`,
    },
    {
      id: "featured",
      label: "Featured",
      description:
        "Bold and immersive design with full-width image and text overlay - great for visual impact",
      icon: `<path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />`,
    },
  ];

  async function loadPreviewImage() {
    const img = new Image();
    img.src =
      "https://images.unsplash.com/photo-1506744038136-46273834b3fb?w=300&h=300&fit=crop";
    await img.decode();
    previewImage = img.src;
  }

  function closeModal() {
    isModalOpen = false;
  }

  async function saveAndClose() {
    isSaving = true;

    const [error, resp] = await Ajax.put(
      `/dashboard/blocks/${block.id}/update_layout_style`,
      {
        layout_style: selectedStyle,
      }
    );

    if (error) {
      notify({
        type: "error",
        message: error?.data?.[0] || "An error occurred. Please try again.",
      });
    } else {
      block.layout_style = selectedStyle;

      updater(({ blocks }) => {
        return { blocks: blocks.map((b) => (b.id === block.id ? block : b)) };
      });

      closeModal();
    }

    isSaving = false;
  }

  $: if (isModalOpen && !previewImage) {
    loadPreviewImage();
  }
</script>

<Modal bind:isOpen={isModalOpen} size="2xl">
  <div slot="header">
    <h3 class="text-lg font-bold text-gray-900">Choose Your Link Style</h3>
  </div>

  <div class="mt-4">
    <div class="bg-gradient-to-br from-purple-50 to-violet-50 rounded-lg p-4">
      <div class="flex flex-col gap-4">
        {#each displayStyles as style}
          <button
            class="group flex flex-col sm:flex-row bg-white rounded-xl border-2 transition-all duration-200 hover:shadow-lg overflow-hidden {selectedStyle ===
            style.id
              ? 'border-purple-500 ring-2 ring-purple-200'
              : 'border-gray-200 hover:border-purple-300'}"
            on:click={() => (selectedStyle = style.id)}
          >
            <div class="flex-1 p-4">
              <div class="flex items-center mb-2">
                <div class="bg-purple-100 rounded-full p-2 mr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 text-purple-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    {@html style.icon}
                  </svg>
                </div>
                <span class="font-semibold text-base text-gray-900"
                  >{style.label}</span
                >
                {#if selectedStyle === style.id}
                  <div class="ml-2 text-purple-600">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                {/if}
              </div>
              <p
                class="text-gray-600 text-xs sm:text-sm leading-relaxed text-left mt-3"
              >
                {style.description}
              </p>
            </div>

            <div
              class="w-full sm:w-[200px] bg-gray-50 p-3 flex items-center justify-center border-t sm:border-l border-gray-100"
            >
              {#if style.id === "classic"}
                <div class="h-24 sm:h-28 flex items-center justify-center">
                  <div
                    class="flex items-center p-3 bg-white rounded-lg shadow-sm w-full"
                  >
                    <div
                      class="w-10 h-10 rounded mr-3 bg-gray-200 flex items-center justify-center"
                    >
                      {#if previewImage}
                        <img
                          src={previewImage}
                          alt="Mountain landscape"
                          class="w-10 h-10 object-cover rounded"
                        />
                      {/if}
                    </div>
                    <div class="text-center flex-1 text-sm">
                      Your Link Title
                    </div>
                  </div>
                </div>
              {:else}
                <div
                  class="relative h-24 sm:h-28 w-full rounded-lg overflow-hidden"
                >
                  <div
                    class="w-full h-full bg-gray-200"
                    style={previewImage
                      ? `background-image: url('${previewImage}'); background-size: cover; background-position: center;`
                      : ""}
                  ></div>
                  <div
                    class="absolute inset-x-0 bottom-0 bg-gradient-to-t from-black/80 to-black/10 p-3"
                  >
                    <div
                      class="text-white text-sm font-medium text-left drop-shadow-md"
                    >
                      Your Link Title
                    </div>
                  </div>
                </div>
              {/if}
            </div>
          </button>
        {/each}
      </div>
    </div>

    <div class="mt-4 flex justify-end space-x-2 border-t pt-4">
      <button
        type="button"
        class="px-3 py-2 text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none transition-colors duration-200"
        on:click={closeModal}
        disabled={isSaving}
      >
        Cancel
      </button>
      <button
        type="button"
        class="inline-flex justify-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none disabled:opacity-50 transition-colors duration-200"
        on:click={saveAndClose}
        disabled={isSaving}
      >
        {#if isSaving}
          <svg
            class="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            />
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
          Saving...
        {:else}
          Save
        {/if}
      </button>
    </div>
  </div>
</Modal>

{#if excludedTypes.includes(block.block_type) === false && block.image_url}
  <button
    on:click={() => (isModalOpen = true)}
    class="text-gray-400 hover:text-purple-500 transition-colors duration-200 ease-in-out relative group flex items-center mr-4"
    title="Design"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-4 w-4"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="M12 20h9" />
      <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" />
    </svg>
    <span
      class="absolute bottom-[140%] left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap"
    >
      Design
    </span>
  </button>
{/if}
