<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { fade, fly } from "svelte/transition";

  export let isOpen = false;
  export let iframeUrl = "";
  export let title = "";
  export let width = "325px";
  export let height = "740px";
  export let goToButtonText = ""; // New prop for customizable button text
  export let goToButtonUrl;
  export let klass = "";

  const dispatch = createEventDispatcher();

  let isMobile = false;
  let scrollbarWidth;

  function closeModal() {
    dispatch("close");
  }

  function checkMobile() {
    isMobile = window.innerWidth <= 768;
  }

  function goToUrl() {
    window.open(goToButtonUrl || iframeUrl, "_blank");
  }

  function disableBodyScroll() {
    scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = `${scrollbarWidth}px`;
  }

  function enableBodyScroll() {
    document.body.style.overflow = "";
    document.body.style.paddingRight = "";
  }

  onMount(() => {
    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  });

  $: if (isOpen) {
    disableBodyScroll();
  } else {
    setTimeout(enableBodyScroll, 200); // Delay enabling scroll to match transition duration
  }
</script>

{#if isOpen && iframeUrl}
  <div
    class="fixed inset-0 bg-black bg-opacity-75 flex flex-col justify-center items-center z-50"
    on:click={closeModal}
    transition:fade={{ duration: 100 }}
  >
    <div
      class="bg-transparent relative rounded-lg overflow-hidden"
      style="max-width: {isMobile
        ? 'calc(100% - 30px)'
        : width}; max-height: {isMobile
        ? 'calc(100% - 80px)'
        : height}; width: calc(100% - 30px); height: calc(100% - 80px);"
      on:click|stopPropagation
      transition:fly={{ y: 200, duration: 500 }}
    >
      <div class="relative w-full h-full">
        <div class="absolute inset-0 flex items-center justify-center">
          <div
            class="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white-900"
          ></div>
        </div>
        <iframe
          src={iframeUrl}
          {title}
          class="absolute top-0 left-0 w-full h-full {klass}"
          frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div
      class="flex items-center justify-center mt-4 space-x-4"
      transition:fly={{ y: 50, duration: 500, delay: 200 }}
    >
      <button
        class="bg-white text-gray-800 rounded-full px-4 py-2 flex items-center justify-center cursor-pointer transition-all duration-300 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 shadow-lg"
        on:click|stopPropagation|preventDefault={closeModal}
      >
        <i class="fas fa-times mr-2"></i>
        <span>Close</span>
      </button>
      {#if goToButtonText}
        <button
          class="bg-blue-500 text-white rounded-full px-4 py-2 flex items-center justify-center cursor-pointer transition-all duration-300 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 shadow-lg"
          on:click|stopPropagation|preventDefault={goToUrl}
        >
          <i class="fas fa-external-link-alt mr-2"></i>
          <span>{goToButtonText}</span>
        </button>
      {/if}
    </div>
  </div>
{/if}
