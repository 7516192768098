<script>
  import { fade } from "svelte/transition";
  export let page;

  let selectedStyle = page.profile_style || "classic";

  $: page.profile_style = selectedStyle;
</script>

<div class="w-full">
  <h4 class="text-lg font-bold text-gray-800 mb-8">Profile Style</h4>

  <div class="grid grid-cols-2 w-64 gap-6 justify-items-center">
    <!-- Classic Style -->
    <button
      class="relative group rounded-xl overflow-hidden transition-all duration-200 {selectedStyle ===
      'classic'
        ? 'ring-2 ring-purple-500 scale-[1.02]'
        : 'hover:scale-[1.02]'}"
      on:click={() => (selectedStyle = "classic")}
    >
      <div class="aspect-[4/5] w-full bg-gray-50 p-4">
        <div class="h-full flex flex-col items-center">
          <div
            class="w-12 h-12 rounded-full bg-purple-100 mb-3 mt-3 overflow-hidden"
          >
            <img
              src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=100&w=300"
              alt="Profile Demo"
              class="w-full h-full object-cover"
            />
          </div>
          <div class="w-20 h-3 bg-gray-300 rounded-full mb-2"></div>
          <div class="space-y-1 w-full">
            <div class="w-full h-3 bg-gray-200 rounded-full"></div>
          </div>
        </div>
      </div>
      <div
        class="absolute inset-0 bg-black/60 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity"
      >
        <span class="text-white font-medium text-sm">Classic Style</span>
      </div>
      {#if selectedStyle === "classic"}
        <div
          class="absolute top-2 right-2 bg-purple-500 text-white p-1.5 rounded-full"
          transition:fade
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      {/if}
    </button>

    <!-- Hero Style -->
    <button
      class="relative group rounded-xl overflow-hidden transition-all duration-200 {selectedStyle ===
      'hero'
        ? 'ring-2 ring-purple-500 scale-[1.02]'
        : 'hover:scale-[1.02]'}"
      on:click={() => (selectedStyle = "hero")}
    >
      <div class="aspect-[4/5] w-full bg-gray-50 relative">
        <div class="absolute inset-0 bg-purple-100">
          <img
            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=100&w=300"
            alt="Profile Demo"
            class="w-full h-full object-cover"
          />
          <div
            class="absolute inset-0 bg-gradient-to-b from-transparent to-white/90"
          ></div>
        </div>
        <div class="relative h-full flex flex-col items-center p-4">
          <div class="flex-1"></div>
          <div class="w-20 h-3 bg-gray-300 rounded-full mb-2"></div>
          <div class="space-y-1 w-full">
            <div class="w-full h-3 bg-gray-200 rounded-full"></div>
          </div>
        </div>
      </div>
      <div
        class="absolute inset-0 bg-black/60 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity"
      >
        <span class="text-white font-medium text-sm">Hero Style</span>
      </div>
      {#if selectedStyle === "hero"}
        <div
          class="absolute top-2 right-2 bg-purple-500 text-white p-1.5 rounded-full"
          transition:fade
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      {/if}
    </button>
  </div>

  <p class="mt-6 text-sm text-gray-600">
    {#if selectedStyle === "classic"}
      Classic style displays your profile in a traditional vertical layout with
      your profile image above your name and bio.
    {:else}
      Hero style showcases your profile image as a stunning background header,
      creating a more immersive and visually striking profile.
    {/if}
  </p>
</div>
