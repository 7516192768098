<script>
  import Logo from "$components/Logo.svelte";
  import Input from "$components/Input.svelte";
  import Button from "$components/Button.svelte";
  import { goto } from "$utils/goto";
  import { notify } from "$utils/notify";
  import { validateErrors } from "$utils/form";
  import Ajax from "$utils/ajax";

  export let pageUser;
  export let page;
  export let user;

  let password = "";
  let confirmPassword = "";
  let errors = {};
  let loading = false;

  async function handleSubmit(event) {
    if (password !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match.";
      return;
    }

    loading = true;
    errors = {};

    const [ajaxErrors, resp] = await Ajax.put(`/invites/${pageUser.token}`, {
      password,
    });

    if (ajaxErrors) {
      errors = validateErrors(errors, { password }, ajaxErrors);
    } else {
      notify({ message: "Invitation accepted successfully", type: "success" });
      goto("/dashboard");
    }

    loading = false;
  }
</script>

{#if page && user && pageUser}
  <div
    class="min-h-screen bg-gradient-to-br from-indigo-100 to-purple-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div class="flex justify-center">
        <Logo />
      </div>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
        <h2 class="text-center text-md font-extrabold text-gray-900">
          Accept Invitation
        </h2>
        <p
          class="mt-2 pb-6 mb-8 border-b border-gray-200 text-center text-sm text-gray-600">
          Join <span class="font-semibold">{page.profile_name}</span> on BioInk
        </p>

        <form class="space-y-6" on:submit|preventDefault={handleSubmit}>
          <Input
            label="New Password"
            type="password"
            id="password"
            name="password"
            required={true}
            bind:value={password}
            error={errors.password}
            on:keyup={() => {
              delete errors.password;
              errors = errors;
            }} />

          <Input
            label="Confirm Password"
            type="password"
            id="confirm-password"
            name="confirm-password"
            required={true}
            bind:value={confirmPassword}
            error={errors.confirmPassword}
            on:keyup={() => {
              delete errors.confirmPassword;
              errors = errors;
            }} />

          <div>
            <Button type="submit" fullWidth={true} {loading}>
              Accept Invitation
            </Button>
          </div>
        </form>
      </div>
    </div>
  </div>
{:else}
  <div
    class="flex flex-col items-center justify-center min-h-screen bg-gray-100">
    <div class="mb-8">
      <Logo />
    </div>
    <div class="max-w-md w-full bg-white shadow-md rounded-lg p-8">
      <h2 class="text-2xl font-bold text-center text-red-600 mb-6">
        Invalid Invitation Link
      </h2>
      <p class="text-gray-700 text-center mb-6">
        We're sorry, but the invitation link you've used is not valid, has
        expired or has already been used.
      </p>
      <p class="text-gray-600 text-center">
        If you believe this is an error, please contact our support team for
        assistance.
      </p>
      <div class="mt-8 text-center">
        <a href="/" class="text-indigo-600 hover:text-indigo-800 font-medium"
          >Return to Home</a>
      </div>
    </div>
  </div>
{/if}
