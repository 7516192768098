<script>
  export let lifetimeStats;
</script>

<h2 class="text-xl font-bold mb-4">Lifetime</h2>
<div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
  <div class="bg-white p-4 rounded-lg shadow">
    <div class="flex items-center justify-between sm:flex-col sm:items-start">
      <div class="flex items-center">
        <svg
          class="w-5 h-5 mr-2 text-blue-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          ><path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          ></path><path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
          ></path></svg
        >
        <h3 class="text-sm font-semibold text-gray-600">Views</h3>
      </div>
      <p class="text-xl font-bold sm:mt-2">
        {lifetimeStats.views.toLocaleString()}
      </p>
    </div>
  </div>
  <div class="bg-white p-4 rounded-lg shadow">
    <div class="flex items-center justify-between sm:flex-col sm:items-start">
      <div class="flex items-center">
        <svg
          class="w-5 h-5 mr-2 text-green-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          ><path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
          ></path></svg
        >
        <h3 class="text-sm font-semibold text-gray-600">Clicks</h3>
      </div>
      <p class="text-xl font-bold sm:mt-2">
        {lifetimeStats.clicks.toLocaleString()}
      </p>
    </div>
  </div>
  <div class="bg-white p-4 rounded-lg shadow">
    <div class="flex items-center justify-between sm:flex-col sm:items-start">
      <div class="flex items-center">
        <svg
          class="w-5 h-5 mr-2 text-yellow-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          ><path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
          ></path></svg
        >
        <h3 class="text-sm font-semibold text-gray-600">Click Rate</h3>
      </div>
      <p class="text-xl font-bold sm:mt-2">
        {lifetimeStats.clickThroughRate || 0}%
      </p>
    </div>
  </div>
  <div class="bg-white p-4 rounded-lg shadow">
    <div class="flex items-center justify-between sm:flex-col sm:items-start">
      <div class="flex items-center">
        <svg
          class="w-5 h-5 mr-2 text-purple-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          ><path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
          ></path></svg
        >
        <h3 class="text-sm font-semibold text-gray-600">Subscribers</h3>
      </div>
      <p class="text-xl font-bold sm:mt-2">
        {lifetimeStats.subscribers.toLocaleString()}
      </p>
    </div>
  </div>
</div>
