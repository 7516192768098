<script>
  import { onMount } from "svelte";
  import { getThemeFromId } from "$utils/themes";
  import { sendEvent } from "$utils/event";
  import {
    initializeAbly,
    subscribeToChannel,
    closeConnection,
  } from "$utils/ably";

  import PageLayout from "$layouts/Page.svelte";
  import ProfileHeader from "$app-components/ProfileHeader.svelte";
  import BlockViewer from "$app-components/BlockViewer.svelte";
  import SubscribeButton from "$app-components/SubscribeButton.svelte";
  import SharePage from "$app-components/SharePage.svelte";
  import ChatButton from "$app-components/chat/ChatButton.svelte";

  export let page;
  export let blocks;

  const theme = getThemeFromId(page).theme;

  // Get URL parameters
  const urlParams = new URLSearchParams(window.location.search);
  const showHeader = urlParams.get("header") !== "false";
  const showSocialLinks = urlParams.get("social_links") !== "false";
  const showChat = urlParams.get("chat") !== "false";

  $: directRedirectBlock = blocks?.find((block) => block.direct_redirect);

  let userId;

  try {
    userId = localStorage.getItem("userId");

    if (!userId) {
      userId = generateUserId();
      localStorage.setItem("userId", userId);
    }
  } catch (e) {
    userId = generateUserId();
  }

  function generateUserId() {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  }

  function pageViewedEvent() {
    sendEvent("profile:view", {
      page_id: page.id,
      referrer: document.referrer,
    });
  }

  async function subscribeToPage() {
    return await subscribeToChannel({
      channelName: page.realtime_token,
      eventName: "general",
      presence: true,
      callback: (message) => {
        new CustomEvent("page:realtime:message", {
          detail: message,
        });
      },
    });
  }

  async function subscribeToUser() {
    return await subscribeToChannel({
      channelName: `user:${userId}`,
      eventName: "general",
      presence: true,
      callback: (message) => {
        window.dispatchEvent(
          new CustomEvent("user:realtime:message", {
            detail: message,
          })
        );
      },
    });
  }

  async function redirectPageIfDirectRedirect() {
    if (directRedirectBlock) {
      blockViewed({ block_id: directRedirectBlock.id });
      blockClicked({ block_id: directRedirectBlock.id });
      setTimeout(() => (window.location.href = directRedirectBlock.url), 0);
    }
  }

  onMount(async () => {
    pageViewedEvent();
    redirectPageIfDirectRedirect();

    if (directRedirectBlock) {
      return;
    }

    await initializeAbly(userId);

    const unsubscribePage = await subscribeToPage();
    const unsubscribeUser = await subscribeToUser();

    return () => {
      unsubscribePage();
      unsubscribeUser();
      closeConnection();
    };
  });

  function blockClicked(data) {
    sendEvent("profile:block-click", {
      page_id: page.id,
      ...data,
    });
  }

  function blockViewed(data) {
    sendEvent("profile:block-view", {
      page_id: page.id,
      ...data,
    });
  }

  function socialLinkClicked(data) {
    sendEvent("profile:social-link-click", {
      page_id: page.id,
      ...data,
    });
  }
</script>

{#if directRedirectBlock}
  <div
    class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
  >
    <div
      class="bg-white p-6 rounded-lg shadow-lg max-w-xl w-full mx-4 transform transition-all duration-200"
    >
      <div class="flex items-center space-x-3 mb-4">
        <div class="bg-purple-100 p-2 rounded-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 text-purple-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 7l5 5m0 0l-5 5m5-5H6"
            />
          </svg>
        </div>
        <div>
          <h2 class="text-xl font-semibold text-gray-900">Redirecting...</h2>
          <p class="text-gray-500 text-sm">
            Just a moment while we take you there
          </p>
        </div>
      </div>

      <div class="bg-gray-50 rounded-lg p-4 border border-gray-200">
        <p class="text-sm text-gray-600 mb-2">You are being redirected to:</p>
        <a
          href={directRedirectBlock.url}
          class="text-base text-purple-600 hover:text-purple-800 break-all hover:underline flex items-center group"
        >
          <span class="mr-2">{directRedirectBlock.url}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 transform transition-transform group-hover:translate-x-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
            />
          </svg>
        </a>
      </div>

      <div class="mt-6 flex justify-center">
        <div class="flex items-center space-x-1">
          <div
            class="w-1.5 h-1.5 bg-purple-400 rounded-full animate-bounce"
          ></div>
          <div
            class="w-1.5 h-1.5 bg-purple-400 rounded-full animate-bounce delay-100"
          ></div>
          <div
            class="w-1.5 h-1.5 bg-purple-400 rounded-full animate-bounce delay-200"
          ></div>
        </div>
      </div>
    </div>
  </div>
{:else}
  <PageLayout {page}>
    <BlockViewer
      onActualPage={true}
      {blocks}
      page={{
        ...page,
        social_links: showSocialLinks ? page.social_links : [],
      }}
      {blockClicked}
      {blockViewed}
      {socialLinkClicked}
    >
      <svelte:fragment slot="header">
        {#if showHeader}
          {#if page.subscriptions_enabled}
            <SubscribeButton {page} />
          {/if}

          {#if page.share_page_enabled}
            <SharePage {page} />
          {/if}

          <ProfileHeader klass="mb-4" {page} />
        {/if}
      </svelte:fragment>
      <svelte:fragment slot="footer"></svelte:fragment>
    </BlockViewer>

    {#if page.chat_enabled && showChat}
      <ChatButton {page} {userId} />
    {/if}
  </PageLayout>
{/if}
